import {
  GET_ACTIVE_TAB,
  GET_MFO_NAME,
  GET_PROPERTY_NAME,
  GET_MARKETING_MATERIAL,
  CREATE_MARKETING_MATERIAL,
} from "../actionTypes";
import config from "../../../config";
import axios from "axios";

export const mfoNameAction = (data) => {
  return {
    type: GET_MFO_NAME,
    payload: data,
  };
};

export const propertyNameAction = (data) => {
  return {
    type: GET_PROPERTY_NAME,
    payload: data,
  };
};

export const activeTabAction = (data) => {
  return {
    type: GET_ACTIVE_TAB,
    payload: data,
  };
};

/**@description get all Properties
 */
// export const getMarketingMaterail = (mfoId, propertyId, data) => async (dispatch) => {
//   return new Promise((resolve) => {
//     axios
//       .get(
//         `${config.RestServiceURL}/marketing-materials/mfos/${mfoId}/properties/${propertyId}`,
//         { params: data }
//       )
//       .then((res) => {
//         const data = res.data;
//         dispatch({
//           type: GET_MARKETING_MATERIAL,
//           payload: data,
//         });
//         resolve(data);
//       })
//       .catch((err) => {
//         console.error("getMarketingMaterail", err.message);
//         resolve([]);
//       });
//   });
// };

export const getMarketingMaterialByCategories = (mfoId, propertyId, data) => async (dispatch) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${config.RestServiceURL}/marketing-materials/mfos/${mfoId}/properties/${propertyId}`,
        { params: data }
      )
      .then((res) => {
        const data = res.data;
        dispatch({
          type: GET_MARKETING_MATERIAL,
          payload: data,
        });
        resolve({ data, status: true });
      })
      .catch((err) => {
        console.error("getMarketingMaterialByCategories", err.response);
        resolve({
          status: false,
          message: err.response?.data?.message,
          statusCode: err.response?.data?.statusCode,
        });
      });
  });
}

/**@description Create Marketing Material
 */
export function createMarketingMaterial(data) {
  return new Promise((resolve) => {
    axios
      .post(`${config.RestServiceURL}/marketing-materials`, data)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, message: "Uploaded" });
        } else {
          resolve({ status: false, message: "Failed to upload" });
        }
      })
      .catch((err) => {
        console.error("uploadMarketingMaterial", err.response);
        resolve(null);
      });
  });
}

export function updateMarketingMaterial(marketingMaterialId, data) {
  return new Promise((resolve) => {
    axios.put(`${config.RestServiceURL}/marketing-materials/${marketingMaterialId}`, data)
    .then((res) => {
      if(res.status === 200 || res.status === 201) {
        resolve({status: true, data: res.data})
      } else {
        resolve({status: false})
      }
    })
    .catch((err) => {
      resolve({status: false, message: err.response?.data?.message})
    })
  })
}

export const deleteMarketingMaterial = (marketingMaterialId, imageIds) => {
  return new Promise((resolve) => {
    axios
      .delete(
        `${config.RestServiceURL}/marketing-materials/${marketingMaterialId}`,
        { data: imageIds }
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, message: "deleted" });
        } else {
          resolve({ status: false, message: "Failed to delete" });
        }
      })
      .catch((err) => {
        console.error("deleteMarketingMaterial", err);
        resolve(false);
      });
  });
};


export const getMarketingMaterailById = (propertyId) => {
  return new Promise((resolve) => {
    axios.get(`${config.RestServiceURL}/marketing-materials/properties/${propertyId}`).then((res) => {
      resolve({ data: res.data, status: true });
    }).catch((err) => {
      console.error("deleteMarketingMaterial", err);
      resolve(false);
    })
  })
}