import axios from "axios";
import { GET_TENANT, GET_TENANTS, OVERVIEW } from "./actionTypes";
import config from "../../config";

/**@description get all tenant
 */
export const getTenants = (id) => (dispatch) => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/properties/${id}/tenants`)
      .then((res) => {
        dispatch({
          type: GET_TENANTS,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((err) => {
        console.error("getTenants", err.response);
        resolve([]);
      });
  });
};

/**@description get a tenant
 */
export const getTenant = (id) => ({
  type: GET_TENANT,
  payload: id,
});

/**@description get a marketing material of a property
 */
export const getPropertyOverview = (pId) => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/properties/${pId}/overview`)
      .then((res) => {
        res.data._id = pId;
        resolve(res.data);
      })
      .catch((err) => {
        console.error("getPropertyOverview", err);
        resolve({ _id: pId });
      });
  });
};

/**@description get overview data
 */
export const getOverviewData = (pId) => (dispatch) => {
  return new Promise(async (resolve) => {
    const data = await getPropertyOverview(pId);
    dispatch({
      type: OVERVIEW,
      payload: data,
    });
    resolve(data);
  });
};

/**@description get overall overview data
 */
export const getOverallOverview = () => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/overview`)
      .then((res) => {
        res.data._id = "overall";
        resolve(res.data);
      })
      .catch((err) => {
        console.error("getOverallOverview", err);
        resolve({ _id: "overall" });
      });
  });
};

export const getPropertyMarketingMaterial = (pId) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${config.RestServiceURL}/marketing-materials/properties/${pId}/download`,
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        resolve({ status: true, data: res.data });
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};
/**@description download media kit
 */
export const downloadMediaKit = () => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/overview/media-kit/download`,
      {
        responseType: "blob",
      })
      .then((res) => {
        resolve({ status: true, data: res.data });
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description create a tenant
 */
export const createTenant = (tenant) => {
  return new Promise((resolve) => {
    axios
      .post(`${config.RestServiceURL}/tenants`, tenant)
      .then((res) => {
        resolve({ status: true });
      })
      .catch((err) => {
        console.error("createTenant", err.data);
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description create multiple tenants
 */
export const createMultipleTenants = (tenantsData) => {
  return new Promise((resolve) => {
    axios
      .post(`${config.RestServiceURL}/tenants/multiple`, tenantsData)
      .then((res) => {
        resolve({ status: true });
      })
      .catch((err) => {
        console.error("createMultipleTenants", err.data);
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description update a tenant
 */
export const updateTenant = (tenant) => {
  return new Promise((resolve) => {
    axios
      .put(`${config.RestServiceURL}/tenants/${tenant._id}`, tenant)
      .then((res) => {
        resolve({ status: true });
      })
      .catch((err) => {
        console.error("updateTenant", err.data);
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description delete a tenant
 */
export const deleteTenant = (id) => {
  return new Promise((resolve) => {
    axios
      .delete(`${config.RestServiceURL}/tenants/${id}`)
      .then((res) => {
        resolve(true);
      })
      .catch((err) => {
        console.error("deleteTenant", err.data);
        resolve(false);
      });
  });
};
