import { IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import CustomButton from "../../../components/custom-button/CustomButton";
import Loader from "../../../components/loader/Loader";
import TenantListEmpty from "../../../components/tenant-list-empty/TenantListEmpty";
import {
  addRooms,
  addStorageFacility,
  deleteMultipleFacilities,
  getAllStorageFacilities,
  getAPIKey,
  getFacilityRooms,
  updateStorageFacilityDetails,
} from "../../../redux/actions/territory-manager-actions/territoryManager";
import {
  characterCheckRegex,
  decryptKey,
  excludeSpace,
  getFormattedPhoneNum,
  reformatPhoneNumber,
} from "../../../utils/helperMethods";
import {
  COUNTRY_CODE,
  STORAGE_FACILITY_HEADINGS,
  STORAGE_FACILITY_MODAL_DATA,
} from "../../Constants";
import TerritoryTable from "../territoryTable";
import AddRooms from "./addRoomsModal";
import "./storagefacilities.scss";
import StorageModal from "./storageModal";

const StorageFacilities = () => {
  const [selected, setSelected] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [statusModal, setStatusModal] = useState({});
  const [isAdd, setisAdd] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [tableHeadings, settableHeadings] = useState([
    ...STORAGE_FACILITY_HEADINGS,
  ]);
  const [storageFacilityData, setstorageFacilityData] = useState([]);
  const [facilityModaldata, setfacilityModalData] = useState([
    ...STORAGE_FACILITY_MODAL_DATA,
  ]);
  const [showRoomModal, setshowRoomModal] = useState(false);
  const [googleAddress, setgoogleAddress] = useState({});
  const [address, setAddress] = useState("");
  const [roomData, setRoomData] = useState([]);
  const [facilityId, setfacilityId] = useState("");
  const [isfacilityDisable, setisfacilityDisable] = useState(true);
  const [apiKey, setApikey] = useState("");
  const [emptyTab, setEmptyTab] = useState(false);
  const [addRoomData, setAddRoomData] = useState({});
  const [isDisable, setisDisable] = useState(true);
  const [isAddMore, setisAddMore] = useState(false);
  const [availableRooms, setAvailableRooms] = useState([]);

  useEffect(async () => {
    getAllStoredData();
    getAPIData();
  }, []);

  const getAPIData = async () => {
    const { data } = await getAPIKey();
    const googleApiKey = decryptKey(data?.googleApiKey);
    setApikey(googleApiKey);
  };
  /* GET ALL Storage Facilities Data */
  const getAllStoredData = async () => {
    setShowLoader(true);
    const { data, message } = await getAllStorageFacilities();
    (data || []).map((data) => {
      data.contactNumber = getFormattedPhoneNum(data.contactNumber.slice(-10));
    });
    if (message) {
      setEmptyTab(true);
    }
    setstorageFacilityData([...data]);
    setShowLoader(false);
  };

  facilityModaldata.map((data) => {
    const addressLine = `${googleAddress?.city}, ${googleAddress?.state}, ${googleAddress?.zipcode}`;
    if (["city", "state", "zipcode", "address"].includes(data.key)) {
      if (data.key == "city") {
        data.value = googleAddress?.city ? googleAddress?.city : "";
      } else if (data.key === "state") {
        data.value = googleAddress?.state ? googleAddress?.state : "";
      } else if (data.key === "zipcode") {
        data.value = googleAddress?.zipcode ? googleAddress?.zipcode : "";
      } else {
        data.value = addressLine ? addressLine : "";
      }
    }
  });

  const handleChange = ({ key, value }) => {
    const index = facilityModaldata.findIndex((data) => data.key === key);
    const userItem = facilityModaldata[index];

    if (["facilityName", "contactNumber"].includes(userItem.key)) {
      if (userItem.key === "contactNumber") {
        userItem.value = getFormattedPhoneNum(value);
      } else {
        userItem.value = excludeSpace(value);
      }
      userItem.hasError =
        (userItem.value || "").trim().length < userItem.minLength &&
        value.length > 0;
      const inputType = userItem.type === "numeric" ? "digits" : "characters";
      userItem.errorMessage = userItem.hasError
        ? `${userItem.title} must be ${
            key === "contactNumber"
              ? userItem.minLength - 4
              : userItem.minLength
          } ${inputType}`
        : "";

      setfacilityModalData([...facilityModaldata]);
    }

    if (userItem.key === "pricePerCuft") {
      if (value <= userItem.minValue) {
        userItem.value = value;
        userItem.hasError = value.length < 0 ? false : true;
        userItem.errorMessage = `Enter valid price`;
      } else {
        userItem.value = value;
        userItem.hasError = false;
      }
    }
    setfacilityModalData([...facilityModaldata]);
  };

  const getRoomsData = async () => {
    setShowLoader(true);
    const { data: roomsData } = await getFacilityRooms(
      selectedData?.facilityId
    );
    setShowLoader(false);
    setAvailableRooms([...roomsData]);
  };

  const handleAddrooms = async () => {
    setisAdd(false);
    setisAddMore(true);
    setshowRoomModal(true);
    getRoomsData();
  };
  const handleAddRooms = async () => {
    setShowLoader(true);
    const facilityID = isAdd ? facilityId : selectedData.facilityId;
    const { message, status } = await addRooms(roomData, facilityID);
    if (status) {
      handleShowModal({
        cancelText: "Ok",
        title: status ? `Success` : `Failed`,
        message: status
          ? `${
              isAdd ? "Storage facility created and" : ""
            } Rooms added successfully!`
          : `${message}`,
        isAlert: true,
      });
      setRoomData([]);
      if (isAdd) {
        setisAddMore(false);
        setShowModal(false);
        setshowRoomModal(false);
      }
      getRoomsData();
      getAllStoredData();
    } else if (message) {
      handleShowModal({
        cancelText: "Ok",
        title: message && `Failed`,
        message: message,
        isAlert: true,
      });
    }
    setShowLoader(false);
  };

  const handleSelection = async (data) => {
    const selectedData = storageFacilityData.filter((storageData) => {
      return data.includes(storageData._id);
    });
    setSelected(data);
    selectedData.map((data) => {
      setSelectedData({
        facilityName: data.facilityName,
        contactNumber: data.contactNumber,
        facilityId: data._id,
        pricePercuft: data.pricePerCuft,
      });
    });
  };
  const clearInput = () => {
    facilityModaldata.forEach((data) => {
      data.value = "";
    });
    googleAddress.state = "";
    googleAddress.city = "";
    googleAddress.zipcode = "";
    setRoomData([]);
    setAddRoomData({});
    setfacilityModalData([...facilityModaldata]);
  };
  const handleAddFacility = () => {
    setisAdd(true);
    setShowModal(true);
  };
  const handleRemoveIcon = () => {
    setshowRoomModal(false);
    setShowModal(false);
    clearInput();
    facilityModaldata.map((data) => (data.value = ""));
    setfacilityModalData([...facilityModaldata]);
  };

  /**@description Handle hide Model Functionality
   */
  const handleHideModal = () => {
    statusModal.show = false;
    setStatusModal({ ...statusModal });
  };

  /**@description Handle Show Model Functionality
   */
  const handleShowModal = ({
    cancelText,
    confirmText,
    isAlert,
    title,
    message,
    handleConfirm = () => {},
  }) => {
    statusModal.show = true;
    statusModal.cancelText = cancelText || "Cancel";
    statusModal.confirmText = confirmText || "Ok";
    statusModal.isAlert = isAlert || false;
    statusModal.title = title || "";
    statusModal.message = message || "";
    statusModal.handleConfirm = handleConfirm;
    statusModal.handleCancel = handleHideModal;
    setStatusModal({ ...statusModal });
  };

  const handleAddStorageFacility = async () => {
    const updatedFacilityData = {};
    facilityModaldata.forEach((data) => {
      if (data.key === "contactNumber") {
        updatedFacilityData[data.key] = `${COUNTRY_CODE}${reformatPhoneNumber(
          data.value
        )}`;
      } else if (data.key === "facilityName" || isAdd) {
        updatedFacilityData[data.key] = data.value;
      } else if (data.key === "pricePerCuft" || isAdd) {
        updatedFacilityData[data.key] = data.value;
      }
    });
    const { data, message, status } = isAdd
      ? await addStorageFacility(updatedFacilityData)
      : await updateStorageFacilityDetails(
          selectedData?.facilityId,
          updatedFacilityData
        );
    if (isAdd) {
      if (status) {
        setshowRoomModal(true);
        setfacilityId(data._id);
        setisAddMore(false);
        clearInput();
      } else if (message) {
        handleShowModal({
          cancelText: "Ok",
          title: message && `Failed`,
          message: "Failed to create Storage facility!, please try again",
          isAlert: true,
        });
      }
    } else {
      handleShowModal({
        cancelText: "Ok",
        title: status ? `Success` : `Failed`,
        message: status
          ? `Facility details updated successfully!`
          : `Failed to Update the Facility Details!, please try again.`,
        isAlert: true,
      });
      setSelected([]);
      setShowModal(false);
    }
    getAllStoredData();
  };
  const handleDeleteFacility = async () => {
    setShowLoader(true);
    const { status, message } = await deleteMultipleFacilities(selected);
    setShowLoader(false);
    const facilityName = `Storage facilit${selected.length > 1 ? "ies" : "y"}`;
    handleShowModal({
      cancelText: "Ok",
      title: status ? `Success` : `Failed`,
      message: status ? `${facilityName} deleted successfully!` : `${message}`,
      isAlert: true,
    });
    setSelected([]);
    getAllStoredData();
  };

  const handleShowDeleteModal = () => {
    if (selected?.length) {
      handleShowModal({
        confirmText: "Delete",
        title: `Delete facilit${selected.length > 1 ? "ies" : "y"}`,
        message: `Are you sure you want to delete the selected facilit${
          selected.length > 1 ? "ies" : "y"
        }?`,
        handleConfirm: handleDeleteFacility,
      });
    }
  };

  const handleEditFacility = () => {
    facilityModaldata.map((data) => {
      if (data.key === "facilityName") {
        data.value = selectedData.facilityName;
      } else if (data.key === "contactNumber") {
        data.value = selectedData.contactNumber;
      } else if (data.key === "pricePerCuft") {
        data.value = selectedData.pricePercuft;
      }
    });
    setfacilityModalData([...facilityModaldata]);
    setShowModal(true);
    setisAdd(false);
  };
  const displayingStorageTable = () => {
    return (
      <div className='table'>
        <TerritoryTable
          tableHeading={tableHeadings}
          rows={[...storageFacilityData]}
          tablecellpadding={"10.5vw"}
          minHeight={"24vh"}
          maxHeight={"46vh"}
          selected={selected}
          handleSelection={handleSelection}
        />
        <div
          className='addfacilityStyle'
          role='presentation'
          onClick={() => {
            handleAddFacility();
          }}
        >
          <IconButton
            aria-label='upload picture'
            component='span'
            className='addbutton'
          >
            <img src={"/images/shared/plus.svg"} alt='PlusIcon' />
          </IconButton>
        </div>
      </div>
    );
  };
  const displayingDeletebutton = () => {
    return (
      <CustomButton
        title={"Delete"}
        className={"storagefacilityDelete"}
        handleButtonClick={() => handleShowDeleteModal()}
        disabled={selected.length ? false : true}
      />
    );
  };

  const displayingEditButton = () => {
    return (
      <CustomButton
        title={"Edit"}
        className={"storagefacilityEdit"}
        handleButtonClick={() => handleEditFacility()}
        disabled={selected.length === 1 ? false : true}
      />
    );
  };

  const displayingAddRooms = () => {
    return (
      <CustomButton
        title={"Add room"}
        className={"storagefacilityAdd"}
        handleButtonClick={() => handleAddrooms()}
        disabled={selected.length === 1 ? false : true}
      />
    );
  };
  return (
    <div className='storageFacilityOverview'>
      {emptyTab ? (
        <>
          <div className={"tenantListEmpty"}>
            <TenantListEmpty
              width={"75%"}
              header='You have no storage facilties added to your list yet'
              canAdd={true}
              buttonText={"Add New Storage Facilities"}
              handleButtonClick={handleAddFacility}
            />
          </div>
        </>
      ) : (
        <>
          <div className='facilityButtons'>
            {displayingAddRooms()} {displayingEditButton()}{" "}
            {displayingDeletebutton()}
          </div>
          {displayingStorageTable()}

          {statusModal?.show ? (
            <ConfirmModal
              cancelText={statusModal.cancelText}
              confirmText={statusModal.confirmText}
              isAlert={statusModal.isAlert}
              title={statusModal.title}
              message={statusModal.message}
              handleCancel={statusModal.handleCancel}
              handleConfirm={statusModal.handleConfirm}
            />
          ) : null}
          <Loader show={showLoader} />
        </>
      )}

      {showModal ? (
        <StorageModal
          open={true}
          setShowModal={setShowModal}
          handleRemoveIcon={handleRemoveIcon}
          isAdd={isAdd}
          storageFacilityData={!isAdd ? selectedData : ""}
          handleChange={handleChange}
          facilityModaldata={facilityModaldata}
          handleAddrooms={handleAddrooms}
          handleAddStorageFacility={handleAddStorageFacility}
          setgoogleAddress={setgoogleAddress}
          setAddress={setAddress}
          address={address}
          googleAddress={googleAddress}
          handleAddRooms={handleAddRooms}
          roomData={roomData}
          setRoomData={setRoomData}
          isfacilityDisable={isfacilityDisable}
          apiKey={apiKey}
          addRoomData={addRoomData}
          setAddRoomData={setAddRoomData}
          setisDisable={setisDisable}
          isDisable={isDisable}
        />
      ) : null}

      {showRoomModal && (
        <AddRooms
          open={true}
          roomData={roomData}
          addRoomData={addRoomData}
          setAddRoomData={setAddRoomData}
          setisDisable={setisDisable}
          isDisable={isDisable}
          handleRemoveIcon={handleRemoveIcon}
          isAddMore={isAddMore}
          setRoomData={setRoomData}
          handleAddRooms={handleAddRooms}
          availableRooms={availableRooms}
          handleShowModal={handleShowModal}
          handleShowDeleteModal={handleShowDeleteModal}
          setShowLoader={setShowLoader}
          getRoomsData={getRoomsData}
          facilityID={selectedData?.facilityId}
        />
      )}
    </div>
  );
};
export default StorageFacilities;
