import React, { useState, useEffect } from "react";
import { Box, Modal } from "@mui/material";
import { ADDDRIVER_BOX_STYLE } from "../../../components/Constants";
import CustomInput from "../../../components/custom-input/CustomInput";
import Loader from "../../../components/loader";
import { ADD_DRIVER_ADDRESS } from "../../Constants";
import CustomButton from "../../../components/custom-button/CustomButton";
import "./addDrivers.scss";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { getAPIKey } from "../../../redux/actions/territory-manager-actions/territoryManager";
import { decryptKey } from "../../../utils/helperMethods";
import { geocodeByAddress } from "react-google-places-autocomplete";


const EditDriverAddress = (props) => {
  const [googleKey, setGoogleKey] = useState("");
  const [addressValue, setaddressValue] = useState("");
  const [googleAddress, setgoogleAddress] = useState({});
  const [isGoogleAddress, setIsGoogleAddress] = useState(false);

  
  const { open } = props;
  useEffect(async () => {
    await getAPIData()
  }, [])

  const getAPIData = async () => {
    const { data } = await getAPIKey();
    const googleApiKey = decryptKey(data?.googleApiKey);
    setGoogleKey(googleApiKey);
  };;

  const createDriver = () => {
    return (
      <div>
        <img
          src="/images/shared/driver.png"
          alt="Delete Icon"
          className="driverIcon"
        />
      </div>
    );
  };
  const handleSelect = (gAddress) => {
    const address = gAddress.label;
    geocodeByAddress(address)
      .then((results) => {
        const googleAddress = (results[0].address_components || [])
          .map((x) => x)
          .filter((data) =>
            ["street_number", "route", "administrative_area_level_1", "locality", "postal_code", "sublocality_level_1", "sublocality_level_2"].includes(
              data.types[0]
            )
          );

        const dta = {};
        googleAddress?.map((data) => {
          if (data.types[0] === "administrative_area_level_1") {
            dta.state = data.long_name;
          } else if (data.types[0] === "locality") {
            dta.city = data.long_name;
          } else if (data.types[0] === "postal_code") {
            dta.zipCode = data.long_name;
          } else if (data.types[0] === "street_number" || data.types[0] === "route") {

            if (!dta.area) {
              dta.area = data.long_name;
            } else {
              if (data.types[0] === "street_number") {
                dta.area = data.long_name + " " + dta.area;
              } else {
                dta.area = dta.area + " " + data.long_name;
              }
            }
          }
        });
        if(!dta.area){
           const localityInfo = (results[0].address_components || [])
          .map((x) => x)
          .filter((data) =>
            ["sublocality_level_1", "sublocality_level_2"].includes(
              data.types.at(-1)
            )
          );
          localityInfo.forEach((data)=> {
            if(!dta.area){
              dta.area = data?.long_name
            }else{
              dta.area = dta.area+" "+ data?.long_name;
            }
          })
        }
        setaddressValue(address);
        setgoogleAddress({ ...dta, address: address });
        setIsGoogleAddress(true);
      })
      .catch((error) => console.error("Error", error));
  };

  const displaySubmitHandle = () => { 
    return (
      <CustomButton
        title={props.isAdd?"Add Driver Address":"Update Driver Address"}
        className={"driverpopupButton"}
        sx={{ width: "100%" }}
        handleButtonClick={() => props.handleSubmit()}
      />
    );
  };

  const displyInputFiled = () => (

    !googleKey ? (<Loader show={true} />) : (<div>
      <GooglePlacesAutocomplete
        apiKey={googleKey}
        placeholder='Enter your address'
        selectProps={{
          addressValue,
          onChange: handleSelect,
          placeholder: "Enter Address",
          onLoadFailed: (error) => { console.log(error) },
          className: "pac-container",
          styles: {
            control: (provided) => ({
              ...provided,
              border: "none",
              borderBottom: '1px solid grey',
            }),
            input: (provided) => ({
              ...provided,
              color: "#000",
              marginTop:"12px",
              marginBottom:"12px",
              fontSize: "16px", 
            }),

            option: (provided) => ({
              ...provided,
              color: "#000",
              fontSize: "20px", 
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "#000",
              fontSize: "16px", 
            }),
          },
        }}
      />

      {ADD_DRIVER_ADDRESS.map((dataItem) => {
        dataItem.value = "";
        dataItem.value = googleAddress[dataItem.key] ? googleAddress[dataItem.key] : (!props.isAdd && !isGoogleAddress ? props.driverAddress?.[dataItem.key]:"");
        return ((!props.driverAddress && dataItem.key !== "address" && !isGoogleAddress) || (dataItem.key === 'zipCode' || (dataItem.key !== "address" && ((!isGoogleAddress && dataItem.value) || googleAddress[dataItem.key]))))&&(
          <div
            className="driverInputStyle"
            key={`displayInputField${dataItem.key}`}
          >
            <CustomInput
              variant="standard"
              id="standard-error-helper-text"
              dataItem={{ ...dataItem }}
              value={dataItem.value ? dataItem.value : ""}
              additionalSx={{ width: "100%", marginTop: "40px" }}
              required={true}
              disabled={addressValue?false:true}
              autoComplete={true}
            />
          </div>
        );
      })}
      <div style={{ marginTop: "40px" }}>{displaySubmitHandle()}</div>
    </div>)
  );

  const displayingmodalRemoveIcon = () => {
    return (
      <div
        className="drivermodalRemoveIcon"
        onClick={() => props.handleRemoveIcon()}
      >
        X
      </div>
    );
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        <Box sx={ADDDRIVER_BOX_STYLE}>
          <div className="mainContent">{createDriver()}</div>
          {!props.showDriverModal && displyInputFiled()}
        </Box>
        {displayingmodalRemoveIcon()}
      </div>
    </Modal>
  );
};

export default EditDriverAddress;
