import { combineReducers } from "redux";
import propertyListReducer from "./propertyListReducer";
import tenantsListReducer from "./tenantsListReducer";
import usersReducer from "./usersReducer";
import userLoginReducer from "./userLoginReducer";
import UpdatePassword from "./Updatepassword";
import propertyManagerReducer from "./propertyManagerListReducer";
import userSignupReducer from "./UsersignupReducer";
import adminZipcodesreducer from "./adminZipcodesreducer"
import marketingMaterialReducer from "./marketingMaterialReducer";

const appReducer = combineReducers({
  tenantsListReducer: tenantsListReducer,
  usersReducer: usersReducer,
  propertyListReducer: propertyListReducer,
  login: userLoginReducer,
  userSignupReducer: userSignupReducer,
  updatePassword: UpdatePassword,
  propertyManagerReducer: propertyManagerReducer,
  serviceableData: adminZipcodesreducer,
  marketingMaterialReducer: marketingMaterialReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
