import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../../components/layout";
import { Roles } from "../../config";
import { getRole } from "../../utils/authentication";
import { BOX_STYLE, MEDIAKIT_BOX_STYLE } from "../../components/Constants";
import {
  createTenant,
  deleteTenant,
  getTenants,
  updateTenant,
  createMultipleTenants,
  getOverviewData,
  getPropertyOverview,
  getOverallOverview,
} from "../../redux/actions/tenantsListActions";
import { getProperties } from "../../redux/actions/propertyListActions";
import Loader from "../../components/loader";
import PropertyDetails from "../property-details/PropertyDetails";
import OverviewHub from "./overview-hub/OverviewHub";
import TenantList from "./tenants-list/Tenants";
import Overview from "../manager-details/overview/Overview";
import ManagerDetails from "../manager-details/ManagerDetails";
import { getPropertyMarketingmaterials } from "../../redux/actions/manager-actions/managerActions";
import { fileTabs } from "../Constants";
import { Link } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { downloadURI } from "../../utils/helperMethods";
import "./MfoOverview.scss";
import { useRef } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { getAPIkeys } from "../../redux/actions/manager-actions/managerActions";
import { decryptKey } from "../../utils/helperMethods";
import CustomModal from "../../components/custom-modal/CustomModal";
import MarketingMaterials from './marketing-materials/MarketingMaterials';

const overallHub = { name: "Overview Hub", _id: "overall" };

const marketingMaterialTab = { name: 'Marketing Materials', _id: 'marketingMaterials' };

function Home() {
  const tenantsData = useSelector((state) => state.tenantsListReducer.tenants);
  const overviewData = useSelector(
    (state) => state.tenantsListReducer.overview
  );
  const pData = useSelector((state) => state.propertyListReducer.properties);
  const isPropertyManager = getRole() === Roles.manager;
  const [overallOverview, setOverallOverview] = useState([]);
  const [properties, setProperties] = useState([...pData]);
  const [activeProperty, setActiveProperty] = useState(overallHub);
  const [tenants, setTenantsData] = useState(tenantsData);
  const [overview, setOverview] = useState(overviewData);
  const [showLoader, setShowLoader] = useState(false);
  const [showTenants, setShowTenants] = useState(false);
  const [showProperty, setShowProperty] = useState(false);
  const [showManagerDetails, setShowManagerDetails] = useState(false);
  const [managerProps, setManagerProps] = useState({});
  const [propertyProps, setPropertyProps] = useState({});
  const [tenantProps, setTenantProps] = useState({});
  const [managerTasks, setmanagerTasks] = useState([]);
  const dispatch = useDispatch();
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [marketingMaterial, setMarketingMaterial] = useState(marketingMaterialTab);
  const [mediaKit, setMediaKit] = useState([]);
  const [decryptPropID, setdecryptPropID] = useState();
  const [decryptWidgetID, setdecryptWidgetID] = useState();

  const fetchOverallOverview = async (propertiesData) => {
    const requests = propertiesData.map((property) =>
      getPropertyOverview(property._id)
    );
    await Promise.all([getOverallOverview(), ...requests]).then((res) => {
      setOverallOverview([...res]);
      dispatch({
        type: "OVERALL_OVERVIEW",
        payload: res,
      });
    });
    setShowLoader(false);
  };

  const fetchProperties = async () => {
    setShowLoader(true);
    const { data } = await getAPIkeys();

    const propertyId = data?.propertyId;
    const widgetId = data?.widgetId;
    const decryptPropID = decryptKey(propertyId);
    const decryptWidgetID = decryptKey(widgetId);

    setdecryptPropID(decryptPropID);
    setdecryptWidgetID(decryptWidgetID);

    const propertiesData = properties?.length
      ? properties
      : await dispatch(getProperties());
    setProperties([...propertiesData]);
    setActiveProperty({ ...overallHub });
    fetchOverallOverview(propertiesData);
  };

  const fetchPropertiesData = async () => {
    const propertiesData = await dispatch(getProperties());

    if (!propertiesData?.length) {
      setActiveProperty({ ...overallHub });
    }
    setProperties([...propertiesData]);

    return propertiesData;
  };

  const fetchTenantData = async (property) => {
    const propertyId = property?._id || activeProperty?._id;

    if (propertyId && propertyId !== "overall") {
      setShowLoader(true);
      const tData = await dispatch(getTenants(propertyId));
      const oData = await dispatch(getOverviewData(propertyId));
      setOverview({ ...oData });
      setTenantsData([...tData]);

      setShowLoader(false);
    } else {
      fetchProperties();
    }
  };

  const handlePropertyChange = (tab) => {
    setActiveProperty(tab);
    fetchTenantData(tab);
  };

  const handleHideTenants = () => {
    setShowTenants(false);
  };

  const handleHideProperty = () => {
    setShowProperty(false);
  };

  const handleHideManager = () => {
    setShowManagerDetails(false);
  };

  const handleUpdateTenant = async (updatedTenantData) => {
    setShowLoader(true);
    const { status, message } = await updateTenant(updatedTenantData);
    if (status) {
      fetchTenantData();
    } else {
      setShowLoader(false);
    }
    return { status, message };
  };

  const handleAddTenant = async (addTenantData, isMulti) => {
    setShowLoader(true);
    const { status, message } = isMulti
      ? await createMultipleTenants(addTenantData)
      : await createTenant(addTenantData);
    if (status) {
      fetchTenantData();
    } else {
      setShowLoader(false);
    }
    return { status, message };
  };

  const handleDeleteTenant = async (deleteIds) => {
    setShowLoader(true);
    let deletedCount = 0;
    const deleteRequests = deleteIds.map((tenantId) => deleteTenant(tenantId));
    await Promise.all(deleteRequests)
      .then((responses) => {
        responses.forEach((res) => {
          if (res) deletedCount += 1;
        });
      })
      .catch((err) => {
        console.error("handleDeleteTenant", err);
      });
    if (deletedCount) fetchTenantData();
    else setShowLoader(false);
    return deletedCount;
  };

  const handleEditProperty = () => {
    setShowProperty(true);
    setPropertyProps({
      editPropertyData: activeProperty || {},
      isAdd: false,
      showAddProperty: true,
      selected: [activeProperty._id],
    });
  };

  const handleViewProperties = () => {
    setShowProperty(true);
    setPropertyProps({
      editPropertyData: {},
      isAdd: false,
      showAddProperty: false,
      selected: [],
    });
  };

  const handleViewManagerDetails = () => {
    setShowManagerDetails(true);
    setManagerProps({
      editManagerData: {},
      isAdd: false,
      showAddManager: false,
      selected: [],
    });
  };

  const handleAddProperty = () => {
    setShowProperty(true);
    setPropertyProps({
      editPropertyData: {},
      isAdd: true,
      showAddProperty: true,
      selected: [],
    });
  };

  const handleViewTenants = () => {
    setShowTenants(true);
    setTenantProps({
      editTenantData: {},
      isAdd: false,
      showAddTenant: false,
      selected: [],
    });
  };

  const handleDownloadFile = (fileURL) => {
    downloadURI(fileURL, "file");
  };
  const tawkMessengerRef = useRef();

  const toggleChat = () => {
    tawkMessengerRef.current.toggleVisibility();
  };

  const onLoad = () => {
    toggleChat();
  };

  useEffect(() => {
    fetchProperties();
    return () => {
      if (!window.Tawk_API?.isChatHidden()) {
        window.Tawk_API.minimize();
        window.Tawk_API.toggleVisibility();
      }
    };
  }, []);

  // let marketingMaterial = [];
  // let SocialMedia = [];
  // let newsLetter = [];
  // const handleMediakitModal = async (propertyId) => {
  //   const { status, data } = await getPropertyMarketingmaterials(propertyId);
  //   setMediaKit([...data]);
  //   setShowMediaModal(true);
  // };

  // mediaKit.map((data) => {
  //   if (data.type === "LAUNCH_DOCS") {
  //     marketingMaterial.push({ ...data });
  //   } else if (data.type === "NEWS_LETTER") {
  //     newsLetter.push({ ...data });
  //   } else if (data.type === "SOCIAL_MEDIA") {
  //     SocialMedia.push({ ...data });
  //   }
  // });

  // const displayFiletabs = () => {
  //   return (
  //     <Tabs
  //       value={activeTab}
  //       onChange={(e, val) => {
  //         setActivetab(val);
  //       }}
  //       textColor='primary'
  //       indicatorColor='primary'
  //       aria-label='primary tabs example'
  //     >
  //       {fileTabs.map((tab, index) => (
  //         <Tab
  //           value={tab.value}
  //           label={tab.title}
  //           sx={{
  //             fontFamily: "Poppins",
  //             textDecoration: "CamelCase",
  //             textTransform: "initial",
  //             fontSize: "1.125vw",
  //             padding: "20px",
  //             marginRight: "20px",
  //           }}
  //         />
  //       ))}
  //     </Tabs>
  //   );
  // };

  // const mediaKitdata =
  //   activeTab === "marketingMaterial"
  //     ? marketingMaterial
  //     : activeTab === "newsLetter"
  //     ? newsLetter
  //     : activeTab === "socialMedia" && SocialMedia;

  // const tabName =
  //   activeTab === "socialMedia"
  //     ? "Social Media Assets"
  //     : activeTab === "marketingMaterial"
  //     ? "Marketing Material"
  //     : "News Letter";
  // const displayMaterialData = () => {
  //   return mediaKitdata.length > 0 ? (
  //     (mediaKitdata || []).map((data) => (
  //       <div className='mediaKitStyle'>
  //         <Link
  //           href={data.imageUrl}
  //           style={{ marginRight: "2px" }}
  //           onClick={() => handleDownloadFile(data.imageUrl)}
  //         >
  //           {data?.image?.Key.slice(45)}
  //         </Link>
  //         <FileDownloadIcon
  //           sx={{ color: "#198eba", cursor: "pointer" }}
  //           onClick={() => handleDownloadFile(data.imageUrl)}
  //         />
  //       </div>
  //     ))
  //   ) : (
  //     <div className='mediaNotavailableStyle'>{`${tabName} are Not Available`}</div>
  //   );
  // };

  // const handleRemoveIcon = () => {
  //   setShowMediaModal(false);
  // };

  // const displayingmodalRemoveIcon = () => {
  //   return (
  //     <div
  //       className='drivermodalRemoveIcon'
  //       style={{ right: "24%", top: "16%" }}
  //       onClick={() => handleRemoveIcon()}
  //     >
  //       X
  //     </div>
  //   );
  // };

  return (
    <Layout
      activePage="overview"
      handleTabChange={(tab) => {
        handlePropertyChange(tab);
      }}
      tabsData={[overallHub, marketingMaterial, ...properties]}
      activeTab={activeProperty}
    >
      <Loader show={showLoader} />
      {activeProperty?._id === "overall" ? (
        <OverviewHub
          isPropertyManager={isPropertyManager}
          overallOverview={overallOverview}
          properties={properties}
          handleViewManagerDetails={handleViewManagerDetails}
          handleAddProperty={handleAddProperty}
          handleViewProperties={handleViewProperties}
          toggleChat={toggleChat}
        />
      ) : activeProperty?._id === 'marketingMaterials' ?
        <MarketingMaterials
          properties={pData}
        /> : (
          <Overview
            hasTenants={!!tenants?.length && !!overview?.usage?.totalResidents}
            property={activeProperty}
            overview={overview}
            managerTasks={managerTasks}
            isPropertyManager={isPropertyManager}
            handleViewTenants={handleViewTenants}
            handleEditProperty={handleEditProperty}
            //handleMediakitModal={handleMediakitModal}
            setShowLoader={setShowLoader}
            toggleChat={toggleChat}
          />
        )}

      <CustomModal
        isOpen={showTenants}
        handleRemoveIcon={handleHideTenants}
        sx={BOX_STYLE}
        closeButtonStyle='removeIcon'
        content={() => (
          <TenantList
            tenants={tenants}
            handleUpdateTenant={handleUpdateTenant}
            handleAddTenant={handleAddTenant}
            handleDeleteTenant={handleDeleteTenant}
            activeProperty={activeProperty}
            tenantProps={tenantProps || {}}
          />
        )}
      />
      <CustomModal
        isOpen={showProperty}
        handleRemoveIcon={handleHideProperty}
        sx={BOX_STYLE}
        closeButtonStyle='removeIcon'
        content={() => (
          <PropertyDetails
            fetchPropertiesData={fetchPropertiesData}
            propertyProps={propertyProps || {}}
          />
        )}
      />

      <CustomModal
        isOpen={showManagerDetails}
        handleRemoveIcon={handleHideManager}
        closeButtonStyle='removeIcon'
        sx={BOX_STYLE}
        content={() => <ManagerDetails managerProps={managerProps || {}} />}
      />
      {decryptPropID && decryptWidgetID && (
        <TawkMessengerReact
          propertyId={decryptPropID}
          widgetId={decryptWidgetID}
          ref={tawkMessengerRef}
          onLoad={onLoad}
        />
      )}
    </Layout>
  );
}

export default Home;
