/**@description displays Overview component
 *@author Suresh Chekka
 *@version 1.0.0
 */
import React, { useEffect, useState } from "react";
import "./Overview.scss";
import { IconButton, Button, Grid } from "@mui/material";
import {
  numberWithCommas,
  getFormattedPhoneNum,
  downloadFromUrl,
  emailWriting,
  downloadURI,
  openNotionLink,
} from "../../../utils/helperMethods";
import { LIST_DATA } from "../../Constants";
import { getPropertyMarketingMaterial } from "../../../redux/actions/tenantsListActions";
import { VictoryPie } from "victory";
import {
  getPropertyManagerTasks,
  getPropertyMarketingmaterials,
  updateMonthlyTasks,
} from "../../../redux/actions/manager-actions/managerActions";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import Loader from "../../../components/loader/Loader";
import { useRef } from "react";

const Overview = (props) => {
  const { overview, property, isPropertyManager } = props;
  const [statusModal, setStatusModal] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [copied, setCopied] = useState(false);

  const tawkMessengerRef = useRef();

  const handleMinimize = () => {
    tawkMessengerRef.current.minimize();
  };

  const [showModal, setShowModal] = useState(false);

  const [managerTasks, setManagerTaks] = useState([]);

  const fetchManagerTaks = async (propertyId) => {
    const tasks = await getPropertyManagerTasks(propertyId);
    setManagerTaks([...tasks]);
  };

  useEffect(() => {
    fetchManagerTaks(property?._id);
  }, [property?._id]);

  const [listData, setListData] = useState([...LIST_DATA]);

  const handleDownloadMaterial = async () => {
    const data = await getPropertyMarketingMaterial(property?._id);
    downloadFromUrl(data.data);
  };

  /* const handleDownloadMediakit = async () => {
    props.handleMediakitModal(property?._id);

    const { data } = await getPropertyMarketingmaterials(property?._id);

    setMediaKit([...data]);
  };*/

  const handleClipboard = () => {
    navigator.clipboard.writeText("(877) 326-838").then(() => {
      setCopied(true);
    });
  };

  /**@description Handle Hide Model Functionality
   */
  const handleHideModal = () => {
    statusModal.show = false;
    setCopied(false);
    setStatusModal({ ...statusModal });
  };
  /*
  /**@description Handle Show Model Functionality
   */
  const handleShowModal = ({
    cancelText,
    confirmText,
    isAlert,
    title,
    message,
    handleConfirm = () => {},
  }) => {
    statusModal.show = true;
    statusModal.cancelText = cancelText || "Cancel";
    statusModal.confirmText = confirmText || "Ok";
    statusModal.isAlert = isAlert || false;
    statusModal.title = title || "";
    statusModal.message = message || "";
    statusModal.handleConfirm = handleHideModal;
    statusModal.handleCancel = handleHideModal;

    setStatusModal({ ...statusModal });
  };

  const handlephoneNumberModal = () => {
    let value = getFormattedPhoneNum("877326838");
    handleShowModal({
      confirmText: "Ok",
      title: `You can reach out to us via ${value}`,
      handleConfirm: handleHideModal,
    });
  };

  const handleClickOnIncreaseUsage = (item) => {
    switch (item.type) {
      case "UPDATE_TENANT":
        props.handleViewTenants();
        break;
      case "FEEDBACK":
        emailWriting(
          "Multifamily Dashboard- Share your feedback with us today!"
        );
        break;
      default:
        //props.handleMediakitModal(property._id);
        // openNotionLink();
    }
  };

  /**@description displays Earning potential card UI
   */
  const displayPotential = () => {
    const residents = overview?.usage?.residentsUsingNewAmenity || 0;
    const total = overview?.usage?.totalResidents || 0;
    return (
      <div className={"usageCard"}>
        <div>
          <div className='usageTitle'>Usage</div>
          <div className='graphContainer'>
            {total ? (
              <VictoryPie
                colorScale={["#008DAE", "#fff"]}
                animate={{
                  duration: 1000,
                }}
                data={[
                  {
                    x: " ",
                    y: residents,
                  },
                  {
                    x: " ",
                    y: total - residents,
                  },
                ]}
              />
            ) : (
              <div className={"noUsersIcon"} />
            )}
            <div className='residentsText'>
              {total
                ? `${residents} of ${total} residents use your new amenity`
                : "No users"}
            </div>
          </div>
        </div>
        <div>
          <div className='graphUsage'>
            {isPropertyManager ? "Increase" : "View"} Usage
          </div>
          <div className='graphSteps'>
            {isPropertyManager
              ? "Steps to increase usage"
              : "Monthly Manager Tasks"}
          </div>
          <div style={{ maxHeight: "28vh", overflowY: "hidden" }}>
            {(managerTasks || []).map((item, index) => (
              <div className='lists' key={item?.type}>
                <img
                  src={`/images/shared/${
                    item?.status === "COMPLETED" ? "yellow-tick" : "Ellipse5"
                  }.svg`}
                  alt='checkBox'
                  className='listIcon'
                  style={{ cursor: isPropertyManager ? "pointer" : "default" }}
                  onClick={async () => {
                    if (isPropertyManager) {
                      setShowLoader(true);
                      await updateMonthlyTasks(item._id);
                      const tasks = await getPropertyManagerTasks(
                        property?._id
                      );
                      setManagerTaks([...tasks]);
                      setShowLoader(false);
                    }
                  }}
                />
                <span onClick={() => handleClickOnIncreaseUsage(item)} style={{ cursor: ['UPDATE_TENANT', 'FEEDBACK'].includes(item.type) ? "pointer" : "default" }} >
                  {item?.title}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  /**@description displays payment date card UI
   */
  const displayPayment = () => {
    return (
      <div className={"paymentCard"}>
        <div>
          <div className='paymentTitle'>Your Earnings</div>
          <div className='paymentText'>
            {property?.name || "Property specific"} earning
          </div>
        </div>
        <div>
          <div className='pricedata'>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
            >
              <Grid div xs={6}>
                <div className='totalyear'>This Month</div>
              </Grid>
              <Grid div xs={6}>
                <div className='prices'>
                  {`$ ${
                    overview.earnings
                      ? numberWithCommas(overview.earnings.monthEarnings)
                      : 0
                  }`}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className='pricedata'>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
            >
              <Grid div xs={6}>
                <div className='totalyear'>This Year</div>
              </Grid>
              <Grid div xs={6}>
                <div className='prices'>
                  {`$ ${
                    overview.earnings
                      ? numberWithCommas(overview.earnings.yearEarnings)
                      : 0
                  }`}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  };

  const displayIncomeStatements = () => {
    return (
      <div className={"incomeCard"}>
        <div>
          <div className='incomeTitle'>Income Statements</div>
          <div className='incomeText'>
            Download your historical statements here
          </div>
        </div>
        <div className={"ButtonRow"}>
          <Button
            variant='outlined'
            color='primary'
            sx={{
              borderRadius: "3vmax",
              color: "#fff",
              width: "50%",
              opacity: "0.5",
              cursor: "not-allowed",
              border: "0.1vw solid #fff",
              "&:hover": { border: "0.1vw solid #fff" },
            }}
          >
            Coming soon...
          </Button>
        </div>
      </div>
    );
  };
  const displayManagerDetails = () => {
    return (
      <div className={"managerCard"}>
        <div>
          <div className='managerTitle'>Manager:</div>
          <div>{property.manager?.name}</div>
          <div>
            Phone:{" "}
            {getFormattedPhoneNum(property.manager?.phoneNumber.slice(-10))}
          </div>
          <div>Email: {property.manager?.email}</div>
        </div>

        <div className='EditButtonRow'>
          <Button
            variant='outlined'
            size='large'
            sx={{
              borderRadius: "3vmax",
              color: "#fff",
              width: "35%",
              fontSize: "1.25vw",
              fontWeight: 500,
              border: "0.1vw solid #fff",
              "&:hover": { border: "0.1vw solid #fff" },
              textTransform: "capitalize",
            }}
            onClick={props.handleEditProperty}
          >
            Edit
          </Button>
        </div>
      </div>
    );
  };

  /**@description displays Contact Information UI
   */
  const displayContactInfo = () => {
    return (
      <div className={"contactInfoCard"}>
        <div>
          <div className='contactTitle'>Contact Us</div>
          <div className='representativeRow'>
            <img
              src='/images/shared/DeAnna1.png'
              alt='IMG'
              className='representativeImg'
            />
            <div>
              Reach out to your dedicated representative or our support team
              <div className='representativeText'>
                Your Representative:{" "}
                <span className='representativeName'>DeAnna Unger</span>
              </div>
            </div>
          </div>
        </div>

        <div className={"contactRow"}>
          <div onClick={() => props.toggleChat()}>
            <img
              src='/images/shared/Chaticon.svg'
              alt='SMS'
              className='contactIcon'
            />
          </div>

          <img
            src='/images/shared/Emailicon.svg'
            alt='Mail'
            className='contactIcon'
            onClick={() =>
              emailWriting(
                "Multifamily Dashboard- Share your feedback with us today!"
              )
            }
          />
          <div onClick={() => handlephoneNumberModal()}>
            <img
              src='/images/shared/PhoneIcon.svg'
              alt='Contact'
              className='contactIcon'
            />
          </div>
        </div>
      </div>
    );
  };

  const displaydownloadMediakit = () => {
    return (
      <div className={"mediakitCard"}>
        <div>
          <div className='mediaTitle'>For you</div>
          <div className='mediaText'>
            Download a media kit to promote your new amenity specifically
            designed for this property
          </div>
        </div>

        <div className={"ButtonRow"}>
          <IconButton
            variant='outlined'
            color='primary'
            sx={{
              borderRadius: "3vmax",
              color: "#fff",
              width: "44%",
              border: "0.1vw solid #fff",
              "&:hover": { border: "0.1vw solid #fff" },
            }}
            onClick={() => {
              openNotionLink();
              //props.handleMediakitModal(property._id);
            }}
          >
            <div className='mediaKitButtonStyle'>{`Show media kit`}</div>
          </IconButton>
        </div>
      </div>
    );
  };

  const getCardByRole = () =>
    
  isPropertyManager ? displaydownloadMediakit() : displayManagerDetails();

  return (
    <div className={"overviewContainer"}>
      <div className={"rowContainer"} style={{ width: "27%" }}>
        {displayPayment()}
        {displayIncomeStatements()}
      </div>
      <div className={"rowContainer"} style={{ width: "30%" }}>
        {displayPotential()}
      </div>
      <div className={"rowContainer"} style={{ width: "37%", margin: 0 }}>
        {displayContactInfo()}
        {getCardByRole()}
      </div>

      {statusModal?.show ? (
        <ConfirmModal
          cancelText={""}
          confirmText={statusModal.confirmText}
          isAlert={statusModal.isAlert}
          title={statusModal.title}
          message={statusModal.message}
          handleCancel={statusModal.handleCancel}
          handleConfirm={statusModal.handleConfirm}
          handleClipboard={handleClipboard}
          copied={copied}
        />
      ) : null}
      {showLoader ? <Loader /> : null}
    </div>
  );
};

export default Overview;
