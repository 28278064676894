import axios from "axios";
import { GET_USER, GET_USERS, GET_MFOS_PROPERTIES } from "./actionTypes";
import config from "../../config";

/**@description get all users
 */
export const getUsers = (role) => async (dispatch) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${config.RestServiceURL}/users/roles/${
          role ? role : "ACCOUNT_MANAGER"
        }`
      )
      .then((res) => {
        const data = res.data;
        dispatch({
          type: GET_USERS,
          payload: data,
        });
        resolve(data);
      })
      .catch((err) => {
        console.error("getUsers", err.message);
        resolve([]);
      });
  });
};

/**@description get MFOS with Properties
 */
export const getAllMFO = (role) => async (dispatch) => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/users/mfos/properties`)
      .then((res) => {
        const data = res.data;
        dispatch({
          type: GET_MFOS_PROPERTIES,
          payload: data,
        });
        resolve(data);
      })
      .catch((err) => {
        console.error("getAllMFO", err.message);
        resolve([]);
      });
  });
};
// get a USER
export const getUser = (id) => ({
  type: GET_USER,
  payload: id,
});

/**@description Delete Bulk Delete
 */
export const deletebulkMFO = (IDdata) => {
  return new Promise((resolve) => {
    axios
      .delete(`${config.RestServiceURL}/users/mfos/multiple`, { data: IDdata })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        console.error("deleteProperty", err);
        resolve(false);
      });
  });
};

export const createbulkMFO = (mfoData) => {
  return new Promise((resolve) => {
    axios
      .post(`${config.RestServiceURL}/users/mfos/multiple`, mfoData)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        console.error("MFOAddedd", err);
        resolve({
          status: false,
          message: err.response?.data?.message,
          statusCode: err.response?.data?.statusCode,
        });
      });
  });
};
