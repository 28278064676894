import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useState, useEffect } from "react";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import CustomButton from "../../../components/custom-button/CustomButton";
import CustomInput from "../../../components/custom-input/CustomInput";
import Loader from "../../../components/loader/Loader";
import {
  getDriverAppversion,
  getUserAppversion,
  updateAppversions,
} from "../../../redux/actions/appVersionActions";
import {
  characterdotCheckRegex,
  excludeSpace,
} from "../../../utils/helperMethods";
import { APP_VERSION } from "../../Constants";
import "./appVersion.scss";

const AppVersion = () => {
  const [appName, setAppName] = useState("USER");
  const [appVersion, setAppVersion] = useState({ ...APP_VERSION });
  const [userAppversion, setuserAppVersion] = useState("");
  const [driverAppVersion, setDriverAppVersion] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [statusModal, setStatusModal] = useState({});
  const [isDisable, setisDisable] = useState(true);

  useEffect(() => {
    getAppversions();
  }, []);

  const getAppversions = async () => {
    setShowLoader(true);
    const { data: userData, message } = await getUserAppversion();

    setuserAppVersion(userData.releaseVersion);
    const { data: driverData } = await getDriverAppversion();
    setDriverAppVersion((driverData || {}).releaseVersion);
    setShowLoader(false);
    if (message) {
      handleShowModal({
        cancelText: "Ok",
        title: message && `Failed`,
        message: message,
        isAlert: true,
      });
      setShowLoader(false);
    }
  };
  const handleInput = ({ value }) => {
    if (characterdotCheckRegex(value) === false) {
      appVersion.value = value;
      appVersion.hasError = true;
      setisDisable(true);
    } else {
      appVersion.value = excludeSpace(value);
      appVersion.hasError = false;
      setisDisable(false);
    }
    if (value.length === 0) {
      appVersion.value = value;
      appVersion.hasError = false;
      setisDisable(true);
    }
    appVersion.errorMessage = `Please enter a valid Version`;
    setAppVersion({ ...appVersion });
  };

  const handleChange = (data) => {
    setAppName(data);
  };
  const handleSubmit = async () => {
    setShowLoader(true);
    const appData = {
      version: appVersion?.value,
    };
    const { status } = await updateAppversions(appName, appData);

    const SelectedAppName = appName === "USER" ? `Tenant App` : "Driver App";
    handleShowModal({
      cancelText: "Ok",
      title: status ? `Success` : `Failed`,
      message: status
        ? `${SelectedAppName} updated successfully!`
        : `Failed to update the ${SelectedAppName}, please try again.`,
      isAlert: true,
    });
    setisDisable(true);
    setShowLoader(false);
    appVersion.value = "";
    setAppVersion({ ...appVersion });
    getAppversions();
  };

  /**@description Handle hide Model Functionality
   */
  const handleHideModal = () => {
    statusModal.show = false;
    setStatusModal({ ...statusModal });
  };

  /**@description Handle Show Model Functionality
   */
  const handleShowModal = ({
    cancelText,
    confirmText,
    isAlert,
    title,
    message,
    handleConfirm = () => {},
  }) => {
    statusModal.show = true;
    statusModal.cancelText = cancelText || "Cancel";
    statusModal.confirmText = confirmText || "Ok";
    statusModal.isAlert = isAlert || false;
    statusModal.title = title || "";
    statusModal.message = message || "";
    statusModal.handleConfirm = handleConfirm;
    statusModal.handleCancel = handleHideModal;
    setStatusModal({ ...statusModal });
  };

  /**@description Handle Show Delete Model Functionality
   */
  const handleShowUpdateModal = () => {
    const SelectedAppName = appName === "USER" ? `Tenant App` : "Driver App";

    handleShowModal({
      confirmText: "Update",
      title: `Update ${SelectedAppName}`,
      message: `Are you sure you want to update the ${SelectedAppName} version to ${appVersion.value}?`,
      handleConfirm: handleSubmit,
    });
  };

  const displayingAppVersionInput = () => {
    return (
      <div className='BoxStyle'>
        <div className='inputStyle'>
          <div className='InputHeading'>Update App Version</div>
          <CustomInput
            dataItem={appVersion}
            additionalSx={{
              width: "60%",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            handleInput={handleInput}
            InputProps={{
              startAdornment: (
                <FormControl
                  variant='standard'
                  id='standard-error-helper-text'
                  sx={{
                    minWidth: "auto",
                  }}
                >
                  <Select
                    labelId='demo-simple-select-standard-label'
                    id='demo-simple-select-standard'
                    value={appName}
                    label='App'
                    onChange={(e) => handleChange(e.target.value)}
                  >
                    <MenuItem value={"USER"}>Tenant App</MenuItem>
                    <MenuItem value={"DRIVER"}>Driver App</MenuItem>
                  </Select>
                </FormControl>
              ),
            }}
            required={true}
          />

          <div className='btnDiv'>
            <CustomButton
              title='Submit'
              className='versionSubmitButton'
              handleButtonClick={handleShowUpdateModal}
              disabled={isDisable}
            />
          </div>
        </div>

        {displayAppversion()}
      </div>
    );
  };

  const displayAppversion = () => {
    return (
      <div className='inputStyle'>
        <div className='InputHeading'>Latest Version's</div>

        <div className='appNameStyle'>
          <div>Tenant App : </div>{" "}
          <span className='version'>{userAppversion}</span>
        </div>
        <div className='appNameStyle'>
          <div>Driver App : </div>
          <span className='version'>{driverAppVersion}</span>
        </div>
      </div>
    );
  };

  return (
    <div className='appVersionOverview'>
      {displayingAppVersionInput()}
      {statusModal?.show ? (
        <ConfirmModal
          cancelText={statusModal.cancelText}
          confirmText={statusModal.confirmText}
          isAlert={statusModal.isAlert}
          title={statusModal.title}
          message={statusModal.message}
          handleCancel={statusModal.handleCancel}
          handleConfirm={statusModal.handleConfirm}
        />
      ) : null}
      <Loader show={showLoader} />
    </div>
  );
};

export default AppVersion;
