import axios from "axios";
import config from "../../config";

/**
 * @description GET ALL COUPON CODES WITH STATISTICS
 * @param {void}
 * @returns {success: boolean, message: String, data: Object}
 * @author Neha Pawar
 */

export const getAllCouponCodes = () => {
    return new Promise((resolve) => {
        axios.get(`${config.RestServiceURL}/coupons/statistics`)
        .then((res) => {
            if(res.status === 200 || res.status === 201)
            {
                resolve({status: true, data: res.data})
            }
            else{
                resolve({status: false})
            }
        })
        .catch((err) => {
            console.error("getCouponsDetails", err);
            resolve({status: false, message: err.response?.data?.message})
        })
    })
}

/**
 * @description CREATE COUPON CODE
 * @param {Object} data
 * @returns {success: boolean, message: String, data: Object}
 * @author Neha Pawar
 */
export const createCouponCodes = (data) => {
    return new Promise((resolve) => {
        axios.post(`${config.RestServiceURL}/coupons`, data)
        .then((res) => {
            if(res.status === 200 || res.status === 201)
            {
                resolve({status: true, data: res.data})
            }
            else {
                resolve({status: false})
            }
        })
        .catch((err) => {
            console.error("couponCreation", err);
            resolve({status: false, message: err.response?.data?.message})
        })
    })
}

/** @description DELETE COUPON CODE */

export const deleteSingleCouponCode = (couponId) => {
    return new Promise((resolve) => {
        axios.delete(`${config.RestServiceURL}/coupons/${couponId}`)
        .then((res) => {
            if(res.status === 200 || res.status === 201 )
            {
                resolve({status: true})
            }
            resolve({status: false})
        })
        .catch((err) => {
            console.error("deleteCoupon", err)
            resolve({status: false, message: err.response?.data?.message})
        })
    })
}

/** @description DELETE MULTIPLE COUPON CODE */

export const deleteMultipleCouponCode = (couponIds) => {
    return new Promise((resolve) => {
        axios.put(`${config.RestServiceURL}/coupons/delete-all`, couponIds)
        .then((res) => {
            if(res.status === 200 || res.status === 201 )
            {
                resolve({status: true, data: res.data})
            }
            resolve({status: false})
        })
        .catch((err) => {
            console.error("deleteMultipleCoupon", err)
            resolve({status: false, message: err.response?.data?.message})
        })
    })
}

/** UPDATE COUPON INFORMATION */

export const updateCouponDetails = (couponId,couponDetails) => {
    return new Promise((resolve) => {
        axios.put(`${config.RestServiceURL}/coupons/${couponId}`, couponDetails)
        .then((res) => {
            if(res.status === 200 || res.status === 201 )
            {
                resolve({status: true, data: res.data})
            }
            resolve({status:false})
        })
        .catch((err) => {
            console.error("updateCouponDetails", err)
            resolve({status: false, message: err.response?.data?.message})
        })
    })
}

/** ACTIVE OR DE-ACTIVE COUPON CODES */

export const activeDeactiveCouponCode = (couponId,data) => {
 return new Promise((resolve) => {
     axios.put(`${config.RestServiceURL}/coupons/${couponId}/activate`,data)
     .then((res) => {
         if(res.status === 200 || res.status === 201)
         {
             resolve({status: true, data: res.data})
         }
         else{
             resolve({dtatus: false})
         }
     })
     .catch((err) => {
         console.error("activateORdeactivateCoupon", err);
         resolve({status: false, message: err.response?.data?.message})
     })
 })
}