import React, { useState } from "react";
import "./OverviewHub.scss";
import { Button, IconButton } from "@mui/material";
import {
  emailWriting,
  getFormattedPhoneNum,
  numberWithCommas,
} from "../../../utils/helperMethods";
import CustomCard from "../../../components/custom-card/CustomCard";
import { VictoryPie } from "victory";
import ConfirmModal from "../../../components/confirm-modal";
import { downloadFromUrl } from "../../../utils/helperMethods";
import { downloadMediaKit } from "../../../redux/actions/tenantsListActions";

const cardSxProps = {
  backgroundColor: "#007dae",
  color: "#fff",
  borderRadius: "1vw",
};

const OverviewHub = (props) => {
  const { properties, overallOverview, isPropertyManager } = props;
  const [statusModal, setStatusModal] = useState({});
  const [copied, setCopied] = useState(false);

  /**@description Handle Hide Model Functionality
   */
  const handleHideModal = () => {
    statusModal.show = false;
    setCopied(false);
    setStatusModal({ ...statusModal });
  };
  /**@description Handle Show Model Functionality
   */
  const handleShowModal = ({
    cancelText,
    confirmText,
    isAlert,
    title,
    message,
    handleConfirm = () => {},
  }) => {
    statusModal.show = true;
    statusModal.cancelText = cancelText || "Cancel";
    statusModal.confirmText = confirmText || "Ok";
    statusModal.isAlert = isAlert || false;
    statusModal.title = title || "";
    statusModal.message = message || "";
    statusModal.handleConfirm = handleHideModal;
    statusModal.handleCancel = handleHideModal;

    setStatusModal({ ...statusModal });
  };

  const handlephoneNumberModal = () => {
    let value = getFormattedPhoneNum("877326838");
    handleShowModal({
      confirmText: "Ok",
      title: `You can reach out to us via ${value}`,
      handleConfirm: handleHideModal,
    });
  };

  const handleClipboard = () => {
    navigator.clipboard.writeText("(877) 326-838").then(() => setCopied(true));
  };

  /**@description displays Earning potential card UI
   */
  const displayPotential = () => {
    const residents =
      overallOverview?.[0]?.usage?.residentsUsingNewAmenity || 0;
    const total = overallOverview?.[0]?.usage?.totalResidents || 0;
    return (
      <div className={"hubusageCard"}>
        <div className='hubusageTitle'>Usage</div>
        <div className='hubgraphContainer'>
          <VictoryPie
            colorScale={["#008DAE", "#fff"]}
            padding={16}
            animate={{
              duration: 1000,
            }}
            data={[
              {
                x: " ",
                y: residents,
              },
              {
                x: " ",
                y: total - residents || 1,
              },
            ]}
          />
          <div className='hubusageText'>
            {total
              ? `${residents} of ${total} residents use your new amenity`
              : "No users"}
          </div>
        </div>
      </div>
    );
  };

  /**@description displays Add Property card UI
   */
  const displayAddPropertyCard = () => {
    return (
      <div className={"hubUsageCard"}>
        <div className='hubUsageTitle'>Add New Property</div>
        <img
          src='/images/shared/add.svg'
          alt='PLUS'
          className='hubPlusimg'
          onClick={() => {
            props.handleAddProperty();
          }}
        />
      </div>
    );
  };

  /**@description displays Edit Manager card UI
   */

  const displayEditManagerCard = () => {
    return (
      <div className={"hubUsageCard"}>
        <div className='hubUsageTitle'>Edit Managers</div>
        <img
          src='/images/shared/add.svg'
          alt='PLUS'
          className='hubPlusimg'
          onClick={() => {
            props.handleViewManagerDetails();
          }}
        />
      </div>
    );
  };

  /**@description displays Smaller Cards UI
   */
  const displaySmallerCards = (propertyData = {}) => {
    const overview = (overallOverview || []).find(
      (x) => x._id === propertyData._id
    );
    return (
      <div
        className={"hubPropertyCard"}
        key={`displaySmallerCards${propertyData._id}`}
      >
        <div
          className='hubPaymentTitle propertyTabTitle'
          title={propertyData?.name || ""}
        >
          {propertyData?.name}
        </div>
        <div
          className='hubPropertyPaymentText'
          title={isPropertyManager ? "" : propertyData?.manager?.name || ""}
        >
          {isPropertyManager ? "" : `Manager: ${propertyData?.manager?.name}`}
        </div>
        <div className='hubPropertyEarningsRow'>
          <div className='hubTotalyear'>This Month</div>
          <div className='hubPropertyPricedata'>
            $ {numberWithCommas(overview?.earnings?.monthEarnings || 0)}
          </div>
        </div>
      </div>
    );
  };

  /**@description displays Earning potential card UI
   */

  const displayEarningCard = () => {
    const month = overallOverview?.[0]?.earnings?.monthEarnings || 0;
    const year = overallOverview?.[0]?.earnings?.yearEarnings || 0;
    return (
      <div className={"hubPaymentCard"}>
        <div>
          <div className='hubPaymentTitle'>Your Earnings</div>
          <div className='hubPaymentText'>Earnings Across all properties</div>
        </div>

        <div>
          <div className='hubEarningsRow'>
            <div className='hubTotalyear'>This Month</div>
            <div className='hubPricedata'>{`$ ${numberWithCommas(month)}`}</div>
          </div>
          <div className='hubEarningsRow'>
            <div className='hubTotalyear'>This Year</div>
            <div className='hubPricedata yeardata'>{`$ ${numberWithCommas(
              year
            )}`}</div>
          </div>
        </div>
      </div>
    );
  };

  /**@description displays Income Statements card UI
   */
  const displayIncomeStatements = () => {
    return (
      <div className={"hubIncomeCard"}>
        <div>
          <div className='hubIncomeTitle'>Income Statements</div>
          <div className='hubIncomeText'>
            Download your historical statements here
          </div>
        </div>
        <div className={"hubButtonRow"}>
          <Button
            variant='outlined'
            color='primary'
            sx={{
              borderRadius: "3vmax",
              color: "#fff",
              width: "50%",
              opacity: "0.5",
              cursor: "not-allowed",
              border: "0.1vw solid #fff",
              cursor: "not-allowed",
              "&:hover": { border: "0.1vw solid #fff" },
            }}
          >
            Coming soon...
          </Button>
        </div>
      </div>
    );
  };

  const handleDownloadMaterial = async () => {
    const data = await downloadMediaKit();
    let isMediaKit = true;
    downloadFromUrl(data.data, isMediaKit);
  };

  /**@description displays Manager Details card UI
   */

  // const displayManagerDetails = () => {
  //   return (
  //     <div className={"hubManagerCard"}>
  //       <div className='hubMediakitText'>
  //         Download our media kit for your website
  //       </div>
  //       <div className={"hubManagerButtonRow"}>
  //         <IconButton
  //           variant='outlined'
  //           color='primary'
  //           sx={{
  //             borderRadius: "3vmax",
  //             color: "#fff",
  //             width: "30%",
  //             border: "0.1vw solid #fff",
  //             "&:hover": { border: "0.1vw solid #fff" },
  //           }}
  //           onClick={() => {
  //             handleDownloadMaterial();
  //           }}
  //         >
  //           <img
  //             src='/images/shared/DownloadButton.svg'
  //             alt='SMS'
  //             className='hubDownloadIcon'
  //           />
  //         </IconButton>
  //       </div>
  //     </div>
  //   );
  // };
  /**@description displays Contact Information UI
   */
  const displayContactCard = () => {
    return (
      <div className={"hubContactInfoCard"}>
        <div>
          <div className='hubPaymentTitle'>Contact Us</div>
          <div className='hubContactInfoRow'>
            <img
              src='/images/shared/DeAnna1.png'
              alt='IMG'
              className='hubRepresentativeImg'
            />
            <div>
              <div className={"hubMarketingText"}>
                Reach out to your dedicated representative or our support team
              </div>
              <div className='hubRepresentative'>
                Your Representative:{" "}
                <span className='representativeName'>DeAnna Unger</span>
              </div>
            </div>
          </div>
        </div>
        <div className={"hubContactRow"}>
          <div onClick={() => props.toggleChat()}>
            <img
              src='/images/shared/Chaticon.svg'
              alt='SMS'
              className='hubContactIcon sms'
            />
          </div>

          <img
            src='/images/shared/Emailicon.svg'
            alt='Mail'
            className='hubContactIcon mail'
            onClick={() =>
              emailWriting(
                "Multifamily Dashboard- Share your feedback with us today!"
              )
            }
          />
          <div onClick={() => handlephoneNumberModal()}>
            <img
              src='/images/shared/PhoneIcon.svg'
              alt='Contact'
              className='hubContactIcon'
            />
          </div>
        </div>
      </div>
    );
  };

  const displayEmptyCard = () => {
    return (
      <div className={"hubEmptyPropertyCard"}>
        Additional properties in your care will appear here
      </div>
    );
  };

  const getArrowIcon = () => (
    <img
      src='/images/shared/Arrow.svg'
      alt='scroll'
      width='20px'
      height='32px'
    />
  );

  /**@description displays UI if tenants are available
   */
  const displayOverview = () => {
    const element = document.getElementById("propertyContainer");
    const hasScroll = element?.scrollWidth > element?.offsetWidth;
    return (
      <div className={"hubOverviewContainer"}>
        <div className={"hubFirstRow"}>
          <CustomCard
            cardData={displayEarningCard()}
            sxProps={{ ...cardSxProps, height: "42%" }}
          />
          <CustomCard
            cardData={displayIncomeStatements()}
            sxProps={{ ...cardSxProps, height: "54%" }}
          />
        </div>
        <div className='hubChildContainer'>
          <div className={"hubSecondRowContainer"}>
            <div className='hubSecondRow' id='propertyContainer'>
              {hasScroll ? (
                <div
                  className='scrollRightArrow'
                  onClick={() => {
                    document.getElementById(
                      "propertyContainer"
                    ).scrollLeft -= 100;
                  }}
                >
                  {getArrowIcon()}
                </div>
              ) : null}
              {(properties || []).map((property) =>
                displaySmallerCards(property)
              )}
              {hasScroll ? (
                <div
                  className='scrollArrow'
                  onClick={() => {
                    document.getElementById(
                      "propertyContainer"
                    ).scrollLeft += 100;
                  }}
                >
                  {getArrowIcon()}
                </div>
              ) : (
                displayEmptyCard()
              )}
            </div>
          </div>
          <div className='hubThirdRow'>
            {isPropertyManager ? (
              <div className={"rowContainer"} style={{ width: "44%" }}>
                <CustomCard
                  cardData={displayPotential()}
                  sxProps={{
                    height: "100%",
                    backgroundColor: "#007dae",
                    color: "#fff",
                    borderRadius: "20px",
                  }}
                />
              </div>
            ) : (
              <div className='hubAddEditRow'>
                <CustomCard
                  cardData={displayAddPropertyCard()}
                  sxProps={{ ...cardSxProps, height: "48%" }}
                />
                <CustomCard
                  cardData={displayEditManagerCard()}
                  sxProps={{ ...cardSxProps, height: "48%" }}
                />
              </div>
            )}
            <div className={"hubRowContainer"}>
              <CustomCard
                cardData={displayContactCard()}
                sxProps={{
                  ...cardSxProps,
                  minHeight: "100%",
                  marginBottom: "2vh",
                }}
              />
              {/*<CustomCard
                cardData={displayManagerDetails()}
                sxProps={{ ...cardSxProps, height: "35%" }}
              /> */}
            </div>
          </div>
        </div>
        {statusModal?.show ? (
          <ConfirmModal
            handleClipboard={handleClipboard}
            copied={copied}
            cancelText={""}
            confirmText={statusModal.confirmText}
            isAlert={statusModal.isAlert}
            title={statusModal.title}
            message={statusModal.message}
            handleCancel={statusModal.handleCancel}
            handleConfirm={statusModal.handleConfirm}
          />
        ) : null}
      </div>
    );
  };
  return displayOverview();
};

export default OverviewHub;
