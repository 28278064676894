import React, { useState, useRef } from "react";
import CustomCard from "../../../components/custom-card/CustomCard";
import CustomInput from "../../../components/custom-input/CustomInput";
import ConfirmModal from "../../../components/confirm-modal";
import CustomButton from "../../../components/custom-button/CustomButton";
import CustomModal from "../../../components/custom-modal/CustomModal";
import { Button } from "@mui/material";
import _ from "lodash";
import { EDIT_FILE_NAME } from "../../../components/Constants";
import { convertToDateFormat } from "../../../utils/helperMethods";
import ImageWithLoader from "./ImageWithLoader";

const MarketingMaterialForm = (props) => {
  const {
    SubCategorieName,
    activeTab,
    emailAndSmsDetails,
    setEmailAndSmsDetails,
    onSubmitMarketingMaterials,
    setMaxLimitExceed,
    maxLimitExceed,
    socialMediaData,
    SelectedFile,
    setSelectedFile,
    setShowLoader,
    check,
    setCheck,
    webInterface,
    setWebInterface,
    curFileName,
    setCurFileName,
    handledeleteMarketingmaterial,
    webData,
    printedData,
    email,
    isEmpty,
  } = props;

  const [selectedItem, setSelectedItem] = useState("");
  const [editName, setEditName] = useState(false);
  const [currentFileName, setCurrentFileName] = useState({});
  const [showMore, setShowMore] = useState(0);
  const fileInputRef = useRef(null);
  


  const displayHeader = () => (
    <div className={"subCategorieHeaderRow"}>
      <div className="addProperties">{SubCategorieName.title}</div>
    </div>
  );

  const handleTextChange = ({ key, value }) => {
    const index = emailAndSmsDetails.findIndex((data) => data.key === key);
    const item = emailAndSmsDetails[index];
    item.value = value;
    /*Error Handling*/
    if (value.length < item.minLength) {
      item.hasError = true;
      item.errorMessage = `Please enter valid ${item.title}`;
    } else {
      item.hasError = false;
    }
    if (value.length <= 0) {
      item.hasError = false;
    }

    setEmailAndSmsDetails([...emailAndSmsDetails]);
  };

  const renameFile = ({ key, value }) => {
    const file = _.cloneDeep(curFileName);
    const fileExtension = curFileName.value.split(".").pop();
    file.value = `${value}.${fileExtension}`;
    setCurFileName({ ...file });
  };

  const selectedFileName = () => {
    const fileName = curFileName.value.split(".").shift();
    return fileName;
  };

  const renameSelectedFileName = () => {
    const replacementObject = new File([currentFileName], curFileName.value, {
      type: currentFileName.type,
      lastModified: currentFileName.lastModified,
    });
    const newArray = webInterface.map((item, index) =>
      index === curFileName.index ? replacementObject : item
    );
    return newArray;
  };
  const displayEditModal = () => {
    const value = selectedFileName() || "";
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="addCoupon">Edit File Name</div>
        <CustomInput
          dataItem={{ ...curFileName, value }}
          additionalSx={{
            width: "100%",
            marginTop: "30px",
          }}
          handleInput={(data) => renameFile(data)}
          required={true}
        />
        <Button
          onClick={() => {
            setWebInterface(renameSelectedFileName());
            setEditName(false);
          }}
          autoFocus={true}
          sx={{ fontSize: "1.25vw", alignSelf: "flex-end", marginTop: "6vh" }}
        >
          Update
        </Button>
      </div>
    );
  };

  /*@description Displaying UploadFile Button*/
  const displayingUploadButton = (file) => {
    const actualData = activeTab === "socialMedia" ? check : webInterface;
    return (
      <div className={"marketButton"}>
        <label htmlFor="uploadMarketingmaterial" className={"uploadButtonRow"}>
          <input
            className={"upload"}
            type="file"
            accept={selectedItem.type}
            id="uploadMarketingmaterial"
            multiple={selectedItem.multiple}
            onChange={(e) => handleFileUpload(e)}
            ref={fileInputRef}
          />
          <span
            className={"marketButtonText"}
            onClick={() => setSelectedItem(file)}
          >
            {file?.title}
          </span>
        </label>
        {file.value && (
          <>
            {activeTab === "socialMedia" || activeTab === "web" ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "10px 0px",
                  height: "auto",
                }}
              >
                {(actualData || []).map((curItem, index) => (
                  <div className="addMarketingmaterial" key={index}>
                    <span>{curItem.name || ""}</span>
                    <img
                      src="/images/shared/delete.svg"
                      alt="Delete Icon"
                      className="uploadMaterialDelete"
                      onClick={() => handleDeleteFiles(curItem, index)}
                      role="presentation"
                    />
                    {activeTab === "web" && (
                      <img
                        src={`/images/shared/edit.svg`}
                        alt=""
                        className="editIcon"
                        onClick={() => {
                          setEditName(true);
                          curFileName.value = curItem.name;
                          curFileName.index = index;
                          setCurrentFileName(curItem);
                          setCurFileName({ ...curFileName });
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="addMarketingmaterial">
                <span>{file?.value || ""}</span>
                <img
                  src="/images/shared/delete.svg"
                  alt="Delete Icon"
                  className="uploadMaterialDelete"
                  onClick={() => handleDeleteFiles(file.key)}
                  role="presentation"
                />
              </div>
            )}
            {editName && (
              <CustomModal
                isOpen={true}
                handleRemoveIcon={() => setEditName(false)}
                sx={EDIT_FILE_NAME}
                content={displayEditModal}
                closeButtonStyle="fileEditRemove"
              />
            )}
          </>
        )}
      </div>
    );
  };

  const handelShow = (id) => {
    setShowMore(id);
  };

  const handleDeleteFiles = (curItem, indexValue) => {
    if (activeTab !== "socialMedia" && activeTab !== "web") {
      const index = SelectedFile.findIndex(
        (curValue) => curValue.key === curItem
      );
      SelectedFile[index].value = "";
      setSelectedFile([...SelectedFile]);
    } else {
      const actualData = activeTab === "socialMedia" ? check : webInterface;
      const updatedItems = actualData.filter((item, i) => i !== indexValue);
      activeTab === "socialMedia"
        ? setCheck([...updatedItems])
        : setWebInterface([...updatedItems]);
    }
  };

  const displaySocialMediaImages = () => {
    const maxLength = 150;
    return (
      <div className="socialMediaMainContainer">
        <div className="socialHeading">Uploaded Details</div>
        <div className="socialSubContainer">
          {(socialMediaData || []).map((data, index) => {
            const isTestOpen = index === showMore;
            return (
              <div
                style={
                  data.photoUrls.length > 1
                    ? { display: "flex" }
                    : { display: "flex", flexDirection: "column" }
                }
                key={index}
              >
                {data?.photoUrls?.length !== 0 && (
                  <>
                    {data.photoUrls.map((curElem) => (
                      <div className="socialMediaImageContainer">
                        <ImageWithLoader
                          src={curElem.url}
                          alt="preview-image"
                        />
                        <img
                          src="/images/shared/deletewhite.svg"
                          alt="Delete Icon"
                          className="webdeleteIcon"
                          onClick={() => handledeleteMarketingmaterial(data)}
                        />
                        <div className="imageName">
                          {imageName(curElem.key)}
                        </div>
                        <div
                          style={{
                            marginTop: "20px",
                            width: "20vw",
                          }}
                        >
                          {data?.text.length > maxLength ? (
                            <>
                              <p className="socialTextStyle">
                                {isTestOpen
                                  ? data?.text
                                  : data?.text.substring(0, maxLength) + "..."}
                              </p>
                              {isTestOpen ? (
                                <div
                                  className="showLessButton"
                                  onClick={() => handelShow(0)}
                                  style={{ cursor: "pointer", color: "blue" }}
                                >
                                  "Show less"
                                </div>
                              ) : (
                                <div
                                  className="showLessButton"
                                  onClick={() => handelShow(index)}
                                  style={{ cursor: "pointer", color: "blue" }}
                                >
                                  "Show more"
                                </div>
                              )}
                            </>
                          ) : (
                            <div className="socialTextStyle">{data?.text}</div>
                          )}
                        </div>{" "}
                      </div>
                    ))}
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const imageName = (data) => {
    const name = data?.split("/");
    const lastPart = name[name.length - 1];
    return lastPart;
  };

  const displayingFileCard = (data) => {
    return (
      <div className="socialMediaMainContainer">
        <div className="socialHeading">Uploaded Details</div>
        <div className="socialSubContainer">
          {(webData || []).map((data) => (
            <div
              style={
                data.photoUrls.length > 1
                  ? { display: "flex" }
                  : { display: "flex", flexDirection: "column" }
              }
            >
              {data.photoUrls.length !== 0 && (
                <>
                  {data?.photoUrls.map((curElem) => (
                    <div className="socialMediaImageContainer">
                      <ImageWithLoader src={curElem.url} alt="preview-image" />
                      <div
                        className="imageName"
                        style={{ textAlign: "center", marginBottom: "30px" }}
                      >
                        {imageName(curElem.key)}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  /*@description Displaying Submit Button*/
  const displayingSubmitButton = () => {
    let disable;
    const filteredInputsData = emailAndSmsDetails.filter((dataItem) => {
      if ([activeTab].includes(dataItem.tab)) {
        return dataItem;
      }
    });
    const selectedFIlesInfo = SelectedFile.filter((curFile) => {
      if ([activeTab].includes(curFile.tab)) {
        return curFile;
      }
    });
    let data = Object.values(filteredInputsData).some(
      (x) => x?.hasError || (x?.value || "")?.length < x?.minLength
    );
    let files =
      activeTab === "socialMedia"
        ? check.length === 0
        : activeTab === "web"
        ? webInterface.length === 0
        : Object.values(selectedFIlesInfo).some((x) => x.value === "");
    disable = data || files;
    return (
      <div className="marketingMaterialSubmitbutton">
        <CustomButton
          title={"Submit"}
          handleButtonClick={() => onSubmitMarketingMaterials()}
          additionalSx={{
            fontSize: "2vh",
            width: "10%",
            padding: "1vh 6vw",
          }}
          disabled={disable}
        />
      </div>
    );
  };

  /*@description handles the uploading files*/
  const handleFileUpload = async (e) => {
    setShowLoader(true);
    const file = e.target.files[0];
    const filesArray = Array.from(e.target.files);
    if (file || filesArray) {
      const index = SelectedFile.findIndex(
        (file) => file.key === selectedItem.key
      );
      const item = SelectedFile[index];
      if (["html", "pdf", "pdfprinted"].includes(selectedItem.key)) {
        item.value = file.name;
        item.file = file;
      } else {
        item.value = file.name;
        const maxFiles =
          activeTab === "web" &&
          SubCategorieName.subCategory === "WEBSITE_BANNER"
            ? 1
            : 6;
        if (filesArray.length <= maxFiles) {
          activeTab === "socialMedia"
            ? setCheck([...check, ...filesArray])
            : setWebInterface([...webInterface, ...filesArray]);
        } else {
          setMaxLimitExceed(true);
        }
      }
      setSelectedFile([...SelectedFile]);
      setTimeout(() => {
        setShowLoader(false);
      }, 1000);
      fileInputRef.current.value = "";
    }
  };
  const displayFormFields = () => {
    const currentTabData =
      activeTab === "marketingMaterial"
        ? email
        : activeTab === "socialMedia"
        ? socialMediaData
        : activeTab === "web"
        ? webData
        : printedData;
    const updated = currentTabData
      ? currentTabData[currentTabData?.length - 1]
      : null;
    const val = updated?.updatedAt
      ? convertToDateFormat(updated.updatedAt)
      : "N/A";

    return (
      <>
        <div className="addSubcategoriForm">
          {activeTab !== "web" &&
            emailAndSmsDetails.map((dataItem) => {
              const value = dataItem.value;
              return (
                [activeTab].includes(dataItem.tab) && (
                  <div
                    style={{ width: "50%" }}
                    className={
                      dataItem.key === "sms" ? "" : "addSubcategoryInput"
                    }
                    key={`displayFormFields${dataItem.title}`}
                  >
                    <CustomInput
                      dataItem={{ ...dataItem, value }}
                      additionalSx={{
                        "& .MuiInputBase-input": {
                          padding: "0 0 0",
                        },
                        "& .css-ll96sp-MuiInputBase-root-MuiInput-root": {
                          marginTop: "0",
                        },
                        fontSize: "1.25vw",
                        width: "120%",
                        margin: 0,
                      }}
                      multiline={true}
                      id="standard-basic"
                      label="Standard"
                      variant="standard"
                      handleInput={(data) => {
                        handleTextChange(data);
                      }}
                      maxRow={3}
                      required={true}
                    />
                  </div>
                )
              );
            })}

          <div style={{}}>
            {activeTab === "web" && isEmpty && (
              <div className="emptyphoto">
                You have no added photos. Please Upload
              </div>
            )}

            {SelectedFile.map((file) => {
              return (
                [activeTab].includes(file.tab) && displayingUploadButton(file)
              );
            })}
          </div>
          {(currentTabData || []).length > 0 && !isEmpty && (
            <div style={{ marginTop: "10px" }}>
              {`Last updated on:`}{" "}
              <span style={{ color: "#198eba" }}>{val}</span>
            </div>
          )}
          {displayingSubmitButton()}
          {activeTab === "web" && !isEmpty && displayingFileCard({})}
          {activeTab === "socialMedia" &&
            !isEmpty &&
            displaySocialMediaImages()}

          {maxLimitExceed && (
            <ConfirmModal
              cancelText="Okay"
              handleCancel={() => setMaxLimitExceed(false)}
              isAlert={true}
              message={
                SubCategorieName.subCategory === "WEBSITE_BANNER"
                  ? "Please upload only one file at a time."
                  : "Please select up to 6 files."
              }
            />
          )}
        </div>
      </>
    );
  };

  const displayModalContent = () => {
    return (
      <>
        {displayHeader()}
        {displayFormFields()}
      </>
    );
  };

  return (
    <div>
      <CustomCard
        cardData={displayModalContent()}
        sxProps={{
          height: "60vh",
          width: "90vh",
          boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
          display: "flex",
          flexDirection: "column",
        }}
      />
    </div>
  );
};

export default MarketingMaterialForm;
