import { GET_PPROPERTIES, GET_PROPERTY } from "../actions/actionTypes";

const initialState = {
  properties: [],
  property: null,
  loading: true,
  messsage: "",
};
const propertyListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PPROPERTIES:
      return {
        ...state,
        properties: payload,
      };
    case GET_PROPERTY:
      return {
        ...state,
        property: state.properties.find(
          (propertyItem) => propertyItem._id === payload
        ),
      };
    default:
      return state;
  }
};
export default propertyListReducer;
