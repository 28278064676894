import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import Signup from "./signup/Signup";
import Login from "./login/Login";
import ResetPassword from "./reset-password/ResetPassword";
import ForgotPassword from "./login/ForgotPassword";

function PublicRoutes() {
  return (
    <Fragment>
      <Switch>
        <Route
          path="/login/:role"
          render={({ match }) => <Login role={match.params.role} />}
        />
        <Route
          path="/resetpassword/:role"
          render={({ match }) => <ResetPassword role={match.params.role} />}
        />
        <Route
          path="/forgotpassword/:role"
          render={({ match }) => <ForgotPassword role={match.params.role} />}
        />
        <Route
          path="/signup/:role"
          render={({ match }) => <Signup role={match.params.role} />}
        />
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </Fragment>
  );
}

export default PublicRoutes;
