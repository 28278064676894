/**@description displays property view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import axios from "axios";
import {
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_SUCCESS,
  USER_LOGIN_REQUEST,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
} from "../actionTypes";
import config from "../../../config/index";
import { handleLogin } from "../../../utils/authentication";

/**@description USER Signup Action Creator Function */

export const userSignup = (userInfo) => {
  return new Promise((resolve) => {
    axios
      .post(`${config.RestServiceURL}/users`, userInfo)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        console.error("userSignup", err);
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description Forgot Password */

export const forgotPassword = (username) => {
  return new Promise((resolve) => {
    axios
      .put(`${config.RestServiceURL}/users/forgot-password`, { username })
      .then((res) => {
        resolve({ status: true });
      })
      .catch((err) => {
        console.error("forgotpassword", err);
        resolve({
          status: false,
          message: err.response?.data?.message,
          userStatus: err.response?.data?.userStatus,
        });
      });
  });
};

/**@description Confirm Password */

export const confirmPassword = ({ confirmationCode, password, username }) => {
  return new Promise((resolve) => {
    axios
      .put(`${config.RestServiceURL}/users/confirm-forgot-password`, {
        confirmationCode,
        password,
        username,
      })
      .then((res) => {
        resolve({ status: true });
      })
      .catch((err) => {
        console.error("confirmpassword", err.response);
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description Resend temporary password */

export const ResendTemporaryPassword = (username) => {
  return new Promise((resolve) => {
    axios
      .put(`${config.RestServiceURL}/users/resend-temporary-password`, {
        username,
      })
      .then((res) => {
        resolve({ status: true });
      })
      .catch((err) => {
        console.error("ResendPassword", err.response);
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description USER Login Action Creator Function */

export const UserLogin =
  (userInfo, roleData, history, role) => async (dispatch) => {
    return new Promise((resolve) => {
      const username = userInfo.email;
      const password = userInfo.password;
      dispatch({
        type: USER_LOGIN_REQUEST,
      });
      axios
        .post(`${config.RestServiceURL}/users/login`, {
          username,
          password,
        })
        .then((res) => {
          const { data } = res;
          dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: { data, username, roleData },
          });
          resolve({ status: true, data });
        })
        .catch((error) => {
          dispatch({
            type: USER_LOGIN_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          });
          resolve({ status: false });
        });
    });
  };

/**@description USER Reset Password Action Creator FUnction */
export const resetPassword = (username, password, session) => {
  return new Promise((resolve) => {
    axios
      .post(`${config.RestServiceURL}/users/update-password-challenge`, {
        username,
        password,
        session,
      })
      .then((res) => {
        if (res.data?.AuthenticationResult) {
          handleLogin(res.data.AuthenticationResult || {});
        }
        resolve({ status: true });
      })
      .catch((error) => {
        console.error("ResetPassword", error.response);
        resolve({
          status: false,
          message: error.response?.data?.message
            ? error.response.data.message
            : error.message,
        });
      });
  });
};

/**@description delete a property
 */
export const deleteManager = (id) => {
  return new Promise((resolve) => {
    axios
      .delete(`${config.RestServiceURL}/users/${id}`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        console.error("deleteProperty", err);
        resolve(false);
      });
  });
};
