import React, { useState } from "react";
import {
  PENDINGREQUEST_BOX_STYLE,
  ORDER_BOX_STYLE,
} from "../../../components/Constants";
import "./Orders.scss";
import CustomButton from "../../../components/custom-button/CustomButton";
import CustomModal from "../../../components/custom-modal/CustomModal";

const OrdersModal = (props) => {
  const [largeItemModal, setLargeItemModal] = useState(false);

  const toggleChild = () => {
    setLargeItemModal(true);
  };
  const handleRemoveIcon = () => {
    props.setShowModal(false);
  };

  const getTitle = (key, item) => {
    if (key === "customerAddress") {
      return "Customer Address";
    } else if (key === "clientName") {
      return "Customer Name";
    } else if (key === "selectedPlan") {
      return "Selected Plan/month";
    } else if (key === "storageFacilityAddress") {
      return "Storage Facility Address";
    } else if (key === "orderId") {
      return "Order ID";
    } else {
      return;
    }
  };

  const renderInput = (key, data) => {
    const keyToUse = getTitle(key);
    return (
      <div className="row">
        <div className="itemHeader">{keyToUse} :</div>
        <div className="itemNameStyle">{`${
          key === "selectedPlan"
            ? data[key] === ""
              ? ""
              : "$" + data[key]
            : data[key]
        }`}</div>
      </div>
    );
  };

  const displayButton = () => {
    return (
      <div className={"addButton"}>
        <CustomButton
          title={`${"Close"}`}
          additionalSx={{
            padding: "0.5vw 4vw",
            fontWeight: 600,
            fontSize: "1vw",
          }}
          handleButtonClick={() => handleRemoveIcon()}
        />
      </div>
    );
  };

  const largeItems = (data) => {
    return (
      <>
        <div className="row1">
          <div>Item</div>
          <div>Quantity</div>
        </div>
        {(data.nonBinItemList || []).map((item) => (
          <div className="row1">
            <div className="itemStyles">{item?.item?.item}</div>
            <div className="itemStyles">{item?.quantity || ""}</div>
          </div>
        ))}
      </>
    );
  };

  const orderDetails = () => {
    return (
      <div>
        {(props.popUpDetail || []).map((data, index) => (
          <div key={index}>
            <div className="popUpStyle">
              {["orderId", "clientName", "customerAddress"].map((key) => {
                return renderInput(key, data);
              })}
              {data.noOfBins > 0 && (
                <div className="row">
                  <div className="itemHeader">No. Of Bins :</div>
                  <div className="item">{`${data.noOfBins} ${
                    data.noOfBins === 1 ? "Bin" : "Bins"
                  }`}</div>
                </div>
              )}

              {data.noOfNonBins > 0 && (
                <div className="row">
                  <div className="itemHeader">No. Of Non Bins :</div>
                  <div
                    className="item"
                    onClick={() => {
                      toggleChild();
                    }}
                  >{`${data.noOfNonBins} Large ${
                    data.noOfNonBins === 1 ? "item" : "items"
                  }`}</div>
                </div>
              )}

              <div className="row">
                <div className="itemHeader">Coordinates :</div>
                {data?.unit !== "" && data?.coordinates !== "" && (
                  <div className="itemNameStyle">{`OrderID: #${data?.orderId}, Unit: ${data?.unit}, ${data?.coordinates} `}</div>
                )}
              </div>
              {["selectedPlan", "storageFacilityAddress"].map((key) => {
                return renderInput(key, data);
              })}
            </div>
           <CustomModal
              isOpen={largeItemModal}
              sx={ORDER_BOX_STYLE}
              handleRemoveIcon={() => setLargeItemModal(false)}
              content={() => largeItems(data)}
              closeButtonStyle= "selectmodalRemoveIcon"
            /> 
          </div>
        ))}
        {displayButton()}
      </div>
    );
  };

  return (
    <CustomModal
      isOpen={true}
      sx={PENDINGREQUEST_BOX_STYLE}
      handleRemoveIcon={() => handleRemoveIcon()}
      content={orderDetails}
    />
  );
};

export default OrdersModal;
