import React, { useState, useEffect } from "react";
import "./CouponCode.scss";
import CustomTable from "../../../components/custom-table/Customtable";
import { COUPONCODES_HEADING } from "../../Constants";
import CustomButton from "../../../components/custom-button/CustomButton";
import IconButton from "@mui/material/IconButton";
import TenantListEmpty from "../../../components/tenant-list-empty/TenantListEmpty";
import AddCoupon from "./AddCoupon";
import { ADD_COUPON_CODE, EXPIRY_DATE } from "../../../components/Constants";
import _ from "lodash";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import CustomModal from "../../../components/custom-modal/CustomModal";
import CustomDate from "../../../components/custom-date/CustomDate";
import {
  getAllCouponCodes,
  deleteSingleCouponCode,
  deleteMultipleCouponCode,
  activeDeactiveCouponCode,
} from "../../../redux/actions/discountCodes";
import {
  convertToDMYFormat,
  convertNumberToDecimal,
} from "../../../utils/helperMethods";
import Loader from "../../../components/loader/Loader";

const CouponCodes = () => {
  const [selected, setSelected] = useState([]);
  const [addCouponCode, setAddCouponCode] = useState(false);
  const [couponDetails, setCouponDetails] = useState([...ADD_COUPON_CODE]);
  const [edit, setEdit] = useState(false);
  const [expiryDate, setExpiryDate] = useState(false);
  const [newExpiryDate, setNewExpiryDate] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [statusModal, setStatusModal] = useState({});
  const [confirmationPopUp, setConfirmationPopUp] = useState(false);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [deactivate, setDeActive] = useState(false);

  const handleData = (selectedData) => {
    setSelected(selectedData);
  };

  const getAllCouponDetails = async () => {
    setShowLoader(true);
    const { data } = await getAllCouponCodes();
    setData(data);
    setShowLoader(false);
  };

  /**@description handle close add/edit tenant
   */
  const handleClose = () => {
    setAddCouponCode(false);
    const coupon = _.cloneDeep(couponDetails)
    coupon.forEach((data) => {
      data.value = "";
      data.hasError = false;
    });
    setCouponDetails([...coupon]);
  };

  useEffect(() => {
    handleClose();
    getAllCouponDetails();
  }, []);

  let couponTableData = [];
  (data || [])?.map((data) => {
    const couponCode = data.couponInfo?.name;
    const discountPercentage = `${data.couponInfo?.discountPercentage}%`;
    const startDate = convertToDMYFormat(
      data.couponInfo?.createdAt,
      "MM-DD-YYYY"
    );
    const expiryDate = convertToDMYFormat(
      data.couponInfo?.expiryDate,
      "MM-DD-YYYY"
    );
    const noOfUsers = `${data.noOfTimesApplied}`;
    const discountAmount =
      data.totalDiscountedAmountWithoutTax > 0 &&
      `$${convertNumberToDecimal(data.totalDiscountedAmountWithoutTax)}`;

    couponTableData.push({
      _id: data.couponInfo?._id,
      code: couponCode,
      percentage: discountPercentage,
      startDate,
      expiryDate,
      noOfClaims: noOfUsers,
      discountAmount,
      isActive: data.couponInfo?.isActive,
    });
  });

  const displayContent = () => {
    const value = newExpiryDate.value || "";
    return (
      <div className="content">
        <div className="detail">Update discount code expiry date</div>
        <CustomDate
          dataItem={{
            title: "Expiry Date",
            type: "date",
            key: "expiryDate",
            value,
          }}
          minDate={new Date()}
          handleDateChange={(data) => setNewExpiryDate(data)}
          required={true}
        />
        <div style={{ alignSelf: "center" }}>
          <CustomButton
            title={"Update Expiry Date"}
            className="expiryButton"
            handleButtonClick={() => activateOrDeactivateCouponCodes()}
            additionalSx={{
              opacity: newExpiryDate.value === "" ? 0.5 : 1,
              visibility: newExpiryDate.value === "" ? "hidden" : "block",
            }}
            disabled={newExpiryDate.value === undefined}
          />
        </div>
      </div>
    );
  };

  const deactivateCoupon = async () => {
    const couponId = selectedItem.couponInfo._id;
    const { status, message } = await activeDeactiveCouponCode(couponId, {
      isActive: false,
    });
    handleShowModal({
      cancelText: "Ok",
      title: status ? "Success" : "Failed",
      message: status
        ? "Discount code deactivated successfully."
        : message || "Something went wrong.Please try again.",
      isAlert: true,
    });
    setDeActive(false);
  };

  const onClickHandler = async (id) => {
    const selectedItem = data.find((item) => id === item.couponInfo?._id);
    const isActive = !selectedItem.couponInfo?.isActive;
    selectedItem.couponInfo.isActive = isActive;
    setData([..._.cloneDeep(data)]);
    setExpiryDate(selectedItem.couponInfo.isActive);
    setSelectedItem(selectedItem);
    if (!selectedItem.couponInfo.isActive) {
      setDeActive(true);
    }
  };

  const activateOrDeactivateCouponCodes = async () => {
    if (selectedItem.couponInfo.isActive) {
      const couponId = selectedItem.couponInfo._id;
      const { status, message } = await activeDeactiveCouponCode(couponId, {
        isActive: true,
        expiryDate: newExpiryDate.value,
      });
      handleShowModal({
        cancelText: "Ok",
        title: status ? "Success" : "Failed",
        message: status
          ? "Discount code expiry date updated successfully."
          : message || "Something went wrong.Please try again.",
        isAlert: true,
      });
    }
    handleExpiryModalRemove();
  };

  const editCouponData = ({ key, value }) => {
    if (key === "startDate" || key === "expiryDate") {
      const index = couponDetails.findIndex((data) => data.key === key);
      const item = couponDetails[index];
      item.value = value;
    }
    couponDetails[key] = value;
    setCouponDetails([...couponDetails]);
  };

  const handleHideModal = () => {
    statusModal.show = false;
    setStatusModal({ ...statusModal });
    getAllCouponDetails();
  };

  const handleShowModal = ({
    cancelText,
    confirmText,
    isAlert,
    title,
    message,
    handleConfirm = () => {},
  }) => {
    statusModal.show = true;
    statusModal.cancelText = cancelText || "Cancel";
    statusModal.confirmText = confirmText || "Ok";
    statusModal.isAlert = isAlert || false;
    statusModal.title = title || "";
    statusModal.message = message || "";
    statusModal.handleConfirm = handleConfirm;
    statusModal.handleCancel = handleHideModal;
    setStatusModal({ ...statusModal });
  };

  /**@description Displaying Coupon Code Component */
  const displayCouponCodeTable = () => {
    return (
      <>
        {((data?.length || []) > 0) ? (
          <div className="couponTable">
            <CustomTable
              rows={couponTableData || []}
              tableHeading={COUPONCODES_HEADING}
              maxHeight={window.innerHeight * 0.6}
              handleSelection={handleData}
              selected={selected}
              onClickHandler={onClickHandler}
            />
            <div
              className="managerButtondiv"
              role="presentation"
              onClick={() => {
                setAddCouponCode(true);
                setEdit(false);
              }}
            >
              <IconButton
                aria-label="upload picture"
                component="span"
                className="managerAddButton"
              >
                <img src={"/images/shared/plus.svg"} alt="" />
              </IconButton>
            </div>
          </div>
        ) : (
          <div className={"tenantListEmpty"}>
            <TenantListEmpty
              width={"75%"}
              header={"You have no Discount Codes Added"}
              buttonText="Add New Discount Code"
              canAdd={true}
              handleButtonClick={() => setAddCouponCode(true)}
            />
          </div>
        )}
      </>
    );
  };

  /**@description handles edit coupon code details*/
  const handleEditCoupon = () => {
    setAddCouponCode(true);
    setEdit(true);
    if (selected?.length === 1) {
      const tData = data.find((x) => x.couponInfo?._id === selected[0]);
      couponDetails[0].value = tData.couponInfo.name;
      couponDetails[1].value = tData.couponInfo.discountPercentage;
      couponDetails[2].value = tData.couponInfo.expiryDate;
      setCouponDetails([...couponDetails]);
    }
  };

  /**@description Displaying edit Button*/
  const displayingEditButton = () => {
    return (
      <CustomButton
        title={"Edit"}
        className="managerEditbutton"
        handleButtonClick={handleEditCoupon}
        additionalSx={{
          opacity: selected?.length !== 1 ? 0.5 : 1,
          visibility: selected?.length > 1 ? "hidden" : "block",
        }}
        disabled={selected?.length === 0}
      />
    );
  };

  const deleteCouponCodes = async () => {
    let couponId = selected[0];
    const { status, message } =
      selected.length > 1
        ? await deleteMultipleCouponCode(selected)
        : await deleteSingleCouponCode(couponId);
    getAllCouponDetails();
    if (status) {
      handleShowModal({
        cancelText: "Ok",
        title: "Success",
        message: "Discount code deleted successfully.",
        isAlert: true,
      });
    } else {
      handleShowModal({
        cancelText: "Ok",
        title: "Failed",
        message: message || "Something went wrong!,Please try again.",
        isAlert: true,
      });
    }
    setConfirmationPopUp(false);
    setSelected([]);
  };

  /*@description Displaying edit Button*/
  const displayingDeleteButton = () => {
    return (
      <CustomButton
        title={"Delete"}
        className="managerDeletebutton"
        handleButtonClick={() => setConfirmationPopUp(true)}
        additionalSx={{
          opacity: selected?.length === 0 ? 0.5 : 1,
        }}
        disabled={selected?.length === 0}
      />
    );
  };

  const handleExpiryModalRemove = () => {
    setExpiryDate(false);
    setNewExpiryDate("");
    getAllCouponDetails();
  };

  const handleCancelDeActiveCode = () => {
    setDeActive(false);
    getAllCouponDetails();
  };

  return (
    <div className="couponCodeOverview">
      {addCouponCode && (
        <AddCoupon
          couponDetails={couponDetails}
          handleClose={handleClose}
          handleChange={editCouponData}
          setAddCouponCode={setAddCouponCode}
          edit={edit}
          setData={setData}
          data={data}
          selected={selected}
          getAllCouponDetails={getAllCouponDetails}
          handleShowModal={handleShowModal}
          setSelected={setSelected}
        />
      )}
      {(data?.length ||[]) > 0 && (
        <div className="managerButtons">
          {displayingEditButton()}
          {displayingDeleteButton()}
        </div>
      )}
      {displayCouponCodeTable()}
      {confirmationPopUp && (
        <ConfirmModal
          handleCancel={() => setConfirmationPopUp(false)}
          handleConfirm={() => deleteCouponCodes()}
          confirmText="Ok"
          message={"Are you sure! you want to delete selected discount code."}
          title={"Delete Discount Code"}
        />
      )}
      {expiryDate && (
        <CustomModal
          isOpen={true}
          handleRemoveIcon={handleExpiryModalRemove}
          sx={EXPIRY_DATE}
          content={displayContent}
          closeButtonStyle="expiryCrossIcon"
        />
      )}
      {deactivate && (
        <ConfirmModal
          handleCancel={() => handleCancelDeActiveCode()}
          handleConfirm={() => deactivateCoupon()}
          confirmText="Ok"
          cancelText="Cancel"
          message={
            "Are you sure! you want to deactivate selected discount code."
          }
          title={"Deactivate Discount Code"}
        />
      )}
      {statusModal?.show ? (
        <ConfirmModal
          cancelText={statusModal.cancelText}
          confirmText={statusModal.confirmText}
          isAlert={statusModal.isAlert}
          title={statusModal.title}
          message={statusModal.message}
          handleCancel={statusModal.handleCancel}
          handleConfirm={statusModal.handleConfirm}
        />
      ) : null}
      <Loader show={showLoader} />
    </div>
  );
};

export default CouponCodes;
