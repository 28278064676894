/**@description returns UI of date component
 *@author Anusha Gurajapu
 *@version 1.0.0
 */
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from "../custom-input";

const CustomDate = (props) => {
  const { dataItem } = props;

  const Input = React.forwardRef(
    ({ onChange, placeholder, value, isSecure, id, onClick }, ref) => {
      return (
        <CustomInput
          dataItem={{
            ...dataItem,
            value: `${value}`,
            callback: true,
            type: "text",
          }}
          additionalSx={{ width: "100%", margin: 0 }}
          handleInput={onChange}
          handleClick={onClick}
          required={props.required}
        />
      );
    }
  );

  return (
    <DatePicker
      dateFormat={props.dateFormat}
      value={dataItem.value ? new Date(dataItem.value) : null}
      selected={dataItem.value ? new Date(dataItem.value) : null}
      customInput={<Input />}
      onChange={(date) => {
        props.handleDateChange({ key: dataItem.key, value: date });
      }}
      strictParsing={props.strictParsing}
      showMonthDropdown={props.showMonthDropdown}
      showYearDropdown={props.showYearDropdown}
      dropdownMode={props.dropdownMode}
      todayButton={props.todayButton}
      shouldCloseOnSelect={props.shouldCloseOnSelect}
      minDate={props.minDate}
      maxDate={props.maxDate}
      placeholderText={dataItem.placeholder}
      disabled={props.disabled}
    />
  );
};

CustomDate.defaultProps = {
  dataItem: {},
  handleDateChange: () => {},
  dateFormat: "dd/MM/yyyy",
  showMonthDropdown: false,
  showYearDropdown: false,
  dropdownMode: "select",
  strictParsing: true,
  todayButton: "Today",
  shouldCloseOnSelect: true,
  minDate: null,
  maxDate: null,
  required: true,
  disabled:false
};

export default CustomDate;
