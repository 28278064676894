/**@description displays QR Code generator view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import React, { useState } from "react";
import Custombuton from "../../../components/custom-button/CustomButton";
import Loader from "../../../components/loader/Loader";
import "./qrGenerator.scss";
import writeXlsxFile from "write-excel-file";
import { DOWNLOAD_CUSTOMER_SAMPLE_QR } from "../../../components/Constants";
import { QR_CODE } from "../../Constants";
import { generateQRcodes } from "../../../redux/actions/territory-manager-actions/territoryManager";
import CustomInput from "../../../components/custom-input/CustomInput";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import { characterCheckRegex } from "../../../utils/helperMethods";

const QrcodeGenerator = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [statusModal, setStatusModal] = useState({});
  const [qrCode, setqrCode] = useState({ ...QR_CODE });

  /**@description Handle Download Sample File to  Functionality
   */
  const handleDownloadSample = async () => {
    setShowLoader(true);
    const numOfCodes = {
      noOfQRCodes: qrCode?.value,
    };
    const { data, message, status } = await generateQRcodes(numOfCodes);
    if (status) {
      const excelData = [];
      data.forEach((qr) => {
        excelData.push({
          qrCodes: qr.qrCode,
        });
      });
      await writeXlsxFile(excelData, {
        schema: DOWNLOAD_CUSTOMER_SAMPLE_QR.headers,
        fileName: "qrCodes.xlsx",
      });
    } else if (message) {
      handleShowModal({
        cancelText: "Ok",
        title: message && `Failed`,
        message: message && `Failed to Download QR Codes, please try again.`,
        isAlert: true,
      });
    }
    setShowLoader(false);
    qrCode.value = "";
    setqrCode({ ...qrCode });
  };
  const handleInput = ({ value }) => {
    if (value.length <= qrCode.maxLength) {
      if (characterCheckRegex(value) === false || value < qrCode.minValue) {
        qrCode.value = value;
        qrCode.hasError = true;
      } else {
        qrCode.value = value;
        qrCode.hasError = false;
      }
    }
    if (value.length === 0) {
      qrCode.value = value;
      qrCode.hasError = false;
    }
    qrCode.errorMessage = `Please Enter a Valid Number`;
    setqrCode({ ...qrCode });
  };

  /**@description Handle hide Model Functionality
   */
  const handleHideModal = () => {
    statusModal.show = false;
    setStatusModal({ ...statusModal });
  };

  /**@description Handle Show Model Functionality
   */
  const handleShowModal = ({
    cancelText,
    confirmText,
    isAlert,
    title,
    message,
    handleConfirm = () => {},
  }) => {
    statusModal.show = true;
    statusModal.cancelText = cancelText || "Cancel";
    statusModal.confirmText = confirmText || "Ok";
    statusModal.isAlert = isAlert || false;
    statusModal.title = title || "";
    statusModal.message = message || "";
    statusModal.handleConfirm = handleConfirm;
    statusModal.handleCancel = handleHideModal;
    setStatusModal({ ...statusModal });
  };

  const displayCreateQrCodes = () => {
    return (
      <div className='qrcodeInputStyle'>
        <div className='qrCodeTitleStyle'> Generate QR Codes</div>
        <CustomInput
          dataItem={qrCode}
          label='Standard'
          handleInput={(data) => {
            handleInput(data);
          }}
          required={false}
          sxProps={{ width: "80%" }}
          inputProps={{
            maxLength: 5,
            pattern: "^[1-9]+$",
          }}
        />
        <div className='generateButton' style={{ margin: "6.5vh" }}>
          <Custombuton
            title='Download QR codes'
            className={"ExcelButton"}
            handleButtonClick={() => handleDownloadSample()}
            disabled={!qrCode.value || qrCode.hasError === true}
          />
        </div>
      </div>
    );
  };

  /**@description Displaying Body of MFO List Screen Component */
  const displayBody = () => {
    return (
      <div className='mfoListMaintable'>
        <div className='qrcodeMainStyle'>{displayCreateQrCodes()}</div>
      </div>
    );
  };
  return (
    <div className='qrcodeOverview'>
      {displayBody()}
      {statusModal?.show ? (
        <ConfirmModal
          cancelText={statusModal.cancelText}
          confirmText={statusModal.confirmText}
          isAlert={statusModal.isAlert}
          title={statusModal.title}
          message={statusModal.message}
          handleCancel={statusModal.handleCancel}
          handleConfirm={statusModal.handleConfirm}
        />
      ) : null}
      <Loader show={showLoader} />
    </div>
  );
};

export default QrcodeGenerator;
