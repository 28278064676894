import { GET_USER, GET_USERS,GET_MFOS_PROPERTIES } from "../actions/actionTypes";

const initialState = {
  users: [],
  user: null,
  MFOS:[],
};
const usersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_USERS:
      return {
        ...state,
        users: payload,
      };
    case GET_USER:
      return {
        ...state,
        user: state.users.find((userItem) => userItem._id === payload),
      };

      case GET_MFOS_PROPERTIES:
        return {
          ...state,
          MFOS: payload,
        };

    default:
      return state;
  }
};

export default usersReducer;
