/**@description returns UI of Add or Edit tenant component
 *@author Anusha Gurajapu
 *@version 1.0.0
 */
import React, { useRef, useState } from "react";
import readXlsxFile from "read-excel-file";
import writeXlsxFile from "write-excel-file";
import CustomButtom from "../custom-button";
import CustomInput from "../custom-input";
import CustomDate from "../custom-date";
import { DOWNLOAD_TENANTS_SAMPLE, BOX_STYLE } from "../Constants";
import "./AddTenant.scss";
import { validations } from "../../utils/helperMethods";
import CustomModal from "../custom-modal/CustomModal";

const AddTenant = (props) => {
  const { isAdd } = props;
  const [download, setDownload] = useState(false);
  const [fileData, setFileData] = useState({});
  const [tenantsData, setTenantsData] = useState([]);
  const uploadTenants = useRef(null);

  /**@description Handle File upload to  Functionality
   */
  const handleFileUpload = (e) => {
    if (e?.target?.files?.[0]) {
      const file = e.target.files[0];
      setFileData(file);
      readXlsxFile(file).then((rows) => {
        rows.shift();
        setTenantsData([...rows]);
      });
    }
  };

  /**@description Handle File Remove File to  Functionality
   */
  const handleRemoveFile = () => {
    uploadTenants.current.value = null;
    setFileData({ ...{} });
    setTenantsData([]);
  };

  /**@description Handle Download Sample File to  Functionality
   */
  const handleDownloadSample = async () => {
    setDownload(true);
    await writeXlsxFile(DOWNLOAD_TENANTS_SAMPLE.data, {
      schema: DOWNLOAD_TENANTS_SAMPLE.headers,
      fileName: "sampleTenants.xlsx",
    });
    setDownload(false);
  };
  const handleTextChange = ({ key, value, regexType }) => {
    const { tenantInfo } = props;
    const item = validations(tenantInfo, key, value, regexType);
    props.handleChange({ key: item.key, value: item.value });
  };

  /**@description Displaying Header  Functionality
   */
  const displayHeader = () => (
    <div className={"headerRow"}>
      <div className={"addNewTenant"}>{isAdd ? "Add New" : "Edit"} Tenant </div>
      {isAdd ? (
        <div
          className={"uploadRow"}
          role="presentation"
          onClick={() => {
            if (!download) handleDownloadSample();
          }}
        >
          <img
            src="/images/shared/download.svg"
            alt="download"
            className={"addTenantdownloadIcon"}
          />
          <div className={"uploadText"}>
            {download ? "Downloading..." : "Download Sample"}
          </div>
        </div>
      ) : null}
    </div>
  );

  /**@description Displaying Excel Upload   Functionality
   */

  const displayUploadExcel = () => (
    <div className={"uploadExcelRow"}>
      <label htmlFor="uploadTenants" className={"uploadRow"}>
        <input
          className={"upload"}
          type="file"
          accept={
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }
          id="uploadTenants"
          onChange={(e) => handleFileUpload(e)}
          ref={uploadTenants}
        />
        <img src="/images/shared/upload.svg" alt="uploadIcon" />
        <div className={"uploadText"}>Upload Excel Tenant List</div>
      </label>
      {uploadTenants?.current?.value ? (
        <div className={"addTenantFileName"}>
          <span>{fileData?.name || ""}</span>
          <img
            src="/images/shared/delete.svg"
            alt="Remove"
            className={"uploadTenantDelete"}
            role="presentation"
            onClick={() => handleRemoveFile()}
          />
        </div>
      ) : null}
    </div>
  );

  /**@description Displaying Form Functionality
   */

  const displayFormFields = () => (
    <form
      onSubmit={() => {
        props.handleSubmit(tenantsData);
      }}
    >
      {!tenantsData?.length ? (
        <div className="uploadRow">
          {props.tenantInfo.map((dataItem) => {
            const value = props.tenantData?.[dataItem.key] || "";
            return (
              <div
                className={"addTenantInput"}
                key={`displayFormFields${dataItem.title}`}
              >
                {dataItem.type === "date" ? (
                  <CustomDate
                    dataItem={{ ...dataItem, value }}
                    disabled={dataItem.title === 'Lease End Date' && props.isEndDate}
                    handleDateChange={props.handleChange}
                    required={true}
                  />
                ) : (
                  <CustomInput
                    dataItem={{ ...dataItem, value }}
                    additionalSx={{
                      width: "100%",
                      margin: 0,
                    }}
                    handleInput={(data) => handleTextChange(data)}
                    required={true}
                  />
                )}
              </div>
            );
          })}
        </div>
      ) : null}
      <div className={"addTenantButton"}>
        <CustomButtom
          disabled = {props.tenantInfo.some(data=> data.hasError)}
          title={`${isAdd ? "Add Tenants" : "Update Tenant"}`}
          additionalSx={{
            padding: "1vw 8vw",
            fontWeight: 600,
            fontSize: "1.5vw",
          }}
        />
      </div>
    </form>
  );

  const displayModalContent = () => {
    return (
      <>
        {displayHeader()}
        {isAdd ? displayUploadExcel() : null}
        {displayFormFields()}
      </>
    );
  };

  return (
    <CustomModal
      isOpen={props.isOpen}
      handleRemoveIcon={() => props.handleClose()}
      sx={BOX_STYLE}
      content={displayModalContent}
      closeButtonStyle="removeIcon"
    />
  );
};

AddTenant.defaultProps = {
  isOpen: true,
  isAdd: true,
  tenantData: {},
  handleClose: () => {},
  handleChange: () => {},
  handleSubmit: () => {},
};

export default AddTenant;
