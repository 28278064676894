/**@description displays card UI
 *@author Anusha Gurajapu
 *@version 1.0.0
 */
import React from "react";
import { Card } from "@mui/material";

const CustomCard = (props) => {
  return (
    <Card
      raised={props.raised}
      children={props.cardData}
      sx={{ ...props.sxProps, ...props.additionalSx }}
    />
  );
};

CustomCard.defaultProps = {
  cardData: null,
  raised: true,
  sxProps: null,
  additionalSx: {},
};

export default CustomCard;
