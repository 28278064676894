/**@description displays MFO's List view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Custombuton from "../../../components/custom-button/CustomButton";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import Loader from "../../../components/loader/Loader";
import "./MfoList.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllMFO } from "../../../redux/actions/usersActions";

import CustomTable from "../../../components/custom-table/Customtable";
import {
  ADD_MULTI_FAMILY_PROPERTY,
  COUNTRY_CODE,
  MFOLIST_HEADINGS,
} from "../../Constants";
import TenantListEmpty from "../../../components/tenant-list-empty/TenantListEmpty";
import AddMultiFamily from "../add-new-multifamily-operator/AddMultiFamily";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  createMultipleProperty,
  fetchMFOProperties,
} from "../../../redux/actions/propertyListActions";
import {
  deletebulkMFO,
  createbulkMFO,
} from "../../../redux/actions/usersActions";
import { userSignup } from "../../../redux/actions/user-actions/userAction";
import { CREATE_MFO } from "../../Constants";
import {
  getFormattedPhoneNum,
  reformatPhoneNumber,
} from "../../../utils/helperMethods";
import {
  ADD_MULTIFAMILY_OPERATOR,
  BOX_STYLE,
} from "../../../components/Constants";
import PropertyDetailsList from "../../property-details/PropertyDetails";
import CustomModal from "../../../components/custom-modal/CustomModal";
import _ from 'lodash';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: "2.5vw",
          },
        },
      },
    },
  },
});

const MfoList = (props) => {
  const mfoList = useSelector((state) => state.usersReducer.users);

  const mfotableHeadingsData = [...MFOLIST_HEADINGS];
  const [mfoDetailsData, setmfoDetailsData] = useState([...mfoList]);
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [showAddMfo, setShowAddMfo] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [statusModal, setStatusModal] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [PropertyData, setPropertyData] = useState({});
  const [mfoData, setmfoData] = useState({});
  const [allData, setAllData] = useState([]);
  const [ShowMfo, setShowMfo] = useState(false);
  const [managerData, setmanagerData] = useState({});
  const [disable, setDisable] = useState(true);
  const [showMFOProperties, setShowMFOProperties] = useState(null);

  const [mfoDetails, setmfodetails] = useState([...ADD_MULTIFAMILY_OPERATOR]);
  const [propertyDetails, setPropertyDetails] = useState([
    ...ADD_MULTI_FAMILY_PROPERTY,
  ]);

  /**@description Fetching MFO's Functionality
   */
  const fetchMfos = async () => {
    setShowLoader(true);
    const MFO_data = await dispatch(getAllMFO());
    (MFO_data || []).map((data) => {
      data.phoneNumber = getFormattedPhoneNum(data.phoneNumber.slice(-10));
    });
    setmfoDetailsData([...MFO_data]);

    setShowLoader(false);
  };

  useEffect(() => {
    fetchMfos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**@description Delete Property Functionality
   */
  const deleteproperty = (id) => {
    const updates = allData.filter((each, idx) => each.manager.email !== id);

    setAllData([...updates]);
  };

  /**@description Handle Add MFO  Functionality
   */
  const handleAddMfo = (add, tData = {}) => {
    setIsAdd(!!add);
    setShowAddMfo(true);
  };

  /**@description Handle change MFO  Functionality
   */
  const handleChangeMfo = ({ key, value }) => {
    mfoDetails[key] = value;
    setmfodetails([...mfoDetails]);
    setShowMfo(false);
  };

  /**@description Handle change Property  Functionality
   */
  const handleChangeproperty = ({ key, value, data }) => {
    const index = propertyDetails.findIndex((data) => data.key === key);
    const propertyItem = propertyDetails[index];

    propertyItem[key] = value;
    if (key === "apartmentTypes") {
      propertyItem.value = value;
    }
    setPropertyDetails([...propertyDetails]);
  };

  /**@description Handle Add Property Details  Functionality
   */
  const addpropertyDetails = () => {
    const propertyData = { manager: {} };
    propertyDetails.forEach((property) => {
      const managerDetails = ["Mgrname", "email", "phoneNumber"].includes(
        property.key
      )
        ? true
        : false;
      if (managerData._id) {
        propertyData.manager = managerData;
        if (!managerDetails) {
          propertyData[property.key] = property.value;
        }
      } else {
        managerDetails
          ? (propertyData.manager[property.key] = property.value)
          : (propertyData[property.key] = property.value);
      }
    });
    setAllData([...allData, propertyData]);
    propertyDetails.map((data) => (data.value = ""));
    setPropertyDetails([...propertyDetails]);
  };
  /**@description handle close add/edit tenant
   */
  const handleCloseAddMfo = () => {
    setShowAddMfo(false);
    let mfoInfo = _.cloneDeep(mfoDetails);
    mfoInfo.forEach((item) => {
      item.value = "";
      item.hasError = false;
    });
    setmfodetails(mfoInfo);
  };

  useEffect(() => {
    handleCloseAddMfo();
  }, []);
  /**@description preparing Excel upload Data  Functionality
   */
  const prepareTenantData = (tenant) => {
    const createData = {
      role: "MFO",
    };
    const isArray = Array.isArray(tenant);
    CREATE_MFO.forEach((x, ind) => {
      createData[x] = isArray ? tenant[ind] : tenant[x];
    });
    return createData;
  };

  /**@description handles submit add/edit tenant data
   */
  const handleAddMfoData = async (ExcelData) => {
    setShowLoader(true);

    let createMfoData = [{}];
    mfoDetails.forEach((mfoData) =>
      createMfoData.map((data) => {
        if (mfoData.key === "phoneNumber") {
          data.phoneNumber = `${COUNTRY_CODE}${reformatPhoneNumber(
            mfoData.value
          )}`;
        } else if (mfoData.key === "mfoName") {
          data.name = mfoData.value;
        } else {
          data[mfoData.key] = mfoData.value;
          data.role = "MFO";
        }
      })
    );
    const createMfoExcelData = ExcelData?.map((mfo) => {
      mfo[2] = `${COUNTRY_CODE}${mfo[2].toString()}`;
      return prepareTenantData(mfo);
    });
    createMfoExcelData.shift();
    const mfoPayload = ExcelData?.length ? createMfoExcelData : createMfoData;
    const {
      status: mfoStatus,
      data: mfoDatas,
      message,
      statusCode,
    } = await createbulkMFO(mfoPayload);

    if (message) {
      setShowLoader(false);
      handleShowModal({
        cancelText: "Ok",
        title: `Failed`,
        message:
          statusCode === 409
            ? `Email/phoneNumber is already Exists`
            : `Failed to Add MFO, please try again`,
        isAlert: true,
      });
    }
    if (mfoStatus && !allData.length) {
      handleShowModal({
        cancelText: "Ok",
        title: mfoStatus ? `Success` : `Failed`,
        message: mfoStatus
          ? `MFO  Added successfully`
          : "Failed to Add MFO, please try again",

        isAlert: true,
      });
      fetchMfos();
      setShowAddMfo(false);
      setShowLoader(false);
      setPropertyData({});
      setAllData([]);
      mfoDetails.map((data) => (data.value = ""));
      setmfodetails([...mfoDetails]);
    }

    allData.map(async (m) => {
      m.createdBy = mfoDatas ? mfoDatas[0]?._id : "";
      if (m.manager._id) {
        m.manager = m.manager._id;
      } else {
        const { data } = await userSignup({
          name: m?.manager?.Mgrname,
          email: m?.manager?.email,
          phoneNumber: `${COUNTRY_CODE}${reformatPhoneNumber(
            m?.manager?.phoneNumber
          )}`,
          role: "ACCOUNT_MANAGER",
        });
        m.manager = data?._id;
      }
      const { status: propStatus } = await createMultipleProperty(allData);
      handleShowModal({
        cancelText: "Ok",
        title: propStatus ? `Success` : `Failed`,
        message: propStatus
          ? `MFO  Added successfully`
          : "Failed to Add MFO, please try again",

        isAlert: true,
      });
      fetchMfos();
      setShowAddMfo(false);
      setShowLoader(false);
      mfoDetails.map((data) => (data.value = ""));
      setmfodetails([...mfoDetails]);
      setPropertyData({});
      setAllData([]);
    });
  };

  /**@description Handle Hide Modal  Functionality
   */
  const handleHideModal = () => {
    statusModal.show = false;
    setStatusModal({ ...statusModal });
  };

  /**@description Handle Show Modal  Functionality
   */
  const handleShowModal = ({
    cancelText,
    confirmText,
    isAlert,
    title,
    message,
    handleConfirm = () => {},
  }) => {
    statusModal.show = true;
    statusModal.cancelText = cancelText || "Cancel";
    statusModal.confirmText = confirmText || "Ok";
    statusModal.isAlert = isAlert || false;
    statusModal.title = title || "";
    statusModal.message = message || "";
    statusModal.handleConfirm = handleConfirm;
    statusModal.handleCancel = handleHideModal;
    setStatusModal({ ...statusModal });
  };

  /**@description handles delete MFO*/
  const handleDeleteMfo = async () => {
    if (selected?.length) {
      handleHideModal();
      setShowLoader(true);
      const { status } = await deletebulkMFO(selected);
      if (status) {
        fetchMfos();
        setShowLoader(false);
        setSelected([]);
      } else {
        setShowLoader(false);
      }
      handleShowModal({
        cancelText: "Ok",
        title: status ? `Success` : `Failed`,
        message: status
          ? ` MFO(s) deleted successfully!`
          : `Failed to delete MFO(s), please try again.`,
        isAlert: true,
      });
    }
  };

  /**@description Handle Show Delete Modal  Functionality
   */
  const handleShowDeleteModal = () => {
    if (selected?.length) {
      handleShowModal({
        confirmText: "Delete",
        title: `Delete MultiFamily operato${selected.length > 1 ? "rs" : "r"}`,
        message: `Are you sure you want to delete the selected MF${
          selected.length > 1 ? "O's" : "O"
        }?`,
        handleConfirm: handleDeleteMfo,
      });
    }
  };

  const handleShowPropertiesOfMFO = (mfoId) => {
    setShowMFOProperties(mfoId);
  };

  const getSelectedMFOProperties = async () => {
    return await fetchMFOProperties(showMFOProperties);
  };

  /**@description Handling selected Data from Rows*/
  const handlemfoListSelect = (selectedData) => {
    setSelected(selectedData);
  };

  /*@description Displaying delete Button*/
  const displayingDeleteButton = () => {
    return (
      <Custombuton
        title={"Delete"}
        className="mfoListDeletebutton"
        handleButtonClick={handleShowDeleteModal}
        additionalSx={{
          opacity: selected?.length === 0 ? 0.5 : 1,
        }}
      />
    );
  };

  /**@description Displaying MFO Table Component */
  const displayingmfosTable = () => {
    return (
      <ThemeProvider theme={theme}>
        <div className="mfoListpropertyTable">
          <CustomTable
            rows={mfoDetailsData}
            tableHeading={mfotableHeadingsData}
            handleSelection={handlemfoListSelect}
            selected={selected}
            tablecellpadding={"2.5vw"}
            maxHeight={window.innerHeight * 0.6}
            action={[
              {
                propertyCell: true,
                text: "Properties list",
                handleClick: handleShowPropertiesOfMFO,
                id: "_id",
                className: "mfoListViewDashboard",
              },
            ]}
          />

          <div
            className="mfoListButtondiv"
            role="presentation"
            onClick={() => {
              handleAddMfo(true);
            }}
          >
            <IconButton
              aria-label="upload picture"
              component="span"
              className="mfoListAddButton"
            >
              <img
                src={"/images/shared/plus.svg"}
                alt=""
                className="mfoPlusIcon"
              />
            </IconButton>
          </div>
        </div>
      </ThemeProvider>
    );
  };

  /**@description Displaying Body of MFO List Screen Component */
  const displayBody = () => {
    return mfoDetailsData?.length ? (
      <div className="mfoListMaintable">
        <div className="mfoListButtons">{displayingDeleteButton()}</div>
        {displayingmfosTable()}
      </div>
    ) : (
      <div className={"tenantListEmpty"}>
        <TenantListEmpty
          width={"75%"}
          header="You have no MFO added to your list"
          buttonText="Add New MFO"
          canAdd
          handleButtonClick={() => {
            handleAddMfo(true);
          }}
        />
      </div>
    );
  };

  const propertyDetail = () => {
    return (
      <PropertyDetailsList
        fetchPropertiesData={getSelectedMFOProperties}
        mfoId={showMFOProperties}
      />
    );
  };

  return (
    <div className="mfoListOverview">
      {displayBody()}
      {showAddMfo ? (
        <AddMultiFamily
          isAdd={isAdd}
          propertyData={PropertyData}
          handleClose={handleCloseAddMfo}
          handleAddMfoData={handleAddMfoData}
          handleChangeMfo={handleChangeMfo}
          handleChangeproperty={handleChangeproperty}
          addpropertyDetails={addpropertyDetails}
          allData={allData}
          deleteproperty={deleteproperty}
          setAllData={setAllData}
          disable={disable}
          propertyDetails={propertyDetails}
          mfoDetails={mfoDetails}
          mfo={true}
        />
      ) : null}
      {statusModal?.show ? (
        <ConfirmModal
          cancelText={statusModal.cancelText}
          confirmText={statusModal.confirmText}
          isAlert={statusModal.isAlert}
          title={statusModal.title}
          message={statusModal.message}
          handleCancel={statusModal.handleCancel}
          handleConfirm={statusModal.handleConfirm}
        />
      ) : null}
      {showMFOProperties && (
        <CustomModal
          isOpen={true}
          handleRemoveIcon={() => setShowMFOProperties(null)}
          sx={BOX_STYLE}
          closeButtonStyle='removeIcon'
          content={propertyDetail}
        />
      )}
      <Loader show={showLoader} />
    </div>
  );
};

export default MfoList;
