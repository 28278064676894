import axios from "axios";
import config from "../../config";
import { getUserInfo } from "../../utils/authentication";

export const getAllNotifications = () => {
  return new Promise((resolve) => {
    const userInfo = getUserInfo() || {};
    const senderId = userInfo["custom:userId"];
    console.log("userInfo", userInfo);
    axios
      .get(`${config.RestServiceURL}/notifications/admin/${senderId}`)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          resolve([]);
        }
      })
      .catch((err) => {
        console.error("fetchNotifications", err.response);
        resolve([]);
      });
  });
};

export const getNotificationDetails = (notificationId) => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/notifications/${notificationId}/details`)
      .then((res) => {
        if (res.status === 200) {
          resolve({data: res.data, status: true});
        }
      })
      .catch((err) => {
        console.error("notificationDetails", err.response);
        resolve({status: false});
      });
  });
};

export const getAllAppUsers = () => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/app/users`)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          resolve([]);
        }
      })
      .catch((err) => {
        console.error("getAllUsers", err.response);
        resolve([]);
      });
  });
};

export const sendBulkNotification = (data) => {
  return new Promise((resolve) => {
    axios.post(`${config.RestServiceURL}/notifications/push/multiple-users`, data)
    .then(res => {
        if(res.status === 200 || res.status === 201)
            {
                resolve({status: true, data: res.data})
            }
            else {
                resolve({status: false})
            }
    })
    .catch(err => {
        console.error("sendBulkNotification", err.response)
        resolve([])
    }) 
  });
};
