/**@description displays property view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import React, { useRef, useState, useEffect } from "react";
import { FormControl, OutlinedInput, MenuItem } from "@mui/material";
import readXlsxFile from "read-excel-file";
import CustomButtom from "../../../components/custom-button/CustomButton";
import CustomInput from "../../../components/custom-input/CustomInput";
import "./AddMultiFamily.scss";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import {
  BOX_STYLE,
} from "../../../components/Constants";
import { ADD_MULTI_FAMILY_PROPERTY } from "../../Constants";
import { ITEM_HEIGHT, ITEM_PADDING_TOP } from "../../../components/Constants";
import { APARTMENT_TYPE } from "../../Constants";
import { useTheme } from "@mui/material/styles";
import { getManagers } from "../../../redux/actions/manager-actions/managerActions";
import CustomTable from "../../../components/custom-table/Customtable";
import { PROPERTYTABLE_HEADINGS } from "../../Constants";
import CustomModal from "../../../components/custom-modal/CustomModal";
import { validations } from "../../../utils/helperMethods";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const AddMultiFamily = (props) => {
  const { isAdd } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [fileData, setFileData] = useState({});
  const [ExcelData, setExcelData] = useState([]);
  const uploadTenants = useRef(null);
  const tenant = useSelector((state) => state.tenantsListReducer.tenant);
  const [isDisable, setDisable] = useState(false);
  const [editPropertyIndex, setEditPropertyIndex] = useState(-1);
  const apartmentData = [...APARTMENT_TYPE.map((x) => x.type)];

  const propertyManagerData = useSelector(
    (state) => state.propertyManagerReducer
  );
  const { propertyManagerList } = propertyManagerData;

  useEffect(() => {
    dispatch(getManagers());
  }, [dispatch]);

  /**@description Handle File Upload Functionality
   */
  const handleFileUpload = (e) => {
    if (e?.target?.files?.[0]) {
      const file = e.target.files[0];
      setFileData(file);
      readXlsxFile(file).then((rows) => {
        setExcelData([...rows]);
      });
    }
  };

  /**@description Handle remove File  Functionality
   */
  const handleRemoveFile = () => {
    uploadTenants.current.value = null;
    setFileData({ ...{} });
    setExcelData([]);
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChange = (event) => {
    props.handleChangeproperty({
      key: "apartmentTypes",
      value: event.target.value,
    });
  };

  /**@description Handle change MFO data Functionality
   */
  const handleChangemfoData = ({ key, value, regexType }) => {
    const {mfoDetails} = props;
    const item = validations(mfoDetails, key, value, regexType);
    props.handleChangeMfo({ key: item.key, value:item.value });
  };

  /**@description Handle Property details change Functionality
   */
  const handleTextChange = (textData, isManager) => {
    const { key, value, regexType } = textData;
    const dataItem = ADD_MULTI_FAMILY_PROPERTY.find(
      (x) => x.key === key && x.isManager === !!isManager
    );
    if (dataItem?.isManager) {
      let data = props.propertyData?.manager || {};
      let pManager =
        key === "email"
          ? (propertyManagerList || []).find((mgr) => mgr.email === value)
          : null;
      if (pManager) {
        validations(ADD_MULTI_FAMILY_PROPERTY, key, value, regexType);
        data = { ...pManager };
        ADD_MULTI_FAMILY_PROPERTY.forEach((prop)=>{
          if(prop.key ==='Mgrname'){
            prop.value = data.name;
          }
          if(prop.key ==='phoneNumber'){
            const num = data.phoneNumber.substring(1);
             validations(ADD_MULTI_FAMILY_PROPERTY, prop.key, num.substring(num.length-10), prop.regexType); 
          }
        })
        setDisable(true)
      } else if (key === "email" && data?._id) {
        data = {
          email: value,
        };
        setDisable(false)
      } else {
        const item = validations(ADD_MULTI_FAMILY_PROPERTY, key, value, regexType);
        data[key] = item.value;
        setDisable(false)
      }
      props.handleChangeproperty({ key, value, data });
    } else {
      const item = validations(ADD_MULTI_FAMILY_PROPERTY, key, value, regexType);
      props.handleChangeproperty({ key: item.key, value: item.value });
      setDisable(false)
    }
  };

  const handleAddProperty = () => {
    if (editPropertyIndex === -1) {
      props.addpropertyDetails();
    } else {
      props.addpropertyDetails(editPropertyIndex);
    }
    setEditPropertyIndex(-1);
  };

  /**@description Displaying Header Functionality
   */
  const displayHeader = () => (
    <div className={"addMultiFamilyHeaderRow"}>
      <div className={props.mfo ? "addMultiFamilyNewTenant" : "addProperties"}>
        {props.mfo ? "Add New Multifamily Operator" : "Create Properties"}
      </div>
    </div>
  );

  const handleSubmitButton = () => {
    let disable = false;
    let disableproperty = true;

    disable =
      props.mfoDetails.some(
        (x) => x?.hasError || (x?.value || "")?.length < x?.minLength
      ) && ExcelData.length < 1;
    disableproperty = props.allData.length > 0 ? false : true;

    return (
      <CustomButtom
        title={`${
          props.mfo ? "Add New Multifamily Operator" : "Create Properties"
        }`}
        additionalSx={{
          padding: "1vw 8vw",
          fontWeight: 600,
          fontSize: "1vw",
        }}
        handleButtonClick={(e) => {
          e.preventDefault();
          props.handleAddMfoData(ExcelData);
        }}
        disabled={props.mfo ? disable : disableproperty}
        onClick={props.handleCloseAddMfo}
      />
    );
  };

  const displayAddProperty = () => {
    let disable = false;

    disable = props.propertyDetails.some(
      (x) => x?.hasError || (x?.value || "")?.length < x?.minLength
    );
    return (
      props.isProperty && (
        <CustomButtom
          title={`${
            editPropertyIndex === -1 ? "Add Property" : "Update Property"
          }`}
          additionalSx={{
            padding: "1vw 1vw",
            fontWeight: 600,
            fontSize: "1vw",
          }}
          handleButtonClick={handleAddProperty}
          disabled={disable}
        />
      )
    );
  };

  /**@description Displaying Excel MFO upload Functionality
   */
  const displayUploadExcelMFO = () => (
    <div
      className={`addMultiFamilyUploadRow ${
        props.isDisable ? "is-disabled" : ""
      }`}
    >
      <label htmlFor="uploadTenants" className={"addMultiFamilyUploadRow"}>
        <input
          className={"upload"}
          type="file"
          accept={
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }
          id="uploadTenants"
          onChange={(e) => handleFileUpload(e)}
          ref={uploadTenants}
        />
        <img
          src="/images/shared/upload.svg"
          alt="uploadIcon"
          className="mfouploadIcon"
        />
        <div className={"addMultiFamilyUploadText"}>Upload Excel MFO List</div>
      </label>
      {uploadTenants?.current?.value ? (
        <div className={"addMultiFamilyAddMFOFileName"}>
          <span>{fileData?.name || ""}</span>
          <img
            src="/images/shared/delete.svg"
            alt="Remove"
            className={"uploadTenantDelete"}
            role="presentation"
            onClick={() => handleRemoveFile()}
          />
        </div>
      ) : null}
    </div>
  );

  /**@description Displaying Form Functionality
   */

  const displayingCreateProperty = () => {
    return (
      <React.Fragment>
        {!ExcelData?.length ? (
          <>
            <div className={"addpropButton"}>
              <div className='addMultiFamilyHeadertext'>
                Enter the Property details below and click on Add Property.
              </div>
              <div className={"addpropButton"}>{displayAddProperty()}</div>
            </div>
            <div className='addMultiFamilyUploadRow'>
              {ADD_MULTI_FAMILY_PROPERTY.map((dataItem) => {
                const key = dataItem.key;
                const item = props.propertyDetails.find(
                  (x) => x.key === "apartmentTypes"
                );
                const value = item?.value || "";
                return (
                  <div
                    className={"addMultiFamilyTenantInput"}
                    key={`displayFormFields${dataItem.title}`}
                  >
                    {key === "apartmentTypes" ? (
                      <FormControl
                        sx={{
                          m: 0,
                          width: "100%",
                        }}
                      >
                        <InputLabel
                          id='demo-multiple-name-label'
                          sx={{
                            "&.MuiInputLabel-root": {
                              fontSize: "1.5vw",
                              top: "-0.5vh",
                            },
                          }}
                          required={props.mfo ? false : true}
                        >
                          {dataItem.title}
                        </InputLabel>
                        <Select
                          labelId='demo-multiple-name-label'
                          id='demo-multiple-name'
                          value={value}
                          input={<OutlinedInput label='Apartment Types' />}
                          onChange={handleChange}
                          MenuProps={MenuProps}
                          className='select'
                          sx={{
                            "&.MuiOutlinedInput-root": {
                              padding: "1vh 0 0",
                              fontSize: "1.5vw",
                            },
                            "& .MuiSvgIcon-root": {
                              fontSize: "2vw",
                            },
                          }}
                        >
                          {apartmentData.map((apartment) => (
                            <MenuItem
                              key={apartment}
                              value={apartment}
                              style={getStyles(apartment, apartment, theme)}
                              sx={{
                                "&.MuiMenuItem-root": {
                                  fontSize: "1vw",
                                },
                              }}
                            >
                              {apartment}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <CustomInput
                        dataItem={{ ...dataItem }}
                        additionalSx={{ width: "100%", margin: 0 }}
                        handleInput={(data) => {
                          handleTextChange(data, dataItem.isManager);
                        }}
                        disabled={['Mgrname', 'phoneNumber'].includes(dataItem.key)&& isDisable}
                        required={props.mfo ? false : true}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
        {displayPropertyBox()}
      </React.Fragment>
    );
  };

  const displyaingCreateMfo = () => {
    const {mfoDetails} = props;
    return (
      <>
        {isAdd ? displayUploadExcelMFO() : null}
        {!ExcelData?.length ? (
          <div className="addMultiFamilyUploadRow">
            {mfoDetails.map((dataItem) => {
              const key = dataItem.key;
              const value = dataItem[key];
              return (
                <div
                  className={"addMultiFamilyTenantInput"}
                  key={`displayFormFields${dataItem.title}`}
                >
                  {
                    <CustomInput
                      dataItem={{ ...dataItem }}
                      value={value}
                      additionalSx={{ width: "100%", margin: 0 }}
                      handleInput={(data) => {
                        handleChangemfoData(data);
                      }}
                      required={true}
                    />
                  }
                </div>
              );
            })}
          </div>
        ) : null}
      </>
    );
  };

  /**@description Displaying Form Functionality
   */

  const displayFormFields = () => (
    <div>
      {props.mfo && displyaingCreateMfo()}
      {props.isProperty && displayingCreateProperty()}

      <div className={"addTenantButton"}>{handleSubmitButton()}</div>
    </div>
  );

  /**@description Displaying Property Details Card Functionality
   */
  const displayPropertyBox = () => {
    return (
      <div className='addMultiFamilyPropertyContainer'>
        <div className='addMultiFamilyHeadertext'>
          Below Properties will be created
        </div>
        {props.allData?.length ? (
          <CustomTable
            hideCheckbox={true}
            rows={props.allData}
            tableHeading={[...PROPERTYTABLE_HEADINGS]}
            maxHeight={"300px"}
            action={[
              {
                add: true,
                text: (
                  <img
                    src='/images/shared/edit.svg'
                    className='addMultiFamilyCross'
                    alt='Edit'
                  />
                ),
                handleClick: (propertyIndex) => {
                  setEditPropertyIndex(propertyIndex);
                  props.editPropertyDetails(propertyIndex);
                },
                className: "addMultiFamilyCross",
              },
              {
                add: true,
                text: (
                  <img
                    src='/images/shared/delete.svg'
                    className='addMultiFamilyCross'
                    alt='Delete'
                  />
                ),
                handleClick: props.deleteproperty,
                className: "addMultiFamilyCross",
              },
            ]}
          />
        ) : (
          <div className='multiFamilyAddProperty'>
            Please enter the property details above and click on Add Property.
          </div>
        )}
      </div>
    );
  };

  const displayModalContent = () => {
    return (
      <>
        {displayHeader()}
        {displayFormFields()}
      </>
    );
  };

  return (
    <CustomModal
      isOpen={props.isOpen}
      handleRemoveIcon={() => props.handleClose()}
      sx={BOX_STYLE}
      content={displayModalContent}
      closeButtonStyle='removeIcon'
    />
  );
};

AddMultiFamily.defaultProps = {
  isOpen: true,
  isAdd: true,
  tenantData: {},
  handleClose: () => {},
  handleChange: () => {},
  handleChangeMfo: () => {},
  handleChangeproperty: () => {},
  handleSubmit: () => {},
};

export default AddMultiFamily;
