import React from "react";
import { Box, Modal } from "@mui/material";
import {BOX_STYLE} from "../../components/Constants";

const CustomModal = (props) => {
  return (
    <Modal open={props.isOpen}>
      <>
        <Box sx={{ ...props.sx }}>
         {props.content()}
        </Box>
        <div
          className={props.closeButtonStyle}
          onClick={() => props.handleRemoveIcon()}
        >
          X
        </div>
      </>
    </Modal>
  );
};

CustomModal.defaultProps = {
  open: false,
  handleRemoveIcon: () => {},
  sx: BOX_STYLE,
  closeButtonStyle: "modalRemoveIcon",
  content: () => {},
};

export default CustomModal;
