import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/custom-table/Customtable";
import CustomCard from "../../../components/custom-card/CustomCard";
import CustomInput from "../../../components/custom-input/CustomInput";
import CustomButton from "../../../components/custom-button/CustomButton";
import CustomDate from "../../../components/custom-date/CustomDate";
import { HOLIDAY_TABLE_HEADINGS } from "../../Constants";
import styles from "./Holidays.module.scss";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import Loader from "../../../components/loader/Loader";
import axios from "axios";
import config from "../../../config";
import moment from "moment";
import { convertToDMYFormat } from "../../../utils/helperMethods";

const Holidays = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reason, setReason] = useState("");
  const [holidaysData, setHolidaysData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showAddSuccessModal, setShowAddSuccessModal] = useState(false);

  /**
   * @description Fetch Holidays data from the API
   */
  const fetchHolidaysData = async () => {
    setShowLoader(true);
    const { data, status } = await new Promise((resolve) => {
      axios
        .get(`${config.RestServiceURL}/unavailable-dates`)
        .then((res) => {
          if (Array.isArray(res.data)) {
            resolve({
              data: res.data.reverse().map((item) => ({
                ...item,
                name: item.name,
                date: `${item.month + 1}-${item.date}-${item.year}`,
              })),
              status: true,
            });
          } else {
            resolve({ status: false });
          }
        })
        .catch((err) => {
          resolve({ status: false });
        });
    });
    setShowLoader(false);
    data.forEach((data) => {
      data.startDate = convertToDMYFormat(data.startDate);
      data.endDate = convertToDMYFormat(data.endDate);
    });
    if (status) setHolidaysData([...data]);
  };

  useEffect(() => {
    fetchHolidaysData();
  }, []);

  /**
   * @description Hide delete Modal
   */
  const handleHideDeleteModal = () => {
    setSelectedIndex(-1);
    setShowDeleteModal(false);
  };

  /**
   * @description Deletes the selected holiday
   */
  const handleDeleteHoliday = async () => {
    setShowLoader(true);
    const status = await new Promise((resolve) => {
      axios
        .delete(
          `${config.RestServiceURL}/unavailable-dates/${holidaysData[selectedIndex]._id}`
        )
        .then(() => resolve(true))
        .catch((err) => {
          resolve(false);
        });
    });
    if (status) {
      setShowSuccessModal(true);
      handleHideDeleteModal();
      fetchHolidaysData();
    } else {
      setShowLoader(false);
    }
  };

  /**
   * @description Adds given data as a new holiday
   */
  const handleAddHoliday = async () => {
    setShowLoader(true);
    const status = await new Promise((resolve) => {
      axios
        .post(`${config.RestServiceURL}/unavailable-dates`, [
          {
            name: reason,
            startDate: moment(startDate).startOf('day').toISOString(), 
            endDate: moment(endDate).endOf('day').toISOString(),
          },
        ])
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
        });
    });
    if (status) {
      setStartDate("");
      setEndDate("");
      setReason("");
      setShowAddSuccessModal(true);
      fetchHolidaysData();
    } else {
      setShowLoader(false);
    }
  };

  /**
   * @description displays delete confirmation modal
   */
  const displayDeleteModal = () => {
    let isSingleDate =
      holidaysData[selectedIndex]?.startDate ===
      holidaysData[selectedIndex]?.endDate;
    return (
      <ConfirmModal
        isOpen={showDeleteModal}
        title="Delete Holiday"
        message={`Are you sure! you want to delete selected holiday ${
          isSingleDate ? "on" : "from"
        } \n ${holidaysData[selectedIndex]?.startDate || ""} ${
          isSingleDate ? "" : "to " + holidaysData[selectedIndex]?.endDate || ""
        }`}
        handleCancel={handleHideDeleteModal}
        handleConfirm={handleDeleteHoliday}
      />
    );
  };

  /**
   * @description displays delete holiday success modal after successful deletion
   */
  const displayDeleteSuccessModal = () => (
    <ConfirmModal
      isOpen={showSuccessModal}
      title="Deleted"
      message={`Selected holiday deleted successfully!`}
      cancelText="Okay"
      handleCancel={() => setShowSuccessModal(false)}
      isAlert
    />
  );

  /**
   * @description displays add holiday success modal after successful addition
   */
  const displayAddSuccessModal = () => (
    <ConfirmModal
      isOpen={showAddSuccessModal}
      title="Success"
      message={`New Holiday added successfully!`}
      cancelText="Okay"
      handleCancel={() => setShowAddSuccessModal(false)}
      isAlert
    />
  );

  /**
   * @description displays add holiday input section in the main page
   */
  const displayAddHoliday = () => {
    return (
      <div className={styles.addHoliday}>
        <div className={styles.holidayTitle}>ADD HOLIDAY</div>
        <div className={styles.row}>
          <CustomDate
            dataItem={{
              title: "Start Date",
              value: startDate,
              type: "date",
            }}
            handleDateChange={(data) => {
              setStartDate(data.value);
              if (
                endDate &&
                moment(data.value, "DD/MM/YYYY") > moment(endDate, "DD/MM/YYYY")
              ) {
                setEndDate(data.value);
              }
            }}
            required={true}
            minDate={new Date()}
            disabled={false}
          />
          <CustomDate
            dataItem={{ title: "End Date", value: endDate, type: "date" }}
            handleDateChange={(data) => {
              setEndDate(data.value);
            }}
            required={true}
            minDate={new Date(startDate)}
            disabled={false}
          />
        </div>
        <CustomInput
          dataItem={{ title: "Reason", value: reason }}
          additionalSx={{ width: "100%", margin: "3rem 0 4rem" }}
          handleInput={(data) => {
            setReason(data.value);
          }}
          required={true}
          disabled={false}
        />
        <CustomButton
          title={"Add"}
          disabled={!startDate || !endDate || !reason}
          handleButtonClick={handleAddHoliday}
        />
      </div>
    );
  };

  /**
   * @description displays holiday table
   */
  const displayTable = () => (
    <CustomTable
      rows={holidaysData}
      tableHeading={HOLIDAY_TABLE_HEADINGS}
      maxHeight={window.innerHeight * 0.7}
      action={[
        {
          add: true,
          header: "Delete",
          text: (
            <img
              src="/images/shared/delete.svg"
              alt="Remove"
              className={"uploadTenantDelete"}
              role="presentation"
            />
          ),
          handleClick: (selInd) => {
            console.log(selInd);
            setShowDeleteModal(true);
            setSelectedIndex(selInd);
          },
        },
      ]}
      hideCheckbox
    />
  );

  return (
    <div className={styles.holidayBody}>
      {displayTable()}
      <CustomCard
        cardData={displayAddHoliday()}
        sxProps={{
          width: window.innerWidth * 0.7,
          heigt: window.innerHeight * 0.6,
        }}
      />
      {showLoader && <Loader />}
      {displayDeleteModal()}
      {displayDeleteSuccessModal()}
      {displayAddSuccessModal()}
    </div>
  );
};

export default Holidays;
