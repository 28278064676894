import React, { useState } from "react";
import CustomButton from "../../../components/custom-button/CustomButton";
import CustomInput from "../../../components/custom-input";
import {
  getFormattedPhoneNum,
  convertToAlphaNumeric,
} from "../../../utils/helperMethods";
import { TRANSACTION, PAYMENT_TITLES } from "../../Constants";
import {
  verifyPaymentOption,
  updateDriverPaymentStatus,
} from "../../../redux/actions/territory-manager-actions/territoryManager";
import Loader from "../../../components/loader/Loader";
import { Alert } from "@mui/material";
import CustomModal from "../../../components/custom-modal/CustomModal";
import { VERIFY_POPUP } from "../../../components/Constants";
import _ from "lodash";

const PaymentDetailsModal = ({
  paymentData,
  setShowContactModal,
  driverId,
  setshowVerificationModal,
  setRefresh,
  refresh,
}) => {
  const [transactionId, setTransactionId] = useState({ ...TRANSACTION });
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const handleInputField = ({ value }) => {
    transactionId.value = convertToAlphaNumeric(value);
    setErrorMessage("");
    setTransactionId({ ...transactionId });
  };

  const verifyPayment = async (id) => {
    setLoading(true);
    const { status, message } = await verifyPaymentOption(id, {
      isVerified: true,
    });
    setLoading(false);
    if (status) {
      setShowModal(true);
      setErrorMessage("");
    } else {
      setErrorMessage(message);
    }
  };

  const onPaymentSuccess = () => {
    setPaymentSuccess(false);
    setshowVerificationModal(false);
    setRefresh(!refresh);
  };

  const renderContent = () => {
    return (
      <div className='verify'>
        <div className='verifyDetail'>Payment Successful!</div>
        <div className='newDriver'>
          Your payment was processed successfully.
        </div>
        <CustomButton
          title={"close"}
          className={"status"}
          handleButtonClick={onPaymentSuccess}
          additionalSx={{
            fontWeight: 400,
            fontSize: "1vw",
            lineHeight: "1",
          }}
        />
      </div>
    );
  };

  const hidePaymentDetails = () => {
    setShowModal(false);
    setshowVerificationModal(false);
    setRefresh(!refresh);
  };

  const renderSuccessPopup = () => {
    return (
      <div className='verify'>
        <div className='verifyDetail'>
          Verify Payment Details - Payment details verified successfully!
        </div>
        <div className='newDriver'>
          The payment details of the new added driver has been verified
          successfully.
        </div>
        <CustomButton
          title={"Done"}
          className={"done"}
          handleButtonClick={hidePaymentDetails}
        />
      </div>
    );
  };

  const updatePaymentStatus = async () => {
    setLoading(true);
    const { status, message } = await updateDriverPaymentStatus(
      driverId,
      transactionId.value
    );
    setLoading(false);
    if (status) {
      setPaymentSuccess(true);
      setErrorMessage("");
    } else {
      setErrorMessage(message);
    }
  };

  return (
    <>
      {loading && <Loader show={loading} />}
      <h2 className='driverDetails'>
        {paymentData.isVerified
          ? "Driver Details"
          : "Verify Payment Details - Driver Details"}
      </h2>
      {PAYMENT_TITLES.map(
        (data) =>
          _.has(paymentData, data.key) && (
            <div className='contents' key={`paymentModal${data.key}}`}>
              <div style={{ flexBasis: "26%" }}>{data.title}</div>
              <div style={{ display: "flex" }}>
                {data.key === "paymentOption" && (
                  <img
                    src={paymentData.logo}
                    alt='paymentIcon'
                    className='PaymentModeIcon'
                  />
                )}
                <div className='paymentData'>
                  {data.key === "phone"
                    ? getFormattedPhoneNum(paymentData[data.key].slice(-10))
                    : paymentData[data.key]}
                </div>
              </div>
            </div>
          )
      )}
      {paymentData.isVerified && (
        <div className='transactionId'>
          <div className='transactionText'>Transaction ID:</div>
          <CustomInput
            dataItem={transactionId}
            handleInput={(data) => {
              handleInputField(data);
            }}
            required={true}
            sxProps={{ width: "40%", padding: 0 }}
          />
        </div>
      )}
      {errorMessage && (
        <div style={{ position: "absolute", top: "2rem", right: "5rem" }}>
          <Alert severity='error'>{errorMessage}</Alert>
        </div>
      )}
      {!paymentData.isVerified ? (
        <>
          <div className='description'>
            This is a one time payment you are sending to the driver for
            verification of the payment details.
          </div>
          <div className='buttonStyles'>
            <div style={{ marginRight: "30px" }}>
              <CustomButton
                title={"Mark as verified"}
                className={"verifyDriver"}
                handleButtonClick={() => verifyPayment(driverId)}
              />
            </div>
            <CustomButton
              title={"Contact Driver"}
              className={"verifyDriver"}
              handleButtonClick={() => setShowContactModal(true)}
            />
            {showModal && (
              <CustomModal
                isOpen={true}
                handleRemoveIcon={hidePaymentDetails}
                sx={VERIFY_POPUP}
                content={renderSuccessPopup}
                closeButtonStyle='verifyCrossIcon'
              />
            )}
          </div>
        </>
      ) : (
        <div className='buttonContainer'>
          <CustomButton
            title={"Pay Now"}
            className='payNowButton'
            handleButtonClick={updatePaymentStatus}
            disabled={transactionId.value === ""}
          />
        </div>
      )}
      {paymentSuccess && (
        <CustomModal
          isOpen={true}
          handleRemoveIcon={onPaymentSuccess}
          sx={VERIFY_POPUP}
          content={renderContent}
          closeButtonStyle='verifyCrossIcon'
        />
      )}
    </>
  );
};

export default PaymentDetailsModal;
