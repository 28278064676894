import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import {
  ADDDRIVER_BOX_STYLE,
  DRIVERSMALLMODAL_BOX_STYLE,
} from "../../../components/Constants";
import CustomInput from "../../../components/custom-input/CustomInput";
import { ADD_DRIVER } from "../../Constants";
import CustomButton from "../../../components/custom-button/CustomButton";
import "./addDrivers.scss";

const AddDriver = (props) => {
  const { open, handleCode, countryCode, setcountryCode } = props;

  const createDriver = () => {
    return (
      <div>
        <img
          src='/images/shared/driver.png'
          alt='Delete Icon'
          className='driverIcon'
        />
      </div>
    );
  };

  const displaySubmitHandle = () => {
    let disable = false;
    const dataObject = props.driverSignup;
    disable = Object.values(dataObject).some(
      (x) => x?.hasError || (x?.value || "")?.length < x?.minLength
    );
    return (
      <CustomButton
        title={"Add Driver"}
        className={"driverpopupButton"}
        sx={{ width: "100%" }}
        handleButtonClick={() => props.handleSubmit()}
        disabled={disable}
      />
    );
  };

  const handleChange = (code) => {
    setcountryCode(code);
  };
  const displyInputFiled = () => (
    <div>
      {ADD_DRIVER.map((dataItem) => {
        return (
          <div
            className='driverInputStyle'
            key={`displayInputField${dataItem.key}`}
          >
            {dataItem.key === "phoneNumber" ? (
              <div>
                <CustomInput
                  variant='standard'
                  id='standard-error-helper-text'
                  dataItem={{ ...dataItem }}
                  value={""}
                  additionalSx={{ width: "100%", marginTop: "40px" }}
                  handleInput={(data) => {
                    props.handleInput(data);
                  }}
                  InputProps={{
                    startAdornment: (
                      <FormControl
                        variant='standard'
                        id='standard-error-helper-text'
                        sx={{
                          minWidth: "auto",
                        }}
                      >
                        <Select
                          labelId='demo-simple-select-standard-label'
                          id='demo-simple-select-standard'
                          value={countryCode}
                          label='Country Code'
                          onChange={(e) => handleChange(e.target.value)}
                        >
                          <MenuItem value={"+1"}>+1</MenuItem>
                          <MenuItem value={"+91"}>+91</MenuItem>
                        </Select>
                      </FormControl>
                    ),
                  }}
                  required={true}
                />
              </div>
            ) : (
              <CustomInput
                variant='standard'
                id='standard-error-helper-text'
                dataItem={{ ...dataItem }}
                value={""}
                additionalSx={{ width: "100%", marginTop: "40px" }}
                handleInput={(data) => {
                  props.handleInput(data);
                }}
                required={true}
                autoComplete= {true}
              />
            )}
          </div>
        );
      })}
      <div style={{ marginTop: "40px" }}>{displaySubmitHandle()}</div>
    </div>
  );
  const displyverifyInputFiled = () => {
    return (
      <div className='driverCodeStyle'>
        <div className='driverHeadingStyle'>{`Enter the verification code sent to given phone number`}</div>
        <CustomInput
          dataItem={props.code}
          value={props.code.value}
          additionalSx={{ width: "60%" }}
          handleInput={(data) => {
            handleCode(data);
          }}
          variant='standard'
          id='standard-error-helper-text'
          size='small'
        />
        <div style={{ marginTop: "20px" }}>
          <CustomButton
            title={"submit"}
            className={"driverpopupButton"}
            sx={{ width: "100%" }}
            handleButtonClick={() => props.handleCodeSubmit()}
          />
        </div>
      </div>
    );
  };

  const displayingmodalRemoveIcon = () => {
    return (
      <div
        className='drivermodalRemoveIcon'
        onClick={() => props.handleRemoveIcon()}
      >
        X
      </div>
    );
  };

  const displayCode = () => {
    return (
      <Modal
        open={open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div>
          <Box sx={DRIVERSMALLMODAL_BOX_STYLE}>
            <div className='mainContent'>{displyverifyInputFiled()}</div>
          </Box>
          <div
            className='codeRemoveIcon'
            onClick={() => props.handleCodeRemoveIcon()}
          >
            X
          </div>
        </div>
      </Modal>
    );
  };
  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div>
        <Box sx={ADDDRIVER_BOX_STYLE}>
          <div className='mainContent'>{createDriver()}</div>
          {!props.showDriverModal && displyInputFiled()}
          {props.showDriverModal && displayCode()}
        </Box>
        {displayingmodalRemoveIcon()}
      </div>
    </Modal>
  );
};

export default AddDriver;
