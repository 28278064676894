import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TerritoryTable from "../territoryTable";
import Custombuton from "../../../components/custom-button/CustomButton";
import "./pickupdelivery.scss";
import Loader from "../../../components/loader/Loader";
import {
  deliverTypes,
  JOB_STATUS_TYPES,
  JOB_TYPES,
  PICKUP_HEADINGS,
} from "../../Constants";
import {
  getAllconfirmedRequests,
  getAllorderDetails,
  getAppointmentDetails,
  updateAppointmentStatus,
  updateEmptyuserbins,
  updateuserbins,
} from "../../../redux/actions/territory-manager-actions/territoryManager";
import writeXlsxFile from "write-excel-file";
import { DOWNLOAD_CUSTOMER_SAMPLE_QR } from "../../../components/Constants";
import TenantListEmpty from "../../../components/tenant-list-empty/TenantListEmpty";
import { convertDate, convertTime } from "../../../utils/helperMethods";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import _ from "lodash";
import AddDriversModal from "../pendingRequests/AddDriverModal";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: "0.5vw",
          },
        },
      },
    },
  },
});

const PickupDelivery = () => {
  const [PickupData, setPickupData] = useState([]);
  const [pickupHeadingsData, setpickupHeadingsData] = useState([
    ...PICKUP_HEADINGS,
  ]);
  const [showLoader, setShowLoader] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [CustomerDetails, setCustomerDetails] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = React.useState("asc");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [isSorted, setSorted] = useState(false);
  const [emptyTab, setemptyTab] = useState();
  const [disable, setdisable] = useState(true);
  const [statusModal, setStatusModal] = useState({});
  const [diableDriverBtn, setdiableDriverBtn] = useState("");
  const [appointmentID, setappointmentID] = useState();
  const [Modaldata, setModaldata] = useState([]);

  useEffect(async () => {
    getconfirmedAppointments();
    const data = await getAllorderDetails();
    setCustomerDetails(data.data);
  }, []);

  /**@description Handling selected Data from Rows*/
  const handleappointmentListSelect = (selectedData) => {
    setSelected(selectedData);

    const orderId = PickupData.map((data) => data?.order?.orderId);

    if (selectedData.length) {
      setdisable(false);
    } else {
      setdisable(true);
    }
  };

  const getconfirmedAppointments = async () => {
    setShowLoader(true);
    const { data, status, message } = await getAllconfirmedRequests();
    if (message) {
      setShowLoader(false);
      setemptyTab(true);
      setShowDatePicker(false);
    }
    setPickupData([...data]);
    setCustomerDetails(data.order);
    setShowLoader(false);
  };

  const handleConfirmdriverJob = async (data) => {
    setShowLoader(true);
    const status =
      data.appointmentType === deliverTypes.dropoffEmptyBins
        ? await updateEmptyuserbins(data.intialOrderId, {
            currentHolder: JOB_TYPES[data.type].currentHolder,
          })
        : await updateuserbins(data.appointmentID, {
            currentHolder: JOB_TYPES[data.type].currentHolder,
            userBins: data.userBins.map((bin) => bin.userBin),
          });

    handleShowModal({
      cancelText: "Ok",
      title: status ? `Success` : `Failed`,
      message: status
        ? `Successfully  ${
            data.appointmentType === deliverTypes.pickupCustomer
              ? "Picked!"
              : "Delivered!"
          }`
        : `Failed to Delivered/Picked, please try again.`,
      isAlert: true,
    });
    setappointmentID(data._id);

    const appointmentData = {
      status: "JOB_COMPLETED",
    };

    if (status) {
      const { status } = await updateAppointmentStatus(
        data?.appointmentID,
        appointmentData
      );
      getconfirmedAppointments();
      getAllorderDetails();
    }
  };

  let tableData = [];
  let order_ID = "";

  PickupData?.map((data) => {
    const date = convertDate(data?.selectedSlot?.date);
    const startTime = convertTime(data?.selectedSlot?.startTime);
    const endTime = convertTime(data?.selectedSlot?.endTime);
    const intialOrderId = data?.order?._id;

    const orderId = data.order.map((order) => order.orderId);

    const storageFacility = data.order[0]?.plan?.storageFacility;
    const storageFacilityaddress = storageFacility
      ? ` ${storageFacility?.facilityName},${storageFacility?.address}`
      : "";
    const state = data?.user?.physicalAddress?.state;
    const city = data?.user?.physicalAddress?.city;
    const zipcode = data?.user?.physicalAddress?.zipCode;
    const area = data?.user?.physicalAddress?.area;
    const floor = data?.user?.physicalAddress?.floor;
    const gateCode = data?.user?.physicalAddress?.gateCode;
    const apartment = data?.user?.physicalAddress?.apartment;

    const customer_address = `${area ? `${area},` : ""} ${
      city ? `${city},` : ""
    } ${state ? `${state},` : ""} ${zipcode ? `${zipcode};` : ""} ${
      apartment ? `Unit ${apartment};` : ""
    } ${floor ? `Floor ${floor};` : ""} ${gateCode ? `Gate ${gateCode}#` : ""}`;

    order_ID = orderId;
    tableData.push({
      _id: data._id,
      orderDetails: data.order,
      orderId: orderId,
      appointmentID: data?._id,
      intialOrderId: intialOrderId,
      driverName: data.driver?.name,
      userBins: data?.userBins,
      clientName: data?.user?.name ? data?.user?.name : "Not Available",
      dateTime: data.selectedSlot
        ? [
            <div>
              <span style={{ color: "#198eba" }}>{date}</span>
              <br /> {startTime}-{endTime}
            </div>,
          ]
        : "Not Available",
      dateTimeData: date,
      vehicleType: data?.driver?.driverType === "CAR" ? "Car" : "Truck",
      appointmentType: JOB_TYPES[data.type]?.title || data.type,
      type: data.type,
      pickupAddress:
        data.type === deliverTypes.PickupFromuser
          ? data?.user?.physicalAddress?.address
            ? customer_address
            : ""
          : storageFacilityaddress
          ? storageFacilityaddress
          : "",
      deliveryAddress:
        data.type === deliverTypes.PickupFromuser
          ? `${storageFacilityaddress ? `${storageFacilityaddress},` : ""} ${
              data?.order?.spaceLocation?.coordinates
                ? data?.order?.spaceLocation?.coordinates
                : ""
            }`
          : customer_address,
      CustomerAddress: data.user?.physicalAddress
        ? `${city},${state},${zipcode}`
        : "",

      Status: data?.status ? JOB_STATUS_TYPES[data.status] || data.status : "",
    });
  });

  const handleDatePickers = (date) => {
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 1000);

    const data = tableData.filter((data) => {
      const dateSelected = convertDate(date)?.slice(0, 6);
      const tableDate = data?.dateTimeData?.slice(0, 6);
      if (tableDate === dateSelected) {
        return data;
      }
    });
    setSortedData([...data]);
    setSorted(true);
    setShowDatePicker(true);
  };

  const createSortHandler = (property) => (event) => {
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
    handleRequestSort(event, property);
    dataSort(order);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const dataSort = (sortingType) => {
    const appointments = _.orderBy(
      tableData,
      [(item) => item?.driverName?.toLowerCase()],
      [sortingType]
    );
    setSorted(true);
    setSortedData([...appointments]);
  };
  /**@description Handle hide Model Functionality
   */
  const handleHideModal = () => {
    statusModal.show = false;
    setStatusModal({ ...statusModal });
  };

  /**@description Handle Show Model Functionality
   */
  const handleShowModal = ({
    cancelText,
    confirmText,
    isAlert,
    title,
    message,
    handleConfirm = () => {},
  }) => {
    statusModal.show = true;
    statusModal.cancelText = cancelText || "Cancel";
    statusModal.confirmText = confirmText || "Ok";
    statusModal.isAlert = isAlert || false;
    statusModal.title = title || "";
    statusModal.message = message || "";
    statusModal.handleConfirm = handleConfirm;
    statusModal.handleCancel = handleHideModal;
    setStatusModal({ ...statusModal });
  };

  const handleClick = async (id) => {
    const { data: appointmentData } = await getAppointmentDetails(id);
    (PickupData || []).map(async (data) => {
      if (data._id === id) {
        const dataType = data?.type;
        const facilitydata = (data.order || []).map(
          (order) => order?.plan?.storageFacility?.address
        );
        const storagaddress = facilitydata ? [...new Set(facilitydata)] : "";
        const storageFacility =
          data.order.length > 0 ? data?.order[0]?.plan?.storageFacility : null;

        const storageFacilityaddress = storageFacility
          ? `${storageFacility?.facilityName}, ${
              storagaddress ? storagaddress : ""
            }`
          : "";

        const coordinates = (data.order || [])?.map((order) => {
          return {
            orderId: order?.orderId,
            unit: order.spaceLocation?.roomNumber,
            coordinates: order.spaceLocation?.coordinates,
          };
        });

        const userslots = data?.slots;
        const pickupItems =
          appointmentData.order.length > 0
            ? (data?.order || [])
                ?.map((order) => order.noOfBins)
                .reduce((acc, i) => acc + i)
            : null;
        const clientName = data?.user?.name;
        const appointmentID = data?._id;

        const area = data?.user?.physicalAddress?.area;
        const apartment = data?.user?.physicalAddress?.apartment;
        const floor = data?.user?.physicalAddress?.floor;
        const gateCode = data?.user?.physicalAddress?.gateCode;
        const info = data?.user?.physicalAddress?.info;

        const state = data?.user?.physicalAddress?.state;
        const city = data?.user?.physicalAddress?.city;
        const zipcode = data?.user?.physicalAddress?.zipCode;

        const bins = (appointmentData.userBins || []).filter(
          (bins) => bins.userBin.isBin
        );
        const nonBins = (appointmentData.userBins || []).filter(
          (bins) => !bins.userBin.isBin
        );

        const customer_address = `${area ? `${area},` : ""} ${`${
          city ? `${city},` : ""
        } ${state ? `${state},` : ""} ${zipcode ? `${zipcode};` : ""}`} ${
          apartment ? `Unit ${apartment};` : ""
        }  ${floor ? `Floor ${floor};` : ""}  ${
          gateCode ? `Gate ${gateCode}#` : ""
        }${info ? `${info}` : ""}`;
        const emptybins = appointmentData.order.reduce(
          (acc, i) => acc + i.noOfBins,
          0
        );
        let emptyNonBinsData = [];
        (data?.order || []).forEach((order) =>
          emptyNonBinsData.push(...order.nonBinItems)
        );

        const emptynonbins = emptyNonBinsData.reduce(
          (acc, i) => acc + i.quantity,
          0
        );

        setshowModal(true);

        const emptyBins = `${
          emptybins ? `${emptybins} Empty Bin${emptybins > 1 ? `s` : ""} ` : ""
        } `;

        const emptyNonbins = `${
          emptynonbins
            ? `${emptynonbins} QR code${emptynonbins > 1 ? `s` : ""} `
            : ""
        }`;
        const userBins =
          bins.length > 0
            ? `${bins.length} Bin${bins.length > 1 ? `s` : ""}`
            : "";
        const userNonbins =
          nonBins.length > 0
            ? `${nonBins.length} Non Bin${nonBins.length > 1 ? `s` : ""}`
            : "";

        let userBinItems = [];
        let emptyItems = [];
        const nonBinsData = (appointmentData?.order || []).map(
          (data) => data.nonBinItems
        );

        (nonBinsData || []).forEach((data) => {
          data.map((data) => {
            emptyItems.push({
              item: data?.item?.item,
              quantity: data.quantity,
            });
          });
        });
        (appointmentData.userBins || []).forEach((data) => {
          if (data.userBin.item?._id) {
            const itemIndex = userBinItems?.findIndex(
              (x) => x._id === data.userBin.item?._id
            );
            if (itemIndex >= 0) {
              userBinItems[itemIndex].quantity += 1;
            } else {
              userBinItems.push({
                item: data?.userBin.item?.item,
                quantity: 1,
                _id: data?.userBin.item?._id,
                qrCode: data.userBin.qrCode,
              });
            }
          }
        });
        const qrdata = appointmentData.userBins.map((item) => item.userBin.qrCode)
        let binsData = (appointmentData?.userBins || [])
          .filter((bin) => bin.userBin.isBin === true && bin.isReturn === false)
          .map((item) => {
            return item.userBin.qrCode;
          });

        let binArray = (appointmentData?.userBins || []).filter(
          (bin) => bin.userBin.isBin === true
        );

        let nonBinArray = (appointmentData?.userBins || []).filter(
          (bin) => bin.userBin.isBin === false
        );
        setModaldata({
          appointmentID: appointmentID,
          userSlots: userslots,
          binsData,
          binArray,
          nonBinArray,
          appointmentType: data.type,
          qrData: qrdata,
          clientName: clientName ? clientName : "Not Available",
          deliveryType: JOB_TYPES[data.type]?.title || data.type,
          dropoffBinItems:
            dataType === deliverTypes.intialDropoff ? emptyBins : userBins,
          dropoffNonbinItems:
            dataType === deliverTypes.intialDropoff
              ? emptyNonbins
              : userNonbins,
          pickupBinItems:
            dataType === deliverTypes.intialDropoff ? emptyBins : userBins,
          pickupNonbinItems:
            dataType === deliverTypes.intialDropoff
              ? emptyNonbins
              : userNonbins,
          binSpace: data?.binSpace ? data?.binSpace : "",
          nonbinSpace: data?.nonBinSpace ? data?.nonBinSpace : "",
          pickupAddress:
            dataType === deliverTypes.PickupFromuser
              ? customer_address
                ? customer_address
                : ""
              : storageFacilityaddress
              ? storageFacilityaddress
              : "",
          coordinates: coordinates,

          dropoffAddress:
            dataType === deliverTypes.PickupFromuser
              ? storageFacilityaddress
                ? storageFacilityaddress
                : "Not Available"
              : customer_address
              ? customer_address
              : "Not Available",
          assignedDriver: data.driver,
          assignedDriverSlot: data.selectedSlot,
          selectedSlot: data.selectedSlot && {
            startTime: new Date(data?.selectedSlot?.startTime),
            endTime: new Date(data?.selectedSlot?.endTime),
            driver: data.driver,
            date: data?.selectedSlot.date,
          },
          selectedSlotDetails: data.selectedSlot,
          isDraft: data.selectedSlot ? true : false,
          nonBinItemsdata:
            dataType === deliverTypes.intialDropoff ? emptyItems : userBinItems,
        });
      }
    });
  };

  const displayingPickupTable = () => {
    return (
      <ThemeProvider theme={theme}>
        <div className='CustomerTable'>
          <TerritoryTable
            rows={isSorted ? sortedData : tableData || []}
            tableHeading={pickupHeadingsData}
            tablecellpadding={"10.5vw"}
            maxHeight={"40vh"}
            minHeight={"20vh"}
            selected={selected}
            handleSelection={handleappointmentListSelect}
            sortcell={"driverName"}
            datePicker={"dateTime"}
            TableSort={true}
            order={order}
            createSortHandler={(id) => createSortHandler(id)}
            handleDatePicker={() => setShowDatePicker(true)}
            handleDatePickers={handleDatePickers}
            handleConfirmButton={handleConfirmdriverJob}
            disable={diableDriverBtn}
            appointmentID={appointmentID}
            hideCheckbox={true}
            handleClick={(id) => handleClick(id)}
          />
        </div>
      </ThemeProvider>
    );
  };

  /**@description Handle Download QR code IDs to  Functionality
   */
  const handleDownloadSample = async () => {
    if (selected?.length) {
      const data = tableData.filter((_id) => selected.includes(_id._id));
      const excel_data = [];
      (data || []).forEach((customer) => {
        customer?.orderDetails?.forEach((order) => {
          if (order.orderId === order.orderId) {
            order?.qrCodes?.forEach((qr) => {
              const excelData = {
                _id: order.orderId,
                qrCodes: qr,
                name: customer.clientName,
              };
              excel_data.push(excelData);
            });
          }
        });
      });
      await writeXlsxFile(excel_data, {
        schema: DOWNLOAD_CUSTOMER_SAMPLE_QR.headers,
        fileName: "qrCodesdata.xlsx",
      });
    }
  };
  return (
    <div className='pickupdeliveryOverview'>
      {emptyTab ? (
        <div className={"tenantListEmpty"}>
          <TenantListEmpty
            width={"75%"}
            header='No Confirmed Appointments are Found'
            canAdd={false}
          />
        </div>
      ) : (
        <>
          {displayingPickupTable()}
          {showModal && (
            <AddDriversModal
              modalData={Modaldata}
              open={true}
              isAdd={true}
              setshowModal={setshowModal}
            />
          )}
        </>
      )}
      {statusModal?.show ? (
        <ConfirmModal
          cancelText={statusModal.cancelText}
          confirmText={statusModal.confirmText}
          isAlert={statusModal.isAlert}
          title={statusModal.title}
          message={statusModal.message}
          handleCancel={statusModal.handleCancel}
          handleConfirm={statusModal.handleConfirm}
        />
      ) : null}
      <Loader show={showLoader} />{" "}
    </div>
  );
};

export default PickupDelivery;
