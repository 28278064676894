import React from "react";
import { categories } from "../../Constants";

const SubCategoryContainer = (props) => {
  const {
    subValue,
    setSubValue,
    setSubCategorieName,
    activeTab,
    getMarketingMaterialByCategoriesData,
    mfoID,
    pID,
    clearData,
  } = props;
  const displaySubCategories = () => {
    return (
      <div className="subCategoriStyle">
        <div>
          <h1 className="categoriesName">Categories</h1>
        </div>
        <div className="categoriList">
          {categories.map((item) => {
            return (
              [activeTab].includes(item.tab) && (
                <div
                  className={`categoriName ${
                    item.value === subValue ? "categoriNameActive" : ""
                  }`}
                  title={item.title}
                  onClick={() => {
                    setSubValue(item.value);
                    setSubCategorieName(item);
                    let data = categories.findIndex(
                      (data) => data.subCategory === item.subCategory
                    );
                    let subCategory = categories[data];
                    getMarketingMaterialByCategoriesData(
                      mfoID,
                      pID,
                      activeTab,
                      subCategory
                    );
                    clearData();
                  }}
                >
                  <span className="SubNames">{item.title}</span>
                </div>
              )
            );
          })}
        </div>
      </div>
    );
  };

  return <div className="subCategoryStyle">{displaySubCategories()}</div>;
};

export default SubCategoryContainer;
