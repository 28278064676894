import React from "react";
import Layout from "../../components/layout/Layout";
import PropertyDetails from "./PropertyDetails.js";

function action() {
  return (
    <Layout activePage="properties">
      <PropertyDetails />
    </Layout>
  );
}

export default action;
