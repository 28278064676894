import React, { useState, useEffect } from "react";
import CustomButton from "../../../components/custom-button/CustomButton";
import {
  confirmDriver,
  confirmDriverWithCode,
  createDriverSlots,
  deleteDriver,
  driverAddressDeletion,
  deleteMultipleDriver,
  driverCreation,
  updateDriver,
  getDriversInfo,
  driverAddressCreation,
  getPaymentOptions,
} from "../../../redux/actions/territory-manager-actions/territoryManager";
import { getZipcode } from "../../../redux/actions/adminZipcodeAction";
import {
  ADD_DRIVER,
  ADD_DRIVER_ADDRESS,
  COUNTRY_CODE,
  codeDataItem,
  driversHeading,
  driversStatus,
  paymentStatus,
  SLOTS_DATA,
} from "../../Constants";
import {
  POPUP_STYLE,
  VERIFY_POPUP,
  AVAILABLE_DATES_POPUP,
} from "../../../components/Constants";
import TerritoryTable from "../territoryTable";
import Loader from "../../../components/loader/Loader";
import "./Drivers.scss";
import {
  characterCheckRegex,
  convertToDMYFormat,
  driverDays,
  excludeSpace,
  getFormattedPhoneNum,
  reformatPhoneNumber,
  checkIsEmail,
  compareByIdForSorting,
} from "../../../utils/helperMethods";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import TenantListEmpty from "../../../components/tenant-list-empty/TenantListEmpty";
import AddDriversModal from "./addDriverModal";
import EditAddressModal from "./editAddressModal"
import moment from "moment";
import CustomModal from "../../../components/custom-modal/CustomModal";
import PaymentDetailsModal from "./PaymentDetailsModal";
import _ from "lodash";

const DriversTab = () => {
  const [driversData, setdriversData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [selected, setSelected] = useState([]);
  const [statusModal, setStatusModal] = useState({});
  const [disable, setdisable] = useState(true);
  const [editButtonDisable, setEditButtonDisable] = useState(true);
  const [emptyTab, setemptyTab] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isAddAddress, setIsAddAddress] = useState(false)
  const [driverSignup, setdriverSignup] = useState([...ADD_DRIVER]);
  const [driverAddressId, setdriverAddressId] = useState(null);
  const [driverDetails, setDriverdetails] = useState();
  const [driverAddress, setDriverAddress] = useState(null)
  const [code, setCode] = useState({ ...codeDataItem });
  const [statusData, setStatus] = useState(false);
  const [showDriverModal, setShowDriverModal] = useState(false);
  const [countryCode, setcountryCode] = useState(COUNTRY_CODE);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const [showVerificationModal, setshowVerificationModal] = useState(false);
  const [paymentOptionData, setPaymentOptionData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [showContactModal, setShowContactModal] = useState(false);
  const [driverNumber, setDriverNumber] = useState();
  const [driverId, setDriverId] = useState();
  const [refresh, setRefresh] = useState(false);
  const [displayAvailableDates, setDisplayAvailableDates] = useState(false);
  const [daysData, setDaysData] = useState([]);
  const [hoursData, setHoursData] = useState([]);
  const [deletedSlots, setDeletedSlots] = useState([]);
  const [slotsData, setSlotsData] = useState({});

  useEffect(async () => {
    getDriverData();
  }, [refresh]);

  /**
   * @description prepares slot data in required format
   * @param {Array} data
   */
  const prepareSlotsData = (data) => {
    const days = [];
    const slots = [];
    const deleted = [];
    const slotInfo = data;
    (slotInfo.days?.[0]?.slotTimings || []).forEach((selectedSlot) => {
      const formattedData = SLOTS_DATA.slots.find(
        (slot) =>
          slot.startTime === selectedSlot.startTime &&
          slot.endTime === selectedSlot.endTime
      );
      slots.push({ 
        ...formattedData,
        bookMarked: selectedSlot.isFavourite,
      });
    });
    (slotInfo?.days || []).forEach((selectedDay) => {
      const formattedData = SLOTS_DATA.days.find(
        (day) => day.utcDay === selectedDay.day
      );
      days.push({
        ...formattedData,
        bookMarked: selectedDay.isFavourite,
      });
    });
    (slotInfo.deletedSlots || []).forEach((day) => {
      day.slotTimings.forEach((selectedSlot) => {
        const formattedData = SLOTS_DATA.slots.find(
          (slot) =>
            slot.startTime === selectedSlot.startTime &&
            slot.endTime === selectedSlot.endTime
        );
        deleted.push({ ...formattedData, date: day.date });
      });
    });
    setHoursData([...slots]);
    setDaysData([...days.sort(compareByIdForSorting)]);
    setDeletedSlots([...deleted]);
    setSlotsData({ ...slotInfo });
  };

  const getDriverData = async () => {
    setShowLoader(true);
    setemptyTab(true);
    const { status, data, message } = await getDriversInfo();
    const { PaymentOptions } = await getPaymentOptions();
    setPaymentOptionData(PaymentOptions);
    setdriversData(data);
    setemptyTab(false);
    setShowLoader(false);
  };

  /**
   * @description displays selected days and selected slots view
   * @param {Array} selectedData
   */
  const displaySelectedView = (selectedData, showBookMark = false) => {
    return selectedData.map((data, index) => {
      const selectedBookmark = "/images/shared/fav-selected.png";
      const bookmark = "/images/shared/fav.png";
      return (
        <div
          key={`displaySelectedView${data._id}${index}`}
          style={{
            display: "flex",
            width: "40%",
            justifyContent: "space-between",
          }}
        >
          <div style={{}}>
            <div className="day">{data.title}</div>
          </div>
          {showBookMark && (
            <img
              src={data.bookMarked ? selectedBookmark : bookmark}
              style={{
                width: 12,
                height: 12,
                alignSelf: "center",
              }}
              alt="starIcon"
            />
          )}
        </div>
      );
    });
  };

  const displayDeletedSlot = (slot) => {
    return (
      <div className="day">
        {`${convertToDMYFormat(slot.date)} ${slot.title}`}
      </div>
    );
  };

  /**
   * @description displays given slots of the driver
   */
  const displaySlotsView = () => {
    const hasSlots = slotsData._id;
    return (
      <>
        {hasSlots && (
          <div className="slotHeader">
            <div style={{ alignSelf: "center", marginBottom: "1.5vh" }}>
              Driver Slots
            </div>
            <div className="date">Selected Dates</div>
            <div className="day">
              {`${convertToDMYFormat(
                slotsData.startDate
              )}   -   ${convertToDMYFormat(slotsData.endDate)}`}
            </div>
            <div className="date">Selected Days</div>
            {displaySelectedView(daysData, true)}
            <div className="date">Selected Times</div>
            {displaySelectedView(hoursData, true)}
            {deletedSlots?.length > 0 && hasSlots ? (
              <div style={{}}>
                <div className="date">Deleted Slots</div>
                {deletedSlots.map((slot, ind) => displayDeletedSlot(slot, ind))}
              </div>
            ) : null}
          </div>
        )}
      </>
    );
  };

  const slotsDetails = (data) => {
    prepareSlotsData(data);
    setDisplayAvailableDates(true);
  };
  const driverPaymentDetails = (
    item,
    paymentOption,
    totalAmount,
    driver,
    payNow
  ) => {
    setshowVerificationModal(true);
    payNow
      ? (item.totalAmount = `$${totalAmount}`)
      : (item.firstPayment = "$1");
    setPaymentData({
      ...item,
      logo: paymentOption[0]?.logo,
    });
    setDriverNumber(getFormattedPhoneNum(driver.phoneNumber.slice(-10)));
    setDriverId(driver._id);
  };

  let tableData = [];
  driversData.forEach((driver) => {
    const driverStatus =
      driver.status === driversStatus.confirmed ? "Confirmed" : "Unconfirmed";
    const paymentData = [{ ...driver?.payoutMethod }];
    const paymentOption =
      driver?.payoutMethod?.paymentOption &&
      paymentOptionData.filter(
        (payment) =>
          payment.paymentOption === driver?.payoutMethod.paymentOption
      );
    const requestedJobs = driver?.payments.filter(
      (payment) =>
        payment.paymentStatus === paymentStatus.requested ||
        payment.paymentStatus === paymentStatus.pending
    );
    const totalAmount = requestedJobs.reduce((accum, curElem) => {
      return accum + curElem.amount;
    }, 0);
    const paidJobs = driver?.payments.filter(
      (payment) => payment.paymentStatus === paymentStatus.paid
    );

    const state = driver?.physicalAddress?.state;
    const city = driver?.physicalAddress?.city;
    const zipcode = driver?.physicalAddress?.zipCode;
    const area = driver?.physicalAddress?.area;

    const driver_address = `${area ? `${area},` : ""} ${`${city ? city : ""
      } ${state ? `${state},` : ""} ${zipcode ? `${zipcode}` : ""}`}`

    tableData.push({
      _id: driver._id,
      driverName: driver.name,
      driverStatus: driverStatus,
      driverNumber: driver.phoneNumber,
      contactNumber: getFormattedPhoneNum(driver.phoneNumber.slice(-10)),
      vehicleAvailability: driver?.vehicle?.vehicleAvailability ? "Yes" : "No",
      driverAddress: driver_address,
      vehicleType: driver?.driverType === "TRUCK" ? "Truck" : "Car",
      availableDates: driver?.slots?.length
        ? driver.slots.map((slot) => (
          <div
            onClick={() => slotsDetails(slot)}
            style={{ cursor: "pointer", color: "#198eba" }}
          >
            {convertToDMYFormat(slot.startDate)} to{" "}
            {convertToDMYFormat(slot.endDate)}
          </div>
        ))
        : null,
      payment: driver?.payoutMethod?.paymentOption
        ? paymentData.map((item) => (
          <div>
            <div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {paymentOption && (
                  <img
                    src={paymentOption[0]?.logo}
                    alt="PaymentIcon"
                    className="paymentIcon"
                  />
                )}
                <div>
                  <span>{item?.email}</span>
                  {requestedJobs.length > 0 && (
                    <div>
                      {requestedJobs.length}{" "}
                      {`pending ${
                        requestedJobs.length > 1 ? "payments" : "payment"
                        }`}
                    </div>
                  )}
                  {item.isVerified && requestedJobs.length > 0 && (
                    <div
                      className="payNow"
                      onClick={() =>
                        driverPaymentDetails(
                          item,
                          paymentOption,
                          totalAmount,
                          driver,
                          true
                        )
                      }
                    >
                      Pay Now
                    </div>
                  )}

                  {!item.isVerified && (
                    <div
                      className="payNow"
                      onClick={() =>
                        driverPaymentDetails(
                          item,
                          paymentOption,
                          totalAmount,
                          driver,
                          false
                        )
                      }
                    >
                      Verify Driver
                    </div>
                  )}

                  {item.isVerified &&
                    paidJobs.length > 0 &&
                    requestedJobs.length === 0 && <div>Paid</div>}
                </div>
              </div>
            </div>
          </div>
        ))
        : "",
    });
  });
  /**@description Handle hide Model Functionality
   */
  const handleHideModal = () => {
    statusModal.show = false;
    setStatusModal({ ...statusModal });
  };

  /**@description Handle Show Model Functionality
   */
  const handleShowModal = ({
    cancelText,
    confirmText,
    isAlert,
    title,
    message,
    handleConfirm = () => {},
  }) => {
    statusModal.show = true;
    statusModal.cancelText = cancelText || "Cancel";
    statusModal.confirmText = confirmText || "Ok";
    statusModal.isAlert = isAlert || false;
    statusModal.title = title || "";
    statusModal.message = message || "";
    statusModal.handleConfirm = handleConfirm;
    statusModal.handleCancel = handleHideModal;
    setStatusModal({ ...statusModal });
  };

  const handleSelection = (data) => {
    setdisable(!data.length > 0);
    setEditButtonDisable(data.length === 1 ? false : true);
    setSelected(data);
  };
  const handleDeleteDriver = async () => {
    setShowLoader(true);
    const { status } =
      selected.length > 1
        ? await deleteMultipleDriver(selected)
        : await deleteDriver(selected);

    handleShowModal({
      cancelText: "Ok",
      title: status ? `Success` : `Failed`,
      message: status
        ? `${selected.length ? "Selected" : `${selected.length}`
        } Driver(s) deleted successfully!`
        : `Failed to Delete Driver!, please try again.`,
      isAlert: true,
    });
    handleSelection([])
    getDriverData();
    setShowLoader(false);
  };

  const handleInput = ({ key, value }) => {
    const index = driverSignup.findIndex((x) => x.key === key);
    const userItem = driverSignup[index];
    if (value.length <= userItem.maxLength) {
      if (value.length === 0) {
        userItem.value = value;
        userItem.hasError = false;
      } else {
        if (key === "password") {
          if (
            characterCheckRegex(value) === false ||
            value.length < userItem.minLength
          ) {
            userItem.value = value;
            userItem.hasError = true;
          } else {
            userItem.value = value;
            userItem.hasError = false;
          }
        } else if (key === "email") {
          userItem.value = value;
          userItem.hasError = !checkIsEmail(value);
        } else {
          userItem.value =
            key === "phoneNumber" ? `${getFormattedPhoneNum(value)}` : value;
        }
        if (["phoneNumber", "name"].includes(userItem.key)) {
          userItem.hasError =
            (userItem.value || "").trim().length < userItem.minLength;
        }
      }
    }
    if (userItem.type === "numeric") {
      userItem.errorMessage = `${userItem.title} must be ${userItem.minLength - 4
        } digits`;
    } else if (userItem.type === "password") {
      userItem.errorMessage = `${userItem.title} must be ${userItem.minLength} digits`;
    } else if (userItem.type === "email") {
      userItem.errorMessage = "Enter a valid email address";
    } else {
      userItem.errorMessage = `${userItem.title} must contain at least ${userItem.minLength} characters`;
    }
    setdriverSignup([...driverSignup]);
  };

  const handleCode = ({ key, value }) => {
    if (value.length <= code.maxLength) {
      code.value = excludeSpace(value);
      code.hasError = (code.value || "").trim().length < code.minLength;
      code.errorMessage = `${code.title} must be ${code.minLength} digits`;
      setCode({ ...code });
    }
  };

  const clearDriverInput = () => {
    driverSignup.forEach((user) => {
      user.value = "";
      user.hasError = false;
    });
    setdriverSignup([...driverSignup]);
  };

  useEffect(() => {
    clearDriverInput();
  }, [!showModal]);

  const clearCodeInput = () => {
    code.value = "";
    setCode({ ...code });
  };
  /* Driver Creation functionality */
  const handleSubmit = async () => {
    const driverData = {};
    driverSignup.forEach((user) => {
      if (user.key !== "confirmpassword") {
        driverData[user.key] =
          user.key === "phoneNumber"
            ? `${countryCode}${reformatPhoneNumber(user.value)}`
            : user.value;
      }
    });
    const { status, data, message } = await driverCreation(driverData);
    if (message) {
      handleShowModal({
        cancelText: "Ok",
        title: message & `Failed`,
        message: message && "Driver is already exists",
        isAlert: true,
      });
      setshowModal(false);
      clearDriverInput();
    } else if (status) {
      if(driverAddressId){
        const driverAddressIdPayload = { physicalAddress: driverAddressId}
        await updateDriver(data.driver._id, driverAddressIdPayload);
      }

      setShowDriverModal(true);
      setStatus(status);

      setDriverdetails(data.driver);
      setshowModal(true);
      setIsAddAddress(false)
      clearDriverInput();
      getDriverData();
    }
  };

  const handleSubmitAddress = async () => {
    let isZipCode = true;
    const addressPayload = {}
    ADD_DRIVER_ADDRESS.map((dataItem) => {
      if(dataItem.value){
        addressPayload[dataItem.key] = dataItem.value;
      }
      
      if(dataItem.key === 'zipCode' && !dataItem.value){
        isZipCode = false;
      }
    })
    if(!isZipCode){
      handleShowModal({
        cancelText: "Ok",
        title: isAddAddress?"Unable to add driver address": "Unable to update driver address",
        message:"Please provide a driver zipcode",
        isAlert: true,
      });
      ADD_DRIVER_ADDRESS.forEach((dataItem) => {
        dataItem.value = "";
      })
      return;
    }

    const existingZipcode = await getZipcode(addressPayload?.zipCode);
    if (!existingZipcode.status) {
      handleShowModal({
        cancelText: "Ok",
        title: "Not serviceable zipcode",
        message:isAddAddress?"We are not available in this area": "Failed to update driver address",
        isAlert: true,
      });

      ADD_DRIVER_ADDRESS.forEach((dataItem) => {
        dataItem.value = "";
      })
      return;
    }


    if(isAddAddress){
     const res = await driverAddressCreation(addressPayload); 
     if(res.status){
      setdriverAddressId(res.data._id);
      setShowEditModal(false);
      setshowModal(true)

    }else{
      handleShowModal({
        cancelText: "Ok",
        title: res.message & `Failed`,
        message: res.message && "Failed to create driver address",
        isAlert: true,
      });
      setShowEditModal(false);
      ADD_DRIVER_ADDRESS.forEach((dataItem) => {
        dataItem.value = "";
      })
    }
  }else{ 
    let res;
    if (driverAddress && driverAddress?._id) {
      res = await driverAddressDeletion(driverAddress?._id);
    }

    res = await driverAddressCreation(addressPayload);

    if (res.status) {
      const driverAddressId = { physicalAddress: res.data._id }
      res = await updateDriver(selected[0], driverAddressId);
    }

    if (res.message) {
      handleShowModal({
        cancelText: "Ok",
        title: res.message & `Failed`,
        message: res.message && "Failed to update driver address",
        isAlert: true,
      });
      setShowEditModal(false);
      ADD_DRIVER_ADDRESS.forEach((dataItem) => {
        dataItem.value = "";
      })
    } else if (res.status) {
      await getDriverData();
      handleShowModal({
        cancelText: "Ok",
        title: `Success`,
        message: "Driver address updated succesfully",
        isAlert: true,
      });
      setShowEditModal(false);

      ADD_DRIVER_ADDRESS.forEach((dataItem) => {
        dataItem.value = "";
      })

    }
  }
  };




  /* Confirm Code && assigning Slots functionality */
  const handleCodeSubmit = async () => {
    const daysData = _.cloneDeep(SLOTS_DATA.days);
    setShowLoader(true);
    const confirmDriverData = {
      username: driverDetails?.phoneNumber,
      code: code.value,
    };
    const { status: codeStatus, message: codeMessage } =
      statusData && (await confirmDriver(confirmDriverData));
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(driverDays() - 1);
    endDate.setHours(0, 0, 0, 0);
    const slotTimings = [
      { startTime: 8, endTime: 12 },
      { startTime: 12, endTime: 16 },
      { startTime: 16, endTime: 20 },
    ];

    const slotData = {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      days: [],
      driver: driverDetails?._id,
    };

    daysData.forEach((day) => {
      slotData.days.push({
        day: day.utcDay,
        isFavourite: false,
        slotTimings,
      });
    });

    if (codeStatus) {
      setshowModal(false);
      setShowDriverModal(false);
      const { status } = await createDriverSlots(slotData);
      handleShowModal({
        cancelText: "Ok",
        title: status ? `Success` : `Failed`,
        message: status
          ? `Driver with slots ${convertToDMYFormat(startDate)} to ${convertToDMYFormat(endDate)}(8am-8pm) added successfully!`
          : `Failed to create Driver slots!, please try again.`,
        isAlert: true,
      });
      getDriverData();
      setShowLoader(false);
      clearDriverInput();
    } else if (codeMessage) {
      handleShowModal({
        cancelText: "Ok",
        title: codeMessage && `Failed`,
        message: codeMessage && codeMessage,
        isAlert: true,
      });
      setShowLoader(false);
    }
    clearCodeInput();
    setShowLoader(false);
    setshowModal(false);
    setShowEditModal(false)
  };

  /* Resend Confirmation Code functionality */

  const handleConfirmButton = async (driverdata) => {
    setDriverdetails({
      phoneNumber: driverdata?.driverNumber,
      _id: driverdata?._id,
    });
    const driverInfo = {
      username: `${driverdata?.driverNumber}`,
    };
    const { status, message } = await confirmDriverWithCode(driverInfo);
    if (status) {
      setshowModal(true);
      setShowDriverModal(true);
      setStatus(true);
    } else if (message) {
      handleShowModal({
        cancelText: "Ok",
        title: message && `Failed`,
        message: message,
        isAlert: true,
      });
    }
  };

  /* Displaying Drivers Table functionality */
  const displayingTerritorytable = () => {
    return (
      <TerritoryTable
        tableHeading={[...driversHeading]}
        rows={[...tableData]}
        tablecellpadding={"10.5vw"}
        maxHeight={"40vh"}
        minHeight={"20vh"}
        handleSelection={handleSelection}
        selected={selected}
        handleConfirmButton={handleConfirmButton}
      />
    );
  };

  const displayingDeleteButton = () => {
    return (
      <CustomButton
        title={"Delete"}
        className={"deleteButton"}
        handleButtonClick={() => handleDeleteDriver()}
        disabled={disable}
      />
    );
  };

  const renderVerificationPopUp = () => {
    return (
      <div className="verify">
        <div className="verifyDetail">Verify Payment Details</div>
        <div className="newDriver">
          A new driver has been added. Please verify their payment details.
        </div>
        <CustomButton
          title={"Verify Details"}
          className={"verifyButton"}
          handleButtonClick={() => setshowVerificationModal(true)}
          additionalSx={{
            fontWeight: 600,
            fontSize: "1.2vw",
            lineHeight: "1",
          }}
        />
      </div>
    );
  };
  const handleRemoveIcon = () => {
    setshowModal(false);
    setShowEditModal(false);
    clearDriverInput();
  };
  const handleCodeRemoveIcon = () => {
    setshowModal(false);
    setShowEditModal(false);
    setShowDriverModal(false);
    clearDriverInput();
    clearCodeInput();
  };
  const handleButtonClickForAddress = ()=> {
    setIsAddAddress(true);
    setShowEditModal(true);
    setDriverAddress(null)
  }
  const handleButtonClickForEditAddress = ()=> {
    setIsAddAddress(false);
    setShowEditModal(true);
    setDriverAddress(driversData.find((dData) => dData._id === selected[0])?.physicalAddress);
  }
  const displayingDriverpopupButton = () => {
    return (
      <CustomButton
        title={"Add Driver"}
        className={"driverpopupButton"}
        handleButtonClick={handleButtonClickForAddress}
      />
    );
  };
  const displayingDriverpopupEditButton = () => {
    return (
      <CustomButton
        title={"Edit Address"}
        className={"driverEditButton"}
        handleButtonClick={handleButtonClickForEditAddress}
        disabled={editButtonDisable}
      />
    );

  };

  const hideContactModal = () => {
    setShowContactModal(false);
    setshowVerificationModal(false);
  };

  const renderUnsuccessPopup = () => {
    return (
      <div className="verify">
        <div className="verifyDetail">
          Verify Payment Details - Payment details verified unsuccessfully!
        </div>
        <div className="newDriver">
          The payment details provided by the driver are invalid. Please get in
          touch with the driver at {driverNumber}
        </div>
        <CustomButton
          title={"Done"}
          className={"done"}
          handleButtonClick={hideContactModal}
        />
      </div>
    );
  };

  const hideVerifyPopUp = () => {
    setShowVerifyPopup(false);
  };

  const hideVerificationModal = () => {
    setshowVerificationModal(false);
  };

  return (
    <div className="driversOverview">
      {emptyTab ? (
        <div className={"tenantListEmpty"}>
          <TenantListEmpty
            width={"75%"}
            header="No Drivers are Found"
            canAdd={false}
          />
        </div>
      ) : (
        <>
          {showModal && (
            <AddDriversModal
              open={true}
              isAdd={true}
              handleRemoveIcon={handleRemoveIcon}
              driverSignup={driverSignup}
              setdriverSignup={setdriverSignup}
              handleCodeSubmit={handleCodeSubmit}
              setDriverdetails={setDriverdetails}
              setCode={setCode}
              code={code}
              handleInput={handleInput}
              handleSubmit={handleSubmit}
              showDriverModal={showDriverModal}
              handleCodeRemoveIcon={handleCodeRemoveIcon}
              handleCode={handleCode}
              setcountryCode={setcountryCode}
              countryCode={countryCode}
            />
          )}
          {showEditModal && (
            <EditAddressModal
              open={true}
              isAdd={isAddAddress}
              driverAddress = {driverAddress}
              handleRemoveIcon={handleRemoveIcon}
              handleInput={()=>{}}
              handleSubmit={handleSubmitAddress}
              showDriverModal={showDriverModal}
            />
          )}
          <div className="driverButtons">
            {displayingDriverpopupButton()} {displayingDriverpopupEditButton()} {displayingDeleteButton()}
          </div>
          {showVerifyPopup && (
            <CustomModal
              isOpen={showVerifyPopup}
              handleRemoveIcon={hideVerifyPopUp}
              sx={VERIFY_POPUP}
              content={renderVerificationPopUp}
              closeButtonStyle="verifyCrossIcon"
            />
          )}
          {showVerificationModal && (
            <CustomModal
              isOpen={true}
              handleRemoveIcon={hideVerificationModal}
              sx={POPUP_STYLE}
              content={() => (
                <PaymentDetailsModal
                  paymentData={paymentData}
                  setShowContactModal={setShowContactModal}
                  driverId={driverId}
                  setshowVerificationModal={setshowVerificationModal}
                  setRefresh={setRefresh}
                  refresh={refresh}
                  hideContactModal={hideContactModal}
                />
              )}
              closeButtonStyle="crossIcon"
            />
          )}
          {showContactModal && (
            <CustomModal
              isOpen={true}
              handleRemoveIcon={hideContactModal}
              sx={VERIFY_POPUP}
              content={renderUnsuccessPopup}
              closeButtonStyle="verifyCrossIcon"
            />
          )}
          {displayAvailableDates && (
            <CustomModal
              isOpen={true}
              handleRemoveIcon={() => setDisplayAvailableDates(false)}
              sx={AVAILABLE_DATES_POPUP}
              content={displaySlotsView}
              closeButtonStyle="slotsCrossIcon"
            />
          )}
          {displayingTerritorytable()}
        </>
      )}

      {statusModal?.show && (
        <ConfirmModal
          cancelText={statusModal.cancelText}
          confirmText={statusModal.confirmText}
          isAlert={statusModal.isAlert}
          title={statusModal.title}
          message={statusModal.message}
          handleCancel={statusModal.handleCancel}
          handleConfirm={statusModal.handleConfirm}
          textTransform={"capitalize"}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};

export default DriversTab;
