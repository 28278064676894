import React, { useEffect } from "react";
import { FormControl, MenuItem, OutlinedInput } from "@mui/material";
import CustomInput from "../custom-input";
import CustomButtom from "../custom-button";
import { BOX_STYLE, ADD_PROPERTY } from "../Constants";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import "./AddProperty.scss";
import { APARTMENT_TYPE } from "../../routes/Constants";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getManagers } from "../../redux/actions/manager-actions/managerActions";
import { ITEM_HEIGHT, ITEM_PADDING_TOP } from "../Constants";
import { getFormattedPhoneNum } from "../../utils/helperMethods";
import { validations } from "../../utils/helperMethods";
import CustomModal from "../custom-modal/CustomModal";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const AddProperty = (props) => {
  const apartmentData = [...APARTMENT_TYPE.map((x) => x.type)];
  const theme = useTheme();
  const dispatch = useDispatch();
  const propertyManagerData = useSelector(
    (state) => state.propertyManagerReducer
  );
  const { propertyManagerList } = propertyManagerData;
  const { isAdd } = props;

  useEffect(() => {
    dispatch(getManagers());
  }, [dispatch]);

  /**@description get Styles  Functionality
   */
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  /**@description Handle change in Aprtment types input Functionality
   */
  const handleChange = (event) => {
    props.handleChange({ key: "apartmentTypes", value: event.target.value });
  };

  /**@description Handle change in add Property form input Functionality
   */
  const handleTextChange = (textData, isManager) => {
    const { key, value, regexType } = textData;
    const { addProperty } = props;
    const dataItem = ADD_PROPERTY.find(
      (x) => x.key === key && x.isManager === !!isManager
    );
    if (dataItem?.isManager) {
      let data = props.propertyData?.manager || {};
      const item = validations(addProperty, key, value, regexType);
      let pManager =
        key === "email"
          ? (propertyManagerList || []).find((mgr) => mgr.email === item.value)
          : null;
      if (pManager) {
        data = { ...pManager };
      } else if (key === "email" && data?._id) {
        data = {
          email: item.value,
        };
      } else {
        data[key] = item.value;
      }
      props.handleChange({ key: "manager", value: data });
    } else {
      const item = validations(addProperty, key, value, regexType);
      props.handleChange({ key: item.key, value: item.value });
    }
  };

  /**@description Displaying Header Functionality
   */
  const displayHeader = () => (
    <div className={"propertyHeaderRow"}>
      <div className={"addProperty"}>
        {isAdd ? "Add" : "Edit"} Property Details
      </div>
    </div>
  );

  /**@description Displaying Form Functionality
   */
  const displayFormFields = () => (
    <form onSubmit={props.handleSubmit}>
      <div className="uploadRow">
        {props.addProperty.map((dataItem) => {
          const key = dataItem.key;
          let value = props.propertyData?.[key] || "";
          let isDisable = false;
          if (dataItem.isManager) {
            value =
              key === "phoneNumber"
                ? getFormattedPhoneNum(props.propertyData?.manager?.[key]) || ""
                : key === "managerName"
                ? props.propertyData?.manager?.name ||
                  props.propertyData?.manager?.managerName ||
                  ""
                : props.propertyData?.manager?.email || "";
            isDisable = key !== "email" && props.propertyData?.manager?._id;
          }
          return (
            <div
              className={"addPropertyInput"}
              key={`displayFormFields${dataItem.title}`}
            >
              {key === "apartmentTypes" ? (
                <FormControl
                  sx={{
                    m: 0,
                    width: "100%",
                  }}
                  required
                >
                  <InputLabel
                    id="demo-multiple-name-label"
                    sx={{
                      "&.MuiInputLabel-root": {
                        fontSize: "1.5vw",
                        top: "-0.5vh",
                      },
                    }}
                  >
                    {dataItem.title}
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={value}
                    input={<OutlinedInput label="Apartment Types" />}
                    onChange={handleChange}
                    MenuProps={MenuProps}
                    className="select"
                    sx={{
                      "&.MuiOutlinedInput-root": {
                        padding: "1vh 0 0",
                        fontSize: "1.5vw",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: "2vw",
                      },
                    }}
                  >
                    {apartmentData.map((apartment) => (
                      <MenuItem
                        key={apartment}
                        value={apartment}
                        style={getStyles(apartment, apartment, theme)}
                        sx={{
                          "&.MuiMenuItem-root": {
                            fontSize: "1vw",
                          },
                        }}
                      >
                        {apartment}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <CustomInput
                  dataItem={{ ...dataItem, value }}
                  additionalSx={{ width: "100%", margin: 0 }}
                  handleInput={(data) => {
                    handleTextChange(data, dataItem.isManager);
                  }}
                  required={true}
                  disabled={isDisable}
                />
              )}
            </div>
          );
        })}
      </div>

      <div className={"addPropertyButton"}>
        <CustomButtom
          title={`${
            isAdd ? "Add Property Details" : "Update Property Details"
          }`}
          additionalSx={{
            padding: "1vw 8vw",
            fontWeight: 600,
            fontSize: "1.5vw",
          }}
        />
      </div>
    </form>
  );

  const displayModalContent = () => {
    return (
      <>
        {displayHeader()}
        {displayFormFields()}
      </>
    );
  };

  return (
    <CustomModal
      isOpen={props.isOpen}
      handleRemoveIcon={() => props.handleClose()}
      sx={BOX_STYLE}
      content={displayModalContent}
      closeButtonStyle="removeIcon"
    />
  );
};

AddProperty.defaultProps = {
  isOpen: true,
  isAdd: true,
  propertyData: {},
  handleClose: () => {},
  handleChange: () => {},
  handleSubmit: () => {},
};

export default AddProperty;
