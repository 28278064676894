/**@description displays property view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import {
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  userPasswordDetails: [],
  loading: true,
  message: "",
};

/**@description USER Update Password Function */
const UpdatePassword = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_PASSWORD_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        userPasswordDetails: payload,
        message: "Password updated successfully",
        loading: false,
      };
    case UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        message: payload,
      };

    default:
      return state;
  }
};
export default UpdatePassword;
