import React, { useState } from 'react';
import { CircularProgress } from "@mui/material";
const ImageWithLoader = ({ src, alt }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setIsError(true);
  };

  return (
    <div className="card">
      {isLoading && 
      <div className='containerStyle'>
      <CircularProgress color="inherit"/>
      </div>
       }

      {isError ? (
        <div className='containerStyle'>Image failed to load</div>
      ) : (
        <img
          src={src}
          alt={alt}
          onLoad={handleImageLoad}
          onError={handleImageError}
          style={{ display: isLoading ? 'none' : 'block', width: '100%', height: '100%' }}
        />
      )}
    </div>
  );
};

export default ImageWithLoader;