import React, { useState, useEffect } from "react";

import {
  getAllRefundOrders,
  updateOrderStatus,
} from "../../../redux/actions/territory-manager-actions/territoryManager";
import Loader from "../../../components/loader/Loader";
import TerritoryTable from "../territoryTable";
import { REFUND_ORDERS_HEADINGS } from "../../Constants";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TenantListEmpty from "../../../components/tenant-list-empty/TenantListEmpty";

import CustomModal from "../../../components/custom-modal/CustomModal";
import {REFUND_POPUP } from "../../../components/Constants";
import CustomButton from "../../../components/custom-button/CustomButton";
import { ToastContainer, toast } from "react-toastify";

import "./refundOrders.scss";

const RefundOrders = () => {
  const [allRefundOrders, setAllRefundOrders] = useState([]);

  const [refundOrdersData, setRefundOrdersData] = useState([
    ...REFUND_ORDERS_HEADINGS,
  ]);

  const [showLoader, setShowLoader] = useState(false);

  const [msg, setMsg] = useState(false);

  const [getSingleOrder, setSingleOrder] = useState([]);

  const [showPopup, setShowPopup] = useState(false);

  const [updateData, setUpdateData] = useState([]);

  const getRefundInitiatedOrders = async () => {
    setShowLoader(true);
    const { data, message } = await getAllRefundOrders();
    setAllRefundOrders(data);

    if (message) {
      setMsg(true);
    }
    setShowLoader(false);
  };

  const updateRefundStatusBtn = async (id) => {
    const { data, message } = await updateOrderStatus(id);

    if (data) {
      setShowPopup(!showPopup);
      setUpdateData(data);
      toast.success("User order successfully canceled");
    }

    if (message) {
      setMsg(true);
    }
  };

  useEffect(() => {
    getRefundInitiatedOrders();
  }, [updateData]);

  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            "&:last-child": {
              paddingBottom: "6.5vw",
            },
          },
        },
      },
    },
  });

  const onClickHandler = (id) => {
    const singleOrders = allRefundOrders.filter((order) => order?._id === id);
    setShowPopup(true);
    setSingleOrder(singleOrders);
  };

  const refundInitiatedOrders = allRefundOrders?.map((order) => ({
    _id: order?._id,
    orderId: order.orderId,
    clientName: order?.user?.name || "",
    status: order?.orderStatus,
    refundAmount: `$${order?.discountDetails?.finalAmountAfterDiscount ?  (order?.discountDetails?.finalAmountAfterDiscount + order?.discountDetails?.requiredTax).toFixed(2)  : (order?.plan?.storageCost * 4) + (order?.plan?.tax * 4) }`,
  }));

  const data = getSingleOrder.map((order) => {
    return (
      <div className="singleOrderDetails">
        <span
          className = "refundTitle"
        >
          {order?.orderStatus === "REFUND"
            ? "Refund Requested"
            : "Order Cancelled"}
        </span>
        <p>
          <strong>Customer name :</strong> {order?.user?.name}
        </p>
        <p>
          <strong>Refund amount :</strong>
          {`$${order?.discountDetails?.finalAmountAfterDiscount ?  (order?.discountDetails?.finalAmountAfterDiscount + order?.discountDetails?.requiredTax).toFixed(2)  : (order?.plan?.storageCost * 4) + (order?.plan?.tax * 4) }`}
        </p>
        {order?.orderStatus === "REFUND" ? (
          <>
            <span className ="cancelText">
              The cancellation of the order has been processed. To initiate the
              refund, kindly click on the provided button.
            </span>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
               <CustomButton
                title={`${"Cancel"}`}
                additionalSx={{
                  padding: "0.5vw 2vw",
                  fontWeight: 600,
                  fontSize: "1vw",
                }}
                handleButtonClick={() => setShowPopup(false)}
              />
              
              <CustomButton
                title={`${"Refund"}`}
                additionalSx={{
                  padding: "0.5vw 2vw",
                  fontWeight: 600,
                  fontSize: "1vw",
                  marginLeft: "1vw"
                }}
                handleButtonClick={() => updateRefundStatusBtn(order?._id)}
              />
            </div>
          </>
        ) : (
          <span className ="cancelText">
            The order has been successfully cancelled and the refund process has
            been completed.
          </span>
        )}
      </div>
    );
  });


  const contentDisplay = (data) => <div key={data?._id}>{data}</div>

  return (
    <>
      {showPopup && (
        <CustomModal
          isOpen={showPopup}
          sx={REFUND_POPUP}
          content={() => contentDisplay(data)}
          closeButtonStyle="refundRemoveIcon"
          handleRemoveIcon={() => setShowPopup(false)}
        />
      )}
      {msg ? (
        <>
          <div className={"tenantListEmpty"}>
            <TenantListEmpty
              width={"75%"}
              header="No Refund Orders are Found"
              canAdd={false}
            />
          </div>
        </>
      ) : (
        <ThemeProvider theme={theme}>
          <div style={{ margin: "10vh" }} className="CustomerTable">
            <TerritoryTable
              rows={refundInitiatedOrders || []}
              tableHeading={refundOrdersData}
              tablecellpadding={"10.5vw"}
              maxHeight={"46vh"}
              hideCheckbox
              handleClick={(Id) => onClickHandler(Id)}
            />
          </div>
        </ThemeProvider>
      )}
      <Loader show={showLoader} />
      <ToastContainer />
    </>
  );
};

export default RefundOrders;