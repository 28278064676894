/**@description displays navigation tab and children
 *@author Anusha Gurajapu
 *@version 1.0.0
 */
import React from "react";
import { useHistory } from "react-router-dom";
import CustomButton from "../custom-button";
import {
  getUserInfo,
  handleLogout as logoutUser,
} from "../../utils/authentication";
import "./Layout.scss";

const Layout = (props) => {
  const history = useHistory();
  const userInfo = getUserInfo();
  // const [activeNav, setActiveNav] = (0);

  /**@description displays navigation bar UI
    const displayNavBar = () => {
    return (
      <div className={"navItems"}>
        {properties.map((property, index) => {
          return (
            <div
              className={`nav ${activeNav === index ? "activeNav" : ""}`}
              role="presentation"
              onClick={() => {
                setActiveNav(index);
              }}
              key={`displayNavBar${property}`}
            >
              {property}
            </div>
          );
        })}
      </div>
    );
  }; */

  /**@description displays profile UI
  const displayProfile = () => {
    return <div className="profile" />;
  };*/

  const handleLogout = () => {
    const logoutURL = logoutUser();
    history.push(logoutURL);
  };

  const getHeight = () =>
    window.innerHeight -
    (document.getElementById("navBar")?.clientHeight || 120);

  const displayLogout = () => {
    return (
      <CustomButton
        startIcon={
          <img
            src="/images/shared/logout.svg"
            alt="Logout Icon"
            className={"logoutIcon"}
          />
        }
        title={"Logout"}
        className="logout"
        handleButtonClick={handleLogout}
        additionalSx={{
          "& .MuiButton-startIcon": {
            paddingRight: "0.25vw",
          },
          fontSize: "1.25vw",
          padding: "0.25vh 2vw",
          backgroundColor: "#fff",
          color: "rgba(0, 125, 174, 1) !important",
        }}
      />
    );
  };

  /**@description displays properties tabs
   **/
  const displayTabs = () => {
    return (props.tabsData || []).map((tab) => {
      return (
        <div
          className={
            tab._id === props.activeTab?._id
              ? "layoutActiveTab"
              : "layoutInactiveTab"
          }
          role="presentation"
          onClick={() => {
            props.handleTabChange(tab);
          }}
          key={`displayTabs${tab._id}${tab.name}`}
        >
          {tab.name}
        </div>
      );
    });
  };

  const displayTabsContainer = () => {
    const element = document.getElementById("tabsContainer");
    const hasScroll = element?.scrollWidth > element?.offsetWidth;
    return (
      <div className={"tabsRowContainer"}>
        <div className={"tabsContainer"} id="tabsContainer">
          {hasScroll ? (
            <div
              className="scrollRightArrow"
              onClick={() => {
                document.getElementById("tabsContainer").scrollLeft -= 100;
              }}
            >
              <img
                src="/images/shared/Arrow.svg"
                alt="scroll"
                width="20px"
                height="32px"
              />
            </div>
          ) : null}
          {displayTabs()}
          {hasScroll ? (
            <div
              className="scrollArrow"
              onClick={() => {
                document.getElementById("tabsContainer").scrollLeft += 100;
              }}
            >
              <img
                src="/images/shared/Arrow.svg"
                alt="scroll"
                width="20px"
                height="32px"
              />
            </div>
          ) : null}
        </div>
        <div className={"logoContainer"}>
          <img
            src="/images/shared/White.png"
            alt="Valet Closet Logo"
            className="powerByLogo"
          />
        </div>
      </div>
    );
  };

  return (
    <div className={"root"} style={{ height: window.innerHeight }}>
      <div className={"navContainer"} id="navBar">
        <div className={"layoutHeaderContainer"}>
          <div>
            <div className={"userName"}>Hi {userInfo?.name || ""},</div>
            <div className={"welcomeBack"}>Welcome back to your dashboard</div>
          </div>
          {displayLogout()}
        </div>
        {displayTabsContainer()}
      </div>
      <div style={{ height: getHeight() }}>{props.children}</div>
    </div>
  );
};

export default Layout;
