/**@description displays property view
 *@author Suresh Chekka
 *@version 1.0.0
 */

 import {
    USER_SIGNUP_REQUEST,
    USER_SIGNUP_SUCCESS,
    USER_SIGNUP_FAIL,
  } from "../actions/actionTypes";
  
  const initialState = {
    userInfo: [],
    signup: null,
    errorMessage: "",
  };
  
  /**@description USER Login Reducer  Function */
  const userSignupReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case USER_SIGNUP_REQUEST:
        return {
          loading: true,
        };
      case USER_SIGNUP_SUCCESS:
        return {
          ...state,
          userDetails: payload,
          loading: false,
        };
      case USER_SIGNUP_FAIL:
        return {
          ...state,
          errorMessage: payload,
        };
      default:
        return state;
    }
  };
  export default userSignupReducer;