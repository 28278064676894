import React from "react";
import { TextField } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: "1.5vw",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "1.5vw",
          top: "-1vw",
        },
      },
    },
  },
});

const CustomInput = (props) => {
  const { dataItem } = props;
  return (
    <ThemeProvider theme={theme}>
      <TextField
        InputProps={props.InputProps}
        error={dataItem.hasError}
        id={props.id}
        inputProps={{
          maxLength: dataItem.maxLength,
        }}
        maxRows={props.maxRow}
        label={dataItem.title}
        type={dataItem.type}
        value={dataItem.value}
        variant={props.variant}
        helperText={dataItem.hasError ? dataItem.errorMessage : null}
        className={props.className}
        multiline={props.multiline}
        onChange={(e) => {
          dataItem.callback
            ? props.handleInput(e)
            : props.handleInput({
                value: e.target.value,
                key: dataItem.key,
                regexType: dataItem.regexType,
              });
        }}
        onClick={props.handleClick}
        onKeyPress={props.onKeyPress}
        sx={{ ...props.sxProps, ...props.additionalSx }}
        required={props.required}
        disabled={!!props.disabled}
        autoComplete={props.autoComplete}
        InputLabelProps={{ shrink: true }}
      />
      {dataItem.hasTag ? dataItem.tagLineText : null}
    </ThemeProvider>
  );
};

CustomInput.defaultProps = {
  dataItem: {},
  className: "",
  id: "standard-error",
  variant: "standard",
  required: false,
  disabled: false,
  autoComplete: "off",
  size: "small",
  handleInput: () => {},
  handleClick: () => {},
  sxProps: {
    "& .MuiInputBase-input": {
      padding: "0 0 1vw",
    },
    "& .MuiFormHelperText-root": {
      bottom: "0.2rvw",
    },
    ".css-1ptx2yq-MuiInputBase-root-MuiInput-root:before": {
      borderBottom: "0.0625vw solid #e8e8e8",
    },
  },
  additionalSx: {},
};
export default CustomInput;
