/**@description displays property view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import React, { useState, useEffect } from "react";
import "./adminZipcodes.scss";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import CustomCard from "../../../components/custom-card/CustomCard";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CustomButton from "../../../components/custom-button/CustomButton";
import CustomInput from "../../../components/custom-input/CustomInput";
import CustomTable from "../../../components/custom-table/Customtable";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import {
  STATE_ZIPCODE,
  STATE_ZIPCODETABLE_HEADINGS,
  ZIPCOD_DETAILS,
} from "../../Constants";
import {
  getserviceableData,
  getDriverServiceableData,
  createserviceableData,
  createDriverServiceableData,
  deleteserviceableData,
  deleteDriverServiceableData
} from "../../../redux/actions/adminZipcodeAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loader/Loader";
import Custombuton from "../../../components/custom-button/CustomButton";

const AdminZipcodes = (props) => {
  const tabs = ['User', 'Driver']
  const serviceabledata = useSelector(
    (state) => state.serviceableData.ServiceableData
  );

  const statezipcode = [...STATE_ZIPCODE];
  const statezipcodetableHeadingsData = [...STATE_ZIPCODETABLE_HEADINGS];
  const [serviceableData, setserviceableData] = useState([...serviceabledata]);
  const [searchText, setSearchText] = useState("");
  const [searchedserviceableData, setSearchedserviceableData] = useState([])
  const [selected, setSelected] = useState([]);
  const [activeTab, setActivetab] = useState(0);
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [ShowModal, setShowModal] = useState(false);
  const [statusModal, setStatusModal] = useState({});

  const [inputValue, setinputValue] = useState("");
  const [data, setData] = useState([]);
  const [zipCode, setzipCode] = useState([...ZIPCOD_DETAILS]);
  const [zipcodeType, setZipcodeType] = useState('user');

  const handleChange = () => {
    if (zipcodeType === 'user') {
      setZipcodeType('driver')
    } else {
      setZipcodeType('user')
    }
  };

  /**@description Fetching serviceable Zipcodes Data Functionality
   */
  const fetchserviceableData = async () => {
    setSearchText("");
    setSearchedserviceableData([]);
    setShowLoader(true);
    let serviceablestateData;
    if (zipcodeType === 'user') {
      serviceablestateData = await dispatch(getserviceableData());
    } else {
      serviceablestateData = await dispatch(getDriverServiceableData());
    }
    setShowLoader(false);
    setserviceableData([...serviceablestateData]);
  };

  useEffect(() => {
    fetchserviceableData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipcodeType]);

  /**@description Handle Property Select  Functionality
   */

  const handlepropertyListSelect = (selectedData) => {
    setSelected(selectedData);
  };

  const handleclick = () => {
    setShowModal(true);
  };

  /**@description Handle input onchange  Functionality
   */
  const onchange = ({ key, value }) => {
    const index = zipCode.findIndex((x) => x.key === key);
    const zipCodeItem = zipCode[index];

    if (value.length < zipCodeItem.minLength) {
      zipCodeItem.value = value;
      zipCodeItem.hasError = true;
      zipCodeItem.errorMessage = `Please Enter a valid ${zipCodeItem.title}`;
    } else if (index > -1) {
      zipCodeItem.value = value;
      zipCodeItem.hasError = false;
    }
    if (value.length === 0) {
      zipCodeItem.value = value;
      zipCodeItem.hasError = false;
    }
    setzipCode([...zipCode]);
    setinputValue(zipCodeItem.value);
  };

  /**@description add Zipcodes Functionality
   */
  const addzipcodes = async (event) => {
    if (event.key === "Enter") {
      const value = inputValue.split(",");
      setData([...data, ...value]);
      setinputValue("");
    }
  };

  /**@description Handle on Submit Functionality
   */
  const onSubmit = async (e) => {
    setShowLoader(true);
    e.preventDefault();

    const serviceabledata = data?.map((zipCode) => ({ zipCode }));


    let res;
    if (zipcodeType === 'user') {
      res = await createserviceableData(serviceabledata);
    } else {
      res = await createDriverServiceableData(serviceabledata);
    }
    if (res.status) {
      fetchserviceableData();
      setData([]);
      handleClearInput();
    }


    handleShowModal({
      cancelText: "Ok",
      title: res.status ? `Success` : `Failed`,
      message: res.status ? `${zipcodeType} serviceable zipcode(s) Added successfully!` : res.message,
      isAlert: true,
    });
    setShowLoader(false);
  };

  /**@description Handle on Delete Functionality
   */
  const onDelete = (id) => {
    const updates = data.filter((each, idx) => each !== id);
    setData([...updates]);
  };

  /**@description Handle hide Model Functionality
   */
  const handleHideModal = () => {
    statusModal.show = false;
    setStatusModal({ ...statusModal });
  };

  /**@description Handle Show Model Functionality
   */
  const handleShowModal = ({
    cancelText,
    confirmText,
    isAlert,
    title,
    message,
    handleConfirm = () => { },
  }) => {
    statusModal.show = true;
    statusModal.cancelText = cancelText || "Cancel";
    statusModal.confirmText = confirmText || "Ok";
    statusModal.isAlert = isAlert || false;
    statusModal.title = title || "";
    statusModal.message = message || "";
    statusModal.handleConfirm = handleConfirm;
    statusModal.handleCancel = handleHideModal;
    setStatusModal({ ...statusModal });
  };

  /**@description handles delete Zipcode*/
  const handleDeleteZipcode = async () => {
    if (selected?.length) {
      handleHideModal();
      setShowLoader(true);
      let res;

      if (zipcodeType === 'user') {
        res = await deleteserviceableData(selected);
      } else {
        res = await deleteDriverServiceableData(selected);
      }


      handleShowModal({
        cancelText: "Ok",
        title: res.status ? `Success` : `Failed`,
        message: res.status
          ? `${selected.length ? "Selected" : `${selected.length}`
          } ${zipcodeType} serviceable zipcode(s) deleted successfully!`
          : res.message,
        isAlert: true,
      });

      if (res.status) {
        fetchserviceableData();
        setSelected([]);
        handleClearInput();
      } else {
        setShowLoader(false);
      }
      setShowLoader(false);
    }
  };

  /**@description Handle Show Delete Model Functionality
   */
  const handleShowDeleteModal = () => {
    if (selected?.length) {
      handleShowModal({
        confirmText: "Delete",
        title: `Delete ${zipcodeType} serviceable zipcod${selected.length > 1 ? "es" : "e"}`,
        message: `Are you sure you want to delete the selected ${zipcodeType} serviceable zipcod${selected.length > 1 ? "es" : "e"
          }?`,
        handleConfirm: handleDeleteZipcode,
      });
    }
  };

  /**@description Handle displaying Zipcode card  Functionality
   */
  const displayZipcodeCard = () => {
    return (
      <div className='numbersData'>
        {(data || [])?.map((each, idx) => (
          <div key={idx} className='zipcodeCardStyle'>
            <div className='adminZipcodesNumber'> {each}</div>{" "}
            {each && (
              <div
                style={{ marginLeft: "20px" }}
                onClick={() => onDelete(each)}
                className='adminZipcodesCross'
              >
                X
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const ttt = () => {
    return (
      <CustomTable
        rows={!searchText.length ? serviceableData.sort(compareByZipcode) : searchedserviceableData}
        tableHeading={statezipcodetableHeadingsData}
        handleSelection={handlepropertyListSelect}
        selected={selected}
        maxHeight={"65vh"}
        width={"35vw"}
      />
    );
  };

  /*@description Displaying delete Button*/
  const displayingDeleteButton = () => {
    return (
      <Custombuton
        title={"Delete"}
        className='zipcodeDeletebutton'
        handleButtonClick={handleShowDeleteModal}
        additionalSx={{
          opacity: selected?.length === 0 ? 0.5 : 1,
        }}
      />
    );
  };

  const displayTabs = () => {
    return (<ToggleButtonGroup
      color="primary"
      value={zipcodeType}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      className="tabsZip"
    >
      <ToggleButton value="user" className={`${zipcodeType === 'user' ? "activeTabZip" : "tabZip"}`} >User</ToggleButton>
      <ToggleButton value="driver" className={`${zipcodeType === 'driver' ? "activeTabZip" : "tabZip"}`} >Driver</ToggleButton>
    </ToggleButtonGroup>
    )

  };

  function compareByZipcode(a, b) {
    if (a.zipCode < b.zipCode) {
      return -1;
    } else if (a.zipCode > b.zipCode) {
      return 1;
    } else {
      return 0;
    }
  }

  const handleSearchChange = function (event) {
    const searchTextValue = event.target.value
    setSearchText(searchTextValue)
    setSearchedserviceableData(serviceableData.filter((serviceData) => serviceData.state?.toLowerCase().includes(searchTextValue.toLowerCase()) ||
      serviceData.zipCode.toString().toLowerCase().includes(searchTextValue.toLowerCase())).sort(compareByZipcode));
  }

  const handleClearInput = () => {
    setSearchText('');
  };

  const displaySearchBar = () => {
    return (
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search zipcodes"
          value={searchText}
          onChange={handleSearchChange}
        />
       {searchText === '' ? (
                <IconButton aria-label="search">
                  <SearchIcon />
                </IconButton>
              ) : (
                <IconButton aria-label="clear" onClick={handleClearInput}>
                  <ClearIcon />
                </IconButton>
              )}
      </div>
    );
  }


  /**@description Handle Show added Zipcodes  Functionality
   */
  const displayAddNewZipcodes = () => {
    return (
      <div className='mainFormBox'>
        <div className='formBox'>
          {zipCode.map((zip, index) => (
            <div key={index} className='zipCodeBox'>
              <div className='add_zipcode_title'>Add New {zipcodeType} Zipcode</div>
              <div className='zipcode_input_filed'>
                <div className='inputBox'>
                  <CustomInput
                    dataItem={{
                      ...zip,
                      value: inputValue,
                      type: "text",
                    }}
                    label='Standard'
                    handleInput={onchange}
                    handleClick={() => {
                      handleclick();
                    }}
                    name={index}
                    value={inputValue}
                    required={false}
                    sxProps={{ width: "100%" }}
                    inputProps={{
                      maxLength: 5,
                      pattern: "/^[0-9\b]+$/",
                    }}
                    onKeyPress={addzipcodes}
                  />
                  <div className='exampleNumbers'>e.g. 00000, 22222, after adding please click enter</div>
                </div>

                {inputValue.length <= 5 ? (
                  <div className='box arrow-left'>
                    Add multiple zipcodes seprated by commas
                  </div>
                ) : (
                  ""
                )}
              </div>
              {data.length > 0 && (
                <CustomCard
                  cardData={displayZipcodeCard()}
                  sxProps={{
                    overflow: "auto",
                    boxShadow: "none",
                  }}
                />
              )}
            </div>
          ))}

          <div className='add_new_zipcode_button'>
            <CustomButton
              title={`Add New ${zipcodeType} Zipcodes`}
              handleButtonClick={onSubmit}
              additionalSx={{}}
              disabled={(data || []).length < 1}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='all zipcodeContainer'>
        <div className="selectButton">
          {displaySearchBar()}
          {displayTabs()}
        </div>

        <div className={"adminZipcodeRowContainer"}>

          <div className='textContainer'>

            <div className='tableheader_deletebutton'>
              <div className='table_header'>
                {statezipcode.length === 0
                  ? `Please Add New ${zipcodeType} Zipcodes.`
                  : `These are our current ${zipcodeType} serviceable zipcodes.`}
              </div>
              {displayingDeleteButton()}

            </div>

            {((serviceableData.length && !searchText.length)  || (searchText.length && searchedserviceableData.length)) ?ttt(): 
            <div className="childs not-found-heading">
            <h2>No zipcode found</h2>
          </div>
          }

           
          </div>
          <div className='secondContainer'>

            <CustomCard
              cardData={displayAddNewZipcodes()}
              sxProps={{
                width: "35vw",
                height: "65vh",
                margin: "3%",
                overflow: "visible",
              }}
            />
          </div>
        </div>
        {statusModal?.show ? (
          <ConfirmModal
            cancelText={statusModal.cancelText}
            confirmText={statusModal.confirmText}
            isAlert={statusModal.isAlert}
            title={statusModal.title}
            message={statusModal.message}
            handleCancel={statusModal.handleCancel}
            handleConfirm={statusModal.handleConfirm}
          />
        ) : null}
        <Loader show={showLoader} />
      </div>
    </div>
  );
};

export default AdminZipcodes;
