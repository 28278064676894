import {
  GET_ACTIVE_TAB,
  GET_MFO_NAME,
  GET_PROPERTY_NAME,
  GET_MARKETING_MATERIAL,
  GET_PROPERTY_MARTKETING_MATERIAL
} from "../actions/actionTypes";

const initialState = {
  mfoName: "",
  PropertyName: "",
  activeTabData: 1,
  marketingMaterial: [],
};

const marketingMaterialReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MFO_NAME:
      return { ...state, mfoName: action.payload };

    case GET_PROPERTY_NAME:
      return { ...state, PropertyName: action.payload };

    case GET_ACTIVE_TAB:
      return { ...state, activeTabData: action.payload };
    case GET_MARKETING_MATERIAL:
      return {
        ...state,
        marketingMaterial: action.payload
      }
    default:
      return state;
  }
};
export default marketingMaterialReducer;
