/**@description displays property view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import React, { useEffect, useRef, useState } from "react";
import "./MarketingMaterial.scss";
import Loader from "../../../components/loader/Loader";
import ConfirmModal from "../../../components/confirm-modal";
import { useDispatch } from "react-redux";
import {
  createMarketingMaterial,
  updateMarketingMaterial,
  getMarketingMaterialByCategories,
  deleteMarketingMaterial,
} from "../../../redux/actions/marketingmaterial-actions/marketingmaterialAction";
import { getAllMFO } from "../../../redux/actions/usersActions";
import { Tab, Tabs } from "@mui/material";
import { fileTabs, categories, FILE_NAME } from "../../Constants";
import { ADD_EMAIL_AND_SMS, FILE_TYPES } from "../../../components/Constants";
import SubCategoryContainer from "./SubCategoryContainer";
import MarketingMaterialForm from "./MarketingMaterialForm";

const MarketingMaterial = () => {
  const [propertyDetailsData, setPropertytDetailsData] = useState([]);
  const uploadTenants = useRef(null);
  const [showLoader, setShowLoader] = useState(false);
  const [mfoID, setmfoID] = useState("");
  const [pID, setpID] = useState("");
  const [mfoName, setmfoName] = useState("");
  const [propertyName, setpropertyName] = useState("");
  const [active, setactive] = useState("");
  const [emailAndSmsDetails, setEmailAndSmsDetails] = useState([
    ...ADD_EMAIL_AND_SMS,
  ]);
  // const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState({});
  const dispatch = useDispatch();
  const [activeTab, setActivetab] = useState("marketingMaterial");
  const [SubCategorieName, setSubCategorieName] = useState(categories[0]);
  const [subValue, setSubValue] = useState(categories[0].value);
  const [SelectedFile, setSelectedFile] = useState([...FILE_TYPES]);
  const [socialMediaData, setSocialMediaData] = useState([]);
  const [webData, setWebData] = useState([]);
  const [check, setCheck] = useState([]);
  const [webInterface, setWebInterface] = useState([]);
  const [curFileName, setCurFileName] = useState({ ...FILE_NAME });
  const [maxLimitExceed, setMaxLimitExceed] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [printedData, setPrintedData] = useState([]);
  const [data, setData] = useState([]);

  /**@description Handle MFO Tab onClick  Functionality
   */

  const handleMfoClick = async (data, mfoId, pId) => {
    setmfoID(mfoId);
    setShowLoader(true);
    setmfoName(data);
    setactive(mfoId);
    setpID(pId);
    fetchmarketingMaterial(mfoId, pId);
    setShowLoader(false);
    setActivetab("marketingMaterial");
  };

  /**@description Fetching Marketing Material  Functionality
   */
  const fetchmarketingMaterial = async (mfoId, propertyId) => {
    getMarketingMaterialByCategoriesData(
      mfoId,
      propertyId,
      activeTab,
      SubCategorieName.subCategory
    );
    clearData();
  };

  /**@description Handle Fetch Properties  Functionality
   */
  const fetchProperties = async (mfoID, pID, mfoName, pName) => {
    setShowLoader(true);
    const MFO_data = await dispatch(getAllMFO());
    let MFO_DATA = [];
    MFO_data?.filter((mfo) => {
      if (mfo?.properties?.length) {
        MFO_DATA?.push(mfo);
      }
      return MFO_DATA;
    });

    const mfoId = propertyDetailsData.length ? mfoID : MFO_DATA[0]?._id;
    const mfoname = propertyDetailsData.length ? mfoName : MFO_DATA[0]?.name;
    const propertyId =
      propertyDetailsData.length > 1 ? pID : MFO_DATA[0]?.properties[0]?._id;
    const propertyname = propertyDetailsData.length
      ? pName
      : MFO_DATA[0]?.properties[0].name;
    const requiredData = {
      category: "EMAIL_&_SMS",
      subCategory: "PRE_LAUNCH",
    };
    const { data, status } = await dispatch(
      getMarketingMaterialByCategories(mfoId, propertyId, requiredData)
    );
    if (status) {
      setData(data);
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
    setmfoName(mfoname);
    setmfoID(mfoId);
    setactive(mfoId);

    setpID(propertyId);
    setpropertyName(propertyname);
    setPropertytDetailsData([...MFO_DATA]);
    setShowLoader(false);
  };

  /**@description Handle Hide Modal  Functionality
   */
  const handleHideModal = () => {
    statusModal.show = false;
    setStatusModal({ ...statusModal });
  };

  /**@description Handle show Modal  Functionality
   */
  const handleShowModal = ({
    cancelText,
    confirmText,
    isAlert,
    title,
    message,
    handleConfirm = () => {},
  }) => {
    statusModal.show = true;
    statusModal.cancelText = cancelText || "Cancel";
    statusModal.confirmText = confirmText || "Ok";
    statusModal.isAlert = isAlert || false;
    statusModal.title = title || "";
    statusModal.message = message || "";
    statusModal.handleConfirm = handleConfirm;
    statusModal.handleCancel = handleHideModal;
    setStatusModal({ ...statusModal });
  };

  /**@description handles delete manager*/


  const handledeleteMarketingmaterial = async (data) => {
    const imageIds = [data.photos[0]._id];
    const marketingMaterialId = data._id;
    const deletem = async () => {
      setShowLoader(true);
      const { status } = await deleteMarketingMaterial(
        marketingMaterialId,
        imageIds
      );

      handleShowModal({
        cancelText: "Ok",
        title: status ? `Success` : `Failed`,
        message: status
          ? `Marketing Material deleted successfully!`
          : `Failed to delete Marketing Material, please try again.`,
        isAlert: true,
        handleConfirm: getMarketingMaterialByCategoriesData(
          mfoID, 
          pID,
          activeTab,
          SubCategorieName
        ),
      });
      if (status) {
        setShowLoader(false);
      }
    };
    handleShowModal({
      confirmText: "Delete",
      title: `Delete ${SubCategorieName.title}`,
      message: `Are you sure you want to delete the selected ${SubCategorieName.title}?`,
      handleConfirm: deletem,
    });
  };

  useEffect(() => {
    fetchProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*@description Displaying MFO Details*/

  const displayingMfoDetails = () => {
    return (
      <div className="first">
        {propertyDetailsData?.map((item) => (
          <div
            className={item._id === active ? "mfoNameActive" : "mfo"}
            key={`items${item._id}`}
          >
            <h1
              className="mfoName hideNames"
              title={item.name}
              onClick={() => {
                handleMfoClick(
                  item?.name,
                  item?._id,
                  item?.properties[0]._id,
                  item?.properties[0]._name
                );

                item._id !== active &&
                  setpropertyName(item?.properties[0].name);
              }}
            >
              {item.name}
            </h1>
            <div className="allProperty">
              {item?.properties?.map((p) => (
                <div
                  key={`prop${p._id}`}
                  className={`property ${
                    pID === p._id && `activeProperty`
                  } hideNames ${item._id === active && ``}`}
                  onClick={() => {
                    item._id === active && setpropertyName(p.name);
                    setpID(p._id);
                    setmfoID(item._id);
                    fetchmarketingMaterial(item._id, p._id);
                  }}
                  style={{
                    cursor: item._id !== active ? "not-allowed" : "default",
                    pointerEvents: item._id !== active && "none",
                  }}
                  title={p.name}
                >
                  &#8226; {p?.name}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const tabNames = () => {
    if (activeTab === "marketingMaterial") {
      return "Email & SMS";
    } else if (activeTab === "socialMedia") {
      return "Social Media";
    } else if (activeTab === "web") {
      return "Web";
    } else {
      return "Printed";
    }
  };
  /*@description Displaying Navigation Text*/
  const displayNavigation = () => {
    return propertyDetailsData?.length ? (
      <h4 className="main">
        {`${mfoName} ${` > `} ${propertyName} ${` > `} ${tabNames()} ${`>`} ${
          SubCategorieName.title
        }`}
      </h4>
    ) : null;
  };

  const currentSubCategory = async (curTab) => {
    let data = categories.findIndex((data) => data.tab === curTab);
    setSubValue(categories[data].value);
    setSubCategorieName(categories[data]);
  };

  const getMarketingMaterialByCategoriesData = async (
    mfoId,
    propertyId,
    curTab,
    subCategory
  ) => {
    setShowLoader(true);
    const requiredData = {
      category: category(curTab),
      subCategory: subCategory.subCategory,
    };

    const { data, status } = await dispatch(
      getMarketingMaterialByCategories(mfoId, propertyId, requiredData)
    );
    if (status) {
      if (curTab === "socialMedia") {
        setSocialMediaData(data);
      } else if (curTab === "web") {
        setWebData(data);
      } else if (curTab === "marketingMaterial") {
        setData(data);
      } else {
        setPrintedData(data);
      }
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
    setShowLoader(false);
  };

  const onTabChange = (val) => {
    clearData();
    setActivetab(val);
    currentSubCategory(val);
    let data = categories.findIndex((data) => data.tab === val);
    let subCategory = categories[data];
    getMarketingMaterialByCategoriesData(mfoID, pID, val, subCategory);
  };

  const displayFiletabs = () => {
    return (
      <Tabs
        value={activeTab}
        onChange={(e, val) => onTabChange(val)}
        textColor="primary"
        indicatorColor="primary"
        aria-label="primary tabs example"
      >
        {fileTabs.map((tab, index) => (
          <Tab
            value={tab.value}
            label={tab.title}
            sx={{
              fontFamily: "Poppins",
              textDecoration: "CamelCase",
              textTransform: "initial",
              fontSize: "1.125vw",
            }}
          />
        ))}
      </Tabs>
    );
  };

  const category = (val) => {
    if ((val ?? activeTab) === "marketingMaterial") {
      return "EMAIL_&_SMS";
    } else if ((val ?? activeTab) === "socialMedia") {
      return "SOCIAL_MEDIA";
    } else if ((val ?? activeTab) === "web") {
      return "WEB";
    } else {
      return "PRINTED";
    }
  };

  const clearData = () => {
    const updatedEmailAndSmsDetails = emailAndSmsDetails.map((item) => ({
      ...item,
      value: "",
    }));

    const updatedSelectedFile = SelectedFile.map((curFile) => {
      if ([activeTab].includes(curFile.tab)) {
        const currentFile = curFile;
        currentFile.value = ""
        const { file, ...rest } = currentFile;
        return rest;
      }
      return curFile;
    });
    setEmailAndSmsDetails(updatedEmailAndSmsDetails);
    setSelectedFile(updatedSelectedFile);
    setCheck([]);
    setWebInterface([]);
  };

  const onSubmitMarketingMaterials = async () => {
    const textFieldData = emailAndSmsDetails.filter((item) => item.tab === activeTab);
    const files = SelectedFile.filter((item) => item.tab === activeTab);
    const currentTabData =
      activeTab === "marketingMaterial"
        ? data
        : activeTab === "socialMedia"
        ? socialMediaData
        : activeTab === "web"
        ? webData
        : printedData;

    const formData = new FormData();
    formData.append("mfoId", mfoID);
    formData.append("propertyId", pID);
    formData.append("category", category());
    formData.append("subCategory", SubCategorieName.subCategory);

  if(!isEmpty && SubCategorieName.subCategory === "WEBSITE_BANNER")
  {
    const photoId = [];
    photoId.push(webData[0].photos[0]._id)
    formData.append("photoIds", photoId)
  }
    switch (activeTab) {
      case "marketingMaterial":
        formData.append("title", textFieldData[0].value);
        formData.append("subText", textFieldData[1].value);
        formData.append("sms", textFieldData[2].value);
        formData.append("pdf", files[1].file);
        formData.append("html", files[0].file);
        break;
      case "socialMedia":
        formData.append("text", textFieldData[0].value);
        for (let i = 0; i < check.length; i++) {
          formData.append("photos", check[i]);
        }
        break;
      case "web":
        for (let i = 0; i < webInterface.length; i++) {
          formData.append("photos", webInterface[i]);
        }
        break;
      default:
        formData.append("title", textFieldData[0].value);
        formData.append("subText", textFieldData[1].value);
        formData.append("pdf", files[0].file);
    }
    setShowLoader(true);
    const { status } =
    isEmpty 
        ? await createMarketingMaterial(formData)
        : await updateMarketingMaterial(currentTabData[0]._id,formData);
    handleShowModal({
      cancelText: "Ok",
      title: status ? `Success` : `Failed`,
      message: status
        ? `Marketing Material uploaded successfully!`
        : `Failed to upload Marketing Material, please try again.`,
      isAlert: true,
      handleConfirm: getMarketingMaterialByCategoriesData(
        mfoID,
        pID,
        activeTab,
        SubCategorieName
      ),
    });
    if (status) {
      setShowLoader(false);
      clearData();
    }
  };

  return (
    <>
      <div className="mainPanel">
        {displayingMfoDetails()}
        <div className="second">
          <div>
            <div
              className="fileTypeStyle"
              style={{
                marginLeft: "21vw",
              }}
            >
              {(propertyDetailsData || []).length >= 1 ? displayFiletabs() : ""}
            </div>
          </div>

          <div>
            {displayNavigation()}
            {(propertyDetailsData || []).length >= 1 && (
              <div
                style={{
                  display: "flex",
                  height: "60vh",
                }}
              >
                <SubCategoryContainer
                  activeTab={activeTab}
                  subValue={subValue}
                  setSubValue={setSubValue}
                  setSubCategorieName={setSubCategorieName}
                  mfoID={mfoID}
                  pID={pID}
                  clearData={clearData}
                  getMarketingMaterialByCategoriesData={
                    getMarketingMaterialByCategoriesData
                  }
                />

                <MarketingMaterialForm
                  SubCategorieName={SubCategorieName}
                  activeTab={activeTab}
                  emailAndSmsDetails={emailAndSmsDetails}
                  setEmailAndSmsDetails={setEmailAndSmsDetails}
                  onSubmitMarketingMaterials={onSubmitMarketingMaterials}
                  maxLimitExceed={maxLimitExceed}
                  setMaxLimitExceed={setMaxLimitExceed}
                  socialMediaData={socialMediaData}
                  SelectedFile={SelectedFile}
                  setSelectedFile={setSelectedFile}
                  setShowLoader={setShowLoader}
                  check={check}
                  setCheck={setCheck}
                  webInterface={webInterface}
                  setWebInterface={setWebInterface}
                  curFileName={curFileName}
                  setCurFileName={setCurFileName}
                  handledeleteMarketingmaterial={handledeleteMarketingmaterial}
                  webData={webData}
                  emailAndSmsData={emailAndSmsDetails}
                  printedData={printedData}
                  email={data}
                  isEmpty={isEmpty}
                />
              </div>
            )}
          </div>
        </div>
        {statusModal?.show ? (
          <ConfirmModal
            cancelText={statusModal.cancelText}
            confirmText={statusModal.confirmText}
            isAlert={statusModal.isAlert}
            title={statusModal.title}
            message={statusModal.message}
            handleCancel={statusModal.handleCancel}
            handleConfirm={statusModal.handleConfirm}
          />
        ) : null}
        <Loader show={showLoader} />
      </div>
    </>
  );
};

export default MarketingMaterial;
