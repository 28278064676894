import axios from "axios";
import { GET_SERVICEABLE } from "./actionTypes";
import config from "../../config";

/**@description get all Serviceable Data
 */
export const getserviceableData = () => async (dispatch) => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/service-locations`)
      .then((res) => {
        const data = res.data;
        dispatch({
          type: GET_SERVICEABLE,
          payload: data,
        });
        resolve(data);
      })
      .catch((err) => {
        console.error("getServiceable Zipcode data", err.message);
        resolve([]);
      });
  });
};

export const getDriverServiceableData = () => async (dispatch) => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/driver-service-locations`)
      .then((res) => {
        const data = res.data;
        dispatch({
          type: GET_SERVICEABLE,
          payload: data,
        });
        resolve(data);
      })
      .catch((err) => {
        console.error("getDriverServiceable Zipcode data", err.message);
        resolve([]);
      });
  });
};

/**@description create multiple Serviceable Data
 */
export const createserviceableData = (servicelocationData) => {
  return new Promise((resolve) => {
    axios
      .post(
        `${config.RestServiceURL}/service-locations/multiple`,
        servicelocationData
      )
      .then(() => {
        resolve({ status: true });
      })
      .catch((err) => {
        console.error("createServiceable Zipcode data", err.message);
        resolve({
          status: false,
          message:
            err.response?.data?.message ||
            "Failed to add user serviceable zipcode(s), please try again.",
        });
      });
  });
};

export const createDriverServiceableData = (servicelocationData) => {
  return new Promise((resolve) => {
    axios
      .post(
        `${config.RestServiceURL}/driver-service-locations/multiple`,
        servicelocationData
      )
      .then(() => {
        resolve({ status: true });
      })
      .catch((err) => {
        console.error("createServiceable Zipcode data", err.message);
        resolve({
          status: false,
          message:
            err.response?.data?.message ||
            "Failed to add driver serviceable zipcode(s), please try again.",
        });
      });
  });
};

/**@description delete multiple Serviceable Data
 */
export const deleteserviceableData = (servicelocationData) => {
  return new Promise((resolve) => {
    axios
      .delete(`${config.RestServiceURL}/service-locations/multiple`, {
        data: servicelocationData,
      })
      .then(() => {
        resolve({ status: true });
      })
      .catch((err) => {
        console.error("deleteServiceable Zipcode data", err.message);
        resolve({
          status: false,
          message:
            err.response?.data?.message ||
            "Failed to delete user serviceable zipcode(s), please try again.",
        });
      });
  });
};

export const deleteDriverServiceableData = (servicelocationData) => {
  return new Promise((resolve) => {
    axios
      .delete(`${config.RestServiceURL}/driver-service-locations/multiple`, {
        data: servicelocationData,
      })
      .then(() => {
        resolve({ status: true });
      })
      .catch((err) => {
        console.error("deleteServiceable Zipcode data", err.message);
        resolve({
          status: false,
          message:
            err.response?.data?.message ||
            "Failed to delete driver serviceable zipcode(s), please try again.",
        });
      });
  });
};


export const getZipcode = (zipcCode) => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/driver-service-locations/zip-codes/${zipcCode}`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};
