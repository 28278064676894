/**@description displays property view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import axios from "axios";
import config from "../../../config/index";
import { getTodaydate } from "../../../utils/helperMethods";
import { paymentStatus } from "../../../routes/Constants";

/**@description GET ALL ORDER DETAILS Function */
export const getAllorderDetails = () => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/orders`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        console.error("userSignup", err);
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description GET ORDERS BY ID */
export const getOrderDetails = (orderId) => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/orders/${orderId}`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        console.err("getOrders", err);
        resolve({ status: false, message: err.response?.dada?.message });
      });
  });
};

/**@description GET ALL PENDING REQUEST DETAILS Function */
export const getAllpendingRequests = () => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/appointments?type=PENDING`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        console.error("pendingRequests", err);
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description GET ALL CONFIRMED REQUEST DETAILS Function */
export const getAllconfirmedRequests = () => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/appointments?type=CONFIRMED`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        console.error("pendingRequests", err);
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description GET ALL AVAILABLE DRIVERS Function */

export const getAlldrivers = (appointmentID) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${config.RestServiceURL}/appointments/${appointmentID}/driver-slots`
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**
 * @description get available drivers and slots by appointment
 * @param {appointmentID} 
 * @returns {success: boolean, message: String, data: Object}  
 * @author Neha Pawar 
 */

export const getAvailableDriversAndSlots = (appointmentId) => {
  return new Promise((resolve) => {
    axios.get(`${config.RestServiceURL}/appointments/${appointmentId}/drivers`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data })
        }
        else {
          resolve({ status: false })
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message })
      })
  })
}

/**
 * @description Get Drivers hourly slots
 * @param {driverId} 
 * @returns {success: boolean, message: String, data: Object}  
 * @author Neha Pawar 
 */

export const getDriversHourlySlots = (driverId, appointmentId, data) => {
  return new Promise((resolve) => {
    axios.get(`${config.RestServiceURL}/appointments/${appointmentId}/drivers/${driverId}/hourly-slots`, { params: data })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data })
        }
        else {
          resolve({ resolve: false })
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message })
      })
  })
}

/**@description CONFIRM APPOINTMENT Function */

export const confirmAppointment = (appointmentID, appointmentData) => {
  if (Object.hasOwn(appointmentData.selectedSlot, "isDrafted"))
    delete appointmentData.selectedSlot.isDrafted;
  return new Promise((resolve) => {
    axios
      .put(
        `${config.RestServiceURL}/appointments/${appointmentID}/confirm`,
        appointmentData
      )
      .then((res) => {
        console.log("data", res.data);
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description UPDATE EMPTY USERBINS Function */

export const updateEmptyuserbins = (orderID, binsData) => {
  return new Promise((resolve) => {
    axios
      .put(`${config.RestServiceURL}/user-bins/orders/${orderID}`, binsData)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description UPDATE USER BINS Function */

export const updateuserbins = (appointmentID, binsData) => {
  return new Promise((resolve) => {
    axios
      .put(
        `${config.RestServiceURL}/user-bins/appointments/${appointmentID}/current-holder`,
        binsData
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description UPDATE APPOINTMENT STATUS Function */

export const updateAppointmentStatus = (appointmentID, appointmentData) => {
  return new Promise((resolve) => {
    axios
      .put(
        `${config.RestServiceURL}/appointments/${appointmentID}/status`,
        appointmentData
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};
/**@description GET ALL STORED ITEMS Function */

export const getAllStoredItems = () => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/user-bins/storage-facilities/stored-items`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description GET DRIVERS INFO API */

export const getDriversInfo = () => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/drivers?date=${getTodaydate()}`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description DELETE DRIVER API */

export const deleteDriver = (driverId) => {
  return new Promise((resolve) => {
    axios
      .delete(`${config.RestServiceURL}/drivers/${driverId}`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};
export const driverAddressDeletion = (addressId) => {
  return new Promise((resolve) => {
    axios
      .delete(`${config.RestServiceURL}/driver-addresses/${addressId}`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};


/**@description DELETE Multiple DRIVER API */

export const deleteMultipleDriver = (driverData) => {
  return new Promise((resolve) => {
    axios
      .delete(`${config.RestServiceURL}/drivers/multiple`, { data: driverData })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description CREAT DRIVER API */

export const driverCreation = (driverData) => {
  return new Promise((resolve) => {
    axios
      .post(`${config.RestServiceURL}/drivers`, driverData)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};
export const driverAddressCreation = (driverAddressData) => {
  return new Promise((resolve) => {
    axios
      .post(`${config.RestServiceURL}/driver-addresses`, driverAddressData)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

export const updateDriver = (
  driverId,
  updatedDriverData
) => {
  return new Promise((resolve) => {
    axios
      .put(
        `${config.RestServiceURL}/drivers/${driverId}`,
        updatedDriverData
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};


/**@description UPDATE APPOINTMENT STATUS Function */

export const confirmDriver = (codeData) => {
  return new Promise((resolve) => {
    axios
      .put(`${config.RestServiceURL}/drivers/confirm`, codeData)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**
 * @description create driver slots 
 * @param {Object} driverSlots
 * @returns {status: boolean, data: Object, error: Object}
 *  @author Neha Pawar
 */
export const createDriverSlots = (driverSlots) => {
  return new Promise(async resolve => {
    axios
      .post(`${config.RestServiceURL}/driver-selections`, driverSlots)
      .then(res => {
        resolve({ status: true, data: res.data });
      })
      .catch(err => {
        console.error('Error', err.response?.data);
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
}

/**@description RESEND OTP TO CONFIRM DRIVER API */

export const confirmDriverWithCode = (driverData) => {
  return new Promise((resolve) => {
    axios
      .put(
        `${config.RestServiceURL}/drivers/resend-confirmation-code`,
        driverData
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description GENERATE QR CODES */

export const generateQRcodes = (qrCodes) => {
  return new Promise((resolve) => {
    axios
      .post(`${config.RestServiceURL}/qrcodes`, qrCodes)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description GET ALL STORAGE FACILITIES Function */

export const getAllStorageFacilities = () => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/storage-facilities`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description DELETE ALL STORAGE FACILITIES Function */

export const deleteMultipleFacilities = (facilitiesData) => {
  return new Promise((resolve) => {
    axios
      .delete(`${config.RestServiceURL}/storage-facilities/multiple`, {
        data: facilitiesData,
      })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description Add STORAGE FACILITIES Function */

export const addStorageFacility = (data) => {
  return new Promise((resolve) => {
    const facilityData = {
      pricePerCuft: 0,
      ...data,
    };
    axios
      .post(`${config.RestServiceURL}/storage-facilities`, facilityData)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description Update STORAGE FACILITIES Details */

export const updateStorageFacilityDetails = (
  facilityId,
  updatedfacilityData
) => {
  return new Promise((resolve) => {
    axios
      .put(
        `${config.RestServiceURL}/storage-facilities/${facilityId}`,
        updatedfacilityData
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description Add Rooms FACILITIES Function */
export const addRooms = (roomsData, facilityId) => {
  return new Promise((resolve) => {
    axios
      .post(
        `${config.RestServiceURL}/storage-facilities/${facilityId}/rooms`,
        roomsData
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description GET GOOGLE API KEYFunction */

export const getAPIKey = () => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/api-keys`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description GET Facility Rooms Function */
export const getFacilityRooms = (facilityId) => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/storage-facilities/${facilityId}/rooms`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description Get storage facilities by roomId */
export const getRoomSpace = (storageFacilityId, roomId) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${config.RestServiceURL}/storage-spaces/storage-facilities/${storageFacilityId}/room/${roomId}`
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description Create Storage facility room walkways */

export const createWalkway = (storageFacilityId, roomId, requestBody) => {
  return new Promise((resolve) => {
    axios
      .post(
        `${config.RestServiceURL}/storage-facilities/${storageFacilityId}/rooms/${roomId}/walkway`,
        requestBody
      )
      .then((res) => {
        resolve({ status: true, data: res.data });
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description Delete a Walkway */

export const deleteWalkway = (storageFacilityId, roomId, requestBody) => {
  return new Promise((resolve) => {
    axios
      .delete(
        `${config.RestServiceURL}/storage-facilities/${storageFacilityId}/rooms/${roomId}/walkway`,
        {
          data: requestBody,
        }
      )
      .then((res) => {
        resolve({ status: true, data: res.data });
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description DELETE ROOM  FACILITIES Function */

export const deleteRoom = (facilityId, roomsData) => {
  return new Promise((resolve) => {
    axios
      .delete(
        `${config.RestServiceURL}/storage-facilities/${facilityId}/rooms/multiple`,
        {
          data: roomsData,
        }
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

// Function to un Assign Driver
export const unAssigndriver = (appointmentID) => {
  return new Promise((resolve) => {
    axios
      .put(
        `${config.RestServiceURL}/appointments/${appointmentID}/unassign-driver`
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};
export const getAppointmentDetails = (appointmentID) => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/appointments/${appointmentID}`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**
 * @description fetch driver Payment options
 * @param {void}
 * @returns {success: boolean, message: String, data: Object}
 * @author Neha Pawar
 */

export const getPaymentOptions = () => {
  return new Promise((resolve) => {
    axios.get(`${config.RestServiceURL}/drivers/payment-options`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, PaymentOptions: res.data })
        }
        else {
          resolve({ status: false })
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message })
      })
  })
}

/**
 * @description verifying driver payment details
 * @param {string} driverId
 * @returns {success: boolean, data: Object}
 * @author Neha Pawar
 */

export const verifyPaymentOption = (driverId) => {
  return new Promise((resolve) => {
    axios.put(`${config.RestServiceURL}/drivers/${driverId}/verify-payment-option`, { isVerified: true })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data })
        }
        else {
          resolve({ status: false })
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message })
      })
  })
}

/**
 * @description update driver payment status
 * @param {string} driverId
 * @returns {success: boolean,message: String data: Object}
 * @author Neha Pawar
 */

export const updateDriverPaymentStatus = (driverId, transactionId) => {
  return new Promise((resolve) => {
    axios.put(`${config.RestServiceURL}/drivers/${driverId}/payments/status`, {
      paymentStatus: paymentStatus.paid,
      transactionId
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data })
        }
        else {
          resolve({ status: false })
        }
      })
      .catch((err) => {
        resolve({ resolve: false, message: err.response?.data?.message })
      })
  })
}


export const getAllRefundOrders = () => {
  return new Promise((resolve) => {
    axios.get(`${config.RestServiceURL}/orders/refund-initiated-orders`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data })
        }
        else {
          resolve({ status: false })
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message })
      })
  })
}


export const updateOrderStatus = (orderId) => {
  return new Promise((resolve) => {
    axios.put(`${config.RestServiceURL}/orders/${orderId}/update-refund-status`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data })
        }
        else {
          resolve({ status: false })
        }
      })
      .catch((err) => {
        resolve({ resolve: false, message: err.response?.data?.message })
      })
  })
}