import React from "react";
import { STORED_ITEMS_BOX_STYLE } from "../../../components/Constants";
import { STOREDITEMS_MODAL_DATA } from "../../Constants";
import CustomModal from "../../../components/custom-modal/CustomModal";

const storedItemsModal = (props) => {
  const { open, modalData, setShowModal } = props;

  const displayModalData = () => {
    return STOREDITEMS_MODAL_DATA.map((data) => (
      <div className="modalDataStyle">
        <div className="itemNameStyle"> {data.Name}:</div>
        <div
          className="itemValueStyle"
          style={{
            width: "32vw",
            maxHeight: "18vh",
            overflowY: "scroll",
            wordBreak: "break-all",
          }}
        >
          {modalData[data.key]}
        </div>
      </div>
    ));
  };
  return (
    <CustomModal
      isOpen={open}
      sx={STORED_ITEMS_BOX_STYLE}
      handleRemoveIcon={() => setShowModal(false)}
      content={displayModalData}
      closeButtonStyle="itemsModalRemoveIcon"
    />
  );
};
export default storedItemsModal;
