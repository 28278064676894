import {
  GET_TENANT,
  GET_TENANTS,
  OVERVIEW,
  OVERALL_OVERVIEW,
} from "../actions/actionTypes";

const initialState = {
  tenants: [],
  tenant: {},
  overview: {},
};
const tenantsListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TENANTS:
      return {
        ...state,
        tenants: payload,
      };
    case GET_TENANT:
      return {
        ...state,
        tenant: state.tenants.find((tenantItem) => tenantItem._id === payload),
      };
    case OVERVIEW:
      return {
        ...state,
        overview: payload,
      };
    case OVERALL_OVERVIEW:
      return {
        ...state,
        overallOverview: payload,
      };
    default:
      return state;
  }
};
export default tenantsListReducer
