import axios from "axios";
import config from "../../config";

/**@description UPDATE APP VERSION'S*/

export const updateAppversions = (appName, appVersion) => {
  return new Promise((resolve) => {
    axios
      .post(`${config.RestServiceURL}/apps/${appName}/version`, appVersion)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description GET LATEST USER APP VERSION'S*/

export const getUserAppversion = () => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/apps/USER/version/0.0.0`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description GET LATEST DRIVER APP VERSION'S*/
export const getDriverAppversion = () => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/apps/DRIVER/version/0.0.0`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};
