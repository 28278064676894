import React from "react";
import CustomButton from "../custom-button";
import CustomCard from "../custom-card";
import "./TenantListEmpty.scss";

const TenantListEmpty = (props) => {
  const displayEmptyComponent = () => {
    return (
      <div className="emptyTenantsListContainer">
        <div className="emptyTenantsList">{props.header}</div>
        {props.canAdd ? (
          <CustomButton
            startIcon={
              <img
                src="/images/shared/plus.svg"
                alt="addIcon"
                className={"addIcon"}
              />
            }
            handleButtonClick={props.handleButtonClick}
            title={props.buttonText}
            additionalSx={{
              "& .MuiButton-startIcon": {
                paddingRight: "2rem",
              },
              fontSize: "1vw",
              padding: "0.5rem 6.5rem 0.5rem 1.5rem",
            }}
          />
        ) : null}
      </div>
    );
  };

  return (
    <CustomCard
      cardData={displayEmptyComponent()}
      sxProps={{ width: props.width, heigt: props.height }}
    />
  );
};

TenantListEmpty.defaultProps = {
  width: "100%",
  height: "100%",
  header: "You have no tenants added to your list",
  buttonText: "Add New Tenants",
  canAdd: true,
  handleButtonClick: () => {},
};

export default TenantListEmpty;
