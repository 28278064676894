import React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TableSortLabel,
} from "@mui/material";
import _ from "lodash";
import {
  convertToDateFormat,
  getFormattedPhoneNum,
} from "../../utils/helperMethods";
import "./CustomTable.scss";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: "1.25vw",
          height: "1.25vw",
        },
      },
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e1e5ea",
    color: theme.palette.common.black,
    fontWeight: 500,
    fontSize: "1vw",
    padding: "1vw 0vw",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "1vw",
    color: "rgba(84, 100, 122, 1)",
    paddingLeft: "1.75vw",
  },
  [`&.${tableCellClasses.root}`]: {
    paddingLeft: "1.75vw",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.common.white,
  },

  "&:last-child td, &:last-child th": {
    borderBottom: 10,
  },
}));

const CustomTable = (props) => {
  const { tableHeading, rows, handleSelection, selected, hideCheckbox } = props;

  /**@description checkbox onClick Functionality For particular row data*/
  const handleClick = (e, email) => {
    e.preventDefault();
    const selectedIndex = selected.indexOf(email);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    handleSelection(newSelected);
  };

  /**@description checkbox onClick Functionality For All rows data*/

  const handleAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = (rows || []).map((n) => n._id);
      handleSelection(newSelecteds);
      return;
    } else {
      handleSelection([]);
    }
  };

  const isSelected = (email) => (selected || []).indexOf(email) !== -1;

  /**@description displaying Table Head Data*/
  const displayingTableHeadings = () => {
    return (
      <ThemeProvider theme={theme}>
        <TableHead>
          <TableRow className="tableHead">
            {hideCheckbox ? null : (
              <StyledTableCell>
                <Checkbox
                  onClick={(event) => handleAllClick(event)}
                  checked={
                    rows?.length > 0 && selected?.length === rows?.length
                  }
                />
              </StyledTableCell>
            )}
            {tableHeading.map((row) => (
              <StyledTableCell
                align="left"
                key={`displayingTableHeadings${row.Name}`}
                style={row.style}
              >
                {row.key === props.sortcell ? (
                  <TableSortLabel
                    active={props.orderBy === row._id}
                    direction={props.orderBy === row._id ? props.order : "asc"}
                    onClick={props.createSortHandler(row._id)}
                  >
                    {row.Name}
                  </TableSortLabel>
                ) : (
                  row.Name
                )}
                {row.key === props.datePicker && (
                  <img
                    src="/images/shared/calendar.png"
                    alt="addIcon"
                    className={"addcalendarIcon"}
                    onClick={props.handleDatePicker()}
                  />
                )}
              </StyledTableCell>
            ))}
            {(props.action || []).map((action) => {
              return action.add || action.propertyCell ? (
                <StyledTableCell>
                  {action.propertyCell ? "Properties" : action.header || ""}
                </StyledTableCell>
              ) : null;
            })}
          </TableRow>
        </TableHead>
      </ThemeProvider>
    );
  };

  const getFormattedValue = (formatType, value) => {
    switch (formatType) {
      case "date":
        return convertToDateFormat(value);
      case "phone":
        return getFormattedPhoneNum(value);
      default:
        return value;
    }
  };

  /**@description displaying Table Body Data*/
  const displayingTableBody = () => {
    return (
      <TableBody>
        {(rows || []).map((row, index) => {
          const isItemSelected = isSelected(row._id);
          return (
            <StyledTableRow
              key={`displayingTableBody${row._id}${index}`}
              sx={{ paddingBottom: "px" }}
              tabIndex={-1}
              onClick={() => props.handleClick(row._id)}
            >
              {hideCheckbox ? null : (
                <StyledTableCell sx={{ borderBottom: "none" }}>
                  <Checkbox
                    checked={isItemSelected}
                    onClick={(event) => handleClick(event, row._id)}
                  />
                </StyledTableCell>
              )}

              {tableHeading.map((header, ind) => {
                const ispropertyMgrNo = header.key === "propertyMgrNo";
                const value = _.get(row, header.key);
                return (
                  <StyledTableCell
                    align="left"
                    sx={{ borderBottom: "none" }}
                    className={[ispropertyMgrNo ? "tablecell" : ""]}
                    key={ind}
                  >
                    {value && !Array.isArray(value) ? (
                      `${
                        header.format
                          ? getFormattedValue(header.formatType, value)
                          : value
                      }`
                    ) : value && Array.isArray(value) ? (
                      value.map((item, i) => <div key={i}>{item}</div>)
                    ) : header.key === "details" ? (
                      <p
                        style={{
                          padding: "0.3vw 0.3vw",
                          fontSize: "1vw",
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "#198eba",
                        }}
                        onClick={() => {
                            props.onClickViewDetails(row._id);                       
                        }}
                      >
                        View
                      </p>
                    ) : header.key === "couponCodeStatus" ? (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row.isActive}
                              onChange={() => props.onClickHandler(row._id)}
                            />
                          }
                          label={
                            <p style={{ width: "2rem" }}>
                              {row.isActive ? "Active" : "Inactive"}
                            </p>
                          }
                        />
                      </FormGroup>
                    ) : (
                      "-"
                    )}
                  </StyledTableCell>
                );
              })}
              {(props.action || []).map((action) => {
                if (action?.add || action?.propertyCell) {
                  return (
                    <StyledTableCell align="left" sx={{ borderBottom: "none" }}>
                      <div
                        onClick={() => {
                          if (action.id) {
                            action.handleClick(row[action.id]);
                          } else {
                            action.handleClick(index);
                          }
                        }}
                        className={action.className}
                      >
                        {action.text}
                      </div>
                    </StyledTableCell>
                  );
                }
                return null;
              })}
            </StyledTableRow>
          );
        })}
      </TableBody>
    );
  };
  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: props.maxHeight,
          width: props.width,
          paddingBottom: props.tablecellpadding,
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          {displayingTableHeadings()}
          {displayingTableBody()}
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

CustomTable.defaultProps = {
  handleClick: () => {},
  handleAllClick: () => {},
};

export default CustomTable;
