import React, { useState, useEffect, useRef } from "react";

import { fileTabs, categories } from "../../Constants";
import { Tab, Tabs } from "@mui/material";
import { getMarketingMaterailById } from "../../../redux/actions/marketingmaterial-actions/marketingmaterialAction";
import { Link } from "react-router-dom";

import "./MarketingMaterials.scss";
import CustomModal from "../../../components/custom-modal/CustomModal";
import { SMS_POPUP } from "../../../components/Constants";
import PDFViewer from "./PDFViewer";

const MarketingMaterials = ({ properties }) => {
  const [activeTab, setActiveTab] = useState("marketingMaterial");

  const [marketingMaterial, setMarketingMaterial] = useState([]);

  const [expand, setExpand] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [smsData, setSmsData] = useState(null);

  const [selectedProperty, setSelectedProperty] = useState(null);

  const onTabChange = (value) => {
    setActiveTab(value);
  };

  useEffect(async () => {
    const firstPropertyId = properties[0]?._id;
    const data = await getMarketingMaterailById(firstPropertyId);
    setMarketingMaterial(data?.data);
    setSelectedProperty(firstPropertyId);
  }, []);

  const getPropertyId = async (id) => {
    const data = await getMarketingMaterailById(id);
    setMarketingMaterial(data?.data);
    setSelectedProperty(id);
  };

  const { emailAndSms, printed, socialMedia, web } =
    marketingMaterial?.reduce(
      (result, data) => {
        switch (data?.category) {
          case "EMAIL_&_SMS":
            result.emailAndSms.push(data);
            break;
          case "WEB":
            result.web.push(data);
            break;
          case "SOCIAL_MEDIA":
            result.socialMedia.push(data);
            break;
          case "PRINTED":
            result.printed.push(data);
            break;
          default:
            break;
        }
        return result;
      },
      { emailAndSms: [], web: [], socialMedia: [], printed: [] }
    ) || {};

  const handleCustomeModal = (data) => {
    setIsOpen(!isOpen);
    setSmsData(data);
  };

  const splitTheWord = (word) => {
    return word.split("_").join(" ");
  };

  const contentDisplay = (smsData) => {
    return <div className="popup">{smsData}</div>;
  };

  const emptyComponent = () => {
    return (
      <h2 className="emptyComponentText">
        Currently, there is no content available in this section. Please check
        back later.
      </h2>
    );
  };

  const emailAndSmsData = emailAndSms?.map((data) => {
    return (
      <div className="email-container">
        <h2>{splitTheWord(data?.subCategory)}</h2>
        <h4>{data?.title}</h4>
        <p>{data?.subText}</p>
        <div className="btn-container">
          {data?.htmlUrl?.url && (
            <button
              className="btn"
              onClick={() => {
                if (data?.htmlUrl?.url) {
                  window.open(data.htmlUrl?.url);
                } else {
                  alert("No Html file");
                }
              }}
            >
              HTML
            </button>
          )}

          {data?.pdfUrl?.url && (
            <button
              className="btn"
              onClick={() => {
                if (data?.pdfUrl?.url) {
                  window.open(data.pdfUrl?.url);
                } else {
                  alert("No Pdf file");
                }
              }}
            >
              PDF
            </button>
          )}

          {data?.sms && (
            <button
              onClick={() => handleCustomeModal(data?.sms)}
              className="btn"
            >
              SMS
            </button>
          )}
        </div>
        <hr />
      </div>
    );
  });

  const webData =
    web?.length > 0
      ? web?.map((data, index) => {
          if (data?.subCategory === "WEBSITE_BANNER") {
            return (
              <>
                <h2>Website Banner</h2>
                <div className="webBannerContainer" key={index}>
                  <div className="webBannerChilds">
                    {data?.photoUrls?.map((pic) => {
                      return (
                        <div
                          onClick={() => {
                            window.open(pic?.url);
                          }}
                        >
                          <img
                            className="web-banner-image"
                            src={pic?.url}
                            alt="image"
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="web-side-content webBannerChilds">
                    <p>
                      Suitable for display on your resident portal,
                      incorporation into printed materials like event flyers and
                      monthly calendars, and inclusion in emails sent to
                      residents.
                    </p>
                    {data?.photoUrls?.map((pic) => {
                      return (
                        <div
                          onClick={() => {
                            window.open(pic?.url);
                          }}
                        >
                          <button className="btn">Download</button>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <hr />
              </>
            );
          }

          return (
            <div>
              <h2>Amneties Modules</h2>
              <p>
                Incorporate these images into the Amenities section where
                applicable
              </p>
              <div className="website-banner">
                {data?.photoUrls?.map((pic) => {
                  return (
                    <div className="all-image-container">
                      <img
                        onClick={() => {
                          window.open(pic?.url);
                        }}
                        className="social-media-image"
                        src={pic?.url}
                        alt="image"
                      />
                      <div className="clickToDownload">Click to download</div>
                    </div>
                  );
                })}
              </div>
              <hr />
            </div>
          );
        })
      : emptyComponent();

  const socialMediaData =
    socialMedia?.length > 0
      ? socialMedia?.map((data, index) => {
          return (
            <>
              {data?.photoUrls?.length > 0 && (
                <>
                  <div key={index}>
                    <h2>{splitTheWord(data.subCategory)}</h2>
                    <div className="image-contianers">
                      {data?.photoUrls.map((pic, index) => {
                        return (
                          <div className="image-box" key={index}>
                            <div>
                              <div
                                className="all-image-container"
                                onClick={() => {
                                  if (pic?.url) {
                                    window.open(pic.url);
                                  } else {
                                    alert("No Image found");
                                  }
                                }}
                              >
                                <img
                                  className="social-media-image"
                                  src={pic?.url}
                                  alt="image"
                                />
                                <div className="clickToDownload">
                                  Click to download
                                </div>
                              </div>
                              <p>{data.text}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <hr />
                </>
              )}
            </>
          );
        })
      : emptyComponent();

  const returnPrintedDataAsPerCategory = (data) => {
    return (
      <>
        <h2>{splitTheWord(data.subCategory)}</h2>
        <div className="printedDataContainer">
          <div className="printedDataContainerChilds">
            <PDFViewer pdfUrl={data?.pdfUrl?.url} />
          </div>
          <div className="printedDataContainerChilds">
            <h3 className="printedParaContent">{data.title}</h3>
            <p className="printedParaContent">{data.subText}</p>
            <button
              onClick={() => {
                window.open(data?.pdfUrl?.url);
              }}
              className="btn"
            >
              Download
            </button>
          </div>
        </div>
        <hr />
      </>
    );
  };

  const printedData = printed?.map((data) => {
    if (data.subCategory === "TABLE_TOP_SIGN") {
      return returnPrintedDataAsPerCategory(data);
    }

    if (data.subCategory === "COMMUNITY_SIGN") {
      return returnPrintedDataAsPerCategory(data);
    }

    if (data.subCategory === "MOVE_IN_FLIER") {
      return returnPrintedDataAsPerCategory(data);
    }
  });

  return (
    <>
      <div className="container">
        <div className="propertiesList childs">
          <h1>Properties</h1> <hr />
          {properties?.map((property) => {
            const isSelected = property._id === selectedProperty;
            return (
              <>
                <div
                  className={`${isSelected ? "applyColor" : ""}`}
                  key={property._id}
                  onClick={() => getPropertyId(property._id)}
                >
                  <h4>{property.name}</h4>
                </div>
                <hr />
              </>
            );
          })}
        </div>

        {marketingMaterial?.length ? (
          <div className="childs">
            <div className="container-tabs">
              <Tabs
                value={activeTab}
                onChange={(e, val) => onTabChange(val)}
                textColor="primary"
                indicatorColor="primary"
                aria-label="primary tabs example"
              >
                {fileTabs.map((tab, index) => (
                  <Tab
                    value={tab.value}
                    label={tab.title}
                    key={tab.value}
                    sx={{
                      fontFamily: "Poppins",
                      textDecoration: "CamelCase",
                      textTransform: "initial",
                      fontSize: "1.125vw",
                    }}
                  />
                ))}
              </Tabs>
            </div>
            <div className="data-container">
              {activeTab === "marketingMaterial" &&
              emailAndSmsData?.length > 0 ? (
                <>
                  <div className="description">
                    <p>
                      Here are the download links for both HTML and PDF versions
                      of the pre-prepared emails for your residents. If you're
                      unable to utilize HTML format, use the PDF version instead
                    </p>
                    <div className="instructions-container">
                      <div
                        onClick={() => setExpand(!expand)}
                        className="description-instructions"
                      >
                        <p>
                          Instructions for any HTML email program (e.g. Outlook,
                          Gmail, etc.)
                        </p>
                        <h2>{expand ? "-" : "+"} </h2>
                      </div>
                      <div
                        className={`expandable-container ${
                          expand ? "open" : "close"
                        }`}
                      >
                        {expand && (
                          <ol>
                            <li>
                              Click the HTML code button that appears below.
                            </li>
                            <li>
                              From there, click the three vertical dots in the
                              upper right-hand corner, and select "Download".
                            </li>
                            <li>
                              Open the .txt file from your downloads.
                              RIGHT-CLICK / SELECT ALL / COPY.{" "}
                            </li>
                            <li>
                              Open a new email template in the email program.
                            </li>
                            <li>
                              Click the source button at the top right corner
                              and paste the code into the body of the email.
                            </li>
                            <li>
                              Click preview and the email should populate!.
                            </li>
                          </ol>
                        )}
                      </div>
                      <p className="below-para-description">
                        Would you rather reach out to your residents through
                        text/SMS? Below, you'll also find messages prepared
                        specifically for SMS communication.
                      </p>
                    </div>
                  </div>{" "}
                  <hr />
                  {emailAndSmsData}
                </>
              ) : !emailAndSmsData?.length &&
                activeTab === "marketingMaterial" ? (
                emptyComponent()
              ) : null}
              {activeTab === "web" && webData}
              {activeTab === "socialMedia" && socialMediaData}
              {activeTab === "printed" &&
                (printed?.length > 0 ? (
                  <div>
                    <p>
                      Incorporation into printed materials like event flyers and
                      monthly calendars, and inclusion in emails sent to
                      residents.
                    </p>
                    {printedData}
                  </div>
                ) : (
                  emptyComponent()
                ))}
            </div>
          </div>
        ) : (
          <div className="childs not-found-heading">
            <h2>No marketing materials found</h2>
          </div>
        )}
      </div>
      <div className="popup">
        {isOpen && (
          <CustomModal
            isOpen={isOpen}
            sx={SMS_POPUP}
            content={() => contentDisplay(smsData)}
            closeButtonStyle="removeIconStyles"
            handleRemoveIcon={() => setIsOpen(false)}
          />
        )}
      </div>
    </>
  );
};

export default MarketingMaterials;
