import { Backdrop, CircularProgress } from "@mui/material";

const Loader = (props) => {
  return (
    <Backdrop
      sx={{
        color: props.color,
        zIndex: (theme) => theme.zIndex.tooltip + 9999,
      }}
      open={props.show}
      onClick={props.handleClose}
    >
      <CircularProgress
        color="inherit"
        style={{ width: "3vw", height: "2vw" }}
      />
    </Backdrop>
  );
};

Loader.defaultProps = {
  color: "#189EBA",
  show: true,
  handleClose: () => {},
};

export default Loader;
