/**@description displays property view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import React, { useState, useEffect } from "react";
import CustomuserInput from "../../components/custom-input";
import Custombuton from "../../components/custom-button";
import { Link, useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";

import "./Login.scss";
import { LOGIN_DETAILS, ROLE_NAME } from "../Constants";
import { UserLogin } from "../../redux/actions/user-actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { Roles } from "../../config";
import {
  setCommonHeaders,
  handleLogin as loginUser,
} from "../../utils/authentication";
import "./Login.scss";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          width: "24%",
        },
      },
    },
  },
});

const LoginScreen = (props) => {
  const [loginInfo, setloginInfo] = useState([...LOGIN_DETAILS]);
  const [btndisable, setbtndisable] = useState(false);
  const roleName = { ...ROLE_NAME };
  const history = useHistory();
  const dispatch = useDispatch();

  const roleData = Roles[props.role];

  const login = useSelector((state) => state.login);
  const { errorMessage } = login;

  useEffect(() => {
    if (!Roles[props.role]) {
      history.replace("/login/mfo");
    }
  }, [history, props.role]);

  /**@description Handles onchange user details*/
  const onchange = ({ key, value }) => {
    const index = loginInfo.findIndex((x) => x.key === key);

    if (index > -1) {
      loginInfo[index].value = value;
      loginInfo[index].hasError = false;
      setloginInfo([...loginInfo]);
    }
    //setbtndisable(false);
  };
  const handleLogin = (data) => {
    if (data?.Session) {
      const { IdToken } = data?.AuthenticationResult || {};
      history.push(`/resetpassword/${Roles[props.role]}`);
    } else {
      loginUser(data?.AuthenticationResult || {});
      setloginInfo([...loginInfo]);
      history.push("/app");
    }
  };
  /**@description  Handles  Login Detailsr*/
  const onSubmitHandler = async (e) => {
    e.preventDefault();

    loginInfo.forEach((user, index) => {
      if (user.value === "") {
        loginInfo[index].errorMessage = `Please Enter ${user.title} `;
        loginInfo[index].hasError = true;
      } else {
        if (
          user.type === "email" ||
          (user.value?.length >= user.minLength &&
            user.value?.length <= user.maxLength)
        ) {
          loginInfo[index].hasError = false;
        } else {
          loginInfo[
            index
          ].errorMessage = ` ${user.title} should contain atleast ${user.minLength} characters`;
          loginInfo[index].hasError = true;
        }
      }
    });
    setloginInfo([...loginInfo]);
    const userDetailsData = {};
    loginInfo.forEach((data) => {
      userDetailsData[data.key] = data.value;
    });
    const { status, data } = await dispatch(
      UserLogin(userDetailsData, roleData, history, Roles[props.role]),

      setbtndisable(true)
    );
    if (status) handleLogin(data);

    setbtndisable(false);
  };

  /**@description Display Login Details*/

  const dispalyLogin = () => {
    return (
      <form onSubmit={onSubmitHandler} className="formdiv">
        {loginInfo.map((user, index) => (
          <ThemeProvider theme={theme} key={`loginForm${user.title}`}>
            <CustomuserInput
              dataItem={user}
              className="LogininputStyle"
              handleInput={onchange}
              name={index}
              required={true}
              value={user.value}
              key={`signupForm${user.title}`}
              autoComplete={'on'}
            />
          </ThemeProvider>
        ))}

        <ThemeProvider theme={theme}>
          <Custombuton
            title={"Login"}
            className="button"
            disabled={btndisable}
          />
          <Link
            to={`/forgotpassword/${Roles[props.role]}`}
            className="linkStyle"
          >
            Forgot Password?
          </Link>
        </ThemeProvider>
      </form>
    );
  };

  return (
    <div className="mainLoginSection">
      <img
        src="/images/shared/fullcolor.png"
        alt="valeticon"
        className="loginvaletIcon"
      />
      <div className="loginheading">{roleName[props.role]} Dashboard</div>
      <div className="alerts">
        {errorMessage && (
          <ThemeProvider theme={theme}>
            <Alert severity="error" sx={{ position: "absolute", top: "-14px"}}>
              {errorMessage}
            </Alert>
          </ThemeProvider>
        )}
        {dispalyLogin()}
      </div>
    </div>
  );
};

export default LoginScreen;
