import React, { useState } from "react";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CustomButton from "../../components/custom-button/CustomButton";
import CustomuserInput from "../../components/custom-input";
import { FORGOTPASSWORD_DETAILS, RESETPASSWORD } from "../Constants";
import {
  forgotPassword,
  confirmPassword,
  ResendTemporaryPassword,
} from "../../redux/actions/user-actions/userAction";
import "./ForgotPassword.scss";
import { Alert } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import ConfirmModal from "../../components/confirm-modal";
import { checkIsEmail } from "../../utils/helperMethods";
import Loader from "../../components/loader/Loader";

const theme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          width: "24%",
        },
      },
    },
  },
});

function ForgotPassword() {
  const [passwordInfo, setPasswordInfo] = useState({
    ...FORGOTPASSWORD_DETAILS,
  });
  const [resetPasswordDetail, setResetPasswordDetail] = useState({
    ...RESETPASSWORD,
  });
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [sendOTP, setSendOTP] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const onEmailChange = ({ key, value }) => {
    const item = passwordInfo[key];
    item.value = value;
    setErrorMessage(null);
    setPasswordInfo({ ...passwordInfo });
  };

  const handleTextChange = ({ key, value }) => {
    resetPasswordDetail[key].value = value;
    resetPasswordDetail[key].hasError = false;
    setErrorMessage(null);
    setResetPasswordDetail({ ...resetPasswordDetail });
  };

  const handleConfirm = async () => {
    const email = passwordInfo.email.value;
    setIsLoading(true);
    const { status, message } = await ResendTemporaryPassword(email);
    setIsLoading(false);
    if (status) {
      history.replace("/app");
    } else {
      setErrorMessage(message);
    }
  };

  const displayAlert = () => {
    return (
      <ConfirmModal
        title='Resend Password'
        handleConfirm={handleConfirm}
        confirmText='Resend Password'
        cancelText={""}
        message={
          "Temporary password will be sent to your email, Please login and reset your password."
        }
      />
    );
  };
  const onSendOTP = async () => {
    const email = passwordInfo.email.value;
    if (checkIsEmail(email)) {
      setIsLoading(true);
      const { status, message, userStatus } = await forgotPassword(email);
      setIsLoading(false);
      if (status) {
        setShowResetPassword(true);
        Object.values(resetPasswordDetail).forEach((x) => {
          x.value = "";
        });
        setResetPasswordDetail({ ...resetPasswordDetail });
      } else if (userStatus === "FORCE_CHANGE_PASSWORD") {
        setShowModal(true);
      } else {
        setErrorMessage(message);
        setShowResetPassword(false);
      }
    } else {
      setErrorMessage("Invalid Email Address");
    }
  };

  const onConfirmPassword = async () => {
    const passwordDetails = {
      confirmationCode: resetPasswordDetail.code.value,
      password: resetPasswordDetail.password.value,
      username: passwordInfo.email.value,
    };
    const confirmPassword1 = resetPasswordDetail.confirmpassword.value;
    if (passwordDetails.password.length >= 8 || confirmPassword1.length >= 8) {
      if (passwordDetails.password === confirmPassword1) {
        setIsLoading(true);
        const { status, message } = await confirmPassword(passwordDetails);
        setIsLoading(false);
        if (status) {
          setPasswordUpdated(true);
        } else {
          setErrorMessage(message);
        }
      } else {
        setErrorMessage("Password and Confirm Password should be same");
      }
    } else {
      setErrorMessage("Password must contain at least 8 characters");
    }
  };

  const handleGoToHome = () => {
    Object.values(resetPasswordDetail).forEach((x) => {
      x.value = "";
    });
    passwordInfo.email.value = "";
    setResetPasswordDetail({ ...resetPasswordDetail });
    setPasswordInfo({ ...passwordInfo });
    history.replace("/app");
  };

  /**@description Display Alert Modal */
  const displaySuccessAlert = () => {
    return (
      <ConfirmModal
        cancelText='Go to Home'
        handleCancel={handleGoToHome}
        isAlert={true}
        message={"Password updated successfully"}
        title={"Success"}
      />
    );
  };

  const displayEmail = () => {
    const disable =
      passwordInfo.email?.value?.length === 0 ||
      passwordInfo.email?.value === undefined;
    return (
      <div className='formdiv1'>
        {Object.values(passwordInfo).map((user, index) => (
          <ThemeProvider theme={theme} key={`loginForm${user.title}`}>
            <CustomuserInput
              dataItem={user}
              className='LogininputStyle'
              handleInput={onEmailChange}
              name={index}
              required={true}
              value={user.value}
              key={`forgotPassword${user.title}`}
            />
          </ThemeProvider>
        ))}

        <ThemeProvider theme={theme}>
          <CustomButton
            title={"Send Verification Code"}
            className='button'
            handleButtonClick={onSendOTP}
            disabled={disable}
          />
        </ThemeProvider>
        <Link to={`/app`} className='linkStyle'>
          Login
        </Link>
      </div>
    );
  };

  const resendOTP = () => {
    setSendOTP(true);
    onSendOTP();
  };

  const displayResetPasswordInputs = () => {
    const buttonTitle = !showResetPassword ? "Send OTP" : "Reset Password";
    return (
      <div className='formdiv'>
        <div className='emailText'>
          We have sent a verification code to
          <div className='emailContainer'>
            <div className='emailField1'>{passwordInfo.email.value || ""} </div>
            <img
              src={`/images/shared/edit.svg`}
              alt=''
              className='editIcon'
              onClick={() => {
                setErrorMessage(null);
                setShowResetPassword(false);
              }}
            />
          </div>
        </div>
        {["code", "password", "confirmpassword"].map((key, index) => {
          const user = resetPasswordDetail[key];
          return (
            <ThemeProvider theme={theme} key={`loginForm${user.title}`}>
              <CustomuserInput
                dataItem={user}
                className='LogininputStyle'
                handleInput={handleTextChange}
                name={index}
                required={true}
                value={user.value}
                key={`forgotPassword${user.title}`}
              />
            </ThemeProvider>
          );
        })}

        <ThemeProvider theme={theme}>
          <CustomButton
            title={buttonTitle}
            className='button'
            handleButtonClick={onConfirmPassword}
            disabled={resetPasswordDetail.code?.value?.length === 0}
          />
          <Link onClick={() => resendOTP()} className='linkStyle'>
            Resend Verification Code
          </Link>
        </ThemeProvider>
      </div>
    );
  };

  return (
    <div className='mainLoginSection'>
      <img src='/images/shared/logo.png' alt='valeticon' className='icon' />

      {isLoading && <Loader show={isLoading} />}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          width: "100%",
        }}
      >
        <div className='passwordHeading'>Forgot Password</div>
        {errorMessage && (
          <div className='forgotPasswordAlerts'>
            <Alert severity='error'>{errorMessage}</Alert>
          </div>
        )}
      </div>
      <div className='alertStyle'>
        {showResetPassword ? displayResetPasswordInputs() : displayEmail()}
        {passwordUpdated && displaySuccessAlert()}
        {showModal && displayAlert()}
      </div>
    </div>
  );
}

export default ForgotPassword;
