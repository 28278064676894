export const GET_TENANT = "GET_TENANT";
export const GET_TENANTS = "GET_TENANTS";
export const OVERVIEW = "OVERVIEW";
export const OVERALL_OVERVIEW = "OVERALL_OVERVIEW";

export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const GET_PROPERTY = "GET_PROPERTY";
export const GET_PPROPERTIES = "GET_PPROPERTIES";

export const USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST";
export const USER_SIGNUP_FAIL = "USER_SIGNUP_FAIL";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";

export const GET_PROPERTY_MANAGER_REQUEST = "GET_PROPERTY_MANAGER_REQUEST";
export const GET_PROPERTY_MANAGER_SUCCESS = "GET_PROPERTY_MANAGER_SUCCESS";
export const GET_PROPERTY_MANAGER_FAIL = "GET_PROPERTY_MANAGER_FAIL";

export const GET_SERVICEABLE = "GET_SERVICEABLE";
export const CREATE_SERVICEABLE = "CREATE_SERVICEABLE";
export const GET_MFO_NAME = "GET_MFO_NAME";
export const GET_PROPERTY_NAME = "GET_PROPERTY_NAME";
export const GET_ACTIVE_TAB = "GET_ACTIVE_TAB";

export const GET_MARKETING_MATERIAL = "GET_MARKETING_MATERIAL";
export const CREATE_MARKETING_MATERIAL = "CREATE_MARKETING_MATERIAL";

export const GET_PROPERTY_MANAGER_TASKS = "GET_PROPERTY_MANAGER_TASKS";
export const GET_MFOS_PROPERTIES = "GET_MFOS_PROPERTIES";

export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
