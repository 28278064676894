import React from "react";
import Layout from "../../components/layout/Layout";

function action() {
  return (
    <Layout activePage="notFound">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="/images/shared/bin.svg"
          alt="Not Found"
          width="15%"
          height="15%"
        />
        <h2>Requested page not found</h2>
      </div>
    </Layout>
  );
}

export default action;
