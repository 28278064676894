import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TerritoryTable from "../territoryTable";
import Loader from "../../../components/loader/Loader";
import "./pendingrequests.scss";
import { deliverTypes, JOB_TYPES, PENDING_HEADINGS } from "../../Constants";
import {
  confirmAppointment,
  getAvailableDriversAndSlots,
  getDriversHourlySlots,
  getAllpendingRequests,
  unAssigndriver,
} from "../../../redux/actions/territory-manager-actions/territoryManager";
import AddDriversModal from "./AddDriverModal";
import TenantListEmpty from "../../../components/tenant-list-empty/TenantListEmpty";
import { convertDate, convertTime } from "../../../utils/helperMethods";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import moment from "moment";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: "2.5vw",
          },
        },
      },
    },
  },
});

const PendingRequests = (props) => {
  const [PendingRequestsData, setPendingRequestsData] = useState([]);
  const [pickupHeadingsData, setpickupHeadingsData] = useState([
    ...PENDING_HEADINGS,
  ]);
  const [showModal, setshowModal] = useState(false);
  const [Modaldata, setModaldata] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [emptyTab, setemptyTab] = useState(false);

  const [driverID, setDriverID] = useState("");
  const [date, setdate] = useState();
  const [datedriverSlot, setdatedriverSlot] = useState();
  const [showSelectSlot, setSelectSlot] = useState(false);
  const [hourlySlots, sethourlySlots] = useState([]);
  const [driverSelectedSlotData, setdriverSelectedSlotData] = useState({});
  const [statusModal, setStatusModal] = useState({});
  const [selectdSlotId, setselectdSlotId] = useState("");
  const [driverSlotSelected, setdriverSlotSelected] = useState(false);
  const [selectedSlotdata, setselectedSlotdata] = useState({});
  const [slot, setSlot] = useState(false);

  const [selectedDriver, setSelectedDriver] = useState("");
  const [slotDriver, setSlotDriver] = useState("");
  const [slotConfirmed, setSlotconfirmed] = useState(false);
  const [userSlot, setuserSlot] = useState({});
  const [slotData, setSlotData] = useState("");
  const [draftedAppointmentId, setdraftedAppointmentId] = useState("");

  const [appointmentId, setAppointmentId] = useState("");

  const handleConfirmSelect = (data) => {
    const slotId = data.filter((item) => item.id === selectdSlotId);
    const slotObj = Object.fromEntries(slotId.flatMap(Object.entries));
    if (slotId) {
      setdriverSlotSelected(true);
      setSlotData(slotObj.id);
      setselectedSlotdata(slotObj);
      setdriverSelectedSlotData(slotObj);
    }
    setdriverSlotSelected(true);
    setSelectSlot(false);
    setSelectedDriver(slotDriver);
    setSlotconfirmed(true);
  };
  const handleSelectslot = (data) => {
    if (selectdSlotId !== data.id) {
      setSlotDriver(data.driverId);
      setselectdSlotId(data?.id);
      setSlot(true);
    }
  };

  const handleConfirmButton = () => {
    confirmuserAppointment("DRIVER_ASSIGNED");
  };

  const handleDraftButton = () => {
    confirmuserAppointment("DRAFT");
  };

  const handledriverSlots = async ({ slotData, id }) => {
    setDriverID(id);
    setdate(slotData.date);
    setdatedriverSlot(slotData);
    const { data, status } = await getDriversHourlySlots(id, appointmentId, {
      date: slotData.date,
      startTime: slotData.startTime,
      endTime: slotData.endTime,
    });
    if (status) {
      data?.slots?.forEach(
        (hours, index) => (
          (hours.id = `${id}${hours.startTime}${index}`),
          (hours.driverId = id),
          (hours.appointmentId = hours._id || "")
        )
      );
      sethourlySlots(data.slots);
    }
    setSelectSlot(true);
  };

  /**@description Handle hide Model Functionality
   */
  const handleHideModal = () => {
    statusModal.show = false;
    setStatusModal({ ...statusModal });
  };

  /**@description Handle Show Model Functionality
   */
  const handleShowModal = ({
    cancelText,
    confirmText,
    isAlert,
    title,
    message,
    handleConfirm = () => {},
  }) => {
    statusModal.show = true;
    statusModal.cancelText = cancelText || "Cancel";
    statusModal.confirmText = confirmText || "Ok";
    statusModal.isAlert = isAlert || false;
    statusModal.title = title || "";
    statusModal.message = message || "";
    statusModal.handleConfirm = handleConfirm;
    statusModal.handleCancel = handleHideModal;
    setStatusModal({ ...statusModal });
  };
  const confirmuserAppointment = async (statusdata) => {
    setShowLoader(true);
    let userSlot = {};
    (Modaldata?.dateTime || []).map((data) => {
      if (
        moment(
          driverSelectedSlotData.startTime ??
            driverSelectedSlotData.selectedSlot.startTime
        ) >= moment(data.startTime) &&
        moment(
          driverSelectedSlotData.endTime ??
            driverSelectedSlotData.selectedSlot.endTime
        ) <= moment(data.endTime)
      ) {
        userSlot = { ...data };
      }
    });
    const appointmentData = {
      driver: driverID,
      instruction: "string",
      status: statusdata,
      driverSlot: {
        date: datedriverSlot.date,
        startTime: datedriverSlot.startTime,
        endTime: datedriverSlot.endTime,
      },
      selectedSlot: {
        date:
          driverSelectedSlotData.date ??
          driverSelectedSlotData.selectedSlot.date,
        startTime:
          driverSelectedSlotData.startTime ??
          driverSelectedSlotData.selectedSlot.startTime,
        endTime:
          driverSelectedSlotData.endTime ??
          driverSelectedSlotData.selectedSlot.endTime,
        isDrafted: statusdata === "DRAFT" ? true : false,
      },
      userSlot: {
        date: userSlot.date,
        startTime: userSlot.startTime,
        endTime: userSlot.endTime,
      },
    };
    const showSuccess = (status) => {
      handleShowModal({
        cancelText: "Ok",
        title: status ? `Success` : `Failed`,
        message: status
          ? `Successfully  ${
              statusdata === "DRAFT" ? "Saved as Draft" : `Scheduled!`
            }`
          : `Failed to Scheduled a appointment!, please try again.`,
        isAlert: true,
      });
      getAlldriversfunction();
      setShowLoader(false);
      setshowModal(false);
      setSelectSlot(false);
      setSelectSlot(false);
      setselectdSlotId("");
      setdriverSlotSelected(false);
      setuserSlot({});
      setdriverSelectedSlotData({});
      setdatedriverSlot({});
      setDriverID("");
      setuserSlot({});
      props.setPendingRequests((PendingRequestsData?.length || []) - 1);
    };
    if (selectedSlotdata.status === "DRAFT") {
      await unAssigndriver(selectedSlotdata.appointmentId);
    }
    const { status } = await confirmAppointment(
      Modaldata?.appointmentID,
      appointmentData
    );
    showSuccess(status);
  };

  useEffect(async () => {
    getAlldriversfunction();
  }, []);

  const getAlldriversfunction = async (id) => {
    setShowLoader(true);
    const { data, status, message } = await getAllpendingRequests();

    if (message) {
      setemptyTab(true);
      setShowLoader(false);
    }
    setPendingRequestsData(data);
    setdriverSelectedSlotData({ ...data?.selectedSlot });

    setShowLoader(false);
  };
  let tableData = [];
  (PendingRequestsData || [])?.map((data) => {
    const status = data.status;
    const apartment = data?.user?.physicalAddress?.apartment;
    const clientName = data?.user?.name;
    const orderId = (data.order || []).map((order) => order.orderId);

    const state = data?.user?.physicalAddress?.state;
    const city = data?.user?.physicalAddress?.city;
    const zipcode = data?.user?.physicalAddress?.zipCode;
    const area = data?.user?.physicalAddress?.area;
    const floor = data?.user?.physicalAddress?.floor;
    const gateCode = data?.user?.physicalAddress?.gateCode;

    const customer_address = `${area ? `${area},` : ""} ${`${
      city ? city : ""
    } ${state ? `${state},` : ""} ${zipcode ? `${zipcode};` : ""}`} ${
      apartment ? `Unit ${apartment};` : ""
    }  ${floor ? `Floor ${floor};` : ""}  ${
      gateCode ? `Gate ${gateCode}#` : ""
    }`;

    const datetimeData = (data.slots || []).map((slot) => {
      return {
        date: convertDate(slot.date),
        startTime: convertTime(slot.startTime),
        endTime: convertTime(slot.endTime),
      };
    });

    tableData.push({
      _id: data?._id,
      appointmentID: data?._id,
      orderId: orderId,
      clientName: clientName ? clientName : "Not Available",
      dateTime: datetimeData.length
        ? (datetimeData || []).map((slot) => (
            <div>
              <span style={{ color: "#198eba" }}>{slot.date}</span>
              <br />
              <span>
                {slot.startTime}-{slot.endTime}
              </span>
            </div>
          ))
        : "Not Available",
      deliveryType: JOB_TYPES[data.type]?.title || data.type,
      CustomerAddress: customer_address ? customer_address : "Not Available",
      Status: status === "APPOINTMENT_CREATED" ? "New Request" : "Draft",
    });
  });

  const filterTabledata = (id) => {
    (PendingRequestsData || []).map(async (data) => {
      if (data._id === id) {
        setAppointmentId(data?._id);
        const { data: DriversData } = await getAvailableDriversAndSlots(
          data._id
        );
        const dataType = data?.type;
        const facilitydata = (data.order || []).map(
          (order) => order?.plan?.storageFacility?.address
        );
        const storagaddress = facilitydata ? [...new Set(facilitydata)] : "";
        const storageFacility =
          data.order.length > 0 ? data?.order[0]?.plan?.storageFacility : null;

        const storageFacilityaddress = storageFacility
          ? `${storageFacility?.facilityName}, ${
              storagaddress ? storagaddress : ""
            }`
          : "";

        const coordinates = (data.order || [])?.map((order) => {
          return {
            orderId: order?.orderId,
            unit: order?.spaceLocation?.roomNumber,
            coordinates: order?.spaceLocation?.coordinates,
          };
        });

        const userslots = data?.slots;
        const pickupItems =
          data.order.length > 0
            ? (data?.order || [])
                ?.map((order) => order.noOfBins)
                .reduce((acc, i) => acc + i)
            : null;
        const clientName = data?.user?.name;
        const appointmentID = data?._id;

        const area = data?.user?.physicalAddress?.area;
        const apartment = data?.user?.physicalAddress?.apartment;
        const floor = data?.user?.physicalAddress?.floor;
        const gateCode = data?.user?.physicalAddress?.gateCode;
        const info = data?.user?.physicalAddress?.info;

        const state = data?.user?.physicalAddress?.state;
        const city = data?.user?.physicalAddress?.city;
        const zipcode = data?.user?.physicalAddress?.zipCode;

        const bins = (data.userBins || []).filter((bins) => bins.userBin.isBin);
        const nonBins = (data.userBins || []).filter(
          (bins) => !bins.userBin.isBin
        );
        const customer_address = `${area ? `${area},` : ""} ${`${
          city ? `${city},` : ""
        } ${state ? `${state},` : ""} ${zipcode ? `${zipcode};` : ""}`} ${
          apartment ? `Unit ${apartment};` : ""
        }  ${floor ? `Floor ${floor};` : ""}  ${
          gateCode ? `Gate ${gateCode}#` : ""
        }${info ? `${info}` : ""}`;
        const emptybins = data.order.reduce((acc, i) => acc + i.noOfBins, 0);
        let emptyNonBinsData = [];
        (data?.order || []).filter((order) =>
          emptyNonBinsData.push(...order.nonBinItems)
        );

        const emptynonbins = emptyNonBinsData.reduce(
          (acc, i) => acc + i.quantity,
          0
        );

        setshowModal(true);

        const Empty_bins = `${
          emptybins ? `${emptybins} Empty Bin${emptybins > 1 ? `s` : ""} ` : ""
        } `;

        const Empty_nonbins = `${
          emptynonbins
            ? `${emptynonbins} QR code${emptynonbins > 1 ? `s` : ""} `
            : ""
        }`;
        const userBins =
          bins.length > 0
            ? `${bins.length} Bin${bins.length > 1 ? `s` : ""}`
            : "";
        const userNonbins =
          nonBins.length > 0
            ? `${nonBins.length} Non Bin${nonBins.length > 1 ? `s` : ""}`
            : "";
        if (data.selectedSlot) {
          setdriverSelectedSlotData(data.selectedSlot || {});
          setdatedriverSlot(data.driverSlot || {});
          setDriverID(data.driver || "");
          setuserSlot(data.userSlot || {});
          setselectdSlotId(data.selectedSlot._id);
        }

        let userBinItems = [];
        let emptyItems = [];
        const nonBinsData = data.order.map((data) => data.nonBinItems);
        (nonBinsData || []).forEach((data) => {
          data.forEach((data) => {
            emptyItems.push({
              item: data?.item?.item,
              quantity: data?.quantity,
            });
          });
        });
        (data.userBins || []).forEach((data) => {
          if (data.userBin.item?._id) {
            const itemIndex = userBinItems?.findIndex(
              (x) => x._id === data.userBin.item?._id
            );
            if (itemIndex >= 0) {
              userBinItems[itemIndex].quantity += 1;
            } else {
              userBinItems.push({
                item: data?.userBin.item?.item,
                quantity: 1,
                _id: data?.userBin.item?._id,
                qrCode: data.userBin.qrCode,
              });
            }
          }
        });
        let binsData = (data?.userBins || [])
          .filter((bin) => bin.userBin.isBin === true && bin.isReturn === false)
          .map((item) => {
            return item.userBin.qrCode;
          });

        let binArray = (data?.userBins || []).filter(
          (bin) => bin.userBin.isBin === true
        );

        let nonBinArray = (data?.userBins || []).filter(
          (bin) => bin.userBin.isBin === false
        );
        setModaldata({
          appointmentID: appointmentID,
          userSlots: userslots,
          binsData,
          binArray,
          nonBinArray,
          appointmentType: data.type,
          clientName: clientName ? clientName : "Not Available",
          deliveryType: JOB_TYPES[data.type]?.title || data.type,
          dropoffBinItems:
            dataType === deliverTypes.intialDropoff ? Empty_bins : userBins,
          dropoffNonbinItems:
            dataType === deliverTypes.intialDropoff
              ? Empty_nonbins
              : userNonbins,
          pickupBinItems:
            dataType === deliverTypes.intialDropoff ? Empty_bins : userBins,
          pickupNonbinItems:
            dataType === deliverTypes.intialDropoff
              ? Empty_nonbins
              : userNonbins,
          binSpace: data?.binSpace ? data?.binSpace : "",
          nonbinSpace: data?.nonBinSpace ? data?.nonBinSpace : "",
          pickupAddress:
            dataType === deliverTypes.PickupFromuser
              ? customer_address
                ? customer_address
                : ""
              : storageFacilityaddress
              ? storageFacilityaddress
              : "",
          coordinates: coordinates,

          dropoffAddress:
            dataType === deliverTypes.PickupFromuser
              ? storageFacilityaddress
                ? storageFacilityaddress
                : "Not Available"
              : customer_address
              ? customer_address
              : "Not Available",
          dateTime: userslots ? userslots : "Not Available",
          availableDrivers: DriversData ? DriversData : "Drivers Not Available",
          selectedSlot: data.selectedSlot && {
            startTime: new Date(data?.selectedSlot?.startTime),
            endTime: new Date(data?.selectedSlot?.endTime),
            driver: data.driver,
            date: data?.selectedSlot.date,
          },
          selectedSlotDetails: data.selectedSlot,
          isDraft: data.selectedSlot ? true : false,
          nonBinItemsdata:
            dataType === deliverTypes.intialDropoff ? emptyItems : userBinItems,
        });
      }
    });
  };

  const displayingPickupTable = () => {
    return (
      <ThemeProvider theme={theme}>
        <div className="CustomerTable">
          <TerritoryTable
            rows={tableData || []}
            tableHeading={pickupHeadingsData}
            tablecellpadding={"10.5vw"}
            maxHeight={"46vh"}
            hideCheckbox
            handleClick={(id) => filterTabledata(id)}
            dateTimedata={true}
          />
        </div>
      </ThemeProvider>
    );
  };

  return (
    <div className="pendingdeliveryOverview">
      {emptyTab ? (
        <div className={"tenantListEmpty"}>
          <TenantListEmpty
            width={"75%"}
            header="No Pending Requests are Found"
            canAdd={false}
          />
        </div>
      ) : (
        <>
          {displayingPickupTable()}
          {showModal ? (
            <AddDriversModal
              open={true}
              isAdd={true}
              modalData={Modaldata}
              tableData={tableData}
              setshowModal={setshowModal}
              setemptyTab={setemptyTab}
              handledriverSlots={handledriverSlots}
              handleConfirmButton={handleConfirmButton}
              handleDraftButton={handleDraftButton}
              handleSelectslot={handleSelectslot}
              selectdSlotId={selectdSlotId}
              setselectdSlotId={setselectdSlotId}
              setSelectSlot={setSelectSlot}
              handleConfirmSelect={handleConfirmSelect}
              showSelectSlot={showSelectSlot}
              hourlySlots={hourlySlots}
              driverSelectedSlotData={driverSelectedSlotData}
              driverSlotSelected={driverSlotSelected}
              setdriverSelectedSlotData={setdriverSelectedSlotData}
              setdriverSlotSelected={setdriverSlotSelected}
              setActivetab={props.setActivetab}
              slot={slot}
              selectedDriver={selectedDriver}
              datedriverSlot={datedriverSlot}
              slotConfirmed={slotConfirmed}
              sethourlySlots={sethourlySlots}
              slotData={slotData}
              driverAssignModal={true}
              setdraftedAppointmentId={setdraftedAppointmentId}
            />
          ) : null}
        </>
      )}
      {statusModal?.show ? (
        <ConfirmModal
          cancelText={statusModal.cancelText}
          confirmText={statusModal.confirmText}
          isAlert={statusModal.isAlert}
          title={statusModal.title}
          message={statusModal.message}
          handleCancel={statusModal.handleCancel}
          handleConfirm={statusModal.handleConfirm}
        />
      ) : null}
      <Loader show={showLoader} />
    </div>
  );
};

export default PendingRequests;
