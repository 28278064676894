import moment from "moment";
import { Roles } from "../config";
import { getRole } from "./authentication";
import {
  onlyDigits,
  excludingSpaces,
  mailFormat,
  alphabetAndNumber,
  alphabets,
  apartmentNumberFormat,
} from "../routes/Constants";
import CryptoJS from "crypto-js";

export function getURLParams(keys) {
  if (typeof window !== "undefined") {
    if (Array.isArray(keys)) {
      const url = new URL(window.location.href);
      const values = {};
      keys.forEach((key) => {
        values[key] = url.searchParams.get(key);
      });
      return values;
    }
    return new URL(window.location.href).searchParams.get(keys);
  }
  return null;
}

export function getLogoutURL() {
  const roleKey = Object.keys(Roles || {}).find(
    (rKey) => Roles[rKey] === getRole()
  );
  return `/login/${roleKey}`;
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function convertTestDate(date) {
  if (date) {
    return new Date(date);
  }
  return new Date(0);
}

export function convertToDateFormat(date, short = false) {
  if (!date.getDay) {
    date = convertTestDate(date || null);
  }
  return `${date.getDate()} ${monthNames[date.getMonth()]}${
    !short ? ` ${date.getFullYear()}` : ""
  }`;
}

export function getFormattedPhoneNum(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  if (value.startsWith("+91")) {
    value = value.replace("+91", "");
  } else if (value.startsWith("+1")) {
    value = value.replace("+1", "");
  }

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}

export function getTimeStops({ date, start, end, minutesInterval }) {
  const startTime = moment(start).add(start, "hours");
  const endTime = moment(end).add(end, "hours");

  if (endTime.isBefore(startTime)) {
    endTime.add(1, "day");
  }
  const timeStops = [];
  const slots = [];
  while (startTime <= endTime) {
    timeStops.push(new Date(startTime));
    startTime.add(minutesInterval, "minutes");
  }
  if (timeStops?.length > 1) {
    for (let i = 1; i < timeStops?.length; i += 1) {
      slots.push({
        startTime: timeStops[i - 1],
        endTime: timeStops[i],
        id: i,
        date: date,
      });
    }
  }
  return slots;
}

export const convertTime = (time) => {
  return moment(time).format("hh:mma");
};

export const convertDate = (date) => {
  return moment(date).format("DD MMM YYYY").replace(",", "");
};

export const convertTimeslot = (time) => {
  return moment(time).format("hh:mma");
};
export function convertToDMYFormat(date, formatType = 'MM-DD-YY') {
  return date && new Date(date)
    ? new moment(new Date(date)).format(formatType)
    : null;
}
export const convertHourlyTimeslot = (time) => {
  return moment(time).format("hh:mm");
};

export function reformatPhoneNumber(number) {
  return (number || "").replace(/\(/, "").replace(/\) /, "").replace(/-/, "");
}

export function validatingDigits(text) {
  return text.replace(onlyDigits, "");
}

export function convertToAlphaNumeric(text) {
  return text.replace(alphabetAndNumber, '');
}

export function excludeSpace(text) {
  return text.replace(excludingSpaces, "");
}

export function alphabetsRegex(text) {
  return text.replace(alphabets, "");
}

export function apartmentNumberRegex(text) {
  return text.replace(apartmentNumberFormat, "");
}

export function characterCheckRegex(text) {
  const reg = new RegExp("^[0-9]+$");
  return reg.test(text);
}
export function characterdotCheckRegex(text) {
  const reg = new RegExp("^([0-9]{1,2}\\.[0-9]{1,2}\\.[0-9]{1,2})$");
  return reg.test(text);
}

export function checkMailformat(text) {
  const reg = new RegExp(/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/);
  return reg.test(text);
}
export function downloadFromUrl(data, isMediaKit) {
  let blob = new Blob([data], { type: "application/zip" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  !isMediaKit
    ? link.setAttribute("download", "Marketingmaterial.zip")
    : link.setAttribute("download", "Mediakit.zip");
  document.body.appendChild(link);
  link.click();
}

export function checkIsEmail(text) {
  return new RegExp(mailFormat).test(text);
}
export function downloadURI(uri, name) {
  const link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export const openlivechat = () => {
  var s1 = document.createElement("script"),
    s0 = document.getElementsByTagName("script")[0];
  s1.async = true;
  s1.src = "https://embed.tawk.to/62e36b3854f06e12d88bdf39/1guufgsth";
  s1.charset = "UTF-8";
  s1.setAttribute("crossorigin", "*");
  s0.parentNode.insertBefore(s1, s0);
};

export const emailWriting = (subject) => {
  return (window.location = `mailto:support@valetcloset.com?subject=${subject}`);
};

export const decryptKey = (key) => {
  return CryptoJS.enc.Base64.parse(key).toString(CryptoJS.enc.Utf8);
};

export function convertNumberToDecimal(number, decimal = 2) {
  return isNaN(number) ? 0 : Number(number).toFixed(decimal);
}

export const driverDays = () => {
  const a = new Date();
  const b = new Date();
  b.setMonth(a.getMonth() + 1);
  return b;
};

export const getTodaydate = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return new Date(date).toISOString();
};

export const openNotionLink = () => {
  window.open(
    "https://www.notion.so/valetclosetguides/f6f0c60828b94096b67fabfeff0910cb?v=24f12f6a483a40748487f33c932a8661&pvs=4",
    "_blank"
  );
};

export const validations = (data, key, value, regexType) => {
  const index = data.findIndex((data) => data.key === key);
  const item = data[index];
  if (value.length === 0) {
    item.hasError = false;
    item.value = value;
  } else if ((value || "").trim().length <= item.maxLength) {
    if (regexType === "digits") {
      item.value = validatingDigits(value);
    } else if (regexType === "alphanumeric") {
      item.value = apartmentNumberRegex(value);
    } else if (regexType === "phoneNumber") {
      item.value = getFormattedPhoneNum(value);
    } else if (regexType === "alphabet") {
      item.value = alphabetsRegex(value);
    } else {
      item.value = value;
    }
    item.hasError = key === "email" ? !checkIsEmail(value) : null;
    if (item.hasError) {
      item.errorMessage = `Please enter valid ${item.title}`;
    } else {
      item.hasError = (item.value || "").trim().length < item.minLength;
      if (item.hasError) {
        item.errorMessage =
          key === "phoneNumber"
            ? `Please enter valid ${item.title}`
            : regexType === "digits"
            ? `${item.title} must be digits`
            : `${item.title} must contain at least ${item.minLength} characters`;
      }
    }
  }
  return item;
};

export const compareByIdForSorting = (a, b) => {
  if (a._id < b._id) {
    return -1;
  } else if (a._id > b._id) {
    return 1;
  } else {
    return 0;
  }
}
