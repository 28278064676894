/**@description displays property view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import "./TerritoryOverview.scss";
import CustomButton from "../../components/custom-button";
import { handleLogout as logoutUser } from "../../utils/authentication";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { territory_tabs } from "../Constants";
import PickupDelivery from "./pickup&deliveries/Pickup&Deliveries";
import PendingRequests from "./pendingRequests/pendingrequests";
import DriversTab from "./drivers/Drivers";
import { getAllpendingRequests } from "../../redux/actions/territory-manager-actions/territoryManager";
import StoredItems from "./storedItems/StoredItems";
import QRCodeGenerator from "./QrcodeGenerator/qrGenerator";
import StorageFacilities from "./StorageFacilities/storagefacilities";
import Orders from "./orders/Orders";
import RefundOrders from "./refundOrders/RefundOrders";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          width: "24%",
          "&:hover": {
            backgroundColor: "#fff",
            Color: "rgba(0, 125, 174, 1)",
          },
        },
      },
    },
  },
});

const AdminOverview = () => {
  const [activeTab, setActivetab] = useState(0);
  const [pendingRequests, setPendingRequests] = useState("");

  const history = useHistory();

  /**@description Admin Logout Functionality
   */
  const handleLogout = () => {
    const logoutURL = logoutUser();
    history.push(logoutURL);
  };

  useEffect(async () => {
    const pendingRequests = await getAllpendingRequests();
    setPendingRequests(pendingRequests?.data?.length);
  }, []);
  /**@description displays Logout Button UI
   */

  const displayLogout = () => {
    return (
      <ThemeProvider theme={theme}>
        <CustomButton
          startIcon={
            <img
              src='/images/shared/logout.svg'
              alt='Logout Icon'
              className={"managerlogoutIcon"}
            />
          }
          title={"Logout"}
          className='logout'
          handleButtonClick={handleLogout}
          additionalSx={{
            "& .MuiButton-startIcon": {
              paddingRight: "0.25vw",
            },
            "&:hover": {
              backgroundColor: "#fff",
              color: "rgba(0, 125, 174, 1)!important",
            },
            fontSize: "1.25vw",
            padding: "0.25vh 2vw",
            backgroundColor: "rgba(0, 125, 174, 1)",
            color: "#fff !important",
          }}
        />
      </ThemeProvider>
    );
  };

  /**@description displays tabs UI
   */
  const displayTabs = () => {
    return territory_tabs.map((tab, index) => (
      <div
        className={`tab ${activeTab === index ? "activeTab" : ""}`}
        role='presentation'
        onClick={() => {
          setActivetab(index);
        }}
        key={`displayTabs${tab}`}
      >
        {tab === "Pending Requests" ? tab : tab}
        {tab === "Pending Requests" && pendingRequests > 0 && (
          <span className='pendingRequestsStyle'>{`(${pendingRequests})`}</span>
        )}
      </div>
    ));
  };
  /**@description displays tab data based on selected tab
   */
  const getTabData = () => {
    switch (activeTab) {
      case 0:
        return (
          <PendingRequests
            setPendingRequests={setPendingRequests}
            setActivetab={setActivetab}
          />
        );
      case 1:
        return <PickupDelivery />;
      case 2:
        return <QRCodeGenerator />;
      case 3:
        return <StorageFacilities />;
      case 4:
        return <StoredItems />;
      case 5:
        return <DriversTab />;
      case 6:
        return <Orders />
      case 7:
        return <RefundOrders />
      default:
        return null;
    }
  };

  return (
    <div className='adminOverview'>
      <div className='navbar_header'>
        <div className={"managerTabContainers"}>{displayTabs()} </div>
        <div className='managerLogout'>{displayLogout()}</div>
      </div>
      {getTabData()}
    </div>
  );
};

export default AdminOverview;
