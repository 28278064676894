import React from "react";
import { Button } from "@mui/material";

const CustomButton = (props) => {
  return (
    <div>
      <Button
        variant="contained"
        className={props.className}
        onClick={props.handleButtonClick}
        type="submit"
        sx={{ ...props.sx, ...props.additionalSx }}
        startIcon={props.startIcon}
        disabled={props.disabled}
      >
        {props.title}
      </Button>
    </div>
  );
};

CustomButton.defaultProps = {
  startIcon: null,
  title: null,
  handleButtonClick: () => {},
  sx: {
    textTransform: "capitalize",
    width: "100%",
    padding: "1vw",
    borderRadius: "0.625vw",
    backgroundColor: "#189EBA",
    fontWeight: 600,
    fontSize: "1vw",
  },
  additionalSx: {},
  disabled: false,
};

export default CustomButton;
