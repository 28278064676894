import React, { useEffect, useState } from "react";
import TerritoryTable from "../territoryTable";
import { ORDERS_HEADINGS } from "../../Constants";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./Orders.scss";
import {
  getAllorderDetails,
  getOrderDetails,
} from "../../../redux/actions/territory-manager-actions/territoryManager";
import { convertNumberToDecimal } from "../../../utils/helperMethods";
import OrdersModal from "../orders/OrdersModal";
import Loader from "../../../components/loader/Loader";
import TenantListEmpty from "../../../components/tenant-list-empty/TenantListEmpty";

const Orders = () => {
  const [ordersHeadingsData, setOrdersHeadingsData] = useState([
    ...ORDERS_HEADINGS,
  ]);
  const [ordersData, setOrdersData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [popUpDetail, setPopUpDetail] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [emptyTab, setemptyTab] = useState(false);

  const getAllOrders = async () => {
    setshowLoader(true);
    const { data, message } = await getAllorderDetails();
    setOrdersData(data);

    if (message) {
      setemptyTab(true);
    }
    setshowLoader(false);
  };

  let orderData = [];

  (ordersData || [])?.map((data) => {
    const orderId = data.orderId;
    const clientName = data?.user?.name || "";
    const noOfBins = data?.noOfBins === 0 ? "0" : data?.noOfBins;
    const quantity = (data.nonBinItems || []).reduce(
      (acc, i) => acc + i.quantity,
      0
    );
    const noOfNonBins = quantity ? quantity : "0";
    
    orderData.push({
      _id: data?._id,
      orderId,
      clientName,
      noOfBins,
      noOfNonBins,
      selectedPlan: data?.plan?.totalCostWithTax
        ? "$" + convertNumberToDecimal(data?.plan?.totalCostWithTax)
        : "",
    });
  });

  useEffect(async () => {
    getAllOrders();
  }, []);

  let popUpDetails = [];

  const onClickHandler = async (id) => {
    (ordersData || []).map(async (data) => {
      if (data._id === id) {
        const orderDetail = await getOrderDetails(id);
        const orderId = orderDetail.data.orderId;
        const clientName = orderDetail.data.user.name;
        const state = orderDetail.data?.user?.physicalAddress?.state;
        const city = orderDetail.data?.user?.physicalAddress?.city;
        const zipcode = orderDetail.data?.user?.physicalAddress?.zipCode;
        const area = orderDetail.data?.user?.physicalAddress?.area;
        const floor = orderDetail.data?.user?.physicalAddress?.floor;
        const gateCode = orderDetail.data?.user?.physicalAddress?.gateCode;
        const apartment = orderDetail.data?.user?.physicalAddress?.apartment;
        const customerAddress = `${area ? `${area},` : ""} ${`${
          city ? city : ""
        } ${state ? `${state},` : ""} ${zipcode ? `${zipcode};` : ""}`} ${
          apartment ? `Unit ${apartment};` : ""
        }  ${floor ? `Floor ${floor};` : ""}  ${
          gateCode ? `Gate ${gateCode}#` : ""
        }`;
        const noOfBins = orderDetail.data?.noOfBins;
        const nonBinItems = orderDetail.data?.nonBinItems.map(item => item.quantity);
        const sum = nonBinItems.reduce((accu, currentValue) => accu + currentValue, 0);
        const noOfNonBins = sum ?? orderDetail.data?.plan?.noOfNonBinItems;
        const selectedPlan =
          convertNumberToDecimal(orderDetail.data?.plan?.totalCostWithTax) ||
          "";
        const roomNumber = orderDetail.data?.spaceLocation?.roomNumber || "";
        const coordinates = orderDetail.data?.spaceLocation?.coordinates || "";

        const storageFacilityAddress = orderDetail.data?.plan?.storageFacility
          ? `${orderDetail.data?.plan?.storageFacility?.facilityName}, ${orderDetail.data?.plan?.storageFacility.address}`
          : "";
        if (popUpDetails.length === 0) {
          popUpDetails.push({
            orderId,
            clientName,
            customerAddress,
            noOfBins,
            noOfNonBins,
            selectedPlan,
            coordinates,
            storageFacilityAddress,
            nonBinItemList: orderDetail.data?.nonBinItems,
            unit: roomNumber,
          });
        }

        setShowModal(true);
        setPopUpDetail(popUpDetails);
      }
    });
  };
  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            "&:last-child": {
              paddingBottom: "2.5vw",
            },
          },
        },
      },
    },
  });
  return (
    <div className="pendingdeliveryOverview">
      {emptyTab ? (
        <div className={"tenantListEmpty"}>
          <TenantListEmpty
            width={"75%"}
            header="No Orders are Found"
            canAdd={false}
          />
        </div>
      ) : (
        <>
          <ThemeProvider theme={theme}>
            <div className="CustomerTable">
              <TerritoryTable
                rows={orderData || []}
                tableHeading={ordersHeadingsData}
                tablecellpadding={"10.5vw"}
                maxHeight={"46vh"}
                hideCheckbox
                handleClick={(Id) => onClickHandler(Id)}
              />
            </div>
          </ThemeProvider>

          {showModal && (
            <OrdersModal
              setShowModal={setShowModal}
              showModal={showModal}
              popUpDetail={popUpDetail}
            />
          )}
        </>
      )}

      <Loader show={showLoader} />
    </div>
  );
};

export default Orders;
