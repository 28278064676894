import React, { useState, useEffect } from "react";
import CustomTable from "../../../components/custom-table/Customtable";
import { NOTIFICATION_HEADING } from "../../Constants";
import IconButton from "@mui/material/IconButton";
import CreateNotificationModal from "./CreateNotificationModal";
import NotificationDetails from "./NotificationDetails";
import {
  getAllNotifications,
  getNotificationDetails,
  getAllAppUsers,
} from "../../../redux/actions/notificationAction";
import Loader from "../../../components/loader";
import { convertToDMYFormat } from "../../../utils/helperMethods";
import _ from "lodash";
import TenantListEmpty from "../../../components/tenant-list-empty/TenantListEmpty";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";

const Notification = () => {
  const [createNotification, setCreateNotification] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [notificationData, setNotificationData] = useState("");
  const [data, setData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [tenantInfo, setTenantInfo] = useState([]);
  const [accountDeleted, setAccountDeleted] = useState(false);

  useEffect(() => {
    getAllNotification();
  }, []);

  const getAllNotification = async () => {
    setShowLoader(true);
    const data = await getAllNotifications();
    let notificationDataDetails = [];
    (data || []).map((curElem) => {
      let date = convertToDMYFormat(curElem.createdAt, "MM-DD-YYYY");
      notificationDataDetails.push({
        _id: curElem._id,
        date: date,
        title: curElem.title,
        message: curElem.body,
      });
    });
    setNotificationData(notificationDataDetails);
    setShowLoader(false);
  };

  const handleClose = () => {
    setCreateNotification(false);
    setViewDetails(false);
    setData([]);
  };

  const onClickViewDetails = async (id) => {
    let sentDate = notificationData.find((item) => item._id === id);
    const { data } = await getNotificationDetails(id);
    if (data.length > 0) {
      let users =
        data.length > 0
          ? (data[0]?.users ?? []).map((curUser) => curUser).join(", ")
          : "";
      const dynamicValues = {
        Date: sentDate.date,
        Title: data[0].notification.title,
        Message: data[0].notification.message,
        "Sent Count": data[0].sentCount,
        "Read Count": data[0].readCount,
        Users: users,
      };
      const labels = [
        "Date",
        "Title",
        "Message",
        "Sent Count",
        "Read Count",
        "Users",
      ];

      const details = labels.map((label) => ({
        label: label,
        value: dynamicValues[label],
      }));
      setViewDetails(true);
      setData(details);
    } else {
      setAccountDeleted(true);
    }
  };

  const getUsers = async () => {
    const data = await getAllAppUsers();
    setTenantInfo(data);
  };

  const displayPromotionalNotification = () => {
    return (
      <>
        {notificationData.length > 0 ? (
          <div className="couponTable">
            <CustomTable
              rows={notificationData || []}
              tableHeading={NOTIFICATION_HEADING}
              maxHeight={window.innerHeight * 0.6}
              onClickViewDetails={onClickViewDetails}
              hideCheckbox={true}
            />
            <div
              className="managerButtondiv"
              role="presentation"
              onClick={() => {
                setCreateNotification(true);
                getUsers();
              }}
            >
              <IconButton
                aria-label="upload picture"
                component="span"
                className="managerAddButton"
              >
                <img src={"/images/shared/plus.svg"} alt="" />
              </IconButton>
            </div>
          </div>
        ) : (
          <div className={"tenantListEmpty"}>
            <TenantListEmpty
              width={"75%"}
              header={"No Promotional Notifications were sent."}
              buttonText={"Create Promotional Notification"}
              canAdd={true}
              handleButtonClick={() => {
                setCreateNotification(true);
                getUsers();
              }}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div className="couponCodeOverview">
      {displayPromotionalNotification()}
      {viewDetails && (
        <NotificationDetails
          handleClose={handleClose}
          notificationData={data}
        />
      )}
      {createNotification && (
        <CreateNotificationModal
          handleClose={handleClose}
          setCreateNotification={setCreateNotification}
          tenantInfo={tenantInfo}
          getUsers={getUsers}
          getAllNotification={getAllNotification}
        />
      )}
      {accountDeleted && (
        <ConfirmModal
          isOpen={true}
          title="Failed"
          message={`User account has been deleted!`}
          cancelText="Okay"
          handleCancel={() => setAccountDeleted(false)}
          isAlert
        />
      )}
      <Loader show={showLoader} />
    </div>
  );
};

export default Notification;
