import React from "react";
import CustomModal from "../../../components/custom-modal/CustomModal";
import { EXPIRY_DATE } from "../../../components/Constants";
import "./Notification.scss";

const NotificationDetails = (props) => {
  const displayHeader = () => (
    <div className="notificationHeader">Notification Details</div>
  );

  const displayFormFields = () => (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {(props.notificationData || []).map((item, index) => {
        let textLength = item.value.length;
        return (
          <div key={index} className="notificationContainer">
            <div className="notificationLabel">{item.label} :</div>
            <div
              style={
                item.label === "Users"
                  ? {
                      color: "#54647a",
                      width: "80%",
                      overflowY: "scroll",
                      height: textLength > 30 ? "100px" : "50px",
                      marginBottom: textLength > 30 ? "50px" : "",
                      outline: "1px solid lightgrey",
                      padding: "2%",
                    }
                  : { color: "#54647a", width: "80%" }}
            >
              {item.value}
            </div>
          </div>
        );
      })}
    </div>
  );

  const displayModalContent = () => {
    return (
      <>
        {displayHeader()}
        {displayFormFields()}
      </>
    );
  };
  return (
    <div>
      {props.NotificationDetails ||
        ([] && (
          <CustomModal
            isOpen={true}
            handleRemoveIcon={props.handleClose}
            sx={EXPIRY_DATE}
            content={displayModalContent}
            closeButtonStyle="expiryCrossIcon"
          />
        ))}
    </div>
  );
};
export default NotificationDetails;
