import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import {
  ITEM_BOX_STYLE,
  PENDINGREQUEST_BOX_STYLE,
  SMALLMODAL_BOX_STYLE,
} from "../../../components/Constants";
import "./AddDrivers.scss";
import CustomButton from "../../../components/custom-button/CustomButton";
import {
  deliverTypes,
  PENDING_HEADINGS_DROPOFF_DATA,
  PENDING_HEADINGS_PICKUP_DATA,
} from "../../Constants";
import {
  convertDate,
  convertHourlyTimeslot,
  convertTime,
  convertTimeslot,
} from "../../../utils/helperMethods";
import CustomModal from "../../../components/custom-modal/CustomModal";
import _ from "lodash";

const AddDriversModal = (props) => {
  const [showItems, setShowItems] = useState(false);
  const [mouseOver, setMouseover] = useState(false);
  const [showBinItems, setShowBinItems] = useState(false);
  const {
    modalData,
    handledriverSlots,
    handleDraftButton,
    handleConfirmButton,
    setshowModal,
    handleSelectslot,
    selectdSlotId,
    handleConfirmSelect,
    setSelectSlot,
    showSelectSlot,
    driverSlotSelected,
    driverSelectedSlotData,
    setdriverSlotSelected,
    open,
    hourlySlots,
    setActivetab,
    setselectdSlotId,
    selectedDriver,
    slotConfirmed,
    setdraftedAppointmentId,
    slotData,
  } = props;

  const handleRemoveSlotModal = () => {
    setSelectSlot(false);
    setselectdSlotId(slotData);
    if (setSelectSlot) {
      setSelectSlot(false);
    }
    if (!selectdSlotId || !slotConfirmed) {
      setselectdSlotId && setselectdSlotId("");
    }
    if (!selectdSlotId) {
      setdriverSlotSelected && setdriverSlotSelected(false);
    }
    setShowItems && setShowItems(false);
  };

  const handleDropoffEmptyBins = () => {
    if (!deliverTypes.dropoffEmptyBins) {
      setActivetab(1);
    }
    if (modalData.nonBinArray.length > 0) {
      setShowItems(true);
    }
  };

  const renderBinItems = () => {
    if (modalData.binsData.length > 0) {
      setShowBinItems(true);
    }
  };

  const itemsModal = () => {
    let largeItem = modalData?.nonBinArray?.filter(
      (item) => item.isReturn === false
    );
    let unusedQR = modalData.nonBinArray.length - largeItem.length;
    const displayModalContent = () => {
      return (
        <>
          <h2 className="binDetailTitle">Non-Bin Details</h2>
          <table
            style={{ borderCollapse: "separate", borderSpacing: "50px 20px" }}
          >
            <thead>
              {largeItem.length > 0 && (
                <tr>
                  <th className="tableHeader">Item</th>
                  <th className="tableHeader">Quantity</th>
                  {modalData.nonBinArray.length > 0 && (
                    <th className="tableHeader">QR Code</th>
                  )}
                </tr>
              )}
            </thead>
            <tbody>
              {(modalData.nonBinItemsdata || []).map((data, index) => (
                <>
                  {largeItem.length > 0 && (
                    <tr key={index}>
                      <td style={{ color: "#54647a" }}>{data.item}</td>
                      <td style={{ color: "#54647a" }}>{data.quantity}</td>
                      {modalData.nonBinArray.length > 0 ? (
                        <td style={{ color: "#54647a" }}>{data.qrCode}</td>
                      ) : null}
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
          {modalData.appointmentType !== deliverTypes.DropoffUser &&
            modalData.appointmentType !== deliverTypes.intialDropoff && (
              <>
                <div className="nonBinDetailsContainer">
                  <div className="nonBinContainer">Packed item(s) :</div>
                  <div className="data">{largeItem.length}</div>
                </div>
                {unusedQR > 0 && (
                  <div className="nonBinDetailsContainer">
                    <div className="nonBinContainer">Unused QR code(s) :</div>
                    <div className="data">{unusedQR}</div>
                  </div>
                )}
              </>
            )}
        </>
      );
    };
    return (
      <CustomModal
        isOpen={showItems}
        sx={ITEM_BOX_STYLE}
        handleRemoveIcon={() => setShowItems(false)}
        content={displayModalContent}
        closeButtonStyle="selectmodalRemoveIcon"
      />
    );
  };

  const displayBinItems = () => {
    let usedBinCount = modalData?.binArray?.filter(
      (item) => item.isReturn === false
    );
    let unUsedBin = modalData.binArray.length - usedBinCount.length;
    return (
      <>
        <h2 className="binDetailTitle">Bin Details</h2>
        <div className="mainContainer">
          <div className="quantityContainer">Quantity :</div>
          <div className="data">{modalData.binsData.length}</div>
        </div>
        <div className="mainContainer">
          <div className="quantityContainer">QR Code :</div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {(modalData.binsData || []).map((data, index) => (
              <div key={index} className="data">
                {`${data}${index < modalData.binsData.length - 1 ? ", " : ""}`}
              </div>
            ))}
          </div>
        </div>
        {modalData.appointmentType !== deliverTypes.DropoffUser &&
          modalData.appointmentType !== deliverTypes.intialDropoff && (
            <>
              <div className="mainContainer">
                <div className="quantityContainer">Packed Bin(s) :</div>
                <div className="data">{usedBinCount.length}</div>
              </div>
              {unUsedBin > 0 && (
                <div className="mainContainer">
                  <div className="quantityContainer">Empty Bin(s) :</div>
                  <div className="data">{unUsedBin}</div>
                </div>
              )}
            </>
          )}
      </>
    );
  };

  const displayingDriversData = () => {
    return (
      <div className="avilableSlots">
        <div>
          {Array.isArray(modalData?.availableDrivers) &&
          modalData?.availableDrivers?.length > 0 ? (
            modalData.availableDrivers.map((driver, index) => (
              <div className="avilableSlots" key={`displayDriver${index}`}>
                {!driver && "Drivers Not Available"}
                <div
                  className="driverName"
                  style={{ wordBreak: "break-all" }}
                >{`${driver?.driver?.name ? driver.driver.name : " "}`}</div>
                <div className="drivenameSpace">{"-"}</div>
                <div className="driverdateTime">
                  {driver.slots.map((slotData) => (
                    <div
                      onClick={() =>
                        handledriverSlots({
                          slotData,
                          id: driver?.driver?._id,
                        })
                      }
                      key={slotData._id}
                    >
                      <span className="dateStyles">
                        {" "}
                        {convertDate(slotData.date).slice(0, 6)}{" "}
                      </span>
                      {convertTime(slotData.startTime)}-
                      {convertTime(slotData.endTime)}
                    </div>
                  ))}
                </div>

                {driverSlotSelected ? (
                  driver?.driver?._id === selectedDriver ? (
                    <div className="dateTime">
                      {driverSlotSelected && (
                        <div className={"selectedSlot"}>
                          {convertTimeslot(
                            driverSelectedSlotData.startTime ??
                              driverSelectedSlotData.selectedSlot.startTime
                          )}
                          -
                          {convertTimeslot(
                            driverSelectedSlotData.endTime ??
                              driverSelectedSlotData.selectedSlot.endTime
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  modalData.isDraft &&
                  (driver?.driver?._id === modalData.selectedSlot.driver ? (
                    <div className={"selectedSlot"}>
                      {convertTimeslot(modalData?.selectedSlot?.startTime)}-
                      {convertTimeslot(modalData?.selectedSlot?.endTime)}
                    </div>
                  ) : (
                    ""
                  ))
                )}
              </div>
            ))
          ) : (
            <div className="driversNot">{"Drivers Not Available"}</div>
          )}
        </div>
      </div>
    );
  };
  const displayingPickupItems = () => {
    return (
      <div className="binsData">
        <div className="binsdataStyle" onClick={() => renderBinItems()}>
          {modalData.pickupBinItems ? modalData.pickupBinItems : ""}
        </div>
        {modalData.pickupBinItems && (
          <div className="totalArea">
            {modalData?.binSpace ? (
              <>
                <div className="binsarea">Total area :</div>{" "}
                <div>
                  {modalData?.binSpace
                    ? `${modalData?.binSpace}  cubic ft`
                    : ""}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  };

  const displayingPickupNonbinItems = () => {
    return (
      <div className="binsData">
        <div className="binsdataStyle" onClick={() => handleDropoffEmptyBins()}>
          {modalData.pickupNonbinItems ? modalData.pickupNonbinItems : ""}
        </div>
        {modalData.pickupNonbinItems && (
          <div className="totalArea">
            {modalData?.nonbinSpace ? (
              <>
                <div className="binsarea">Total area :</div>
                <div>
                  {modalData?.nonbinSpace
                    ? `${modalData?.nonbinSpace}  cubic ft`
                    : ""}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  };

  const displayingdropoffbinItems = () => {
    return (
      <div className="binsData">
        <div
          className="binsdataStyle"
          onClick={() => {
            //  !deliverTypes.dropoffEmptyBins ? setActivetab(1) : ""
            if (modalData.binsData.length) {
              setShowBinItems(true);
            }
          }}
        >
          {modalData.dropoffBinItems ? modalData.dropoffBinItems : ""}
        </div>
        {modalData?.deliveryType === deliverTypes.dropoffEmptyBins
          ? ""
          : modalData.dropoffBinItems && (
              <div className="totalArea">
                {modalData?.binSpace ? (
                  <>
                    <div className="binsarea">Total area :</div>
                    <div>
                      {modalData?.binSpace
                        ? `${modalData?.binSpace} cubic ft`
                        : ""}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
      </div>
    );
  };

  const displayingdropoffNonbinItems = () => {
    return (
      <div className="binsData">
        <div className="binsdataStyle" onClick={() => handleDropoffEmptyBins()}>
          {modalData.dropoffNonbinItems ? modalData.dropoffNonbinItems : ""}
        </div>
        {modalData?.deliveryType === deliverTypes.dropoffEmptyBins
          ? ""
          : modalData.dropoffNonbinItems && (
              <div className="totalArea">
                {modalData?.nonbinSpace ? (
                  <>
                    <div className="binsarea">Total area :</div>
                    <div>
                      {modalData?.nonbinSpace
                        ? `${modalData?.nonbinSpace} cubic ft`
                        : ""}{" "}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
      </div>
    );
  };

  const modalHeadingData =
    modalData?.deliveryType === deliverTypes.pickupCustomer ||
    modalData?.deliveryType === deliverTypes.initialpickupCustomer
      ? PENDING_HEADINGS_PICKUP_DATA
      : PENDING_HEADINGS_DROPOFF_DATA;

  const displayAssignedDriver = () => {
    return (
      <div className="assignedDriverStyle">
        <div className="assignedDrivername">
          {modalData?.assignedDriver?.name}
        </div>
        <div className="vehicleImageStyle">
          <img
            src={
              modalData?.assignedDriver?.driverType === "TRUCK"
                ? "/images/shared/semi-truck.png"
                : "/images/shared/icon-jeep.png"
            }
            alt="Delete Icon"
            className="assigneddriverIcon"
          />
        </div>
        <div className="driverdateTime">
          <span className="dateStyles">
            {convertDate(modalData?.assignedDriverSlot?.date).slice(0, 6)}
          </span>
          {convertTimeslot(modalData?.assignedDriverSlot?.startTime)}-
          {convertTimeslot(modalData?.assignedDriverSlot?.endTime)}
        </div>
        <div className={"assignedSlotStyle"}></div>
      </div>
    );
  };

  const displayInput = () => {
    const getData = (key) => {
      return <div>{modalData[key]}</div>;
    };

    return (
      <div className="addDriversDetails">
        {modalHeadingData.map((data) => {
          return ![
            "dropoffBinItems",
            "dropoffNonbinItems",
            "pickupBinItems",
            "pickupNonbinItems",
            !props.driverAssignModal && "dateTime",
            !props.driverAssignModal && "availableDrivers",
            props.driverAssignModal && "assignedDriver",
          ].includes(data.key) || modalData[data.key]?.length > 1 ? (
            <div className={"ModalDetails"} key={data.key}>
              <div className="dataNameItem">{data.Name}</div>
              {data.key === "availableDrivers" ? (
                displayingDriversData()
              ) : (
                <div
                  className={[
                    data.key === "dateTime"
                      ? "driverTimeslots"
                      : data.key === "coordinates"
                      ? "storageAddress"
                      : "avilableuserSlots",
                  ]}
                >
                  {data.key === "dateTime"
                    ? (modalData.dateTime || []).map((userSlot, index) => (
                        <div
                          className="dateTime"
                          key={`displayuserSlot${index}`}
                        >
                          {convertDate(userSlot.date)},{" "}
                          {convertTime(userSlot.startTime)}-
                          {convertTime(userSlot.endTime)}
                        </div>
                      ))
                    : data.key === "pickupBinItems"
                    ? displayingPickupItems()
                    : data.key === "pickupNonbinItems"
                    ? displayingPickupNonbinItems()
                    : data.key === "dropoffBinItems"
                    ? displayingdropoffbinItems()
                    : data.key === "dropoffNonbinItems"
                    ? displayingdropoffNonbinItems()
                    : data.key === "coordinates"
                    ? (modalData.coordinates || []).map((data, index) => (
                        <div
                          className="dateTime"
                          key={`displayuserSlot${index}`}
                        >
                          <div>{data.storageFacilityaddress}</div>
                          {data?.coordinates
                            ? `orderID:  ${data?.orderId}, unit: ${data?.unit}, ${data?.coordinates}`
                            : null}
                        </div>
                      ))
                    : data.key === "assignedDriver"
                    ? displayAssignedDriver()
                    : getData(data.key)}
                </div>
              )}
            </div>
          ) : null;
        })}
      </div>
    );
  };
  let disabled = modalData.isDraft
    ? modalData.isDraft
      ? false
      : true
    : !driverSlotSelected;
  const displayDraftButton = () => {
    return (
      <div className={"addButton"}>
        <CustomButton
          title={`${"Save Draft"}`}
          additionalSx={{
            padding: "0.5vw 4vw",
            fontWeight: 600,
            fontSize: "1vw",
          }}
          disabled={disabled}
          handleButtonClick={() => handleDraftButton()}
        />
      </div>
    );
  };

  const displayCloseButton = () => {
    return (
      <div className={"addButton"}>
        <CustomButton
          title={`${"Close"}`}
          additionalSx={{
            padding: "0.5vw 4vw",
            fontWeight: 600,
            fontSize: "1vw",
          }}
          disabled={disabled}
          handleButtonClick={() => handleRemoveIcon()}
        />
      </div>
    );
  };

  const displayConfirmButton = () => {
    return (
      <div className={"addButton"}>
        <CustomButton
          title={`${"confirm"}`}
          additionalSx={{
            padding: "0.5vw 4vw",
            fontWeight: 600,
            fontSize: "1vw",
          }}
          disabled={disabled}
          handleButtonClick={() => handleConfirmButton()}
        />
      </div>
    );
  };

  const handleRemoveIcon = () => {
    setshowModal(false);
    if (setselectdSlotId || setSelectSlot || setdriverSlotSelected) {
      setselectdSlotId("");
      setSelectSlot(false);
      setdriverSlotSelected(false);
    }
  };
  const displayingmodalRemoveIcon = () => {
    return (
      <div className="modalRemoveIcon" onClick={() => handleRemoveIcon()}>
        X
      </div>
    );
  };
  const displayingselectedSlotModal = () => {
    const renderDetails = () => {
      const sortedData = _.orderBy(
        hourlySlots,
        [(data) => data.startTime ?? data?.selectedSlot?.startTime],
        ["asc"]
      );
      return (
        <>
          {sortedData?.length > 0 ? (
            <>
              <div
                style={{
                  marginbottom: "10px",
                }}
                className={"slotsModalstyle"}
              >
                <div>Select one Slot</div>
                <div>
                  <div className="colorInfostyle">
                    <div className="yellowStyle"></div>{" "}
                    <div className="slotInfoText"> - Drafted</div>
                  </div>
                  <div className="colorInfostyle">
                    <div className="greenSquareStyle"></div>
                    <div className="slotInfoText">- Selected</div>
                  </div>
                </div>
              </div>

              <div className="slotsHourly">
                {sortedData.map((hours) => {
                  let className = "";
                  if (hours.appointmentId !== "" && hours.status !== "DRAFT") {
                    className = "disableSlot";
                  } else if (hours.id === selectdSlotId ?? slotData) {
                    className = "selectedSlot";
                  } else if (hours.status === "DRAFT") {
                    className = "draftedSlot";
                  }

                  return (
                    <>
                      <div
                        className="slots"
                        onClick={() => {
                          if (className !== "disableSlot") {
                            if (hours.status !== "DRIVER_ASSIGNED") {
                              handleSelectslot(hours);
                              setMouseover(false);
                            }
                            if (hours.status === "DRAFT") {
                              setdraftedAppointmentId(hours._id);
                            }
                          }
                        }}
                        key={hours.id}
                      >
                        <div
                          className={`${"slotdiv"}  ${className}`}
                          title={
                            hours.status === "DRAFT" &&
                            `This slot is drafted to ${hours.appointmentId}`
                          }
                          onMouseOver={() =>
                            hours.status === "DRAFT" && setMouseover(true)
                          }
                        >
                          {convertHourlyTimeslot(
                            hours.startTime ?? hours?.selectedSlot?.startTime
                          )}
                          -
                          {convertHourlyTimeslot(
                            hours.endTime ?? hours?.selectedSlot?.endTime
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>

              <div className="slotConfirmButton">
                <CustomButton
                  title={`${"confirm"}`}
                  additionalSx={{
                    padding: "0.5vw 4vw",
                    fontWeight: 600,
                    fontSize: "1vw",
                  }}
                  disabled={!hourlySlots.find((x) => x.id === selectdSlotId)}
                  handleButtonClick={() => {
                    handleConfirmSelect(hourlySlots);
                  }}
                />
              </div>
            </>
          ) : (
            <div className="slotsEmpty">Slots are Not Available</div>
          )}
        </>
      );
    };
    return (
      <CustomModal
        isOpen={open}
        sx={SMALLMODAL_BOX_STYLE}
        handleRemoveIcon={() => handleRemoveSlotModal()}
        content={renderDetails}
        closeButtonStyle="modalButton"
      />
    );
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        <Box sx={PENDINGREQUEST_BOX_STYLE}>
          {displayInput()}
          {props.driverAssignModal ? (
            <div className="Buttons">
              {displayDraftButton()}
              {displayConfirmButton()}
            </div>
          ) : (
            <div className="Buttons">{displayCloseButton()}</div>
          )}
          {showSelectSlot && displayingselectedSlotModal()}
          {showItems && itemsModal()}
          {showBinItems && (
            <CustomModal
              isOpen={showBinItems}
              sx={ITEM_BOX_STYLE}
              handleRemoveIcon={() => setShowBinItems(false)}
              content={displayBinItems}
              closeButtonStyle="selectmodalRemoveIcon"
            />
          )}
        </Box>
        {displayingmodalRemoveIcon()}
      </div>
    </Modal>
  );
};
export default AddDriversModal;
