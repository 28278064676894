import { Details } from "@mui/icons-material";
import moment from "moment";

const DATA_INFO = {
  fName: {
    name: "",
    minLength: 3,
    maxLength: 25,
    title: "Full Name",
    type: "text",
    key: "fName",
    hasError: false,
  },
  email: {
    email: "",
    minLength: 3,
    maxLength: 64,
    title: "Email Address",
    type: "email",
    key: "email",
    hasError: false,
    required: true,
  },
  password: {
    password: "",
    maxLength: 25,
    minLength: 0,
    title: "Password",
    type: "password",
    reg: "^[0-9]+$",
    key: "password",
    hasError: false,
  },
  oldpassword: {
    value: "",
    maxLength: 25,
    minLength: 0,
    title: "Old Password",
    type: "password",
    reg: "^[0-9]+$",
    key: "oldpassword",
    hasError: false,
  },
  newpassword: {
    value: "",
    maxLength: 25,
    minLength: 0,
    title: "Enter New Password",
    type: "password",
    reg: "^[0-9]+$",
    key: "newpassword",
    hasError: false,
    required: true,
  },
  confirmpassword: {
    value: "",
    maxLength: 25,
    minLength: 0,
    title: "Re Enter New Password",
    type: "password",
    reg: "^[0-9]+$",
    key: "confirmpassword",
    hasError: false,
    required: true,
  },
  operatorgroupname: {
    value: "",
    minLength: 3,
    maxLength: 25,
    title: "Operator Group Name",
    type: "text",
    key: "operatorgroupname",
    hasError: false,
  },
  contactnumber: {
    value: "",
    minLength: 3,
    maxLength: 25,
    title: "Contact Number",
    type: "numeric",
    key: "contactnumber",
    hasError: false,
    hasTag: true,
    tagLineText: <p>e.g. 000-000-0000</p>,
  },
  zipcode: {
    value: "",
    title: "Zipcode",
    type: "numeric",
    key: "Zipcode",
    minLength: 5,
    maxLength: 100,
    hasError: false,
    tagLineText: <p>e.g. 00000,11111,222222</p>,
  },
  code: {
    value: "",
    type: "numeric",
    key: "code",
    title: "Verification Code",
    minLength: 6,
    maxLength: 6,
    hasError: false,
    required: true,
  },
};

export const ZIPCOD_DETAILS = ["zipcode"].map((key) => {
  return {
    ...DATA_INFO[key],
  };
});

export const SIGNUP_DETAILS = ["fName", "email", "password"].map((key) => {
  return {
    ...DATA_INFO[key],
  };
});

export const LOGIN_DETAILS = ["email", "password"].map((key) => {
  return {
    ...DATA_INFO[key],
  };
});

export const RESETPASSWORD_DETAILS = {
  newpassword: DATA_INFO.newpassword,
  confirmpassword: DATA_INFO.confirmpassword,
};

export const FORGOTPASSWORD_DETAILS = {
  email: DATA_INFO.email,
};

export const RESETPASSWORD = {
  code: DATA_INFO.code,
  password: DATA_INFO.password,
  confirmpassword: DATA_INFO.confirmpassword,
};

export const USERCONTACT_DETAILS = [
  "fName",
  "email",
  "operatorgroupname",
  "contactnumber",
].map((key) => {
  return {
    ...DATA_INFO[key],
  };
});

export const ROLE_NAME = {
  admin: "Admin",
  manager: "Account Manager",
  mfo: "Multifamily Operator",
  territory_manager: "Territory Manager",
};

export const CREATE_TENANT = [
  "name",
  "email",
  "apartmentNumber",
  "tenantNumber",
  "leaseStartDate",
  "leaseEndDate",
];
export const CREATE_MFO = ["name", "email", "phoneNumber"];

export const CREATE_PROPERTY = [
  "name",
  "numberOfBuildings",
  "numberOfApartments",
  "apartmentTypes",
  "address",
  "address2",
];

export const TENANTSTABLE_HEADINGS = [
  {
    Name: "Tenant Name",
    key: "name",
  },
  {
    Name: "Email Address",
    key: "email",
  },
  {
    Name: "Apt No",
    key: "apartmentNumber",
  },
  {
    Name: "Tenant No.",
    key: "tenantNumber",
  },
  {
    Name: "Lease Start Date",
    key: "leaseStartDate",
    format: true,
    formatType: "date",
  },
  {
    Name: "Lease End Date",
    key: "leaseEndDate",
    format: true,
    formatType: "date",
  },
];

export const PROPERTYTABLE_HEADINGS = [
  {
    Name: "Property Name",
    key: "name",
  },
  {
    Name: "Property Mgr",
    key: "manager.name",
  },
  {
    Name: "Property Mgr  Email",
    key: "manager.email",
  },
  {
    Name: "Property Mgr No.",
    key: "manager.phoneNumber",
    format: true,
    formatType: "phone",
  },
];

export const MANAGERTABLE_HEADINGS = [
  {
    Name: "Manager Name",
    key: "name",
    regexType: "alphabet",
  },
  {
    Name: "Manager Email",
    key: "email",
  },
  {
    Name: "Contact Number.",
    key: "phoneNumber",
    format: true,
    formatType: "phone",
    regexType: "phoneNumber",
  },
];

export const MFOLIST_HEADINGS = [
  {
    Name: "MFO Name",
    key: "name",
    regexType: "alphabet",
  },
  {
    Name: "Email Address",
    key: "email",
  },
  {
    Name: "Contact Number.",
    key: "phoneNumber",
    format: true,
    formatType: "phone",
    regexType: "phoneNumber",
  },
];

export const PROPERTYMFOTABLE_HEADINGS = [
  {
    Name: "MFO Name",
    key: "name",
  },
  {
    Name: "Properties",
    key: "properties",
  },
  {
    Name: "Property Manager",
    key: "propertyManager",
  },
];

export const HOLIDAY_TABLE_HEADINGS = [
  {
    Name: "Start Date",
    key: "startDate",
  },
  {
    Name: "End Date",
    key: "endDate",
  },
  {
    Name: "Reason",
    key: "name",
  },
];

export const MFOLIST_DETAILS = [
  {
    name: "MFO Name 1",
    email: "mfoname1@gmail.com",
    properties: "property mgr name",
    phoneNumber: "111-111-1111",

    _id: 1,
  },
  {
    name: "MFO Name 2",
    email: "mfoname2@gmail.com",
    properties: "property mgr name",
    phoneNumber: "111-111-1111",

    _id: 2,
  },
  {
    name: "MFO Name 3",
    email: "mfoname3@gmail.com",
    properties: "property mgr name",
    phoneNumber: "111-111-1111",
    _id: 3,
  },
  {
    name: "MFO Name 4",
    propertyName: "Property-4",
    email: "mfoname4@gmail.com",
    properties: "property mgr name",
    phoneNumber: "111-111-1111",

    _id: 4,
  },
  {
    name: "MFO Name 5",
    propertyName: "Property-5",
    email: "mfoname5@gmail.com",
    properties: "property mgr name",
    phoneNumber: "111-111-1111",

    _id: 5,
  },
];

export const PROPERTYMFO_DETAILS = [
  {
    name: "MFO Name",
    properties: [
      "Property Name",
      "Property Name",
      "Property Name",
      "Property Name",
    ],
    propertyManager: [
      "Property Mgr Name",
      "Property Mgr Name",
      "Property Mgr Name",
      "Property Mgr Name",
    ],

    _id: 1,
  },
  {
    name: "MFO Name",
    properties: ["Property Name", "Property Name", "Property Name"],
    propertyManager: [
      "Property Mgr Name",
      "Property Mgr Name",
      "Property Mgr Name",
    ],
    _id: 2,
  },
  {
    name: "MFO Name",
    properties: [
      "Property Name",
      "Property Name",
      "Property Name",
      "Property Name",
      "Property Name",
      "Property Name",
      "Property Name",
      "Property Name",
    ],
    propertyManager: [
      "Property Mgr Name",
      "Property Mgr Name",
      "Property Mgr Name",
      "Property Mgr Name",
      "Property Mgr Name",
      "Property Mgr Name",
      "Property Mgr Name",
      "Property Mgr Name",
    ],
    _id: 3,
  },
];

export const STATE_ZIPCODETABLE_HEADINGS = [
  {
    Name: "state",
    key: "state",
  },
  {
    Name: "zipcode",
    key: "zipCode",
  },
];

export const STATE_ZIPCODE = [
  {
    _id: 1,
    email: "a",
    propertyName: "11125",
  },
  {
    _id: 2,
    email: "b",
    propertyName: "11125",
  },
  {
    _id: 3,
    email: "c",
    propertyName: "11125",
  },
  {
    _id: 4,
    email: "d",
    propertyName: "11125",
  },
  {
    _id: 5,
    email: "e",
    propertyName: "11125",
  },
  {
    _id: 6,
    email: "f",
    propertyName: "11125",
  },
  {
    _id: 7,
    email: "g",
    propertyName: "11125",
  },
  {
    _id: 8,
    email: "h",
    propertyName: "11125",
  },
  {
    _id: 9,
    email: "i",
    propertyName: "11125",
  },
];

export const APARTMENT_TYPE = [
  { type: "Garden-style", value: "Garden-style" },
  { type: "High rise", value: "High rise" },
  { type: "Villas", value: "Villas" },
  { type: "Townhouse", value: "Townhouse" },
  { type: "Walk-up", value: "Walk-up" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const LIST_DATA = [
  {
    key: "social_media",
    value: "Post about Valet Closet on social media",
    isChecked: true,
  },
  {
    key: "valet_closet",
    value: "Share the Valet Closet newsletter with tenants",
    isChecked: true,
  },
  { key: "tenants", value: "Update tenant information" },
  { key: "feedback", value: "Give us feedback" },
];

export const MFODATA = [
  {
    id: 1,
    mfoName: "MFO Name 1",
    property: [
      { name: "Property 1" },
      { name: "Property 2" },
      { name: "Property 3" },
    ],
  },
  {
    id: 2,
    mfoName: "MFO Name 2",
    property: [
      { name: "Property 1" },
      { name: "Property 2" },
      { name: "Property 3" },
    ],
  },
  {
    id: 3,
    mfoName: "MFO Name 3",
    property: [
      { name: "Property 1" },
      { name: "Property 2" },
      { name: "Property 3" },
    ],
  },
  {
    id: 4,
    mfoName: "MFO Name 4",
    property: [
      { name: "Property 1" },
      { name: "Property 2" },
      { name: "Property 3" },
    ],
  },
];

export const ADD_MULTI_FAMILY_PROPERTY = [
  {
    title: "Property Name",
    type: "text",
    key: "name",
    value: "",
    minLength: 3,
    maxLength: 25,
    hasError: false,
    regexType: "alphanumeric",
  },
  {
    title: "No.of Buildings",
    type: "number",
    key: "numberOfBuildings",
    value: "",
    minValue: 1,
    minLength: 1,
    maxLength: 5,
    hasError: false,
    regexType: "digits",
  },
  {
    title: "No.of Apartments",
    type: "number",
    key: "numberOfApartments",
    value: "",
    minValue: 1,
    minLength: 1,
    maxLength: 5,
    hasError: false,
    regexType: "digits",
  },
  { title: "Apartment Type", type: "text", key: "apartmentTypes" },
  {
    title: "Address-1",
    type: "text",
    key: "address",
    value: "",
    minLength: 3,
    maxLength: 25,
    hasError: false,
  },
  {
    title: "Address-2",
    type: "text",
    key: "address2",
    value: "",
    minLength: 3,
    maxLength: 25,
    hasError: false,
  },
  {
    title: "Property Manager Email Address",
    type: "text",
    key: "email",
    value: "",
    minLength: 3,
    maxLength: 64,
    hasError: false,
    isManager: true,
    regexType: "email",
  },
  {
    title: "Property Manager Name",
    type: "text",
    key: "Mgrname",
    value: "",
    minLength: 3,
    maxLength: 25,
    hasError: false,
    isManager: true,
    regexType: "alphabet",
  },
  {
    title: "Property Manager Contact Number",
    type: "text",
    key: "phoneNumber",
    value: "",
    minLength: 14,
    maxLength: 14,
    hasError: false,
    isManager: true,
    regexType: "phoneNumber",
  },
];
export const tabs = [
  "MFOs List",
  "Managers List",
  "Zipcodes",
  "Properties",
  "Marketing Material",
  "App Version",
  "Discount Code",
  "Holidays",
  "Notifications",
];
export const fileTabs = [
  {
    title: "Email & SMS",
    value: "marketingMaterial",
  },
  {
    title: "Social Media",
    value: "socialMedia",
  },
  {
    title: "Web",
    value: "web",
  },
  {
    title: "Printed",
    value: "printed",
  },
];

export const categories = [
  {
    title: "Pre-Launch",
    value: "preLaunch",
    tab: "marketingMaterial",
    subCategory: "PRE_LAUNCH",
  },
  {
    title: "Launch/Now Available",
    value: "launch",
    tab: "marketingMaterial",
    subCategory: "LAUNCH",
  },
  {
    title: "January",
    value: "jan",
    tab: "marketingMaterial",
    subCategory: "JANUARY",
  },
  {
    title: "February",
    value: "feb",
    tab: "marketingMaterial",
    subCategory: "FEBRUARY",
  },
  {
    title: "March",
    value: "march",
    tab: "marketingMaterial",
    subCategory: "MARCH",
  },
  {
    title: "April",
    value: "april",
    tab: "marketingMaterial",
    subCategory: "APRIL",
  },
  {
    title: "May",
    value: "may",
    tab: "marketingMaterial",
    subCategory: "MAY",
  },
  {
    title: "June",
    value: "june",
    tab: "marketingMaterial",
    subCategory: "JUNE",
  },
  {
    title: "July",
    value: "july",
    tab: "marketingMaterial",
    subCategory: "JULY",
  },
  {
    title: "August",
    value: "aug",
    tab: "marketingMaterial",
    subCategory: "AUGUST",
  },
  {
    title: "September",
    value: "sep",
    tab: "marketingMaterial",
    subCategory: "SEPTEMBER",
  },
  {
    title: "October",
    value: "oct",
    tab: "marketingMaterial",
    subCategory: "OCTOBER",
  },
  {
    title: "November",
    value: "nov",
    tab: "marketingMaterial",
    subCategory: "NOVEMBER",
  },
  {
    title: "December",
    value: "dec",
    tab: "marketingMaterial",
    subCategory: "DECEMBER",
  },

  {
    title: "Pre-Launch",
    value: "preLaunch",
    tab: "socialMedia",
    subCategory: "PRE_LAUNCH",
  },
  {
    title: "Launch/Now Available",
    value: "launch",
    tab: "socialMedia",
    subCategory: "LAUNCH",
  },
  {
    title: "January",
    value: "jan",
    tab: "socialMedia",
    subCategory: "JANUARY",
  },
  {
    title: "February",
    value: "feb",
    tab: "socialMedia",
    subCategory: "FEBRUARY",
  },
  {
    title: "March",
    value: "march",
    tab: "socialMedia",
    subCategory: "MARCH",
  },
  {
    title: "April",
    value: "april",
    tab: "socialMedia",
    subCategory: "APRIL",
  },
  {
    title: "May",
    value: "may",
    tab: "socialMedia",
    subCategory: "MAY",
  },
  {
    title: "June",
    value: "june",
    tab: "socialMedia",
    subCategory: "JUNE",
  },
  {
    title: "July",
    value: "july",
    tab: "socialMedia",
    subCategory: "JULY",
  },
  {
    title: "August",
    value: "aug",
    tab: "socialMedia",
    subCategory: "AUGUST",
  },
  {
    title: "September",
    value: "sep",
    tab: "socialMedia",
    subCategory: "SEPTEMBER",
  },
  {
    title: "October",
    value: "oct",
    tab: "socialMedia",
    subCategory: "OCTOBER",
  },
  {
    title: "November",
    value: "nov",
    tab: "socialMedia",
    subCategory: "NOVEMBER",
  },
  {
    title: "December",
    value: "dec",
    tab: "socialMedia",
    subCategory: "DECEMBER",
  },

  {
    title: "Website Banner",
    value: "websiteBanner",
    tab: "web",
    subCategory: "WEBSITE_BANNER",
  },
  {
    title: "Amenities Modules",
    value: "amenitiesModules",
    tab: "web",
    subCategory: "AMENITIES_MODULES",
  },
  {
    title: "Table Top Sign",
    value: "tableTopsign",
    tab: "printed",
    subCategory: "TABLE_TOP_SIGN",
  },
  {
    title: "Community Sign",
    value: "communitySign",
    tab: "printed",
    subCategory: "COMMUNITY_SIGN",
  },
  {
    title: "Move In Flier",
    value: "moveInflier",
    tab: "printed",
    subCategory: "MOVE_IN_FLIER",
  },
];

export const territory_tabs = [
  "Pending Requests",
  "Pickups & Deliveries",
  "QR Code Generator",
  "Storage Facilities",
  "Stored Items",
  "Drivers",
  "Orders",
  "Refund Orders",
];

export const STOREDITEMS_HEADINGS = [
  {
    Name: "Customer Name",
    key: "name",
    style: { width: "20%" },
  },
  {
    Name: "Customer Address",
    key: "address",
    style: { width: "20%" },
  },
  {
    Name: "Storage Facility",
    key: "facilityName",
    style: { width: "20%" },
  },
  {
    Name: "Inventory QR codes",
    key: "qrCodes",
    style: { width: "40%" },
  },
];

export const COUPONCODES_HEADING = [
  {
    Name: "Discount Code",
    key: "code",
  },
  {
    Name: "Discount Percentage",
    key: "percentage",
  },
  {
    Name: "Start Date",
    key: "startDate",
  },
  {
    Name: "Expiry Date",
    key: "expiryDate",
  },
  {
    Name: "No Of Users Claimed",
    key: "noOfClaims",
  },
  {
    Name: "Discount Amount",
    key: "discountAmount",
  },
  {
    Name: "Status",
    key: "couponCodeStatus",
  },
];

export const NOTIFICATION_HEADING = [
  {
    Name: "Date",
    key: "date",
    style: { width: "15%" },
    },
    {
      Name: "Title",
      key: "title",
      style: { width: "25%" },
    },
    {
      Name: "Message",
      key: "message",
      style: { width: "45%" },
    },
    {
      Name: "Details",
      key: "details",
      style: { width: "10%" },
    }
]

export const STOREDITEMS_DATA = [
  {
    _id: "123123",
    name: "Chris Brown",
    address: "Ms Alice Smith Apartment 213 Derrick Street Boston, MA 02130",
    facilityName: "Storage-1",
    qrCodes: ["123123", "345678"],
    binName: "Table-1",
    coordinates: "A1-B4",
    dropoffDriver: "suresh",
  },
  {
    _id: "234233",
    name: "Micheal Smith",
    address: "Ms Alice Smith Apartment 213 Derrick Street Boston, MA 02130",
    facilityName: "Storage-1",
    qrCodes: ["234322", "34534534", "3453454"],
    binName: "Table-2",
    coordinates: "A1-B4",
    dropoffDriver: "suresh",
  },
  {
    _id: "456763",
    name: "John Smith",
    address: "Ms Alice Smith Apartment 213 Derrick Street Boston, MA 02130",
    facilityName: "Storage-1",
    qrCodes: ["345345"],
    binName: "Table-3",
    coordinates: "A1-B4",
    dropoffDriver: "suresh",
  },
];

export const STOREDITEMS_MODAL_DATA = [
  {
    Name: "Facility Name",
    key: "facilityName",
  },
  {
    Name: "Bin Name",
    key: "binName",
  },
  {
    Name: "Co-ordinates",
    key: "coordinates",
  },

  {
    Name: "Dropoff Driver",
    key: "dropoffDriver",
  },
];
export const STORAGE_FACILITY_HEADINGS = [
  {
    Name: "Facility Name",
    key: "facilityName",
    style: { width: "20%" },
  },
  {
    Name: "Contact No.",
    key: "contactNumber",
    style: { width: "20%" },
  },
  {
    Name: "Available Capacity",
    key: "availableCapacity",
    style: { width: "20%" },
  },
  {
    Name: "Total Capacity",
    key: "totalCapacity",
    style: { width: "20%" },
  },

  {
    Name: "Address",
    key: "address",
  },
];
export const STORAGE_FACILITY_DATA = [
  {
    _id: "1",
    name: "Storage Facility",
    contactNumber: "111-111-1111",
    availableCapacity: "200 cu ft/500 cu ft",
    roomSize: "Size 2",
    address: "Ms Alice Smith Apartment 213 Derrick Street Boston, MA 02130",
  },
  {
    _id: "2",
    name: "Storage Facility",
    contactNumber: "111-111-1111",
    availableCapacity: "200 cu ft/500 cu ft",
    roomSize: "Size 2",
    address: "Ms Alice Smith Apartment 213 Derrick Street Boston, MA 02130",
  },
  {
    _id: "3",
    name: "Storage Facility",
    contactNumber: "111-111-1111",
    availableCapacity: "200 cu ft/500 cu ft",
    roomSize: "Size 2",
    address: "Ms Alice Smith Apartment 213 Derrick Street Boston, MA 02130",
  },
];
export const DELIVERY_HEADINGS = [
  {
    Name: "Delivery ID",
    key: "_id",
  },
  {
    Name: "Driver Name",
    key: "name",
  },
  {
    Name: "Client Name",
    key: "clientName",
  },
  {
    Name: "Date & Time",
    key: "dateTime",
  },
  {
    Name: "Vehicle Type",
    key: "vehicleType",
  },
  {
    Name: "Pickup Address",
    key: "pickupAddress",
  },
  {
    Name: "Status",
    key: "Status",
  },
];

export const PICKUP_HEADINGS = [
  {
    Name: "Driver Name",
    key: "driverName",
    style: { width: "16%" },
  },
  {
    Name: "Client Name",
    key: "clientName",
    style: { width: "12%" },
  },
  {
    Name: "Date & Time",
    key: "dateTime",
    style: { width: "20%" },
  },

  {
    Name: "Job Type",
    key: "appointmentType",
    style: { width: "16%" },
  },
  {
    Name: "Pickup Address",
    key: "pickupAddress",
    style: { width: "18%" },
  },
  {
    Name: "Delivery Address",
    key: "deliveryAddress",
    style: { width: "28%" },
  },
  {
    Name: "Order ID",
    key: "orderId",
  },
  {
    Name: "Vehicle Type",
    key: "vehicleType",
    style: { width: "22%" },
  },
  {
    Name: "Status",
    key: "Status",
  },

  {
    Name: "",
    key: "driverJob",
    style: { width: "4%" },
  },
];

export const PENDING_HEADINGS = [
  {
    Name: "Client Name",
    key: "clientName",
    style: { width: "16%" },
  },
  {
    Name: "Date & Time",
    key: "dateTime",
    style: { width: "16%" },
  },
  {
    Name: "Delivery Type",
    key: "deliveryType",
    style: { width: "16%" },
  },
  {
    Name: "Customer Address",
    key: "CustomerAddress",
    style: { width: "26%" },
  },
  {
    Name: "Order ID",
    key: "orderId",
  },
  {
    Name: "Appointment ID",
    key: "appointmentID",
  },
  {
    Name: "",
    key: "Status",
    style: { width: "12%" },
  },
];

export const ORDERS_HEADINGS = [
  {
    Name: "Order ID",
    key: "orderId",
    style: { width: "20%" },
  },
  {
    Name: "Customer Name",
    key: "clientName",
    style: { width: "20%" },
  },
  {
    Name: "No. Of Bins",
    key: "noOfBins",
    style: { width: "20%" },
  },
  {
    Name: "No. Of Non Bins",
    key: "noOfNonBins",
    style: { width: "20%" },
  },
  {
    Name: "Selected Plan/month",
    key: "selectedPlan",
    style: { width: "20%" },
  },
];

export const REFUND_ORDERS_HEADINGS = [
  {
    Name: "Order ID",
    key: "orderId",
    style: { width: "20%" },
  },
  {
    Name: "Customer Name",
    key: "clientName",
    style: { width: "20%" },
  },
  {
    Name: "Refund Amount/Includes tax",
    key: "refundAmount",
    style: { width: "20%" },
  },
  {
    Name: "Status",
    key: "status",
    style: { width: "10%" },
  },
];

export const PENDING_HEADINGS_PICKUP_DATA = [
  {
    Name: "Client Name :",
    key: "clientName",
  },
  {
    Name: "Delivery Type :",
    key: "deliveryType",
  },
  {
    Name: "Pickup bin Items :",
    key: "pickupBinItems",
  },
  {
    Name: "Pickup non bin Items :",
    key: "pickupNonbinItems",
  },
  {
    Name: "Pickup Address :",
    key: "pickupAddress",
  },
  {
    Name: "Dropoff Address :",
    key: "dropoffAddress",
  },
  {
    Name: "Coordinates:",
    key: "coordinates",
  },
  {
    Name: "Date & Time :",
    key: "dateTime",
  },
  {
    Name: "Available Drivers :",
    key: "availableDrivers",
  },
  {
    Name: "Assigned Driver :",
    key: "assignedDriver",
  },
];

export const PENDING_HEADINGS_DROPOFF_DATA = [
  {
    Name: "Client Name :",
    key: "clientName",
  },
  {
    Name: "Delivery Type :",
    key: "deliveryType",
  },
  {
    Name: "Drop-off bin Items :",
    key: "dropoffBinItems",
  },
  {
    Name: "Drop-off non bin Items :",
    key: "dropoffNonbinItems",
  },
  {
    Name: "Pickup Address :",
    key: "pickupAddress",
  },
  {
    Name: "Dropoff Address :",
    key: "dropoffAddress",
  },
  {
    Name: "Coordinates:",
    key: "coordinates",
  },
  {
    Name: "Date & Time :",
    key: "dateTime",
  },
  {
    Name: "Available Drivers :",
    key: "availableDrivers",
  },
  {
    Name: "Assigned Driver :",
    key: "assignedDriver",
  },
];

export const driversHeading = [
  {
    Name: "Driver Name",
    key: "driverName",
    style: { width: "20%" },
  },
  {
    Name: "Contact No.",
    key: "contactNumber",
    style: { width: "20%" },
  },
  {
    Name: "Vehicle Availability",
    key: "vehicleAvailability",
    style: { width: "10%" },
  },
  {
    Name: "Vehicle Type",
    key: "vehicleType",
    style: { width: "10%" },
  },

  {
    Name: "Available Dates",
    key: "availableDates",
    style: { width: "23%" },
  },
  {
    Name: "Driver Address",
    key: "driverAddress",
    style: { width: "30%" },
  },
  {
    Name: "Payments",
    key: "payment",
    style: { width: "20%" },
  },
  {
    Name: "Status",
    key: "driverStatus",
    style: { width: "2%" },
  },
];
export const deliverTypes = {
  pickupCustomer: "Pickup from customer",
  dropoffCustomer: "Drop-off at customer",
  dropoffEmptyBins: "Drop-off empty bins at customer",
  initialpickupCustomer: "Initial pickup from customer",
  intialDropoff: "INITIAL_DROPOFF_AT_USER",
  intialPickupoff: "INITIAL_PICKUP_FROM_USER",
  PickupFromuser: "PICKUP_FROM_USER",
  DropoffUser: "DROPOFF_AT_USER",
};

const CURRENT_HOLDER = {
  user: "USER",
  storageFacility: "STORAGE_FACILITY",
};

export const JOB_TYPES = {
  INITIAL_PICKUP_FROM_USER: {
    title: "Initial pickup from customer",
    currentHolder: CURRENT_HOLDER.storageFacility,
  },
  PICKUP_FROM_USER: {
    title: "Pickup from customer",
    currentHolder: CURRENT_HOLDER.storageFacility,
  },
  DROPOFF_AT_USER: {
    title: "Dropoff at customer",
    currentHolder: CURRENT_HOLDER.user,
  },
  INITIAL_DROPOFF_AT_USER: {
    title: "Dropoff empty bins at customer",
    currentHolder: CURRENT_HOLDER.user,
  },
};

export const JOB_STATUS_TYPES = {
  DRIVER_ASSIGNED: "Scheduled",
  JOB_STARTED: "Job Started",
  NOTIFY_USER: "Enroute",
  LOCATION_REACHED: "Location reached",
  SMS_OR_CALL_USER: "SMS/call user",
  SCANNING_COMPLETED: "Scanning completed",
  PICKUP_COMPLETED: "Pickup completed",
  JOB_COMPLETED: "Delivered",
};

export const ADD_DRIVER = [
  {
    name: "",
    minLength: 3,
    maxLength: 25,
    title: "Name",
    type: "text",
    key: "name",
    hasError: false,
    value: "",
  },
  {
    email: "",
    minLength: 2,
    maxLength: 64,
    title: "Email Address",
    type: "email",
    key: "email",
    hasError: false,
    value: "",
  },
  {
    value: "",
    minLength: 14,
    maxLength: 14,
    title: "Phone Number",
    type: "numeric",
    key: "phoneNumber",
    hasError: false,
    hasTag: true,
  },
  {
    password: "",
    maxLength: 6,
    minLength: 6,
    title: "Password",
    type: "password",
    reg: "^[0-9]+$",
    key: "password",
    hasError: false,
  },
];

export const ADD_DRIVER_ADDRESS = [
  {
    address: "",
    minLength: 3,
    maxLength: 100,
    title: "Address",
    type: "text",
    key: "address",
    hasError: false,
    value: "",
  },
  {
    streetname: "",
    minLength: 2,
    maxLength: 50,
    title: "Street Number or Name",
    type: "text",
    key: "area",
    hasError: false,
    value: "",
  },
  {
    city: "",
    value: "",
    minLength: 2,
    maxLength: 14,
    title: "City",
    type: "text",
    key: "city",
    hasError: false,
    hasTag: true,
  },
  {
    state: "",
    maxLength: 14,
    minLength: 2,
    title: "State",
    type: "text",
    key: "state",
    hasError: false,
  },
  {
    zipcode: "",
    maxLength: 5,
    minLength: 5,
    title: "Zipcode",
    type: "numeric",
    key: "zipCode",
    hasError: false,
  },
];

export const codeDataItem = {
  title: "code",
  key: "code",
  value: "",
  hasError: false,
  minLength: 6,
  maxLength: 6,
};

export const QR_CODE = {
  title: "Enter No of QR Codes",
  key: "qrCode",
  type: "number",
  value: "",
  hasError: false,
  minLength: 1,
  maxLength: 3,
  minValue: 1,
};

export const TRANSACTION = {
  key: "transactionId",
  value: "",
  hasError: false,
  maxLength: 30,
  minValue: 1,
};

export const PAYMENT_TITLES = [
  {
    key: "paymentOption",
    title: "Payment Mode:",
  },
  {
    key: "email",
    title: "Email Address:",
  },
  {
    key: "phone",
    title: "Contact Number:",
  },
  {
    key: "firstPayment",
    title: "First Time Payment:",
  },
  {
    key: "totalAmount",
    title: "Amount:",
  },
];

export const APP_VERSION = {
  title: "Enter version",
  key: "appVersion",
  type: "numeric",
  value: "",
  hasError: false,
  minLength: 1,
  maxLength: 8,
};

export const STORAGE_FACILITY_MODAL_DATA = [
  {
    title: "Storage Facility Name",
    key: "facilityName",
    type: "text",
    value: "",
    minLength: 3,
    maxLength: 50,
    hasError: false,
  },
  {
    title: "Contact Number",
    key: "contactNumber",
    value: "",
    type: "numeric",
    minLength: 14,
    maxLength: 14,
    hasError: false,
  },
  {
    title: "Price Per cu.ft($)",
    key: "pricePerCuft",
    value: "",
    type: "number",
    minLength: 1,
    maxLength: 14,
    minValue: 0,
    hasError: false,
  },
  {
    title: "Address line 1",
    key: "address",
    type: "text",
    value: "",
    minLength: 3,
    maxLength: 25,
    hasError: false,
  },

  {
    title: "City",
    key: "city",
    type: "text",
    value: "",
    minLength: 3,
    maxLength: 25,
    hasError: false,
  },
  {
    title: "State",
    key: "state",
    type: "text",
    value: "",
    minLength: 3,
    maxLength: 25,
    hasError: false,
  },
  {
    title: "Zipcode",
    key: "zipcode",
    type: "text",
    value: "",
    minLength: 3,
    maxLength: 25,
    hasError: false,
  },
];

export const ROOM_HEADINGS = [
  {
    title: "Room Label",
    key: "roomLabel",
    type: "text",
    hasError: false,
    minLength: 3,
    maxLength: 25,
    value: "",
  },
  {
    title: "Rows",
    key: "rows",
    type: "number",
    hasError: false,
    minLength: 3,
    maxLength: 4,
    value: "1",
    minValue: 1,
  },
  {
    title: "Columns",
    key: "columns",
    type: "number",
    hasError: false,
    minLength: 1,
    maxLength: 4,
    value: "1",
    minValue: 1,
  },
];
export const AVAILABLE_ROOMS = [
  {
    Name: "Room Label",
    key: "roomLabel",
  },
  {
    Name: "Rows",
    key: "rows",
  },
  {
    Name: "Columns",
    key: "columns",
  },
];

export const COLORS = [
  "#72E6DA",
  "#838EDE",
  "#E09DBA",
  "#B03060",
  "#FFFF88",
  "#FE9469",
  "#78CA20",
  "#008B8B",
  "#33A1DE",
  "#B26AF3",
];

export const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const COUNTRY_CODE = "+1";

export const numRegex = "^[0-9]+$";

export const alphanumericRegex = "^[a-zA-Z0-9]+$";

export const alphabetAndNumber = /[^0-9a-zA-Z]/g;

export const onlyDigits = /\D/g;

export const alphabets = /[^a-zA-Z\s]/g;

export const apartmentNumberFormat = /[^0-9a-zA-Z\s-]/g;

export const excludingSpaces = "/s/g";

export const driversStatus = {
  confirmed: "CONFIRMED",
  pending: "PENDING",
  requested: "REQUESTED",
};

export const paymentStatus = {
  paid: "PAID",
  pending: "PENDING",
  requested: "REQUESTED",
};

const weekdays = moment.weekdays();

export const FILE_NAME = {
  title: "File Name",
  type: "text",
  key: "name",
  value: "",
  minLength: 3,
  maxLength: 25,
  hasError: false,
  index: "",
};

export const SLOTS_DATA = {
  days: weekdays.map((day, ind) => {
    const date = new Date(moment().add(ind, "days")).setHours(0, 0, 0, 0);
    const currentTimeZoneWeekday = new Date(date).getDay();
    const utcTimeZoneWeekday = new Date(date).getUTCDay();
    return {
      title: weekdays[currentTimeZoneWeekday],
      isSelected: false,
      bookMarked: false,
      utcDay: utcTimeZoneWeekday,
      _id: currentTimeZoneWeekday,
    };
  }),
  slots: [
    {
      title: "Morning (8am-12pm)",
      startTime: 8,
      endTime: 12,
      _id: "slot1",
    },
    {
      title: "Afternoon (12pm-4pm)",
      startTime: 12,
      endTime: 16,
      _id: "slot2",
    },
    {
      title: "Evening (4pm-8pm)",
      startTime: 16,
      endTime: 20,
      _id: "slot3",
    },
  ].map((slot) => {
    return { ...slot, isSelected: false, bookMarked: false };
  }),
};
