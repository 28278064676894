import React, { useRef, useEffect, useState } from 'react';

import './MarketingMaterials.scss'


function PDFViewer({ pdfUrl }) {
    const iframeRef = useRef(null);
 

    useEffect(() => {
        const iframe = iframeRef.current;
        iframe.src = `https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`;
    }, [pdfUrl]);

    return (
        <div>
            <iframe
                title = 'pdfViewer'
                ref={iframeRef}
                className='pdfViewer'
            />
        </div>
    );
}

export default PDFViewer;