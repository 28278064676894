import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ConfirmModal = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleCancel}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth={"md"}
      sx={{ "& .MuiPaper-root": { padding: "0.75vw", borderRadius: "0.5vw" } }}
    >
      <DialogTitle id='alert-dialog-title' sx={{ fontSize: "2vw" }}>
        {props.title}
        {props.handleClipboard && (
          <>
            {props.copied ? (
              <CheckCircleIcon style={{ color: "#007dae", width: "2vw" }} />
            ) : (
              <ContentCopyIcon
                onClick={() => props.handleClipboard()}
                style={{ cursor: "pointer", width: "2vw" }}
              />
            )}
          </>
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id='alert-dialog-description'
          sx={{ fontSize: "1.5vw" }}
          style={{whiteSpace: 'pre-line'}}
        >
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: "0.5vw" }}>
        <Button
          onClick={props.handleCancel}
          autoFocus={props.isAlert}
          sx={{ fontSize: "1.25vw", marginRight: "1.5vw" }}
        >
          {props.cancelText}
        </Button>
        {props.isAlert ? null : (
          <Button
            onClick={props.handleConfirm}
            autoFocus
            sx={{ fontSize: "1.25vw" }}
          >
            {props.confirmText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.defaultProps = {
  isOpen: true,
  title: "",
  message: "",
  cancelText: "Cancel",
  confirmText: "Ok",
  handleCancel: () => {},
  handleConfirm: () => {},
  isAlert: false,
};

export default ConfirmModal;
