import { Modal, Box, TextField, IconButton } from "@mui/material";
import React, { useState } from "react";
import { BOX_STYLE } from "../../../components/Constants";
import CustomButton from "../../../components/custom-button/CustomButton";
import CustomInput from "../../../components/custom-input/CustomInput";
import { ROOM_HEADINGS, STORAGE_FACILITY_MODAL_DATA } from "../../Constants";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress } from "react-google-places-autocomplete";
import AddRooms from "./addRoomsModal";

const StorageModal = (props) => {
  const {
    open,
    handleRemoveIcon,
    isAdd,
    facilityModaldata,
    showRoomModal,
    handleAddStorageFacility,
    setgoogleAddress,
    setAddress,
    roomData,
    setRoomData,
    apiKey,
    setisDisable,
    isDisable,
    addRoomData,
    setAddRoomData,
    handleAddRooms,
  } = props;

  const [addressValue, setaddressValue] = useState("");

  const displayingmodalRemoveIcon = () => {
    return (
      <div
        className='storageModalRemoveIcon'
        onClick={() => handleRemoveIcon()}
      >
        X
      </div>
    );
  };

  const handleSelect = (gAddress) => {
    const address = gAddress.label;
    geocodeByAddress(address)
      .then((results) => {
        const googleAddress = (results[0].address_components || [])
          .map((x) => x)
          .filter((data) =>
            ["administrative_area_level_1", "locality", "postal_code"].includes(
              data.types[0]
            )
          );
        const dta = {};
        googleAddress?.map((data) => {
          if (data.types[0] === "administrative_area_level_1") {
            dta.state = data.long_name;
          } else if (data.types[0] === "locality") {
            dta.city = data.long_name;
          } else if (data.types[0] === "postal_code") {
            dta.zipcode = data.long_name;
          }
        });
        setaddressValue(address);
        setgoogleAddress({ ...dta });
      })
      .catch((error) => console.error("Error", error));
  };
  const handleTextChange = ({ key, value }) => {
    if (isAdd) {
      props.handleChange({ key, value });
      STORAGE_FACILITY_MODAL_DATA.map((data) => data);
    } else {
      props.handleChange({ key, value });
    }
  };

  const displaySubmitHandle = () => {
    let disable = false;
    const dataObject = isAdd
      ? props.facilityModaldata
      : props.facilityModaldata.filter((data) => {
          if (
            ["facilityName", "contactNumber", "pricePerCuft"].includes(data.key)
          ) {
            return data;
          }
        });
    disable = Object.values(dataObject).some(
      (x) => x?.hasError || (x?.value || "")?.length < x?.minLength
    );
    return (
      <div className={"addfacilityButton"}>
        <CustomButton
          title={`${
            isAdd ? "Add Storage Facility" : "Update Storage Facility"
          }`}
          additionalSx={{
            padding: "0.5vw 8vw",
            fontWeight: 600,
            fontSize: "1.5vw",
          }}
          handleButtonClick={() => handleAddStorageFacility()}
          disabled={disable}
        />
      </div>
    );
  };
  /**@description Displaying Form Functionality
   */
  const displayFormFields = () => (
    <>
      <div className='modalHeading'>{` ${
        isAdd ? "Add New" : "Update"
      } Storage Facility`}</div>
      <div className='uploadRow'>
        {facilityModaldata?.map((dataItem) => {
          const key = dataItem.key;
          let isDisable = false;
          return (
            <div
              className={"addFacilityInput"}
              key={`displayFormFields${dataItem.title}`}
            >
              {(isAdd
                ? [
                    "facilityName",
                    "contactNumber",
                    "address",
                    "pricePerCuft",
                    "city",
                    "state",
                    "zipcode",
                  ]
                : ["facilityName", "contactNumber", "pricePerCuft"]
              ).includes(dataItem.key) ? (
                <>
                  {dataItem.key === "address" && isAdd ? (
                    <GooglePlacesAutocomplete
                      apiKey={apiKey}
                      placeholder='Enter your address'
                      selectProps={{
                        addressValue,
                        onChange: handleSelect,
                        placeholder: "Enter Address",

                        styles: {
                          input: (provided) => ({
                            ...provided,
                            color: "#000",
                          }),
                          option: (provided) => ({
                            ...provided,
                            color: "#000",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: "#000",
                          }),
                        },
                      }}
                    />
                  ) : (
                    <CustomInput
                      error
                      variant='standard'
                      id='standard-error-helper-text'
                      dataItem={{ ...dataItem }}
                      additionalSx={{
                        width: "100%",
                        margin: 0,
                        ".css-1zgay8-MuiInputBase-root-MuiInput-root": {
                          marginTop: "0",
                        },
                        "& .MuiInputBase-input": {
                          paddingTop: "1vh",
                        },
                        "& .MuiFormHelperText-root": {
                          position: "relative",
                        },
                      }}
                      handleInput={(data) => {
                        handleTextChange(data);
                      }}
                      required={true}
                      disabled={isDisable}
                    />
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
      {displaySubmitHandle()}
    </>
  );

  const displayRoomInput = ({ roomInfo, index, isAdd = false }) => {
    return (
      <div className='roomStyle'>
        {ROOM_HEADINGS.map((data) => {
          return (
            <div className='room'>
              <TextField
                id='demo-helper-text-misaligned'
                label={data.title}
                value={roomInfo[data.key] || ""}
                onChange={(e) => {
                  if (isAdd) {
                    addRoomData[data.key] = e.target.value;
                    setAddRoomData({ ...addRoomData });
                    if (roomInfo?.rows && roomInfo?.room && roomInfo?.columns) {
                      setisDisable(false);
                    }
                  } else {
                    roomData[index][data.key] = e.target.value;
                    setRoomData([...roomData]);
                  }
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    paddingTop: "0.4vw",
                    paddingBottom: "0.4vw",
                  },
                  "& .MuiOutlinedInput-root": {
                    padding: "0.2vw 0.2vh",
                  },
                }}
              />
            </div>
          );
        })}
        {isAdd ? (
          <IconButton
            aria-label='upload picture'
            component='span'
            className='addroombutton'
            style={{ backgroundColor: "#198eba" }}
            onClick={() => {
              setRoomData([...roomData, addRoomData]);
              setAddRoomData({});
              setisDisable(true);
            }}
            disabled={isDisable}
          >
            <img src={"/images/shared/plus.svg"} alt='plusIcon' />
          </IconButton>
        ) : (
          <IconButton
            aria-label='upload picture'
            component='span'
            className='addroombutton'
            style={{ backgroundColor: "red" }}
            onClick={() => {
              roomData.splice(index, 1);
              setRoomData([...roomData]);
              setisDisable(true);
            }}
          >
            <img
              src={"/images/shared/deletewhite.svg"}
              alt=''
              style={{ width: "20px", height: "20px" }}
            />
          </IconButton>
        )}
      </div>
    );
  };
  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div>
        <Box sx={BOX_STYLE}>{displayFormFields()}</Box>
        {displayingmodalRemoveIcon()}
        {showRoomModal && (
          <AddRooms
            open={true}
            roomData={roomData}
            addRoomData={addRoomData}
            setAddRoomData={setAddRoomData}
            setisDisable={setisDisable}
            isDisable={isDisable}
            isAddMore={false}
            handleRemoveIcon={handleRemoveIcon}
            handleAddRooms={handleAddRooms}
          />
        )}
      </div>
    </Modal>
  );
};
export default StorageModal;
