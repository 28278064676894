const DEV_BASE_URL = "https://dev-api.valetcloset.com";
const STAGE_BASE_URL = "https://stg-api-v10.valetcloset.com";
const PROD_BASE_URL = "https://api-v10.valetcloset.com";

const config = {
  RestServiceURL: "https://api-v10.valetcloset.com"
};

export default config;
export { default as Roles } from "./Roles";
export { default as PrivateRoutesConfig } from "./PrivateRoutesConfig";
