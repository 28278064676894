import React, { useState, useEffect } from "react";
import Loader from "../../../components/loader/Loader";
import { getAllStoredItems } from "../../../redux/actions/territory-manager-actions/territoryManager";
import { STOREDITEMS_HEADINGS } from "../../Constants";
import TerritoryTable from "../territoryTable";
import "./StoredItems.scss";
import StoredItemsModal from "./storedItemsModal";

const StoredItems = () => {
  const [itemsHeading, setitemsHeading] = useState([...STOREDITEMS_HEADINGS]);
  const [tableData, settableData] = useState([]);
  const [modalData, setModaldata] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showLoader, setshowLoader] = useState(false);

  useEffect(() => {
    getStoredItemsFunction();
  }, []);

  const getStoredItemsFunction = async () => {
    setshowLoader(true);
    let { data } = await getAllStoredItems();
    setshowLoader(false);
    let userIds = data.map((userBin) => userBin.user?._id);
    userIds = [...new Set(userIds)];
    const storedItemsData = userIds.map((id) => ({
      user: data.find((userBin) => userBin?.user?._id.toString() === id).user,
      userBins: data.filter((userBin) => userBin?.user?._id.toString() === id),
    }));

    const tableData = storedItemsData.map((data) => {
      const state = data?.user?.physicalAddress?.state;
      const city = data?.user?.physicalAddress?.city;
      const zipcode = data?.user?.physicalAddress?.zipCode;
      const area = data?.user?.physicalAddress?.area;
      const floor = data?.user?.physicalAddress?.floor;
      const gateCode = data?.user?.physicalAddress?.gateCode;
      const apartment = data?.user?.physicalAddress?.apartment;
      const customer_address = `${area ? `${area},` : ""} ${`${
        city ? city : ""
      } ${state ? `${state},` : ""} ${zipcode ? `${zipcode};` : ""}`} ${
        apartment ? `Unit ${apartment};` : ""
      }  ${floor ? `Floor ${floor};` : ""}  ${
        gateCode ? `Gate ${gateCode}#` : ""
      }`;
      const userBins = data?.userBins;
      const coordinates = [];
      userBins?.forEach((bin) => {
          if (bin.order?.spaceLocation?.coordinates) {
            coordinates.push(bin.order?.spaceLocation?.coordinates);
          }
      });
      const driverName = [];
      userBins?.forEach((bin) => {
        if (bin.appointment?.driver?.name.length) {
          driverName.push(bin.appointment.driver.name);
        }
      });
      const binNames = [];
      (userBins || []).filter((bin) => {
        if (bin.tags.length) {
          (binNames || []).push(bin?.tags);
        }
      });
      const qrCodes = userBins?.map((bin) => bin.qrCode);
      const facilityNames = userBins?.map(
        (bin) => bin?.storageFacility?.facilityName
      );
      return {
        _id: data.user?._id,
        name: data.user?.name,
        address: customer_address,
        facilityName: facilityNames.filter(
          (item, index) => facilityNames.indexOf(item) === index
        ),
        qrCodes: qrCodes
          .filter((item, index) => qrCodes.indexOf(item) === index)
          .join(", "),
        binName: binNames.join(", "),
        coordinates: coordinates
          .filter((item, index) => coordinates.indexOf(item) === index)
          .join(", "),
        dropoffDriver: driverName
          .filter((item, index) => driverName.indexOf(item) === index)
          .join(", "),
      };
    });
    settableData(tableData);
  };

  const filterTabledata = (id) => {
    setShowModal(true);
    tableData.map((data) => {
      if (data._id === id) {
        setModaldata({
          facilityName: data?.facilityName,
          binName: data?.binName,
          coordinates: data?.coordinates,
          dropoffDriver: data?.dropoffDriver,
        });
      }
    });
  };
  const displayingItemsTable = () => {
    return (
      <TerritoryTable
        tableHeading={[...itemsHeading]}
        rows={[...tableData]}
        tablecellpadding={"10.6vw"}
        maxHeight={"40vh"}
        minHeight={"20vh"}
        hideCheckbox={true}
        handleClick={(id) => filterTabledata(id)}
      />
    );
  };
  return (
    <div className='storedItemsOverview'>
      {displayingItemsTable()}
      {showModal ? (
        <StoredItemsModal
          modalData={modalData}
          open={true}
          isAdd={true}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      ) : null}
      <Loader show={showLoader} />
    </div>
  );
};

export default StoredItems;
