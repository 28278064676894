import React, { useEffect } from "react";
import CustomInput from "../custom-input/CustomInput";
import CustomButtom from "../custom-button/CustomButton";
import { BOX_STYLE } from "../Constants";
import "./AddManager.scss";
import { useDispatch } from "react-redux";
import { getManagers } from "../../redux/actions/manager-actions/managerActions";
import { getFormattedPhoneNum, validations } from "../../utils/helperMethods";
import CustomModal from "../custom-modal/CustomModal";

const AddManager = (props) => {
  const dispatch = useDispatch();

  const { isAdd, managerDetails, handleChange, handleSubmit, managerData } =
    props;

  useEffect(() => {
    dispatch(getManagers());
  }, [dispatch]);

  /**@description Handle add Manager component Functionality
   */
  const handleTextChange = ({ key, value, regexType }) => {
    const item = validations(managerDetails, key, value, regexType);
    handleChange({ key: item.key, value:item.value });
  };

  /**@description displaying add Manager Header Functionality
   */
  const displayHeader = () => (
    <div className={"managerHeaderRow"}>
      <div className={"addManager"}>
        {isAdd ? "Add" : "Edit"} Manager Details
      </div>
    </div>
  );

  /**@description displaying Form Functionality
   */
  const displayFormFields = () => (
    <form onSubmit={handleSubmit}>
      <div className="manageruploadRow">
        {managerDetails.map((dataItem) => {
          const key = dataItem.key;

          let isDisable = false;
          const value =
            key === "phoneNumber"
              ? getFormattedPhoneNum(managerData?.[key])
              : managerData?.[key] || "";
          isDisable = key === "email" && managerData._id;
          return (
            <div
              className={"addManagerInput"}
              key={`displayFormFields${dataItem.title}`}
            >
              <CustomInput
                dataItem={{ ...dataItem, value }}
                additionalSx={{ width: "100%", margin: 0 }}
                handleInput={(data) => {
                  handleTextChange(data, dataItem.isManager);
                }}
                required={true}
                disabled={isDisable}
              />
            </div>
          );
        })}
      </div>

      <div className={"addManagerButton"}>
        <CustomButtom
          title={`${isAdd ? "Add Manager Details" : "Update Manager Details"}`}
          additionalSx={{
            padding: "1vw 8vw",
            fontWeight: 600,
            fontSize: "1.5vw",
          }}
        />
      </div>
    </form>
  );

  const displayModalContent = () => {
    return (
      <>
        {displayHeader()}
        {displayFormFields()}
      </>
    );
  };
  return (
    <CustomModal
      isOpen={props.isOpen}
      handleRemoveIcon={() => props.handleClose()}
      sx={BOX_STYLE}
      content={displayModalContent}
      closeButtonStyle="removeIcon"
    />
  );
};

AddManager.defaultProps = {
  isOpen: true,
  isAdd: true,
  handleClose: () => {},
  handleChange: () => {},
  handleSubmit: () => {},
};

export default AddManager;
