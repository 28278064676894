/**@description displays property view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  userDetails: [],
  login: null,
  errorMessage: "",
};

/**@description USER Login Reducer  Function */
const userLoginReducer = (state = initialState, { type, payload }, history) => {
  switch (type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        userDetails: payload,
        loading: false,
      };
    case USER_LOGIN_FAIL:
      return {
        ...state,
        errorMessage: payload,
      };
    default:
      return state;
  }
};
export default userLoginReducer;
