import {
  GET_PROPERTY_MANAGER_REQUEST,
  GET_PROPERTY_MANAGER_SUCCESS,
  GET_PROPERTY_MANAGER_FAIL,
  GET_PROPERTY_MANAGER_TASKS
  
} from "../actions/actionTypes";

const initialState = {
  propertyManagerList: [],
  propertyManagerTasks:[]
};

const propertyManagerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PROPERTY_MANAGER_REQUEST:
      return {
        loading: true,
      };
    case GET_PROPERTY_MANAGER_SUCCESS:
      return {
        loading: false,
        propertyManagerList: payload,
      };
    case GET_PROPERTY_MANAGER_FAIL:
      return {
        loading: true,
      };
      case GET_PROPERTY_MANAGER_TASKS:
        return {
          propertyManagerTasks:payload
        };

    default:
      return state;
  }
};

export default propertyManagerReducer
