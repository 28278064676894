import { Box, IconButton, Modal, TextField, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import {
  ADD_ROOMS_BOX_STYLE,
  ADD_GRID_BOX_STYLE,
  ROOMS_BOX_STYLE,
} from "../../../components/Constants";
import CustomButton from "../../../components/custom-button/CustomButton";
import {
  deleteRoom,
  getRoomSpace,
  createWalkway,
  deleteWalkway,
} from "../../../redux/actions/territory-manager-actions/territoryManager";
import { characterCheckRegex } from "../../../utils/helperMethods";
import { AVAILABLE_ROOMS, ROOM_HEADINGS, COLORS } from "../../Constants";
import Loader from "../../../components/loader/Loader";
import TerritoryTable from "../territoryTable";

const AddRooms = (props) => {
  const [selected, setSelected] = useState([]);
  const [showWalkwayModal, setShowWalkWayModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [textFields, setTextFields] = useState([]);
  const [newWalkway, setNewWalkway] = useState({
    startGridLabel: "",
    endGridLabel: "",
  });
  const [roomGrids, setRoomGrids] = useState([]);
  const [roomsData, setRoomsData] = useState([]);
  const [gridColors, setGridColors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const {
    roomData,
    open,
    addRoomData,
    setAddRoomData,
    setisDisable,
    setRoomData,
    handleRemoveIcon,
    isAddMore = { isAddMore },
    availableRooms,
    handleShowModal,
    setShowLoader,
    getRoomsData,
    facilityID,
  } = props;

  const displayRoomInput = ({ roomInfo, index, isAdd = false }) => {
    const disableAdd = isAdd
      ? (roomInfo.roomLabel || "").trim().length < 3 ||
        Number(roomInfo.rows || "0") < 1 ||
        Number(roomInfo.columns || "0") < 1 ||
        !characterCheckRegex(roomInfo.rows || "0") ||
        !characterCheckRegex(roomInfo.columns || "0")
      : false;
    let roomLabelMessage = "Please Enter Valid Room Label";
    return (
      <div className='roomStyle'>
        {ROOM_HEADINGS.map((data) => {
          const charCheckRegex =
            roomInfo[data.key]?.length > 0 &&
            !characterCheckRegex(roomInfo[data.key]) &&
            data.errorMessage;
          return (
            <div className='room' key={data.key}>
              <TextField
                error={
                  data.key === "roomLabel"
                    ? (roomInfo?.roomLabel || "").length &&
                      (roomInfo?.roomLabel || "").trim().length < 3
                    : roomInfo[data.key] < 1 || charCheckRegex
                }
                helperText={
                  data.key === "roomLabel" &&
                  (roomInfo?.roomLabel || "").length &&
                  (roomInfo?.roomLabel || "").trim().length < 3
                    ? roomLabelMessage
                    : roomInfo[data.key] < 1 || charCheckRegex
                    ? `Please Enter Valid ${data.title}`
                    : ""
                }
                type={data.type}
                id='demo-helper-text-misaligned'
                label={data.title}
                value={roomInfo[data.key] || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (isAdd) {
                    if (["rows", "columns"].includes(data.key)) {
                      if (
                        !characterCheckRegex(value) ||
                        value < data.minValue ||
                        (value || "").trim().length < data.minValue
                      ) {
                        addRoomData[data.key] = value;
                        setisDisable(true);
                        data.errorMessage = `Please Enter Valid ${data.title}`;
                      } else {
                        addRoomData[data.key] = value;
                        data.hasError = false;
                      }
                      if (value.length === 0) {
                        addRoomData[data.key] = value;
                        data.hasError = false;
                      }
                    }
                    addRoomData[data.key] = value;
                    setAddRoomData({ ...addRoomData });
                  } else {
                    roomData[index][data.key] = e.target.value;
                    setRoomData([...roomData]);
                  }
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    paddingTop: "0.4vw",
                    paddingBottom: "0.4vw",
                  },
                  "& .MuiOutlinedInput-root": {
                    padding: "0.2vw 0.2vh",
                  },
                }}
              />
            </div>
          );
        })}
        {isAdd ? (
          <IconButton
            aria-label='upload picture'
            component='span'
            className='addroombutton'
            style={{
              backgroundColor: "#198eba",
              cursor: disableAdd ? "not-allowed" : "pointer",
            }}
            onClick={() => {
              setRoomData([...roomData, addRoomData]);
              setAddRoomData({});
              setisDisable(true);
            }}
            disabled={disableAdd}
          >
            <img src={"/images/shared/plus.svg"} alt='plusIcon' />
          </IconButton>
        ) : (
          <IconButton
            aria-label='upload picture'
            component='span'
            className='addroombutton'
            style={{ backgroundColor: "red" }}
            onClick={() => {
              roomData.splice(index, 1);
              setRoomData([...roomData]);
              setisDisable(true);
            }}
          >
            <img
              src={"/images/shared/deletewhite.svg"}
              alt=''
              style={{ width: "20px", height: "20px" }}
            />
          </IconButton>
        )}
      </div>
    );
  };

  const filterTabledata = (id) => {
    setSelected(id);
  };

  const handleDeleteRoom = async () => {
    setShowLoader(true);
    const { status, message } = await deleteRoom(facilityID, selected);
    const roomsLength = (selected || []).length > 1 ? "s" : "";
    if (status) {
      handleShowModal({
        cancelText: "Ok",
        title: status ? `Success` : `Failed`,
        message: status
          ? `Selected room${roomsLength} deleted successfully!`
          : `Failed to delete room${roomsLength} !, please try again.`,
        isAlert: true,
      });
      setSelected([]);
      getRoomsData();
    } else if (message) {
      handleShowModal({
        cancelText: "Ok",
        title: message && `Failed`,
        message: message,
        isAlert: true,
      });
    }

    setShowLoader(false);
  };

  const handleShowDeleteModal = () => {
    if (selected?.length) {
      handleShowModal({
        confirmText: "Delete",
        title: `Delete Room${selected.length > 1 ? "s" : ""}`,
        message: `Are you sure you want to delete the selected room${
          selected.length > 1 ? "s" : ""
        }?`,
        handleConfirm: handleDeleteRoom,
      });
    }
  };

  const displayingDeletebutton = () => {
    return (
      <CustomButton
        title={"Delete"}
        className={"storagefacilityDelete2"}
        handleButtonClick={() => handleShowDeleteModal()}
        disabled={!selected?.length > 0}
      />
    );
  };

  const toggleChild = async () => {
    const selectedRoom = availableRooms.filter(
      (room) => selected.toString() === room._id
    );
    const storageFacilityId = selectedRoom[0].storageFacility;
    const roomId = selected.toString();
    setIsLoading(true);
    const { data, status, message } = await getRoomSpace(
      storageFacilityId,
      roomId
    );
    setIsLoading(false);
    if (status) {
      setShowWalkWayModal(true);
      const orderIds = [...new Set(data.map((x) => x.order))];
      const orderColors = {};
      orderIds.forEach((x, index) => {
        let curIndex = index % COLORS?.length;
        orderColors[x] = x ? COLORS[curIndex] : "#fff";
      });
      setGridColors({ ...orderColors });
      setRoomGrids(data);
      setRoomsData(selectedRoom);
    } else {
      handleShowModal({
        cancelText: "Ok",
        title: message && `Failed`,
        message: message,
        isAlert: true,
      });
    }
  };

  const onCancel = () => {
    setShowModal(false);
    setTextFields([]);
    setNewWalkway({ startGridLabel: "", endGridLabel: "" });
  };

  const handleAddDeleteWalkways = async (isAdd) => {
    const storageFacilityId = roomsData[0].storageFacility;
    const roomId = selected.toString();
    setIsLoading(true);
    const { status, message } = isAdd
      ? await createWalkway(storageFacilityId, roomId, textFields)
      : await deleteWalkway(storageFacilityId, roomId, textFields);
    setIsLoading(false);
    if (status) {
      handleShowModal({
        cancelText: "Ok",
        title: isAdd ? "Created" : "Deleted",
        message: isAdd
          ? `Walkways created successfully!`
          : `Walkways deleted successfully!`,
        isAlert: true,
        handleCancel: onCancel(),
      });
      toggleChild();
    } else {
      handleShowModal({
        cancelText: "Ok",
        title: "Try Again!",
        message:
          message ||
          `Failed to ${
            isAdd ? "create" : "delete"
          }  walkway!, please try again.`,
        isAlert: true,
      });
    }
  };

  const handleAddField = () => {
    setTextFields([...textFields, { ...newWalkway }]);
    setNewWalkway({ startGridLabel: "", endGridLabel: "" });
  };

  const handleRemoveField = (index) => {
    if (textFields.length && index >= 0) {
      const updatedFields = [...textFields];
      updatedFields.splice(index, 1);
      setTextFields([...updatedFields]);
    }
  };

  const handleInputChange = (value, index, fieldName) => {
    const labelValue = (value || "").toUpperCase();
    if (index === -1) {
      setNewWalkway({ ...newWalkway, [fieldName]: labelValue });
    } else {
      const updatedFields = [...textFields];
      updatedFields[index][fieldName] = labelValue;
      setTextFields(updatedFields);
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
    setTextFields([]);
    setNewWalkway({ startGridLabel: "", endGridLabel: "" });
  };

  const displayGridInput = (grid, index) => {
    const isAddNew = index === -1;
    const disableButton = isAddNew
      ? grid.endGridLabel === "" || grid.startGridLabel === ""
      : false;
    return (
      <div style={{ marginBottom: 10 }}>
        <Grid container spacing={3} key={index} style={{ display: "flex" }}>
          {Object.keys(grid).map((gridKey) => (
            <Grid item>
              <TextField
                label={
                  gridKey === "startGridLabel"
                    ? "Start Grid Value"
                    : "End Grid Value"
                }
                value={grid[gridKey]}
                onChange={(e) =>
                  handleInputChange(e.target.value, index, gridKey)
                }
              />
            </Grid>
          ))}
          <Grid item>
            <IconButton
              component='span'
              className='iconStyles'
              style={{
                backgroundColor: isAddNew ? "#198eba" : "red",
                cursor: disableButton ? "not-allowed" : "pointer",
                opacity: disableButton ? 0.5 : 1,
              }}
              onClick={() => {
                if (isAddNew) {
                  handleAddField(index);
                } else {
                  handleRemoveField(index);
                }
              }}
              disabled={disableButton}
            >
              <img
                src={`/images/shared/${
                  isAddNew ? "plus.svg" : "deletewhite.svg"
                }`}
                alt={isAddMore ? "Add" : "Delete"}
                style={{ width: "20px", height: "20px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );
  };

  const displayModal = () => {
    let disableButton = true;
    if (textFields?.length > 0) {
      disableButton = textFields.some(
        (x) => !(x.startGridLabel?.length && x.endGridLabel?.length)
      );
    }
    return (
      <Modal
        open={showModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={ADD_GRID_BOX_STYLE}>
          <div>
            <div className='manageWalkways'>Manage Walkways</div>
            <div className='gridStyles'>
              {textFields.map((data, index) => {
                return displayGridInput(data, index);
              })}
              {displayGridInput(newWalkway, -1)}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              position: "absolute",
              bottom: "5vh",
              left: "8vw",
            }}
          >
            {[{ isAdd: true }, { isAdd: false }].map(({ isAdd }) => (
              <CustomButton
                title={isAdd ? "Add Walkway" : "Delete Walkway"}
                className={isAdd ? "addWalkway" : "deleteWalkway"}
                handleButtonClick={() => handleAddDeleteWalkways(isAdd)}
                disabled={disableButton}
              />
            ))}
          </div>
          <div
            className='addRoomModalRemoveIcon'
            onClick={() => onCloseModal()}
          >
            X
          </div>
        </Box>
      </Modal>
    );
  };

  const displayWalkways = () => {
    return (
      <Modal
        open={showWalkwayModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div>
          <Box sx={ROOMS_BOX_STYLE}>
            <div className='walkwaysButtons'>
              <div>
                <div className='colorInfostyle'>
                  <div className='walkway'></div>{" "}
                  <div className='slotInfoText'> - Walkways</div>
                </div>
                <div className='colorInfostyle'>
                  <div className='emptySpace'></div>{" "}
                  <div className='slotInfoText'> - Empty Space</div>
                </div>
              </div>
              <CustomButton
                title={"Manage Walkways"}
                className={"storagefacilityAdd2"}
                handleButtonClick={() => setShowModal(true)}
              />
            </div>
            <Grid
              container
              style={{
                height: "90%",
                overflow: "scroll",
                flexDirection: "column",
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              {Array.from(
                { length: roomGrids[0]?.room?.rows || 0 },
                (_, row) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {Array.from(
                      { length: roomGrids[0]?.room?.columns || 0 },
                      (_, col) => {
                        const gridIndex =
                          row * roomGrids[0]?.room?.columns + col;
                        const grid = roomGrids[gridIndex];
                        return (
                          <Box
                            sx={{
                              flex: 1,
                              border: "1px solid grey",
                              "& > :not(style)": {},
                            }}
                          >
                            <Paper
                              elevation={3}
                              sx={{ borderRadius: "0px" }}
                              style={{
                                textAlign: "center",
                                padding: "8px",
                                backgroundColor: grid.isWalkWay
                                  ? "grey"
                                  : gridColors[grid.order],
                                color: grid.isWalkWay ? "white" : "black",
                              }}
                            >
                              {grid.gridLabel}
                            </Paper>
                          </Box>
                        );
                      }
                    )}
                  </div>
                )
              )}
            </Grid>

            {displayModal()}
          </Box>
          <div
            className='selectmodalRemoveIcon'
            onClick={() => setShowWalkWayModal(false)}
            style={{ top: "10vh", right: "12vw" }}
          >
            X
          </div>
        </div>
      </Modal>
    );
  };

  const displayAvailableRooms = () => {
    const roomsLength = (availableRooms || []).length > 0;
    return (
      <>
        <div className={roomsLength ? "roomsTable" : "emptyRooms"}>
          {roomsLength ? (
            <>
              <div className='availbelroomHeading'>
                <div className='roomHeading'>Available Rooms </div>
                <span
                  style={{
                    marginBottom: "12px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <CustomButton
                    title={"Show Walkways"}
                    className={"storagefacilityAdd"}
                    handleButtonClick={() => toggleChild()}
                    disabled={selected.length !== 1}
                  />
                  {displayingDeletebutton()}
                </span>
              </div>

              <TerritoryTable
                rows={availableRooms || []}
                tableHeading={AVAILABLE_ROOMS}
                tablecellpadding={"10.5vw"}
                maxHeight={"26vh"}
                width={"32vw"}
                selected={selected}
                handleSelection={filterTabledata}
                dateTimedata={true}
              />
            </>
          ) : (
            <div className='emptyRoomStyles'>No Available Rooms are Found</div>
          )}
        </div>
      </>
    );
  };

  const checkEnableSubmit = () => {
    return !roomData.every(
      (room) =>
        (room.roomLabel || "").trim().length > 2 &&
        room.rows > 0 &&
        characterCheckRegex(room.rows) &&
        characterCheckRegex(room.columns) &&
        room.columns > 0
    );
  };

  const displayAddRooms = () => {
    return (
      <>
        <div className='roomInputMain'>
          <div className='roomHeading'>Add {isAddMore ? "More" : ""} Rooms</div>
          <div className='roomInputStyle'>
            {(roomData || []).map((roomInfo, index) => {
              return displayRoomInput({ roomInfo, index, isAdd: false });
            })}
            {displayRoomInput({
              roomInfo: addRoomData,
              index: roomData?.length,
              isAdd: true,
            })}
          </div>
          <div className='addRoomStyle' style={{ left: !isAddMore && "22%" }}>
            <CustomButton
              title={`${"Submit"}`}
              additionalSx={{
                padding: "0.5vw 8vw",
                fontWeight: 600,
                fontSize: "1.5vw",
              }}
              handleButtonClick={() => props.handleAddRooms()}
              disabled={roomData.length <= 0 || checkEnableSubmit()}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div>
        <Box sx={isAddMore ? ROOMS_BOX_STYLE : ADD_ROOMS_BOX_STYLE}>
          <div className={isAddMore ? "roommainContent" : ""}>
            {displayAddRooms()} {isAddMore ? displayAvailableRooms() : ""}
          </div>
          <div
            className='addRoomModalRemoveIcon'
            onClick={() => handleRemoveIcon()}
          >
            X
          </div>
        </Box>
        <Loader show={isLoading} />
        {displayWalkways()}
      </div>
    </Modal>
  );
};

export default AddRooms;