/**@description displays property view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";
import Custombuton from "../../components/custom-button";
import AddProperty from "../../components/add-property";
import TenantListEmpty from "../../components/tenant-list-empty";
import ConfirmModal from "../../components/confirm-modal";
import Loader from "../../components/loader";
import { COUNTRY_CODE, PROPERTYTABLE_HEADINGS } from "../Constants";
import { Roles } from "../../config";
import { getRole } from "../../utils/authentication";
import "./PropertyDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../redux/actions/usersActions";
import {
  createProperty,
  deleteProperty,
  getProperties,
  updateProperty,
} from "../../redux/actions/propertyListActions";
import {reformatPhoneNumber} from "../../utils/helperMethods"
import { userSignup } from "../../redux/actions/user-actions/userAction";
import CustomTable from "../../components/custom-table/Customtable";
import {ADD_PROPERTY} from "../../components/Constants"

const PropertyDetailsList = (props) => {
  const propertyProps = props.propertyProps || {};
  const properties = useSelector(
    (state) => state.propertyListReducer.properties
  );

  const isPropertyManager = getRole() === Roles.manager;
  const propertytableHeadingsData = [...PROPERTYTABLE_HEADINGS];
  const history = useHistory();
  const dispatch = useDispatch();

  const [propertiesList, setPropertiesList] = useState(properties);

  const [selected, setSelected] = useState(propertyProps?.selected || []);
  const [showAddProperty, setShowAddProperty] = useState(
    !!propertyProps.showAddProperty
  );
  const [isAdd, setIsAdd] = useState(!!propertyProps.isAdd);
  const [editPropertyData, setEditPropertyData] = useState(
    propertyProps.editPropertyData || {}
  );
  const [statusModal, setStatusModal] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [addProperty, setAddProperty] = useState([...ADD_PROPERTY]);

  /**@description Functionality Fetch Property Details
   */
  const fetchProperties = async () => {
    setShowLoader(true);
    const pData = props.fetchPropertiesData
      ? await props.fetchPropertiesData()
      : await dispatch(getProperties());
    dispatch(getUsers());
    setPropertiesList([...pData]);
    setShowLoader(false);
  };

  /**@description Handle to Edit or Add Property  Details
   */
  const handleAddOrEditProperty = (add, tData = {}) => {
    setIsAdd(!!add);
    setEditPropertyData({ ...tData });
    setShowAddProperty(true);
  };

  /**@description handle close add/edit tenant
   */
  const handleCloseAddProperty = () => {
    setShowAddProperty(false);
    addProperty.forEach((data) => {
      data.value = "";
      data.hasError = false;
    });
    setAddProperty([...addProperty]);
  };
  useEffect (() => {
    handleCloseAddProperty()
  },[])

  /**@description handles change of add/edit tenant data
   */ const handleEditPropertyData = ({ key, value }) => {
    editPropertyData[key] = value;
    setEditPropertyData({
      ...editPropertyData,
    });
  };

  /**@description handles submit add/edit tenant data
   */
  const handleEndEditingPropertyData = async (e) => {
    e.preventDefault();
    let isUserCreated = true;
    let userErrorMessage = null;
    setShowLoader(true);
    if (!editPropertyData?.manager?._id) {
      const manager = editPropertyData.manager || {};
      const { status, data, message } = await userSignup({
        name: manager.managerName,
        email: manager.email,
        phoneNumber: `${COUNTRY_CODE}${reformatPhoneNumber(manager.phoneNumber)}`,
        address: manager.address1,
        address2: manager.address2,
        role: Roles.manager,
      });
      userErrorMessage = message;
      if (status) {
        editPropertyData.manager._id = data._id;
      } else {
        isUserCreated = false;
      }
    }
    if (isUserCreated) {
      const propertyData = {
        _id: editPropertyData._id,
        name: editPropertyData.name,
        address: editPropertyData.address,
        address2: editPropertyData.address2,
        numberOfBuildings: editPropertyData.numberOfBuildings,
        numberOfApartments: editPropertyData.numberOfApartments,
        apartmentTypes: editPropertyData.apartmentTypes,
        manager: editPropertyData.manager?._id,
        createdBy: props.mfoId,
      };
      const { status, message } = isAdd
        ? await createProperty(propertyData)
        : await updateProperty(propertyData);
      if (status) {
        setEditPropertyData({});
        setSelected([]);
        fetchProperties();
        setShowAddProperty(false);
      } else {
        setShowLoader(false);
      }
      handleShowModal({
        cancelText: "Ok",
        title: status ? `Success` : `Failed`,
        message: status
          ? message ||
            `${
              isAdd
                ? "Property created successfully."
                : "Property updated successfully."
            }`
          : message ||
            `${
              isAdd
                ? "Failed to create property, please try again."
                : "Failed to update property, please try again."
            }`,
        isAlert: true,
      });
    } else {
      handleShowModal({
        cancelText: "Ok",
        title: `Failed`,
        message:
          userErrorMessage ||
          "Failed to create property manager, please try again.",
        isAlert: true,
      });
      setShowLoader(false);
    }
  };

  /**@description handles edit property*/
  const handleEditProperty = () => {
    if (selected?.length === 1) {
      const pData = propertiesList.find(
        (property) => property._id === selected[0]
      );
      handleAddOrEditProperty(false, pData);
    }
  };

  const handleHideModal = () => {
    statusModal.show = false;
    setStatusModal({ ...statusModal });
  };

  const handleShowModal = ({
    cancelText,
    confirmText,
    isAlert,
    title,
    message,
    handleConfirm = () => {},
  }) => {
    statusModal.show = true;
    statusModal.cancelText = cancelText || "Cancel";
    statusModal.confirmText = confirmText || "Ok";
    statusModal.isAlert = isAlert || false;
    statusModal.title = title || "";
    statusModal.message = message || "";
    statusModal.handleConfirm = handleConfirm;
    statusModal.handleCancel = handleHideModal;
    setStatusModal({ ...statusModal });
  };

  /**@description handles delete property*/
  const handleDeleteProperty = async () => {
    if (selected?.length) {
      handleHideModal();
      setShowLoader(true);
      let deletedCount = 0;
      const requests = selected.map((email) => deleteProperty(email));
      await Promise.all(requests)
        .then((responses) => {
          responses.forEach((res) => {
            if (res) deletedCount += 1;
          });
        })
        .catch((err) => {
          console.error("handleDeleteTenant", err);
        });
      if (deletedCount) {
        fetchProperties();
        setSelected([]);
      } else {
        setShowLoader(false);
      }
      handleShowModal({
        cancelText: "Ok",
        title: deletedCount ? `Success` : `Failed`,
        message: deletedCount
          ? `${
              deletedCount === selected.length
                ? "Selected"
                : `${deletedCount} out of ${selected.length}`
            } propertie(s) deleted successfully!`
          : `Failed to delete propertie(s), please try again.`,
        isAlert: true,
      });
    }
  };

  const handleShowDeleteModal = () => {
    if (selected?.length) {
      handleShowModal({
        confirmText: "Delete",
        title: `Delete Propert${selected.length > 1 ? "ies" : "y"}`,
        message: `Are you sure you want to delete the selected propert${
          selected.length > 1 ? "ies" : "y"
        }?`,
        handleConfirm: handleDeleteProperty,
      });
    }
  };

  const handleGoToDashboard = (propertyId) => {
    history.push(`/app/property/${propertyId}`);
  };

  /**@description Handling selected Data from Rows*/
  const handlepropertyListSelect = (selectedData) => {
    setSelected(selectedData);
  };

  useEffect(() => {
    fetchProperties();
  }, []);

  const displayTabs = () => {
    return <div className={`propertyHeader`}>Properties</div>;
  };

  /**@description Displaying edit Button*/
  const displayingEditButton = () => {
    return (
      <Custombuton
        title={"Edit"}
        className='propertyEditbutton'
        handleButtonClick={handleEditProperty}
        additionalSx={{
          opacity: selected?.length !== 1 ? 0.5 : 1,
          visibility: selected?.length > 1 ? "hidden" : "block",
        }}
      />
    );
  };

  /*@description Displaying edit Button*/
  const displayingDeleteButton = () => {
    return (
      <Custombuton
        title={"Delete"}
        className='propertyDeletebutton'
        handleButtonClick={handleShowDeleteModal}
        additionalSx={{
          opacity: selected?.length === 0 ? 0.5 : 1,
        }}
      />
    );
  };

  /**@description Displaying Property Table Component */
  const displayingPropertyTable = () => {
    return (
      <div className='propertyTable'>
        <CustomTable
          rows={propertiesList}
          tableHeading={propertytableHeadingsData}
          handleSelection={handlepropertyListSelect}
          selected={selected}
          maxHeight={window.innerHeight * 0.6}
          action={[
            {
              add: false,
              text: "View Dashboard",
              handleClick: handleGoToDashboard,
              id: "_id",
              className: "viewDashboard",
            },
          ]}
        />
        {isPropertyManager ? null : (
          <div
            className='propertyButtondiv'
            role='presentation'
            onClick={() => {
              handleAddOrEditProperty(true);
            }}
          >
            <IconButton
              aria-label='upload picture'
              component='span'
              className='propertyAddButton'
            >
              <img src={"/images/shared/plus.svg"} alt='' />
            </IconButton>
          </div>
        )}
      </div>
    );
  };

  const displayBody = () => {
    return propertiesList?.length ? (
      <div className='maintable'>
        {displayTabs()}
        {isPropertyManager ? null : (
          <div className='propertyButtons'>
            {displayingEditButton()}
            {displayingDeleteButton()}
          </div>
        )}
        {displayingPropertyTable()}
      </div>
    ) : (
      <div className={"tenantListEmpty"}>
        <TenantListEmpty
          width={"75%"}
          header='You have no properties added to your list'
          buttonText='Add New Property'
          canAdd={!isPropertyManager}
          handleButtonClick={() => {
            handleAddOrEditProperty(true);
          }}
        />
      </div>
    );
  };

  return (
    <div className='mfoOverview'>
      {displayBody()}
      {showAddProperty ? (
        <AddProperty
          isAdd={isAdd}
          handleClose={handleCloseAddProperty}
          propertyData={editPropertyData}
          handleChange={handleEditPropertyData}
          handleSubmit={handleEndEditingPropertyData}
          addProperty={addProperty}
        />
      ) : null}
      {statusModal?.show ? (
        <ConfirmModal
          cancelText={statusModal.cancelText}
          confirmText={statusModal.confirmText}
          isAlert={statusModal.isAlert}
          title={statusModal.title}
          message={statusModal.message}
          handleCancel={statusModal.handleCancel}
          handleConfirm={statusModal.handleConfirm}
        />
      ) : null}
      <Loader show={showLoader} />
    </div>
  );
};

export default PropertyDetailsList;
