export const BOX_STYLE = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  bgcolor: "background.paper",
  boxShadow: "0px 0.35vw 2vw rgba(0, 0, 0, 0.08)",
  borderRadius: "5px",
  padding: "2vw 4vw 2.5vw",
  height: "80%",
  overflow: "scroll",
};

export const POPUP_STYLE = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  boxShadow: "0px 0.35vw 2vw rgba(0, 0, 0, 0.08)",
  borderRadius: "5px",
  padding: "2vw 4vw 2.5vw",
  height: "50%",
  overflow: "scroll",
};

export const VERIFY_POPUP = { ...POPUP_STYLE, height: "30%", width: "45%" };

export const EXPIRY_DATE = { ...POPUP_STYLE, height: "45%", width: "30%" };

export const SMS_POPUP = { ...VERIFY_POPUP, height: "14%", width: "38%" };

export const NOTIFICATION_POPUP = {...EXPIRY_DATE,height: "58%", width: "50%", padding: "2vw 1.5vw 2vw",}

export const REFUND_POPUP = {
  ...VERIFY_POPUP,
  height: "30%",
  width: "32%",
  padding: "2vw",
  overflow: "hidden",
};

export const AVAILABLE_DATES_POPUP = {
  ...VERIFY_POPUP,
  width: "30%",
  height: "40%",
};

export const EDIT_FILE_NAME = {
  ...VERIFY_POPUP,
  padding: "2vw 3vw 2.5vw",
  width: "28%",
  height: "33%",
};

export const STORED_ITEMS_BOX_STYLE = {
  position: "absolute",
  top: "50vh",
  left: "50vw",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  boxShadow: "0px 0.35vw 2vw rgba(0, 0, 0, 0.08)",
  borderRadius: "5px",
  padding: "2vw 4vw 2.5vw",
  height: "50vh",
};

export const MEDIAKIT_BOX_STYLE = {
  position: "absolute",
  top: "50vh",
  left: "50vw",
  transform: "translate(-50%, -50%)",
  width: "42vw",
  bgcolor: "background.paper",
  boxShadow: "0px 0.35vw 2vw rgba(0, 0, 0, 0.08)",
  borderRadius: "5px",
  padding: "2vw 4vw 2.5vw",
  height: "56vh",
};

export const PENDINGREQUEST_BOX_STYLE = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: "0px 0.35vw 2vw rgba(0, 0, 0, 0.08)",
  borderRadius: "5px",
  padding: "2vw 4vw 2.5vw",
  height: "80%",
  overflow: "scroll",
};

export const SMALLMODAL_BOX_STYLE = {
  position: "absolute",
  top: "50vh",
  left: "50vw",
  transform: "translate(-50%, -50%)",
  width: "28vw",
  bgcolor: "background.paper",
  boxShadow: "0px 0.35vw 2vw rgba(0, 0, 0, 0.08)",
  borderRadius: "5px",
  padding: "2vw 2.5vw",
  height: "38vh",
};
export const ADDDRIVER_BOX_STYLE = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "22%",
  bgcolor: "background.paper",
  boxShadow: "0px 0.35vw 2vw rgba(0, 0, 0, 0.08)",
  borderRadius: "5px",
  padding: "2vw 4vw 2.5vw",
  height: "74%",
  overflow: "scroll",
};

export const ITEM_BOX_STYLE = {
  position: "absolute",
  top: "50vh",
  left: "50vw",
  transform: "translate(-50%, -50%)",
  width: "32vw",
  bgcolor: "background.paper",
  boxShadow: "0px 0.35vw 2vw rgba(0, 0, 0, 0.08)",
  borderRadius: "5px",
  padding: "1vw 2vw",
  height: "36vh",
  overflow: "scroll"
};

export const DRIVERSMALLMODAL_BOX_STYLE = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20%",
  bgcolor: "background.paper",
  boxShadow: "0px 0.35vw 2vw rgba(0, 0, 0, 0.08)",
  borderRadius: "5px",
  padding: "1vw 1vw",
  height: "32%",
  overflow: "scroll",
};

export const ADD_ROOMS_BOX_STYLE = {
  position: "absolute",
  top: "50vh",
  left: "50vw",
  transform: "translate(-50%, -50%)",
  width: "30vw",
  bgcolor: "background.paper",
  boxShadow: "0px 0.35vw 2vw rgba(0, 0, 0, 0.08)",
  borderRadius: "5px",
  padding: "2vw 4vw 2.5vw",
  height: "50vh",
};

export const ADD_GRID_BOX_STYLE = {
  position: "absolute",
  top: "50vh",
  left: "50vw",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "background.paper",
  boxShadow: "0px 0.35vw 2vw rgba(0, 0, 0, 0.08)",
  borderRadius: "5px",
  padding: "2vw 4vw 2.5vw",
  height: "50vh",
};

export const ROOMS_BOX_STYLE = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  bgcolor: "background.paper",
  boxShadow: "0px 0.35vw 2vw rgba(0, 0, 0, 0.08)",
  borderRadius: "5px",
  padding: "2vw 4vw 2.5vw",
  height: "70%",
};
export const ADD_TENANT = [
  {
    title: "Tenant Name",
    type: "text",
    key: "name",
    value: "",
    minLength: 3,
    maxLength: 50,
    hasError: false,
    regexType: "alphabet",
  },
  {
    title: "Email Address",
    type: "email",
    key: "email",
    value: "",
    minLength: 3,
    maxLength: 64,
    hasError: false,
  },
  {
    title: "Apartment Number",
    type: "numeric",
    key: "apartmentNumber",
    value: "",
    minValue: 1,
    minLength: 2,
    maxLength: 10,
    hasError: false,
    regexType: "alphanumeric",
  },
  {
    title: "Tenant Number",
    type: "numeric",
    key: "tenantNumber",
    value: "",
    minValue: 1,
    minLength: 1,
    maxLength: 10,
    hasError: false,
    regexType: "digits",
  },
  { title: "Lease Start Date", type: "date", key: "leaseStartDate", hasError: false,},
  { title: "Lease End Date", type: "date", key: "leaseEndDate", hasError: false, },
];

export const ADD_MULTIFAMILY_OPERATOR = [
  {
    title: "MFO Name",
    type: "text",
    key: "mfoName",
    value: "",
    minLength: 3,
    maxLength: 50,
    hasError: false,
    regexType: "alphabet",
  },
  {
    title: "Email Address",
    type: "text",
    key: "email",
    value: "",
    minLength: 3,
    maxLength: 64,
    hasError: false,
    required: true,
  },
  {
    title: "Contact Number",
    type: "numeric",
    key: "phoneNumber",
    value: "",
    minLength: 14,
    maxLength: 14,
    hasError: false,
    regexType: "phoneNumber",
  },
];

export const ADD_EMAIL_AND_SMS = [
  {
    title: "Title",
    type: "title",
    key: "email&smstitle",
    value: "",
    minLength: 3,
    hasError: false,
    regexType: "alphabet",
    errorMessage: "",
    tab: "marketingMaterial",
  },
  {
    title: "Subtext",
    type: "text",
    key: "subtext",
    value: "",
    minLength: 3,
    hasError: false,
    regexType: "alphabet",
    errorMessage: "",
    tab: "marketingMaterial",
  },
  {
    title: "SMS",
    type: "text",
    key: "sms",
    value: "",
    minLength: 3,
    hasError: false,
    errorMessage: "",
    tab: "marketingMaterial",
    // regexType: "alphabet",
  },
  {
    title: "Text",
    type: "text",
    key: "text",
    value: "",
    minLength: 3,
    hasError: false,
    regexType: "alphabet",
    errorMessage: "",
    tab: "socialMedia",
  },
  {
    title: "SMS",
    type: "text",
    key: "socialmediasms",
    value: "",
    minLength: 3,
    hasError: false,
    errorMessage: "",
    //tab: "socialMedia",
    // regexType: "alphabet",
  },
  {
    title: "Title",
    type: "title",
    key: "printedtitle",
    value: "",
    minLength: 3,
    maxLength: 50,
    hasError: false,
    regexType: "alphabet",
    errorMessage: "",
    tab: "printed",
  },
  {
    title: "Subtext",
    type: "text",
    key: "printedsubtext",
    value: "",
    minLength: 3,
    hasError: false,
    regexType: "alphabet",
    errorMessage: "",
    tab: "printed",
  },
];

export const CREATE_NOTIFICATION = [
  {
    title: "Title",
    type: "title",
    key: "title",
    value: "",
    minLength: 3,
    hasError: false,
    regexType: "alphabet",
    errorMessage: "",
  },
  {
    title: "Message",
    type: "message",
    key: "message",
    value: "",
    minLength: 3,
    hasError: false,
    regexType: "alphabet",
    errorMessage: "",
  },
]

export const PROPERTY_DETAILS_LIST = [
  { title: "Property Name", type: "text", key: "propertyName" },
  { title: "Property Manager Name", type: "text", key: "managerName" },
  {
    title: "Property Manager Email Address",
    type: "email",
    key: "propertyManagerEmail",
  },
  {
    title: "Property Manager Contact Number",
    type: "numeric",
    key: "propertyManagerPhoneNumber",
  },
];

export const ADD_PROPERTY = [
  {
    title: "Property Name",
    type: "text",
    key: "name",
    value: "",
    minLength: 3,
    maxLength: 25,
    hasError: false,
    regexType: "alphanumeric",
  },
  {
    title: "No.of Buildings",
    type: "numeric",
    key: "numberOfBuildings",
    value: "",
    minValue: 1,
    minLength: 1,
    maxLength: 5,
    hasError: false,
    regexType: "digits",
  },
  {
    title: "No.of Apartments",
    type: "numeric",
    key: "numberOfApartments",
    value: "",
    minValue: 1,
    minLength: 1,
    maxLength: 5,
    hasError: false,
    regexType: "digits",
  },
  { title: "Apartment Type", type: "text", key: "apartmentTypes" },
  {
    title: "Address-1",
    type: "text",
    key: "address",
    value: "",
    minLength: 3,
    maxLength: 25,
    hasError: false,
  },
  {
    title: "Address-2",
    type: "text",
    key: "address2",
    value: "",
    minLength: 3,
    maxLength: 25,
    hasError: false,
  },
  {
    title: "Property Manager Email Address",
    type: "email",
    key: "email",
    value: "",
    minLength: 3,
    maxLength: 64,
    hasError: false,
    isManager: true,
  },
  {
    title: "Property Manager Name",
    type: "text",
    key: "managerName",
    value: "",
    minLength: 3,
    maxLength: 25,
    hasError: false,
    isManager: true,
    regexType: "alphabet",
  },
  {
    title: "Property Manager Contact Number",
    type: "text",
    key: "phoneNumber",
    value: "",
    minLength: 14,
    maxLength: 14,
    hasError: false,
    isManager: true,
    regexType: "phoneNumber",
  },
];

export const ADD_Manager = [
  {
    title: "Manager Name",
    type: "text",
    key: "name",
    value: "",
    minLength: 3,
    maxLength: 25,
    hasError: false,
    regexType: "alphabet",
  },

  {
    title: "Manager Email Address",
    type: "email",
    key: "email",
    value: "",
    minLength: 3,
    maxLength: 64,
    hasError: false,
    isManager: true,
  },
  {
    title: "Manager Contact Number",
    type: "text",
    key: "phoneNumber",
    value: "",
    minLength: 14,
    maxLength: 14,
    hasError: false,
    regexType: "phoneNumber",
  },
  {
    title: "Address-1",
    type: "text",
    key: "address",
    value: "",
    minLength: 3,
    maxLength: 25,
    hasError: false,
  },
  {
    title: "Address-2",
    type: "text",
    key: "address2",
    value: "",
    minLength: 3,
    maxLength: 25,
    hasError: false,
  },
];

export const ADD_COUPON_CODE = [
  {
    title: "Discount Code",
    type: "text",
    key: "code",
    value: "",
    maxLength: 50,
    hasError: false,
    regexType: "alphanumeric",
  },
  {
    title: "Discount Percentage",
    type: "text",
    key: "percentage",
    value: "",
    minLength: 1,
    maxLength: 2,
    hasError: false,
    regexType: "digits",
  },
  { title: "Expiry Date", type: "date", key: "expiryDate", value: "" },
];

export const DOWNLOAD_TENANTS_SAMPLE = {
  data: [
    {
      tenantName: "John Smith",
      email: "xyz@gmail.com",
      aptNumber: 123,
      tenantNumber: 12,
      leaseStartDate: new Date(),
      leaseEndDate: new Date(),
    },
  ],
  headers: [
    {
      column: "Tenant Name",
      type: String,
      width: 25,
      value: (x) => x.tenantName,
    },
    {
      column: "Email Address",
      type: String,
      width: 25,
      value: (x) => x.email,
    },
    {
      column: "Apartment Number",
      type: Number,
      width: 25,
      value: (x) => x.aptNumber,
    },
    {
      column: "Tenant Number",
      type: Number,
      width: 25,
      value: (x) => x.tenantNumber,
    },
    {
      column: "Lease Start Date",
      type: Date,
      format: "mm/dd/yyyy",
      width: 25,
      value: (x) => x.leaseStartDate,
    },
    {
      column: "Lease End Date",
      type: Date,
      format: "mm/dd/yyyy",
      width: 25,
      value: (x) => x.leaseEndDate,
    },
  ],
};

export const DOWNLOAD_CUSTOMER_SAMPLE_QR = {
  data: [],
  headers: [
    {
      type: String,
      width: 25,
      value: (x) => x.qrCodes,
    },
    {
      type: String,
      width: 25,
      value: (x) => x.name,
    },
    {
      type: String,
      width: 25,
      value: (x) => x._id,
    },
  ],
};

export const ORDER_BOX_STYLE = {
  position: "absolute",
  top: "50vh",
  left: "50vw",
  transform: "translate(-50%, -50%)",
  width: "26vw",
  bgcolor: "background.paper",
  boxShadow: "0px 0.35vw 2vw rgba(0, 0, 0, 0.08)",
  borderRadius: "5px",
  padding: "2vw 2.5vw",
  height: "26vh",
  overflow: "scroll",
};

export const FILE_TYPES = [
  {
    title: "HTML File",
    key: "html",
    type: ".html, .htm, application/html",
    value: "",
    tab: "marketingMaterial",
    multiple: false,
  },
  {
    title: "PDF File",
    key: "pdf",
    type: "application/pdf",
    value: "",
    tab: "marketingMaterial",
    multiple: false,
  },
  {
    title: "PDF File",
    key: "pdfprinted",
    type: "application/pdf",
    value: "",
    tab: "printed",
    multiple: false,
  },
  {
    title: "Select photo",
    key: "uploadphoto",
    type: "image/*",
    value: [],
    tab: "socialMedia",
    multiple: true,
  },

  {
    title: "Add photo",
    key: "addphoto",
    type: "image/*",
    value: "",
    tab: "web",
    multiple: true,
  },
];

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
