/**@description displays property view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import React, { useState } from "react";
import CustomuserInput from "../../components/custom-input";
import Custombuton from "../../components/custom-button";
import "./Signup.css";
import { SIGNUP_DETAILS, ROLE_NAME } from "../Constants";

const SignupScreen = (props) => {
  const [userInfo, setUserInfo] = useState([...SIGNUP_DETAILS]);
  const roleName = { ...ROLE_NAME };

  /**@description handles changes in user Details*/
  const onchange = ({ key, value }) => {
    const index = userInfo.findIndex((x) => x.key === key);

    if (index > -1) {
      userInfo[index].value = value;
      userInfo[index].hasError = false;
      setUserInfo([...userInfo]);
    }
  };

  /**@description Handles submit user Details  */
  const onSubmitHandler = (e) => {
    e.preventDefault();

    userInfo.forEach((user, index) => {
      if (user.value === null) {
        userInfo[index].errorMessage = `Please Enter ${user.title} `;
        userInfo[index].hasError = true;
      } else {
        if (
          user.type === "email" ||
          (user.value?.length >= user.minLength &&
            user.value?.length <= user.maxLength)
        ) {
          userInfo[index].hasError = false;
        } else {
          userInfo[
            index
          ].errorMessage = ` ${user.title} should contain atleast ${user.minLength} characters`;
          userInfo[index].hasError = true;
        }
      }
    });
    setUserInfo([...userInfo]);
  };

  /**@description Display userDetails */
  const displaySignup = () => {
    return (
      <form onSubmit={onSubmitHandler} className="maindiv">
        {userInfo.map((user, index) => (
          <CustomuserInput
            dataItem={user}
            className="SignupinputStyle"
            handleInput={onchange}
            name={index}
            value={user.value}
            key={`signupForm${user.title}`}
          />
        ))}

        <Custombuton title={"Signup"} className="button" />
      </form>
    );
  };
  return (
    <div className="maintopdiv">
      <img
        src="/images/shared/valetIcon.svg"
        alt="valeticon"
        className="valetIcon"
      />
      <h1 className="heading">{roleName[props.role]} Dashboard</h1>
      {displaySignup()}
    </div>
  );
};

export default SignupScreen;
