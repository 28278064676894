import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TableSortLabel,
} from "@mui/material";
import _ from "lodash";
import {
  convertToDateFormat,
  getFormattedPhoneNum,
} from "../../utils/helperMethods";
import "./territoryTable.scss";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import CustomButton from "../../components/custom-button/CustomButton";

const theme = createTheme({
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: "1.25vw",
          height: "1.25vw",
        },
      },
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e1e5ea",
    color: theme.palette.common.black,
    fontWeight: 500,
    fontSize: "1vw",
    padding: "0.75vw 0vw",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "1vw",
    color: "rgba(84, 100, 122, 1)",
    paddingLeft: "1vw",
  },
  [`&.${tableCellClasses.root}`]: {
    paddingLeft: "1vw",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.common.white,
  },

  "&:last-child td, &:last-child th": {
    borderBottom: 10,
  },
}));

const TerritoryTable = (props) => {
  const { tableHeading, rows, handleSelection, selected, hideCheckbox } = props;
  const [dateValue, setdateValue] = useState(new Date());

  /**@description checkbox onClick Functionality For particular row data*/
  const handleClick = (e, email) => {
    e.preventDefault();
    const selectedIndex = selected.indexOf(email) || "";
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    handleSelection(newSelected);
  };

  /**@description checkbox onClick Functionality For All rows data*/

  const handleAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = (rows || []).map((n) => n._id);
      handleSelection(newSelecteds);
      return;
    } else {
      handleSelection([]);
    }
  };

  const isSelected = (email) => (selected || []).indexOf(email) !== -1;

  /**@description displaying Table Head Data*/
  const displayingTableHeadings = () => {
    return (
      <ThemeProvider theme={theme}>
        <TableHead>
          <TableRow className="tableHead">
            {hideCheckbox ? null : (
              <StyledTableCell>
                <Checkbox
                  onClick={(event) => handleAllClick(event)}
                  checked={
                    rows?.length > 0 && selected?.length === rows?.length
                  }
                />
              </StyledTableCell>
            )}
            {tableHeading.map((row) => (
              <StyledTableCell
                align="left"
                key={`displayingTableHeadings${row.Name}`}
                style={row.style}
              >
                {row.key === props.sortcell ? (
                  <>
                    <span>{row.Name}</span>
                    <TableSortLabel
                      active={props.orderBy === row._id}
                      direction={
                        props.orderBy === row._id ? props.order : "asc"
                      }
                      onClick={props.createSortHandler(row._id)}
                      //style={{ margin: "0%" }}
                    ></TableSortLabel>
                  </>
                ) : row.key === props.datePicker ? (
                  <div className="datePickerStyle">
                    <span className="dateNameStyle">{row.Name}</span>
                    <DatePicker
                      onChange={(value) => {
                        setdateValue(value);
                        props.handleDatePickers(value.toDateString());
                      }}
                      selected={dateValue}
                      closeCalendar={true}
                      customInput={
                        <img
                          src="/images/shared/calendar.png"
                          alt="addIcon"
                          className={"addcalendarIcon"}
                        />
                      }
                      className={"react-datepicker-wrapper-territory"}
                    />
                  </div>
                ) : (
                  row.Name
                )}
              </StyledTableCell>
            ))}
            {props.action?.add ? <StyledTableCell /> : null}
            {props.action?.propertyCell ? (
              <StyledTableCell>Properties</StyledTableCell>
            ) : null}
          </TableRow>
        </TableHead>
      </ThemeProvider>
    );
  };

  const getFormattedValue = (formatType, value) => {
    switch (formatType) {
      case "date":
        return convertToDateFormat(value);
      case "phone":
        return getFormattedPhoneNum(value);
      default:
        return value;
    }
  };

  /**@description displaying Table Body Data*/
  const displayingTableBody = () => {
    return (
      <TableBody>
        {(rows || []).map((row, index) => {
          const isItemSelected = isSelected(row._id);
          return (
            <StyledTableRow
              key={`displayingTableBody${row._id}`}
              sx={{ paddingBottom: "px" }}
              tabIndex={-1}
              onClick={() => props.handleClick(row._id)}
            >
              {hideCheckbox ? null : (
                <StyledTableCell sx={{ borderBottom: "none" }}>
                  <Checkbox
                    checked={isItemSelected}
                    onClick={(event) => handleClick(event, row._id)}
                  />
                </StyledTableCell>
              )}

              {tableHeading.map((header, ind) => {
                const ispropertyMgrNo = header.key === "propertyMgrNo";
                const value = _.get(row, header.key);
                const driverJobtitle =
                  row.appointmentType === "Pickup from customer"
                    ? `Pickedup`
                    : `Droppedoff`;

                return (
                  <StyledTableCell
                    align="left"
                    sx={{ borderBottom: "none" }}
                    className={[ispropertyMgrNo ? "tablecell" : ""]}
                    key={ind}
                    style={header.style}
                  >
                    {["driverJob", "driverStatus"].includes(header.key) ? (
                      <div className={"driverButton"}>
                        <CustomButton
                          title={`${
                            header.key === "driverJob"
                              ? driverJobtitle
                              : row.driverStatus
                          }`}
                          additionalSx={{
                            padding: "0.2vw 0.4vw",
                            textTransform: "capitalize",
                            borderRadius: "8px",
                            fontSize: "0.8vw",
                            width: "100%",
                          }}
                          disabled={
                            header.key === "driverJob"
                              ? row.Status === "Delivered"
                              : row.driverStatus === "Confirmed"
                          }
                          handleButtonClick={(e) => {
                            e.stopPropagation();
                            props.handleConfirmButton(row);
                          }}
                        />
                      </div>
                    ) : value && Array.isArray(value) === false ? (
                      <span>{`${
                        header.format
                          ? getFormattedValue(header.formatType, value)
                          : value
                      }`}</span>
                    ) : value && Array.isArray(value) === true ? (
                      value.map((item, i) => <div key={i}>{item}</div>)
                    ) : (
                      "_"
                    )}
                  </StyledTableCell>
                );
              })}
              {props.action?.add || props.action?.propertyCell ? (
                <StyledTableCell align="left" sx={{ borderBottom: "none" }}>
                  <div
                    onClick={() => {
                      props.action.handleClick(row[props.action.id]);
                    }}
                    className={props.action.className}
                  >
                    {props.action.text}
                  </div>
                </StyledTableCell>
              ) : null}
            </StyledTableRow>
          );
        })}
      </TableBody>
    );
  };
  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: props.maxHeight,
          minHeight: props.minHeight,
          width: props.width,
          minHeight: props.minHeight,
          paddingBottom: props.tablecellpadding,
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          {displayingTableHeadings()}
          {displayingTableBody()}
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};
TerritoryTable.defaultProps = {
  handleClick: () => {},
};
export default TerritoryTable;
