import { GET_SERVICEABLE} from "../actions/actionTypes";

const initialState = {
  ServiceableData: []
};
const serviceableReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SERVICEABLE:
      return {
        ...state,
        ServiceableData: payload,
      };
    default:
      return state;
  }
};

export default serviceableReducer;