import React, { useState } from "react";
import CustomModal from "../../../components/custom-modal/CustomModal";
import { BOX_STYLE } from "../../../components/Constants";
import CustomButton from "../../../components/custom-button/CustomButton";
import CustomInput from "../../../components/custom-input/CustomInput";
import CustomDate from "../../../components/custom-date/CustomDate";
import "./CouponCode.scss";
import { validations, convertToDMYFormat } from "../../../utils/helperMethods";
import {
  createCouponCodes,
  updateCouponDetails,
} from "../../../redux/actions/discountCodes";
import Loader from "../../../components/loader/Loader";

const AddCoupon = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const displayHeader = () => (
    <div className="couponHeader">
      <div className="addCoupon">
        {!props.edit ? "Add" : "Edit"} Discount Code
      </div>
    </div>
  );

  const handleTextChange = ({ key, value, regexType }) => {
    const { couponDetails, handleChange } = props;
    const actualValue = key === "code" ? value.toUpperCase() : value;
    const item = validations(couponDetails, key, actualValue, regexType);
    handleChange({
      key: item.key,
      value: item.value,
    });
  };

  const addOrUpdateDiscountCode = async () => {
    const {
      couponDetails,
      edit,
      selected,
      handleShowModal,
      handleClose,
      getAllCouponDetails,
      setAddCouponCode,
      setSelected,
    } = props;
    const currentData = {
      name: couponDetails[0].value,
      isActive: true,
      discountPercentage: `${couponDetails[1].value}`,
      expiryDate: couponDetails[2].value,
    };
    let couponId = selected[0];
    setShowLoader(true);
    const { status, message } = edit
      ? await updateCouponDetails(couponId, currentData)
      : await createCouponCodes(currentData);
    setShowLoader(false);
    if (status) {
      handleShowModal({
        cancelText: "Ok",
        title: "Success",
        message: edit
          ? "Discount code updated Successfully."
          : "Discount code added Successfully.",
        isAlert: true,
      });
      handleClose();
      getAllCouponDetails();
    } else {
      handleShowModal({
        cancelText: "Ok",
        title: "Failed",
        message: message || "Something went wrong.Please try again.",
        isAlert: true,
      });
    }
    setAddCouponCode(false);
    setSelected([]);
  };
  const displayButton = () => {
    let disable = false;
    const {couponDetails} = props;
    const dataObject = couponDetails;
    disable = Object.values(dataObject).some(
      (attribute) =>
        attribute?.hasError ||
        (attribute?.value || "")?.length < attribute?.minLength ||
        attribute?.value === ""
    );
    return (
      <div className="addButton">
        <CustomButton
          title={`${
            !props.edit ? "Add Discount Code" : "Update Discount Code"
          }`}
          additionalSx={{
            padding: "0.6vw 8vw",
            fontWeight: 600,
            fontSize: "1.5vw",
          }}
          handleButtonClick={addOrUpdateDiscountCode}
          disabled={disable}
        />
      </div>
    );
  };

  const displayFormFields = () => (
    <div>
      <div className="main">
        {props.couponDetails.map((dataItem) => {
          const value = dataItem.value || "";
          return (
            <div
              className="addCouponInput"
              key={`displayFormFields${dataItem.title}`}
            >
              {dataItem.type === "date" ? (
                <CustomDate
                  dataItem={{ ...dataItem, value }}
                  handleDateChange={(data) => props.handleChange(data)}
                  required={true}
                  minDate={new Date()}
                />
              ) : (
                <CustomInput
                  dataItem={{ ...dataItem, value }}
                  additionalSx={{
                    width: "100%",
                    margin: 0,
                  }}
                  handleInput={(data) => handleTextChange(data)}
                  required={true}
                />
              )}
            </div>
          );
        })}
      </div>
      {displayButton()}
    </div>
  );

  const displayModalContent = () => {
    return (
      <>
        {displayHeader()}
        {displayFormFields()}
      </>
    );
  };

  return (
    <div>
      <CustomModal
        isOpen={true}
        handleRemoveIcon={props.handleClose}
        sx={BOX_STYLE}
        content={displayModalContent}
        closeButtonStyle="removeIcon"
      />
      <Loader show={showLoader} />
    </div>
  );
};

export default AddCoupon;
