import React, { useState } from "react";
import CustomModal from "../../../components/custom-modal/CustomModal";
import Loader from "../../../components/loader/Loader";
import {
  NOTIFICATION_POPUP,
  CREATE_NOTIFICATION,
} from "../../../components/Constants";
import CustomButton from "../../../components/custom-button/CustomButton";
import { FormControl, Select, MenuItem, Checkbox } from "@mui/material";
import "./Notification.scss";
import CustomInput from "../../../components/custom-input/CustomInput";
import { sendBulkNotification } from "../../../redux/actions/notificationAction";
import { getUserInfo } from "../../../utils/authentication";
import ConfirmModal from "../../../components/confirm-modal/ConfirmModal";
import _ from "lodash";

const CreateNotificationModal = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [notificationData, setNotificationData] = useState([
    ...CREATE_NOTIFICATION,
  ]);
  const [isSelected, setIsSelected] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [showErrorPopUp, setShowErrorPopUp] = useState(false)

  const displayHeader = () => (
    <div>
      <div className="notificationHeader">Create Notification</div>
    </div>
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "12vw",
        marginBottom: "12vh",
      },
    },
  };

  const handleAllClick = (event) => {
    if (event.target.checked) {
      setSelectedUser([]);
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  };

  const onSelectIndividual = (event, cur) => {
    if (!isSelected) {
      if (event.target.checked) {
        const exists = selectedUser.some((user) => user._id === cur._id);
        if (!exists) {
          setSelectedUser([...selectedUser, cur]);
        }
      } else {
        setSelectedUser((prevUsers) =>
          prevUsers.filter((curUser) => curUser._id !== cur._id)
        );
      }
    }
  };

  const handleClose = () => {
    props.setCreateNotification(false);
    notificationData.forEach((data) => {
      data.value = "";
      data.hasError = false;
    });
    setNotificationData([...notificationData]);
  };

  const displayTenantDropdown = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
        }}
      >
        <FormControl sx={{ m: 1, width: "12vw", mt: 3, fontStyle: "normal" }}>
          <Select
            displayEmpty
            value=""
            onChange={(e) => {}}
            renderValue={() => {
              if (isSelected) {
                return <em className={"selectStyle"}>All Users</em>;
              } else {
                return <em className={"selectStyle"}>Select Users</em>;
              }
            }}
            MenuProps={MenuProps}
            defaultValue=""
            inputProps={{ "aria-label": "Without label" }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Checkbox
                onClick={(event) => handleAllClick(event)}
                checked={isSelected}
              />
              <MenuItem
                value=""
                className={"selectStyle"}
                style={{ fontWeight: "bold" }}
              >
                <em className={"selectStyle"}>Select All</em>
              </MenuItem>
            </div>
            {(props.tenantInfo || []).map((name) => (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Checkbox
                  onClick={(event) => onSelectIndividual(event, name)}
                  checked={
                    !isSelected &&
                    selectedUser.some((user) => user._id === name._id)
                  }
                />
                <MenuItem
                  key={name._id}
                  value={name.name}
                  className={"selectStyle"}
                >
                  {name.name}
                </MenuItem>
              </div>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const handleTextChange = ({ key, value, regexType }) => {
    const index = notificationData.findIndex((data) => data.key === key);
    const item = notificationData[index];
    item.value = value;
    item.hasError = (item.value || "").trim().length < item.minLength;
    if (value.length === 0) {
      item.hasError = false;
      item.value = value;
    }
    if (item.hasError) {
      item.errorMessage = `Please enter valid ${item.title}`;
    }
    setNotificationData([...notificationData]);
  };

  const displayConfirmationModal = () => {
    return (
      <ConfirmModal
        isOpen={showSuccessModal}
        title={"Success"}
        message={"Promotional Notification sent successfully!"}
        cancelText="Okay"
        handleCancel={() => {
          setShowSuccessModal(false);
          handleClose();
        }}
        isAlert
      />
    );
  };

  const displayErrorPopup = () => {
    return (
      <ConfirmModal
        isOpen={showErrorPopUp}
        title={"Failed"}
        message={"Failed to send Notification. Please try after some time!"}
        cancelText="Okay"
        handleCancel={() => {
          setShowErrorPopUp(false);
          handleClose();
        }}
        isAlert
      />
    );
  };

  const CreateNotification = async () => {
    const userInfo = getUserInfo() || {};
    const senderId = userInfo["custom:userId"];

    let userIds = (!isSelected ? selectedUser : props.tenantInfo).map(
      (item) => {
        return {
          user: item._id,
          isRead: false,
        };
      }
    );
    const createNotificationData = {
      userIds,
      title: notificationData[0].value,
      body: notificationData[1].value,
      data: {},
      senderId,
    };
    setShowLoader(true);
    const { data, status } = await sendBulkNotification(createNotificationData);
    if (status) {
      setShowSuccessModal(true);
      setShowLoader(false);
      props.getAllNotification();
    }
    else {
      setShowErrorPopUp(true)
    }
  };

  const displayButton = () => {
    let disable = false;
    const dataObject = notificationData;
    disable =
      Object.values(dataObject).some(
        (attribute) =>
          attribute?.hasError ||
          (attribute?.value || "")?.length < attribute?.minLength ||
          attribute?.value === ""
      ) ||
      (selectedUser.length === 0 && isSelected === false);
    return (
      <CustomButton
        title={"Create"}
        additionalSx={{
          padding: "0.5vw 2vw",
          fontWeight: 600,
          fontSize: "1vw",
          marginLeft: "1vw",
        }}
        handleButtonClick={() => CreateNotification()}
        disabled={disable}
      />
    );
  };

  const displayFormFields = () => {
    let users = selectedUser.map((curUser) => curUser.name).join(", ");
    return (
      <div>
        {displayTenantDropdown()}
        <div className="createNotificationForm">
          {notificationData.map((dataItem) => {
            const value = dataItem.value;
            return (
              <div className="addNotificationInput" key={dataItem.key}>
                <CustomInput
                  dataItem={{ ...dataItem, value }}
                  additionalSx={{
                    "& .MuiInputBase-input": {
                      padding: "0 0 0",
                    },
                    "& .css-ll96sp-MuiInputBase-root-MuiInput-root": {
                      marginTop: "0",
                    },
                    fontSize: "1.25vw",
                    width: "120%",
                    margin: 0,
                  }}
                  handleInput={(data) => handleTextChange(data)}
                  required={true}
                  multiline={true}
                  maxRow={3}
                />
              </div>
            );
          })}
          <div className="displayUsers">
            <div>Users:</div>
            <div className="displayUserNames">
              {isSelected ? "All Users" : users}
            </div>
          </div>
        </div>

        <div className="notificationButton">
          <CustomButton
            title={`${"Cancel"}`}
            className="managerDeletebutton"
            handleButtonClick={handleClose}
          />
          {displayButton()}
        </div>
      </div>
    );
  };

  const displayModalContent = () => {
    return (
      <>
        {displayHeader()}
        {displayFormFields()}
      </>
    );
  };

  return (
    <div>
      <CustomModal
        isOpen={true}
        handleRemoveIcon={handleClose}
        sx={NOTIFICATION_POPUP}
        content={displayModalContent}
        closeButtonStyle="notificationCrossIcon"
      />
      {showSuccessModal && displayConfirmationModal()}
      {displayErrorPopup()}
      <Loader show={showLoader} />
    </div>
  );
};

export default CreateNotificationModal;
