import {
  GET_PROPERTY_MANAGER_REQUEST,
  GET_PROPERTY_MANAGER_SUCCESS,
  GET_PROPERTY_MANAGER_FAIL,
} from "../actionTypes";
import config from "../../../config/index";
import axios from "axios";

/**@description get Manager
 */

export const getManagers = (role) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROPERTY_MANAGER_REQUEST,
    });

    const { data } = await axios.get(
      `${config.RestServiceURL}/users/roles/${role ? role : "ACCOUNT_MANAGER"}`,
      role
    );

    dispatch({
      type: GET_PROPERTY_MANAGER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_PROPERTY_MANAGER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

/**@description create a Manager
 */
export const createManager = (manager) => {
  return new Promise((resolve) => {
    axios
      .post(`${config.RestServiceURL}/users`, manager)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        console.error("userSignup", err);
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description update a manager
 */
export const updateManager = (manager) => {
  return new Promise((resolve) => {
    axios
      .put(`${config.RestServiceURL}/users/${manager.id}`, manager)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        console.error("updateManager", err);
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description delete a property
 */
export const deleteManager = (id) => {
  return new Promise((resolve) => {
    axios
      .delete(`${config.RestServiceURL}/users/${id}`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        console.error("deleteProperty", err);
        resolve(false);
      });
  });
};

export const getPropertyManagerTasks = (propertyId) => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/manager-tasks/properties/${propertyId}`)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          resolve([]);
        }
      })
      .catch((err) => {
        console.error("getPropertyManagerTasks", err.response);
      });
  });
};

export const updateMonthlyTasks = (managerTaskID) => {
  return new Promise((resolve) => {
    axios
      .put(`${config.RestServiceURL}/manager-tasks/${managerTaskID}/status`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};
export const getAPIkeys = () => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/api-keys`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

export const getPropertyMarketingmaterials = (propertyId) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${config.RestServiceURL}/marketing-materials/properties/${propertyId}`
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true, data: res.data });
        } else {
          resolve({ status: false, data: [] });
        }
      })
      .catch((err) => {
        console.error("getMarketingmaterial", err);
        resolve({ status: false, data: [] });
      });
  });
};
