/**@description displays manager view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";
import Custombuton from "../../components/custom-button";
import AddManager from "../../components/add-manager/AddManager";
import TenantListEmpty from "../../components/tenant-list-empty";
import ConfirmModal from "../../components/confirm-modal";
import Loader from "../../components/loader";
import { COUNTRY_CODE, MANAGERTABLE_HEADINGS } from "../Constants";
import { Roles } from "../../config";
import { getRole } from "../../utils/authentication";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../redux/actions/usersActions";
import "./ManagerDetails.scss";

import CustomTable from "../../components/custom-table/Customtable";
import {
  deleteManager,
  updateManager,
  createManager,
} from "../../redux/actions/manager-actions/managerActions";
import { getProperties } from "../../redux/actions/propertyListActions";
import {
  getFormattedPhoneNum,
  reformatPhoneNumber,
} from "../../utils/helperMethods";
import { ADD_Manager } from "../../components/Constants";

const ManagerDetailsList = (props) => {
  const managerProps = props.managerProps || {};
  const propertiesData = useSelector(
    (state) => state.propertyListReducer.properties
  );
  const managersData = useSelector((state) => state.usersReducer.users);
  const isPropertyManager = getRole() === Roles.manager;
  const isAdmin = getRole() === Roles.admin;
  const managertableHeadingsData = [...MANAGERTABLE_HEADINGS];
  const history = useHistory();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(managerProps?.selected || []);
  const [showAddManager, setShowAddManager] = useState(
    !!managerProps.showAddManager
  );
  const [isAdd, setIsAdd] = useState(!!managerProps.isAdd);
  const [editManagerData, setEditManagerData] = useState(
    managerProps.editManagerData || {}
  );
  const [statusModal, setStatusModal] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [managerDetails, setManagerDetails] = useState([...ADD_Manager]);


  const getManagersFromProperties = (propertiesData) => {
    let managersData = [];
    propertiesData.forEach((property) => {
      const isExist = managersData.find(
        (manager) => property.manager?.email === manager.email
      );
      if (!isExist && property.manager?._id) {
        managersData.push(property.manager);
      }
    });
    return managersData;
  };

  const [managersList, setManagersList] = useState(
    isAdmin ? [...managersData] : [...getManagersFromProperties(propertiesData)]
  );

  const fetchManagersData = async () => {
    setShowLoader(true);
    let managersData = [];
    if (isAdmin) {
      managersData = await dispatch(getUsers("ACCOUNT_MANAGER"));
    } else {
      const propertiesData = await dispatch(getProperties());
      managersData = getManagersFromProperties(propertiesData);
    }
    setManagersList([...managersData]);
    setShowLoader(false);
  };

  useEffect(() => {
    fetchManagersData();
  }, []);

  const handleAddOrEditManagers = (add, tData = {}) => {
    setIsAdd(!!add);
    setEditManagerData({ ...tData });
    setShowAddManager(true);
  };

  /**@description handle close add/edit manager
   */
  const handleCloseAddManagers = () => {
    setShowAddManager(false);
    managerDetails.forEach((data) => {
      data.value = "";
      data.hasError = false;
    });
    setManagerDetails([...managerDetails]);
  };

useEffect(() => {
  handleCloseAddManagers()
},[])

  /**@description handles change of add/edit manager data
   */ const handleEditManagersData = ({ key, value }) => {
    editManagerData[key] =
      key === "phoneNumber" ? getFormattedPhoneNum(value) : value;
    setEditManagerData({
      ...editManagerData,
    });
  };

  /**@description handles submit add/edit manager data
   */
  const handleEndEditingManagersData = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    const managerData = {
      id: editManagerData._id,
      name: editManagerData.name,
      email: editManagerData.email,
      phoneNumber: `${COUNTRY_CODE}${reformatPhoneNumber(
        editManagerData.phoneNumber
      )}`,
      address: editManagerData.address,
      address2: editManagerData.address2,
      role: "ACCOUNT_MANAGER",
    };

    const { status, message } = isAdd
      ? await createManager(managerData)
      : await updateManager(managerData);

    if (status) {
      setShowLoader(true);
      setEditManagerData({});
      setSelected([]);
      fetchManagersData();
      setShowAddManager(false);
    } else {
      setShowLoader(false);
    }
    handleShowModal({
      cancelText: "Ok",
      title: status ? `Success` : `Failed`,
      message: status
        ? message ||
          `${
            isAdd
              ? "Manager created successfully."
              : "Manager updated successfully."
          }`
        : message ||
          `${
            isAdd
              ? "Failed to create Manager, please try again."
              : "Failed to update Manager, please try again.."
          }`,
      isAlert: true,
    });
    setShowLoader(false);
  };

  /**@description handles edit manager*/
  const handleEditManagers = () => {
    if (selected?.length === 1) {
      const pData = managersList.find((manager) => manager._id === selected[0]);
      handleAddOrEditManagers(false, {
        ...pData,
        phoneNumber: getFormattedPhoneNum(pData.phoneNumber),
      });
    }
  };

  /**@description handles Hide Model Functionality*/
  const handleHideModal = () => {
    statusModal.show = false;
    setStatusModal({ ...statusModal });
  };

  /**@description handles Show Model Functionality*/
  const handleShowModal = ({
    cancelText,
    confirmText,
    isAlert,
    title,
    message,
    handleConfirm = () => {},
  }) => {
    statusModal.show = true;
    statusModal.cancelText = cancelText || "Cancel";
    statusModal.confirmText = confirmText || "Ok";
    statusModal.isAlert = isAlert || false;
    statusModal.title = title || "";
    statusModal.message = message || "";
    statusModal.handleConfirm = handleConfirm;
    statusModal.handleCancel = handleHideModal;
    setStatusModal({ ...statusModal });
  };

  /**@description handles delete manager*/
  const handleDeleteManager = async () => {
    if (selected?.length) {
      handleHideModal();
      setShowLoader(true);
      let deletedCount = 0;
      const requests = selected.map((id) => deleteManager(id));
      await Promise.all(requests)
        .then((responses) => {
          responses.forEach((res) => {
            if (res) deletedCount += 1;
          });
        })
        .catch((err) => {
          console.error("handleDeleteTenant", err);
        });
      if (deletedCount) {
        fetchManagersData();
        setSelected([]);
      } else {
        setShowLoader(false);
      }
      handleShowModal({
        cancelText: "Ok",
        title: deletedCount ? `Success` : `Failed`,
        message: deletedCount
          ? `${
              deletedCount === selected.length
                ? "Selected"
                : `${deletedCount} out of ${selected.length}`
            } Manager(s) deleted successfully!`
          : `Failed to delete Manager(s), please try again.`,
        isAlert: true,
      });
    }

    setShowLoader(false);
  };

  /**@description handles Show Delete Model Functionality*/
  const handleShowDeleteModal = () => {
    if (selected?.length) {
      handleShowModal({
        confirmText: "Delete",
        title: `Delete Manage${selected.length > 1 ? "rs" : "r"}`,
        message: `Are you sure you want to delete the selected Manage${
          selected.length > 1 ? "rs" : "r"
        }?`,
        handleConfirm: handleDeleteManager,
      });
    }
  };

  /**@description handles go to Dashboard Functionality*/
  const handleGoToDashboard = (managerId) => {
    history.push(`/app/manager/${managerId}`);
  };

  /**@description Handling selected Data from Rows*/
  const handlemanagerListSelect = (selectedData) => {
    setSelected(selectedData);
  };

  /**@description Displaying Tabs Functionality*/
  const displayTabs = () => {
    return <div className={`managerHeader`}>Manager Details</div>;
  };

  /**@description Displaying edit Button*/
  const displayingEditButton = () => {
    return (
      <Custombuton
        title={"Edit"}
        className='managerEditbutton'
        handleButtonClick={handleEditManagers}
        additionalSx={{
          opacity: selected?.length !== 1 ? 0.5 : 1,
          visibility: selected?.length > 1 ? "hidden" : "block",
        }}
      />
    );
  };

  /*@description Displaying edit Button*/
  const displayingDeleteButton = () => {
    return (
      <Custombuton
        title={"Delete"}
        className='managerDeletebutton'
        handleButtonClick={handleShowDeleteModal}
        additionalSx={{
          opacity: selected?.length === 0 ? 0.5 : 1,
        }}
      />
    );
  };

  /**@description Displaying Manager Table Component */
  const displayingManagerTable = () => {
    return (
      <div className='managerTable'>
        <CustomTable
          rows={managersList}
          tableHeading={managertableHeadingsData}
          handleSelection={handlemanagerListSelect}
          selected={selected}
          maxHeight={window.innerHeight * 0.6}
          action={[
            {
              add: false,
              text: "View Dashboard",
              handleClick: handleGoToDashboard,
              id: "_id",
              className: "viewDashboard",
            },
          ]}
        />
        {isAdmin && (
          <div
            className='managerButtondiv'
            role='presentation'
            onClick={() => {
              handleAddOrEditManagers(true);
            }}
          >
            <IconButton
              aria-label='upload picture'
              component='span'
              className='managerAddButton'
            >
              <img src={"/images/shared/plus.svg"} alt='' />
            </IconButton>
          </div>
        )}
      </div>
    );
  };

  /**@description Displaying Manager details Functionality*/
  const displayBody = () => {
    return managersList?.length ? (
      <div className='maintable'>
        {!props.hideHeader && displayTabs()}
        {isPropertyManager ? null : (
          <div className='managerButtons'>
            {displayingEditButton()}
            {displayingDeleteButton()}
          </div>
        )}
        {displayingManagerTable()}
      </div>
    ) : (
      <div className={"tenantListEmpty"}>
        <TenantListEmpty
          width={"75%"}
          header={
            isAdmin
              ? "You have no Manager added to your list"
              : "You have no Managers assigned to your property"
          }
          buttonText='Add New Manager'
          canAdd={isAdmin}
          handleButtonClick={() => {
            handleAddOrEditManagers(true);
          }}
        />
      </div>
    );
  };

  return (
    <div className='mfoOverview'>
      {displayBody()}
      {showAddManager ? (
        <AddManager
          isAdd={isAdd}
          handleClose={handleCloseAddManagers}
          managerData={editManagerData}
          handleChange={handleEditManagersData}
          handleSubmit={handleEndEditingManagersData}
          managerDetails={managerDetails}
        />
      ) : null}
      {statusModal?.show ? (
        <ConfirmModal
          cancelText={statusModal.cancelText}
          confirmText={statusModal.confirmText}
          isAlert={statusModal.isAlert}
          title={statusModal.title}
          message={statusModal.message}
          handleCancel={statusModal.handleCancel}
          handleConfirm={statusModal.handleConfirm}
        />
      ) : null}
      <Loader show={showLoader} />
    </div>
  );
};

ManagerDetailsList.defaultProps = {
  hideHeader: false,
};

export default ManagerDetailsList;
