import axios from "axios";
import { GET_PPROPERTIES, GET_PROPERTY } from "./actionTypes";
import { getUserInfo, getRole } from "../../utils/authentication";
import config, { Roles } from "../../config";

/**@description get all Properties
 */
export const getProperties = () => async (dispatch) => {
  return new Promise((resolve) => {
    const userInfo = getUserInfo() || {};
    const userId = userInfo["custom:userId"];

    const role = getRole();

    let url = `${config.RestServiceURL}/properties`;
    switch (role) {
      case Roles.mfo:
        url = `${config.RestServiceURL}/properties/mfo/${userId}`;
        break;
      case Roles.manager:
        url = `${config.RestServiceURL}/properties/manager/${userId}`;
        break;
      case Roles.admin:
        url = `${config.RestServiceURL}/properties`;
        break;
      default:
        url = `${config.RestServiceURL}/properties`;
    }
    axios
      .get(url)
      .then((res) => {
        const data = res.data;

        dispatch({
          type: GET_PPROPERTIES,
          payload: data,
        });
        resolve(data);
      })
      .catch((err) => {
        console.error("getProperties", err.message);
        resolve([]);
      });
  });
};

export const fetchMFOProperties = (mfoId) => {
  return new Promise((resolve) => {
    axios
      .get(`${config.RestServiceURL}/properties/mfo/${mfoId}`)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          resolve([]);
        }
      })
      .catch((err) => {
        console.error("fetchMFOProperties", err.response);
        resolve([]);
      });
  });
};

/**@description get a property
 */
export const getProperty = (id) => ({
  type: GET_PROPERTY,
  payload: id,
});

/**@description create a property
 */
export const createProperty = (property) => {
  return new Promise((resolve) => {
    const userInfo = getUserInfo() || {};
    if (!property.createdBy) {
      property.createdBy = userInfo["custom:userId"];
    }
    axios
      .post(`${config.RestServiceURL}/properties`, property)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        console.error("createProperty", err);
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description create multiple properties
 */
export const createMultipleProperty = (property) => {
  return new Promise((resolve) => {
    const userInfo = getUserInfo() || {};
    const role = getRole();

    if (role === Roles.mfo) {
      property.createdBy = userInfo["custom:userId"];
    }

    axios
      .post(`${config.RestServiceURL}/properties/multiple`, property)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        console.error("createProperty", err);
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description update a property
 */
export const updateProperty = (property) => {
  return new Promise((resolve) => {
    const userInfo = getUserInfo() || {};
    const role = getRole();
    if (role === Roles.mfo) {
      property.createdBy = userInfo["custom:userId"];
    }
    axios
      .put(`${config.RestServiceURL}/properties/${property._id}`, property)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve({ status: true });
        } else {
          resolve({ status: false });
        }
      })
      .catch((err) => {
        console.error("updateProperty", err);
        resolve({ status: false, message: err.response?.data?.message });
      });
  });
};

/**@description delete a property
 */
export const deleteProperty = (id) => {
  return new Promise((resolve) => {
    axios
      .delete(`${config.RestServiceURL}/properties/${id}`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        console.error("deleteProperty", err);
        resolve(false);
      });
  });
};

/**@description Delete Bulk Properties
 */
export const deletebulkProperties = (IDdata) => {
  return new Promise((resolve) => {
    axios
      .delete(`${config.RestServiceURL}/properties/multiple`, { data: IDdata })
      .then(() => {
        resolve({ status: true });
      })
      .catch((err) => {
        console.error("deleteProperty", err);
        resolve({ status: false });
      });
  });
};
