/**@description displays property view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import CustomTable from "../../../components/custom-table";
import Custombuton from "../../../components/custom-button";
import { TENANTSTABLE_HEADINGS, CREATE_TENANT } from "../../Constants";
import { ADD_TENANT } from "../../../components/Constants";
import AddTenant from "../../../components/add-tenant";
import TenantListEmpty from "../../../components/tenant-list-empty";
import ConfirmModal from "../../../components/confirm-modal";
import "./Tenant.scss";

const TenantsList = (props) => {
  const { tenants, activeProperty, tenantProps } = props;
  const tenantsHeadingData = [...TENANTSTABLE_HEADINGS];

  const [selected, setSelected] = useState(tenantProps?.selected || []);
  const [showAddTenant, setShowAddTenant] = useState(
    !!tenantProps?.showAddTenant
  );
  const [isAdd, setIsAdd] = useState(!!tenantProps?.isAdd);
  const [isEndDate, setIsEndDate] = useState(true);
  const [editTenantData, setEditTenantData] = useState(
    tenantProps?.editTenantData || {}
  );
  const [statusModal, setStatusModal] = useState({});
  const [tenantInfo, setTenantInfo] = useState([...ADD_TENANT]);

  const handleAddOrEditTenant = (add, tData = {}) => {
    setIsAdd(!!add);
    setEditTenantData({ ...tData });
    setShowAddTenant(true);
  };

  /**@description handle close add/edit tenant
   */
  const handleCloseAddTenant = () => {
    setShowAddTenant(false);
    const tenantData = tenantInfo;
    tenantData.forEach((data) => {
      data.value = "";
      data.hasError = false;
    });
    setTenantInfo([...tenantData]);
    setIsEndDate(true)
  };

useEffect (() => {
  handleCloseAddTenant()
},[])

// check for start date less than end date
const checkDateValidation = function(isError){
  setTenantInfo(tenantInfo.map((data) =>{
    if(data.key === 'leaseEndDate'){
      data.hasError = isError;
      data.errorMessage = isError?"Lease End Date should be greater than Lease Start Date":undefined;
    }
    return data;
}))
}

  /**@description handles change of add/edit tenant data
   */
  const handleEditTenantData = ({ key, value }) => {
    if(key === 'leaseStartDate'){
      setIsEndDate(false)
      editTenantData.leaseEndDate = null
      checkDateValidation(false)
    }
    if(key === 'leaseEndDate' && editTenantData.leaseStartDate > value){
      checkDateValidation(true)
    }
    if(key === 'leaseEndDate' && editTenantData.leaseStartDate < value){
      checkDateValidation(false)
    }
    editTenantData[key] = value;
    setEditTenantData({ ...editTenantData });
  };

  const prepareTenantData = (tData) => {
    const createData = {
      property: activeProperty?._id,
    };
    const isArray = Array.isArray(tData);
    CREATE_TENANT.forEach((x, ind) => {
      createData[x] = isArray ? tData[ind] : tData[x];
    });
    return createData;
  };

  /**@description handles submit add/edit tenant data
   */
  const handleEndEditingTenantData = async (tData) => {
    setShowAddTenant(false);
    if (isAdd) {
      let addStatus = {};
      if (tData?.length) {
        const tenantData = tData.map((tenant) => {
          return prepareTenantData(tenant);
        });
        addStatus = await props.handleAddTenant(tenantData, true);
      } else {
        const addTenantData = prepareTenantData(editTenantData);
        addStatus = await props.handleAddTenant(addTenantData);
      }
      const { status, message } = addStatus;
      if (status) setSelected([]);
      else setShowAddTenant(true);
      handleShowModal({
        cancelText: "Ok",
        title: status ? `Success` : `Failed`,
        message:
          message ||
          `${
            status
              ? "Tenant added successfully."
              : "Failed to add tenant, please try again."
          }`,
        isAlert: true,
      });
    } else {
      const updatedTenantData = prepareTenantData(editTenantData);
      updatedTenantData._id = editTenantData._id;
      const { status, message } = await props.handleUpdateTenant(
        updatedTenantData
      );
      if (status) setSelected([]);
      else setShowAddTenant(true);
      handleShowModal({
        cancelText: "Ok",
        title: status ? `Success` : `Failed`,
        message:
          message ||
          `${
            status
              ? "Tenant updated successfully."
              : "Failed to update tenant, please try again."
          }`,
        isAlert: true,
      });
    }
  };

  /**@description handles edit property*/
  const handleEditTenant = () => {
    if (selected?.length === 1) {
      const tData = tenants.find((x) => x._id === selected[0]);
      handleAddOrEditTenant(false, tData);
    }
  };

  const handleHideModal = () => {
    statusModal.show = false;
    setStatusModal({ ...statusModal });
  };

  const handleShowModal = ({
    cancelText,
    confirmText,
    isAlert,
    title,
    message,
    handleConfirm = () => {},
  }) => {
    statusModal.show = true;
    statusModal.cancelText = cancelText || "Cancel";
    statusModal.confirmText = confirmText || "Ok";
    statusModal.isAlert = isAlert || false;
    statusModal.title = title || "";
    statusModal.message = message || "";
    statusModal.handleConfirm = handleConfirm;
    statusModal.handleCancel = handleHideModal;
    setStatusModal({ ...statusModal });
  };

  /**@description handles delete property*/
  const handleDeleteTenant = async () => {
    if (selected?.length) {
      handleHideModal();
      const deletedCount = await props.handleDeleteTenant(selected);
      handleShowModal({
        cancelText: "Ok",
        title: deletedCount ? `Success` : `Failed`,
        message: deletedCount
          ? `${
              deletedCount === selected.length
                ? "Selected"
                : `${deletedCount} out of ${selected.length}`
            } tenant(s) deleted successfully!`
          : `Failed to delete tenant(s), please try again.`,
        isAlert: true,
      });
      if (deletedCount) setSelected([]);
    }
  };

  const handleShowDeleteModal = () => {
    if (selected?.length) {
      handleShowModal({
        confirmText: "Delete",
        title: `Delete Tenant${selected.length > 1 ? "s" : ""}`,
        message: `Are you sure you want to delete the selected tenant${
          selected.length > 1 ? "s" : ""
        } ?`,
        handleConfirm: handleDeleteTenant,
      });
    }
  };

  /**@description Handling Selected data from Table
   */
  const handlepropertyListSelect = (selectedData) => {
    setSelected([...selectedData]);
  };

  /*@description Displaying edit Button*/
  const displayingEditButton = () => {
    return (
      <Custombuton
        title={"Edit"}
        className="Editbutton"
        additionalSx={{
          opacity: selected?.length !== 1 ? 0.5 : 1,
          visibility: selected?.length > 1 ? "hidden" : "block",
        }}
        handleButtonClick={handleEditTenant}
      />
    );
  };

  /**@description Displaying edit Button*/
  const displayingDeleteButton = () => {
    return (
      <Custombuton
        title={"Delete"}
        className="Deletebutton"
        handleButtonClick={handleShowDeleteModal}
        additionalSx={{
          opacity: selected?.length === 0 ? 0.5 : 1,
        }}
      />
    );
  };

  /**@description Displaying Table Component */
  const displayingTable = () => {
    return (
      <div className="table">
        <CustomTable
          rows={tenants}
          tableHeading={tenantsHeadingData}
          handleSelection={handlepropertyListSelect}
          selected={selected}
          maxHeight={window.innerHeight * 0.55}
        />
        <div
          className="buttondiv"
          role="presentation"
          onClick={() => {
            handleAddOrEditTenant(true);
          }}
        >
          <IconButton
            aria-label="upload picture"
            component="span"
            className="addbutton"
          >
            <img src={"/images/shared/plus.svg"} alt="" />
          </IconButton>
        </div>
      </div>
    );
  };

  const displayBody = () => {
    return tenants?.length ? (
      <div className="maintable">
        <div className="buttons">
          {displayingEditButton()}
          {displayingDeleteButton()}
        </div>
        {displayingTable()}
      </div>
    ) : (
      <div className={"tenantListEmpty"}>
        <TenantListEmpty
          width={"75%"}
          handleButtonClick={() => {
            handleAddOrEditTenant(true);
          }}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="tenantHeader">Tenants List</div>
      {displayBody()}
      {showAddTenant ? (
        <AddTenant
          isAdd={isAdd}
          handleClose={handleCloseAddTenant}
          tenantData={editTenantData}
          isEndDate = {isEndDate}
          handleChange={handleEditTenantData}
          handleSubmit={handleEndEditingTenantData}
          tenantInfo={tenantInfo}
          setTenantInfo={setTenantInfo}
        />
      ) : null}
      {statusModal?.show ? (
        <ConfirmModal
          cancelText={statusModal.cancelText}
          confirmText={statusModal.confirmText}
          isAlert={statusModal.isAlert}
          title={statusModal.title}
          message={statusModal.message}
          handleCancel={statusModal.handleCancel}
          handleConfirm={statusModal.handleConfirm}
        />
      ) : null}
    </React.Fragment>
  );
};

export default TenantsList;
