/**@description displays property view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import React, { useState, useEffect } from "react";
import CustomButton from "../../../components/custom-button";
import CustomTable from "../../../components/custom-table";
import Loader from "../../../components/loader/Loader";
import TenantListEmpty from "../../../components/tenant-list-empty";
import ConfirmModal from "../../../components/confirm-modal";
import "./Properties.scss";
import { useDispatch } from "react-redux";
import AddMultiFamily from "../add-new-multifamily-operator/AddMultiFamily";
import {
  deletebulkProperties,
  createMultipleProperty,
} from "../../../redux/actions/propertyListActions";
import { getAllMFO } from "../../../redux/actions/usersActions";

import {
  PROPERTYMFOTABLE_HEADINGS,
  ADD_MULTI_FAMILY_PROPERTY,
  COUNTRY_CODE,
} from "../../../routes/Constants";
import { ADD_MULTIFAMILY_OPERATOR } from "../../../components/Constants";
import {
  getFormattedPhoneNum,
  reformatPhoneNumber,
} from "../../../utils/helperMethods";
import { FormControl, Select, MenuItem } from "@mui/material";
import { Roles } from "../../../config";
import { createbulkMFO } from "../../../redux/actions/usersActions";
import _ from 'lodash';

const Properties = (props) => {
  const [propertiesTableData, setpropertiesTableData] = useState([]);
  const propertytableHeadingsData = [...PROPERTYMFOTABLE_HEADINGS];
  const [selected, setSelected] = useState([]);
  const [showAddProperty, setShowAddProperty] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [statusModal, setStatusModal] = useState({});
  const [PropertyData, setPropertyData] = useState({});
  const [allData, setAllData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const [managerData, setmanagerData] = useState({});
  const [propertyEmail, setPropertyEmail] = useState("");

  const [mfoDetails, setmfodetails] = useState([...ADD_MULTIFAMILY_OPERATOR]);
  const [propertyDetails, setPropertyDetails] = useState([
    ...ADD_MULTI_FAMILY_PROPERTY,
  ]);

  const [mfoNames, setmfoNames] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [selectedMfoName, setSelectedMfoName] = useState("");
  /**@description Fetching Properties and setting data as per UI Functionality
   */
  const fetchProperties = async () => {
    setShowLoader(true);
    const MFO_data = await dispatch(getAllMFO());

    let MFO_DATA = [];
    (MFO_data || []).forEach((mfo) => {
      MFO_DATA.push(mfo);
    });

    MFO_DATA.forEach((mfo) => {
      if (mfo.properties.length) {
        mfo.propertyIds = mfo.properties?.map((properties) => properties?._id);
        mfo.propertyManager = mfo.properties?.map(
          (properties) => properties?.manager?.name
        );
        mfo.properties = mfo.properties?.map((properties) => properties?.name);
      }
    });
    setpropertiesTableData([...MFO_DATA]);
    setmfoNames([...MFO_DATA]);
    setShowLoader(false);
  };

  useEffect(() => {
    fetchProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    filterFunction(propertyEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertiesTableData]);

  /**@description handle add Property Data Functionality
   */
  const handleAddOrEditProperty = (add, tData = {}) => {
    setIsAdd(!!add);
    setShowAddProperty(true);
  };

  /*@description handle close  modal*/
  const handleCloseAddProperty = () => {
    setShowAddProperty(false);
  };

  /**@description handle changing Mfo Data Functionality
   */
  const handleChangeMfo = ({ key, value}) => {
    let mfoData = _.cloneDeep(mfoDetails)
    mfoData[key] = value;
    setmfodetails([...mfoData]);
    //setShowMfo(false);
  };

  /**@description Handle change Property Data Functionality
   */
  const handleChangeproperty = ({ key, value, data }) => {
    const index = propertyDetails.findIndex((data) => data.key === key);
    const propertyItem = propertyDetails[index];
    if (key === "apartmentTypes") {
      propertyItem.value = value;
    }
    propertyDetails[key] = value;
    setPropertyDetails([...propertyDetails]);
    if(data?._id){
      setmanagerData({ ...data });
    }
  };

  const editPropertyDetails = (propertyIndex) => {
    const propertyInfo = allData[propertyIndex];
    propertyDetails.forEach((info) => {
      info.hasError = false;
      if (info.isManager) {
        info.value =
          info.key === "Mgrname"
            ? propertyInfo.manager.name
            : propertyInfo.manager[info.key];
        if (info.key === "phoneNumber") {
          info.value = getFormattedPhoneNum(info.value);
        }
        info.isDisable =
          !!propertyInfo.manager._id &&
          ["Mgrname", "phoneNumber"].includes(info.key);
      } else {
        info.value = propertyInfo[info.key];
      }
    });
    setmanagerData({ ...propertyInfo.manager });
    setPropertyDetails([...propertyDetails]);
  };

  /**@description Handle add Property Details Data Functionality
   */
  const addpropertyDetails = (propertyIndex) => {
    const propertyData = { manager: {} };
    propertyDetails.forEach((property) => {
      const managerDetails = ["Mgrname", "email", "phoneNumber"].includes(
        property.key
      )
        ? true
        : false;
      if (managerData._id) {
        propertyData.manager = managerData;
        if (!managerDetails) {
          propertyData[property.key] = property.value;
        }
      } else if (managerDetails) {
        const key = property.key === "Mgrname" ? "name" : property.key;
        propertyData.manager[key] = property.value;
      } else {
        propertyData[property.key] = property.value;
      }
    });
    if (propertyIndex > -1) {
      allData[propertyIndex] = propertyData;
    } else {
      allData.push(propertyData);
    }
    setAllData([...allData]);
    propertyDetails.forEach((data) => {
      data.value = "";
    });
    setPropertyDetails([...propertyDetails]);
    setmanagerData({})
  };

  /**@description Handle Delete Property Data Functionality
   */
  const deleteproperty = (propertyIndex) => {
    allData.splice(propertyIndex, 1);
    setAllData([...allData]);
  };

  /**@description Handle Submit  Functionality
   */
  const handleAddMfoData = async () => {
    setShowLoader(true);
    const managersInfo = [];
    let propertiesData = [];
    allData.forEach((m) => {
      const property = { ...m };
      property.createdBy = selected.toString();
      if (m.manager?._id) {
        property.manager = m.manager._id;
      } else {
        const email = m.manager?.email;
        const isExist = managersInfo.find((x) => x.email === email);
        if (!isExist) {
          managersInfo.push({
            name: m?.manager?.name,
            email: email,
            phoneNumber: `${COUNTRY_CODE}${reformatPhoneNumber(
              m?.manager?.phoneNumber
            )}`,
            role: Roles.manager,
          });
        }
      }
      propertiesData.push(property);
    });
    const { status, data, message } = await createbulkMFO(managersInfo);
    if (status) {
      propertiesData.forEach((property) => {
        if (property.manager?.email) {
          const manager = data.find((x) => x.email === property.manager.email);
          property.manager = manager?._id;
        }
      });
      allData.forEach((property) => {
        if (!property.manager._id) {
          property.manager = data.find(
            (manager) => manager.email === property.manager.email
          );
        }
      });
      propertiesData = propertiesData.filter(
        (x) => typeof x.manager === "string"
      );
      const { status: propStatus } = await createMultipleProperty(
        propertiesData
      );
      handleShowModal({
        cancelText: "Ok",
        title: propStatus ? `Success` : `Failed`,
        message: propStatus
          ? `Property Details Added successfully`
          : "Failed to Add Property Details, please try again",

        isAlert: true,
      });
      fetchProperties();
      setShowAddProperty(false);
      mfoDetails.forEach((data) => {
        data.value = "";
      });
      setmfodetails([...mfoDetails]);
      setPropertyData({});
      setAllData([]);
    } else {
      handleShowModal({
        cancelText: "Ok",
        title: `Failed to create managers`,
        message,
        isAlert: true,
      });
    }
    setShowLoader(false);
  };

  /**@description Handle Hide Model Functionality
   */
  const handleHideModal = () => {
    statusModal.show = false;
    setStatusModal({ ...statusModal });
  };

  /**@description Handle Show Model Functionality
   */
  const handleShowModal = ({
    cancelText,
    confirmText,
    isAlert,
    title,
    message,
    handleConfirm = () => {},
  }) => {
    statusModal.show = true;
    statusModal.cancelText = cancelText || "Cancel";
    statusModal.confirmText = confirmText || "Ok";
    statusModal.isAlert = isAlert || false;
    statusModal.title = title || "";
    statusModal.message = message || "";
    statusModal.handleConfirm = handleConfirm;
    statusModal.handleCancel = handleHideModal;
    setStatusModal({ ...statusModal });
  };

  /**@description handles delete Property*/
  const handleDeleteProperty = async () => {
    if (selected?.length) {
      handleHideModal();
      const selectedProperties = [];
      selected.forEach((mfo) => {
        const mfoData = propertiesTableData.find((x) => x._id === mfo);
        if (mfoData.propertyIds?.length > 0) {
          selectedProperties.push(...mfoData.propertyIds);
        }
      });
      if (selectedProperties?.length) {
        setShowLoader(true);
        const { status } = await deletebulkProperties(selectedProperties);
        setShowLoader(false);

        if (status) {
          fetchProperties();
          setSelected([]);
        }
        handleShowModal({
          cancelText: "Ok",
          title: status ? `Success` : `Failed`,
          message: status
            ? ` propertie(s) deleted successfully!`
            : `Failed to delete propertie(s), please try again.`,
          isAlert: true,
        });
      } else {
        handleShowModal({
          cancelText: "Ok",
          title: "No Properties",
          message: "No properties added for this mfo to delete.",
          isAlert: true,
        });
      }
    }
  };

  /**@description Handle Show Delete Model Functionality
   */
  const handleShowDeleteModal = () => {
    if (selected?.length) {
      handleShowModal({
        confirmText: "Delete",
        title: `Delete  Propert${selected.length > 1 ? "ies" : "y"}`,
        message: `Are you sure you want to delete the selected Propert${
          selected.length > 1 ? "ies" : "y"
        }?`,
        handleConfirm: handleDeleteProperty,
      });
    }
  };

  /**@description Handling selected Data from Rows*/
  const handlepropertyListSelect = (selectedData) => {
    setSelected(selectedData);
  };

  /*@description Displaying delete Button*/
  const displayingDeleteButton = () => {
    return (
      <CustomButton
        title={"Delete Property"}
        className='propertyAdminDeletebutton'
        additionalSx={{
          opacity: selected?.length === 0 ? 0.5 : 1,
        }}
        handleButtonClick={handleShowDeleteModal}
      />
    );
  };

  const displayTableData = () => {
    if (sortedData.length > 0) {
      return sortedData;
    } else {
      return propertiesTableData;
    }
  };

  /**@description Displaying Property Table Component */
  const displayingPropertyTable = () => {
    return (
      <div className='propertyAdminTable'>
        <CustomTable
          rows={displayTableData() || []}
          tableHeading={propertytableHeadingsData}
          handleSelection={handlepropertyListSelect}
          selected={selected}
          maxHeight={window.innerHeight * 0.6}
          propertiesStyle={true}
          customPadding={true}
        />
      </div>
    );
  };

  const filterFunction = (email) => {
    const filteredData = propertiesTableData.filter(
      (property) => property.email === email
    );
    setSortedData([...filteredData]);
  };

  const handleChange = (property) => {
    setSelectedMfoName(property.name);
    setPropertyEmail(property.email)
    filterFunction(property.email);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "12vw",
        marginBottom: "12vh",
      },
    },
  };

  const displaymfoDropdown = () => {
    return (
      <FormControl sx={{ m: 1, width: "12vw", mt: 3, fontStyle: "normal" }}>
        <Select
          displayEmpty
          value={"Select MFO"}
          onChange={(e) => handleChange(e.target.value)}
          renderValue={() => {
            if (selectedMfoName) {
              return <em className={"selectStyle"}>{selectedMfoName}</em>;
            } else {
              return <em className={"selectStyle"}>Show All</em>;
            }
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem
            value='Show All'
            className={"selectStyle"}
            style={{ fontWeight: "bold" }}
          >
            <em className={"selectStyle"}>Show All</em>
          </MenuItem>
          {(mfoNames || []).map((property) => (
            <MenuItem
              key={property._id}
              value={property}
              className={"selectStyle"}
            >
              {property.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const displayPropertyButton = () => {
    return (
      <CustomButton
        title={"Add Property"}
        className='propertyAddbutton'
        additionalSx={{
          opacity: selected?.length === 1 ? 1 : 0.5,
        }}
        handleButtonClick={handleaddproperty}
      />
    );
  };

  const handleaddproperty = () => {
    if (selected?.length === 1) {
      setShowAddProperty(true);
      propertyDetails.forEach((data) => {
        data.value = "";
        data.hasError = false;
      });
      setAllData([]);
      setPropertyDetails([...propertyDetails]);
    }
  };

  /**@description Displaying Body Functionality
   */
  const displayBody = () => {
    return propertiesTableData?.length ? (
      <div className='propertiesMaintable'>
        <div className='propertyAdminButtons'>
          <div style={{ marginBottom: "2vh" }}> {displaymfoDropdown()} </div>
          {displayPropertyButton()}
          {displayingDeleteButton()}
        </div>
        {displayingPropertyTable()}
      </div>
    ) : (
      <div className={"tenantListEmpty"}>
        <TenantListEmpty
          width={"75%"}
          header='You have no properties added to your list'
          buttonText='Add New Property'
          canAdd
          handleButtonClick={() => {
            handleAddOrEditProperty(true);
          }}
        />
      </div>
    );
  };

  return (
    <div className='propertyAdminOverview'>
      {displayBody()}
      {showAddProperty ? (
        <AddMultiFamily
          isAdd={isAdd}
          handleClose={handleCloseAddProperty}
          handleChangeMfo={handleChangeMfo}
          handleChangeproperty={handleChangeproperty}
          handleSubmit={handleAddMfoData}
          propertyData={PropertyData}
          handleAddMfoData={handleAddMfoData}
          addpropertyDetails={addpropertyDetails}
          editPropertyDetails={editPropertyDetails}
          allData={allData}
          deleteproperty={deleteproperty}
          setAllData={setAllData}
          propertyDetails={propertyDetails}
          mfoDetails={mfoDetails}
          isDisable={true}
          isProperty={true}
        />
      ) : null}
      {statusModal?.show ? (
        <ConfirmModal
          cancelText={statusModal.cancelText}
          confirmText={statusModal.confirmText}
          isAlert={statusModal.isAlert}
          title={statusModal.title}
          message={statusModal.message}
          handleCancel={statusModal.handleCancel}
          handleConfirm={statusModal.handleConfirm}
        />
      ) : null}
      <Loader show={showLoader} />
    </div>
  );
};

export default Properties;
