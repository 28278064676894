/**@description displays property view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";

import CustomuserInput from "../../components/custom-input";
import Custombuton from "../../components/custom-button";
import ConfirmModal from "../../components/confirm-modal";
import { RESETPASSWORD_DETAILS } from "../Constants";
import { resetPassword } from "../../redux/actions/user-actions/userAction";
import "./ResetPassword.scss";
import Loader from "../../components/loader/Loader";

const ResetPasswordScreen = (props) => {
  const [passwordInfo, setPasswordInfo] = useState({
    ...RESETPASSWORD_DETAILS,
  });
  const history = useHistory();
  const login = useSelector((state) => state.login);
  const { userDetails } = login;
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  /**@description handles changes in user Details*/
  const onchange = ({ key, value }) => {
    passwordInfo[key].value = value;
    passwordInfo[key].hasError = false;
    setPasswordInfo({ ...passwordInfo });
    setErrorMessage(null);
  };

  /**@description Handles submit user Details  */
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const pswd = passwordInfo.newpassword.value;
    const cnfrmpaswd = passwordInfo.confirmpassword.value;
    if (!userDetails?.data?.Session) {
      setErrorMessage("Couldnt reset password. Please login again.");
    } else if (pswd === cnfrmpaswd && pswd?.length === 8) {
      setLoading(true);
      const { status, message } = await resetPassword(
        userDetails.username,
        pswd,
        userDetails.data.Session
      );
      setLoading(false);
      setIsPasswordUpdated(status);
      setErrorMessage(message || null);
    } else {
      setErrorMessage(
        pswd?.length === 8
          ? "Password and Confirm Password must be same"
          : "Password must contain 8 characters"
      );
    }
  };

  /**@description Display userDetails */
  const displaySignup = () => {
    return (
      <form onSubmit={onSubmitHandler} className='maindiv'>
        {Object.values(passwordInfo).map((password, index) => (
          <CustomuserInput
            dataItem={password}
            className='SignupinputStyle'
            handleInput={onchange}
            name={index}
            value={password.value}
            key={`signupForm${password.title}`}
          />
        ))}
        <Custombuton title={"Reset Password"} className='button' />
      </form>
    );
  };

  const handleGoToHome = () => {
    history.push("/app");
  };

  /**@description Display Alert Modal */
  const displaySuccessAlert = () => {
    return (
      <ConfirmModal
        cancelText='Go to Home'
        handleCancel={handleGoToHome}
        isAlert={true}
        message={"Password updated successfully"}
        title={"Success"}
      />
    );
  };

  return (
    <div className='maintopdiv'>
      <img
        src='/images/shared/fullcolor.png'
        alt='valeticon'
        className='valetIcon'
      />
      {loading && <Loader />}
      <div className='passwordheading'>Reset Password</div>
      <div className='passwordalerts'>
        {errorMessage && !loading && (
          <Alert severity='error' sx={{ position: "absolute", top: "14px" }}>
            {errorMessage}
          </Alert>
        )}
        {isPasswordUpdated && displaySuccessAlert()}
        {displaySignup()}
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
