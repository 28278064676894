/**@description displays property view
 *@author Suresh Chekka
 *@version 1.0.0
 */

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./AdminOverview.scss";
import MarketingMaterial from "./marketing-material/MarketingMaterial";
import MfoList from "./mfolist/MfoList";
import Properties from "./properties/Properties";
import AdminZipcodes from "./admin-zipcodes/AdminZipcodes";
import CustomButton from "../../components/custom-button";
import Holidays from "./holidays/Holidays";
import { handleLogout as logoutUser } from "../../utils/authentication";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { tabs } from "../Constants";
import AppVersion from "./app-version/appVersion";
import ManagerDetailsList from "../manager-details/ManagerDetails";
import CouponCodes from "./coupon-codes/CouponCodes";
import Notification from "./notifications/Notification";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          width: "24%",
          "&:hover": {
            backgroundColor: "#fff",
            Color: "rgba(0, 125, 174, 1)",
          },
        },
      },
    },
  },
});

const AdminOverview = () => {
  const [activeTab, setActivetab] = useState(0);

  const history = useHistory();

  /**@description Admin Logout Functionality
   */
  const handleLogout = () => {
    const logoutURL = logoutUser();
    history.push(logoutURL);
  };

  /**@description displays Logout Button UI
   */

  const displayLogout = () => {
    return (
      <ThemeProvider theme={theme}>
        <CustomButton
          startIcon={
            <img
              src="/images/shared/logout.svg"
              alt="Logout Icon"
              className={"adminlogoutIcon"}
            />
          }
          title={"Logout"}
          className="logout"
          handleButtonClick={handleLogout}
          additionalSx={{
            "& .MuiButton-startIcon": {
              paddingRight: "0.25vw",
            },
            "&:hover": {
              backgroundColor: "#fff",
              color: "rgba(0, 125, 174, 1)!important",
            },
            fontSize: "1.25vw",
            padding: "0.25vh 2vw",
            backgroundColor: "rgba(0, 125, 174, 1)",
            color: "#fff !important",
          }}
        />
      </ThemeProvider>
    );
  };

  /**@description displays tabs UI
   */
  const displayTabs = () => {
    return tabs.map((tab, index) => (
      <div
        className={`tab1 ${activeTab === index ? "activeTab" : ""}`}
        role="presentation"
        onClick={() => {
          setActivetab(index);
        }}
        key={`displayTabs${tab}`}
      >
        {tab}
      </div>
    ));
  };

  /**@description displays tab data based on selected tab
   */
  const getTabData = () => {
    switch (activeTab) {
      case 0:
        return <MfoList setactivetab={setActivetab} />;
      case 1:
        return <ManagerDetailsList hideHeader={true} />;
      case 2:
        return <AdminZipcodes />;
      case 3:
        return <Properties />;
      case 4:
        return <MarketingMaterial />;
      case 5:
        return <AppVersion />;
      case 6:
        return <CouponCodes />;
      case 7:
        return <Holidays />;
      case 8:
        return <Notification />;
      default:
        return null;
    }
  };

  return (
    <div className="adminOverview">
      <div className="navbar_header">
        <div className={"adminTabContainers"}>{displayTabs()} </div>
        <div className="adminLogout">{displayLogout()}</div>
      </div>
      {getTabData()}
    </div>
  );
};

export default AdminOverview;
